<template>
  <a-card :bordered='false'>
    <!-- 查询区域 -->
    <div v-if='!showButtons'>
      <div class='table-page-search-wrapper'>
        <a-form layout='inline' @keyup.enter.native='searchQuery'>
          <div style='display: flex;flex-wrap: wrap;'>


            <a-form-item label='楼栋' v-if='showBuilding' style='margin-right: 10px'>
              <!--              <common-selector url='/cum/cumBuilding/list' showName='id' :showItem="['id']"-->
              <!--                               title='选择楼栋'-->
              <!--                               v-model='queryParam.buildingId' :multi='false'></common-selector>-->
              <InputSelect v-model='queryParam.buildingId' show-name='buildingNumber' send-params='id' other-show-name='#${id}'
                           url='dbcp/city/propertyManagement/buildingList/CumBuildingList.vue'
                           type='radio' style='width: 200px' :isEdit='buildingId?true:false'></InputSelect>
            </a-form-item>


            <a-form-item label='楼层' style='margin-right: 10px'>
              <a-input placeholder='请输入楼层' v-model='queryParam.floor'></a-input>
            </a-form-item>


            <a-form-item label='房号' style='margin-right: 10px'>
              <a-input placeholder='请输入房号' v-model='queryParam.roomNo'></a-input>
            </a-form-item>


            <a-form-item label='权利人' style='margin-right: 10px'>
              <!--              <common-selector url='/cum/cumOwner/list' showName='name' :showItem="['name']" title='权利人选择器'-->
              <!--                               v-model='queryParam.rightHolder' :multi='false'></common-selector>-->
              <InputSelect v-model='queryParam.rightHolder' show-name='name' send-params='id'
                           url='dbcp/city/cumOwner/CumOwnerList.vue'
                           type='radio' style='width: 200px' :isEdit='queryParam.rightHolder?true:false'></InputSelect>
            </a-form-item>
          </div>


          <a-row>
            <a-col :xl='6' :lg='7' :md='8' :sm='24'>
              <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
                <a-button type='primary' @click='searchQuery' icon='search'>查询</a-button>
                <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset')
                  }}</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

    <!-- 查询区域-END -->

      <!-- 操作按钮区域 -->
      <div class='table-operator'>
        <a-button @click='handleAdd(queryParam.buildingId?{"buildingId":queryParam.buildingId,"rightHolder":queryParam.rightHolder,"projectId":queryParam.projectId}
                  :{"rightHolder":queryParam.rightHolder,"projectId":queryParam.projectId})'
                  type='primary' icon='plus' v-has="'cumUnit:create'">{{ $t('create') }}</a-button>
        <!--      <a-button type="primary" icon="download" @click="handleExportXls('单元管理')">{{ $t('export') }}</a-button>-->
        <!--      <a-upload name="file" :showUploadList="false" :multiple="false" :headers="tokenHeader" :action="importExcelUrl" @change="handleImportExcel">-->
        <!--        <a-button type="primary" icon="import">{{ $t('import') }}</a-button>-->
        <!--      </a-upload>-->
        <!--      <a-dropdown v-if="selectedRowKeys.length > 0">-->
        <!--        <a-menu slot="overlay">-->
        <!--          <a-menu-item key="1" @click="batchDel"><a-icon type="delete"/>{{ $t('delete') }}</a-menu-item>-->
        <!--        </a-menu>-->
        <!--        <a-button style="margin-left: 8px"> {{ $t('batchOperation') }} <a-icon type="down" /></a-button>-->
        <!--      </a-dropdown>-->
      </div>
    </div>
    <a @click="handleShowButtons" style="margin-left: 8px">
      {{ showButtons ?  $t('expend') : $t('collapse') }}
      <a-icon :type="showButtons ?  'down' : 'up'"/>
    </a>

    <!-- table区域-begin -->
    <div>
      <setting-columns :columns='columns' @changeRealColumns='(data) => columns = data'></setting-columns>
      <a-table
        ref="table"
        :size="size"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        :rowSelection="type?{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type,onSelect:handleSelection,onSelectAll:handleSelectAll}:null"
        @change="handleTableChange">
        <!--        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"-->

        <template slot='rowIndex' slot-scope='text,record'>
          {{ record.buildingNumber || '#' + record.id }} {{ record.roomNo ? '-'+record.roomNo : '' }}
        </template>
        <template slot='buildingId' slot-scope='text,record'>
          <a @click='handleBuildingDetail(text)'>{{ record.buildingNumber }}</a>
        </template>
        <template slot='projectId' slot-scope='text,record'>
          <a @click='turnProjectDetail(record.projectId)' >{{text}}</a>
        </template>
        <span slot='surveyingStatus' slot-scope='text,record' v-if='text'>
            <a-tag :color='getSurveyingStatusColor(record.surveyingStatus)'>{{text}}</a-tag>
        </span>

        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="imgSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noPicture') }}</span>
          <img v-else :src="getImgView(text)" height="25px" alt="" style="max-width:80px;font-size: 12px;font-style: italic;"/>
        </template>
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noFile') }}</span>
          <a-button
            v-else
            :ghost="true"
            type="primary"
            icon="download"
            size="small"
            @click="uploadFile(text)">
            {{ $t('download') }}
          </a-button>
        </template>

        <span slot="action" slot-scope="text, record">
          <div style='display: flex;justify-content: space-evenly;align-items: center'>
                <a @click='handleEdit(record)'>{{ $t('edit') }}</a>
            <a-divider type="vertical" />
                <list-actions
                  :record='record'
                  @view='handleView'
                  @edit='handleEdit'
                  @delete='handleDelete'
                  @action='$refs.detailForm.handleActionClick'
                  @copy='handleCopy'
                ></list-actions>
            <a-divider type="vertical" />
            <a-popconfirm :title="$t('confirmDeleteAlert')" @confirm="() => handleDelete(record.id)">
              <a>{{ $t('delete') }}</a>
            </a-popconfirm>
            </div>
        </span>

      </a-table>
    </div>


    <cumUnit-modal ref='modalForm' @ok='modalFormOk'></cumUnit-modal>
    <cumUnit-detail ref='detailForm' @action='handleActionClick' @close='handleViewModalClose'
                    @loadData='loadData' @clearRouteQuery='clearRouteQuery'></cumUnit-detail>

    <cumBuilding-detail ref='buildingDetailForm'></cumBuilding-detail>
    <cum-project-detail ref='projectDetailForm'></cum-project-detail>
  </a-card>
</template>

<script>
import { cumUnitApi } from './modules/api/CumUnit'
import '@/assets/less/TableExpand.less'
import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
import CumUnitModal from './modules/CumUnitModal'
import CumUnitDetail from './modules/CumUnitDetail'
import { ListActions } from 'dbcp-frontends-common/lib/components'
import { CommonSelector } from 'dbcp-frontends-common/lib/components'
import { filterMultiDictText } from '@/components/dict/JDictSelectUtil'
import { SettingColumns } from 'dbcp-frontends-common/lib/components'
import CumBuildingDetail from '../buildingList/modules/CumBuildingDetail'
import { getAction } from 'dbcp-frontends-common/lib/api'
import InputSelect from '@/components/dbcp/InputSelect'
import { getSurveyingStatusColor } from '@/utils/util'
import { SelectorMixin } from 'dbcp-frontends-common/lib/mixins'
import CumProjectDetail from '../../projectManagement/modules/CumProjectDetail'
export default {
  name: 'CumUnitList',
  mixins: [JeecgListMixin, mixinDevice, ViewMixin,SelectorMixin],
  components: {
    CumUnitModal,
    CumUnitDetail,
    ListActions,
    CommonSelector,
    SettingColumns,
    CumBuildingDetail,
    InputSelect,
    CumProjectDetail
  },
  data() {
    return {
      description: '单元管理管理页面',
      // 表头
      columns: [
        {
          title: '栋号 - 房号',
          dataIndex: 'id',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          scopedSlots: { customRender: 'rowIndex' }
        },
        {
          title: '项目',
          align: 'center',
          dataIndex: 'projectId_dictText',
          scopedSlots: { customRender: 'projectId' }
        },
        {
          title: '楼栋',
          align: 'center',
          dataIndex: 'buildingId',
          scopedSlots: { customRender: 'buildingId' }
        },
        {
          title: '楼层',
          align: 'center',
          dataIndex: 'floor'
        },
        {
          title: '房号',
          align: 'center',
          dataIndex: 'roomNo'
        },
        {
          title: '产权证建筑面积(㎡)',
          align: 'center',
          dataIndex: 'certificateConstructionArea'
        },
        {
          title: '加建面积(㎡)',
          align: 'center',
          dataIndex: 'increasedConstructionArea'
        },
        {
          title: '权利人',
          align: 'center',
          dataIndex: 'rightHolder_dictText'
        },
        {
          title: '测绘编号',
          align: 'center',
          dataIndex: 'surveyedNo'
        },
        {
          title: '测绘面积(㎡)',
          align: 'center',
          dataIndex: 'surveyedArea'
        },
        {
          title: '测量状态',
          align: 'center',
          dataIndex: 'surveyingStatus_dictText',
          scopedSlots: { customRender: 'surveyingStatus' }
        },
        {
          title: '备注',
          align: 'center',
          dataIndex: 'remark'
        },
        {
          title: '创建人',
          align: 'center',
          dataIndex: 'addBy_dictText'
        },
        {
          title: '创建时间',
          align: 'center',
          dataIndex: 'addTime'
        },
        {
          title: '更新人',
          align: 'center',
          dataIndex: 'updateBy_dictText'
        },
        {
          title: '更新时间',
          align: 'center',
          dataIndex: 'updateTime'
        },
        {
          title: this.$i18n.tc('action'),
          dataIndex: 'action',
          align: 'center',
          // fixed:"right",
          width: 147,
          scopedSlots: { customRender: 'action' }
        }
      ],
      url: cumUnitApi.url,
      dictOptions: {},
      /* 禁用created列表数据加载 */
      disableMixinCreated: true,
      // selectedRowKeys:[],
      // selectedList:[],
      getSurveyingStatusColor
    }
  },
  props: {
    showBuilding: {
      type: Boolean,
      default: true
    },
    buildingId: {
      type: Number | String
    },
    type:{
      type:String,
    },
    size:{
      type:String,
      default: 'default'
    },
    //是否显示表上面的按钮
    showButtons:{
      type: Boolean,
      default: false
    },
  },
  created() {
    this.queryParam.buildingId = this.buildingId
    this.dataSource = []
    this.loadData();
  },
  watch: {
    'buildingId': {
      handler:function(newV){
        console.log('newV23132', newV)
        this.queryParam.buildingId = newV
        this.dataSource = []
        this.loadData();
      }
    },
    '$attrs.rightHolder':{
      immediate:true ,
      handler : function(val){
        if(val){
          this.queryParam.rightHolder = val ;
          this.loadData();
        }
      }
    },
    '$attrs.projectId':{
      immediate:true,
      handler:function(val){
        console.log("attrs.projectId",val)
        if(val){
          this.queryParam.projectId = val;
          this.loadData();
        }
      }
    }
  },
  computed: {
    importExcelUrl: function() {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    }
  },
  methods: {
    initDictConfig() {
    },
    // initListData(record){
    //   console.log(9999232,record);
    //   this.currentRecord = record
    //   this.queryParam = {};
    //   this.queryParam = Object.assign({},record,this.queryParam);
    //   this.loadData();
    // },
    searchReset() {
      this.queryParam = {} ;
      let record = {rightHolder:this.$attrs.rightHolder};
      this.queryParam = Object.assign({},record,this.queryParam);
      this.loadData();
    },
    handleBuildingDetail(id){
      getAction("/cum/cumBuilding/list",{id:id}).then(res => {
        console.log("获取楼栋信息",res.result.records);
        this.$refs.buildingDetailForm.view(res.result.records[0])
      })
    },
    handleShowButtons(){
      this.showButtons = !this.showButtons;
    },
    turnProjectDetail(id){
      getAction("/cum/cumProject/list",{id}).then(res=>{
        let data = res.result.records[0];
        this.$nextTick(()=>{
          this.$refs.projectDetailForm.view(data);
        })

      })
    }
  }
}
</script>
<style scoped>
@import '~@assets/less/common.less';
</style>
