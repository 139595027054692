<template>
  <a-card :bordered='false'>
    <!-- 查询区域 -->
    <div class='table-page-search-wrapper'>
      <a-form layout='inline' @keyup.enter.native='searchQuery'>
        <a-row :gutter='24'>
          <a-col :xl='6' :lg='7' :md='8' :sm='24' v-if='showUnit'>
            <a-form-item label='单元'>
              <common-selector url='/cum/cumUnit/list' showName='id' :showItem="['id']" title='单元选择器'
                               v-model='queryParam.unitId' :multi='false'></common-selector>
            </a-form-item>
          </a-col>
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <a-form-item label='附属类型'>
              <j-dict-select-tag placeholder='请选择附属类型' v-model='queryParam.type' dictCode='cum_surveyed_type'
                                 :showManage='false' />
            </a-form-item>
          </a-col>
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>查询</a-button>
              <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset')
                }}</a-button>
              <a @click='handleToggleSearch' style='margin-left: 8px'>
                {{ toggleSearchStatus ? $t('collapse') : $t('expend') }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'" />
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class='table-operator'>
      <a-button @click='handleAdd' type='primary' icon='plus' v-has="'cumUnitSubsidiary:create'">{{ $t('create') }}
      </a-button>
      <!--      <a-button type="primary" icon="download" @click="handleExportXls('单元附属管理')">{{ $t('export') }}</a-button>-->
      <!--      <a-upload name="file" :showUploadList="false" :multiple="false" :headers="tokenHeader" :action="importExcelUrl" @change="handleImportExcel">-->
      <!--        <a-button type="primary" icon="import">{{ $t('import') }}</a-button>-->
      <!--      </a-upload>-->
      <a-dropdown v-if='selectedRowKeys.length > 0'>
        <a-menu slot='overlay'>
          <a-menu-item key='1' @click='batchDel'>
            <a-icon type='delete' />
            {{ $t('delete') }}
          </a-menu-item>
        </a-menu>
        <a-button style='margin-left: 8px'> {{ $t('batchOperation') }}
          <a-icon type='down' />
        </a-button>
      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>
      <setting-columns :columns='columns' @changeRealColumns='(data) => columns = data'></setting-columns>
      <a-table
        ref='table'
        size='middle'
        bordered
        rowKey='id'
        :columns='columns'
        :dataSource='dataSource'
        :pagination='ipagination'
        :loading='loading'
        :rowSelection='{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type}'
        class='j-table-force-nowrap'
        @change='handleTableChange'>

        <template slot='htmlSlot' slot-scope='text'>
          <div v-html='text'></div>
        </template>
        <template slot='imgSlot' slot-scope='text'>
          <span v-if='!text' style='font-size: 12px;font-style: italic;'>{{ $t('noPicture') }}</span>
          <img v-else :src='getImgView(text)' height='25px' alt=''
               style='max-width:80px;font-size: 12px;font-style: italic;' />
        </template>
        <template slot='fileSlot' slot-scope='text'>
          <span v-if='!text' style='font-size: 12px;font-style: italic;'>{{ $t('noFile') }}</span>
          <a-button
            v-else
            :ghost='true'
            type='primary'
            icon='download'
            size='small'
            @click='uploadFile(text)'>
            {{ $t('download') }}
          </a-button>
        </template>

        <span slot='action' slot-scope='text, record'>
          <div style='display: flex;justify-content: space-evenly;align-items: center'>
                <a @click='handleEdit(record)'>{{ $t('edit') }}</a>
            <a-divider type="vertical" />
                <list-actions
                  :record='record'
                  @view='handleView'
                  @edit='handleEdit'
                  @delete='handleDelete'
                  @action='$refs.detailForm.handleActionClick'
                  @copy='handleCopy'
                ></list-actions>
            </div>
        </span>

      </a-table>
    </div>


    <cumUnitSubsidiary-modal ref='modalForm' @ok='modalFormOk'></cumUnitSubsidiary-modal>
    <cumUnitSubsidiary-detail ref='detailForm' @action='handleActionClick' @close='handleViewModalClose'
                              @loadData='loadData' @clearRouteQuery='clearRouteQuery'></cumUnitSubsidiary-detail>
  </a-card>
</template>

<script>
import { cumUnitSubsidiaryApi } from './modules/api/CumUnitSubsidiary'
import '@/assets/less/TableExpand.less'
import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
import CumUnitSubsidiaryModal from './modules/CumUnitSubsidiaryModal'
import CumUnitSubsidiaryDetail from './modules/CumUnitSubsidiaryDetail'
import { ListActions } from 'dbcp-frontends-common/lib/components'
import { SelectorMixin } from 'dbcp-frontends-common/lib/mixins'
import { JDictSelectTag } from 'dbcp-frontends-common/lib/components'
import { CommonSelector } from 'dbcp-frontends-common/lib/components'
import { filterMultiDictText } from '@/components/dict/JDictSelectUtil'
import { SettingColumns } from 'dbcp-frontends-common/lib/components'

export default {
  name: 'CumUnitSubsidiaryList',
  mixins: [JeecgListMixin, mixinDevice, ViewMixin, SelectorMixin],
  components: {
    JDictSelectTag,
    CumUnitSubsidiaryModal,
    CumUnitSubsidiaryDetail,
    ListActions,
    CommonSelector,
    SettingColumns
  },
  props: {
    showUnit: {
      type: Boolean,
      default: true
    },
    unitId: {
      type: Number | String
    }
  },
  data() {
    return {
      description: '单元附属管理管理页面',
      // 表头
      columns: [
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function(t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '单元',
          align: 'center',
          dataIndex: 'unitId',
          customRender:function(text){
            return "#" + text
          }
        },
        {
          title: '附属类型',
          align: 'center',
          dataIndex: 'type_dictText'
        },
        {
          title: '面积(㎡)',
          align: 'center',
          dataIndex: 'area'
        },
        {
          title: '更新人',
          align: 'center',
          dataIndex: 'updateBy_dictText'
        },
        {
          title: '更新时间',
          align: 'center',
          dataIndex: 'updateTime'
        },
        {
          title: this.$i18n.tc('action'),
          dataIndex: 'action',
          align: 'center',
          // fixed:"right",
          width: 147,
          scopedSlots: { customRender: 'action' }
        }
      ],
      url: cumUnitSubsidiaryApi.url,
      dictOptions: {},
      /* 禁用created列表数据加载 */
      disableMixinCreated: true
    }
  },
  watch:{
    unitId(newV){
      console.log('newV',newV)
      this.queryParam.unitId = newV
      this.dataSource = []
      this.loadData()
    }
  },
  computed: {
    importExcelUrl: function() {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    }
  },
  created() {
    if (this.unitId) {
      this.queryParam.unitId = this.unitId
    }
    this.loadData()
  },
  methods: {
    initDictConfig() {
    },
    handleAdd: function(record) {
      if (this.unitId) {
        record.unitId = this.unitId
        this.$refs.modalForm.selectUnit = false
      }

      this.$refs.modalForm.add(record)
      this.$refs.modalForm.title = this.$i18n.tc('create')
      this.$refs.modalForm.disableSubmit = false
    },
    searchReset(){
      this.queryParam = {
        unitId:this.unitId
      }
      this.loadData(1);
    }
  }
}
</script>
<style scoped>
@import '~@assets/less/common.less';
</style>
