<template>
  <a-modal
    :width="modalWidth"
    :visible="visible"
    :title="''"
    @ok="handleSubmit"
    @cancel="close"
    :cancelText="$t('close')"
    style="margin-top: -70px"
    wrapClassName="ant-modal-cust-warp"
  >
    <a-row :gutter="10" style="background-color: #ececec; padding: 10px; margin: -10px">

      <a-col :md="12" :sm="24">
        <a-card :bordered="false" :title="leftTitle">
          <a-input-search
            :style="{width:'150px',marginBottom:'15px'}"
            v-model="leftQueryParam[leftSearchQuery]"
            @search="onLeftSearch"
          ></a-input-search>
          <a-button @click="onLeftSearch" type="primary" style="margin-left: 20px" icon="search">{{ $t('search') }}</a-button>
          <a-button @click="leftSearchReset(1)" style="margin-left: 20px" icon="redo">{{ $t('reset') }}</a-button>
          <a-table
            ref="table"
            :scroll="scrollTrigger"
            size="middle"
            rowKey="id"
            :columns="leftColumns"
            :dataSource="departTree"
            :pagination="leftIpagination"
            :rowSelection="{selectedRowKeys: selectedLeftIds, onChange: onSelectChange,type: 'radio',onSelect:onLeftSelect}"
            :loading="leftLoading"
            @change="handleTableChange">
          </a-table>
        </a-card>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-card :bordered="false" :title="rightTitle">
          {{ $t('name') }}:
          <a-input-search
            :style="{width:'150px',marginBottom:'15px'}"
            v-model="rightQueryParam[rightSearchQuery]"
            @search="onRightSearch"
          ></a-input-search>
          <a-button @click="onRightSearch" type="primary" style="margin-left: 20px" icon="search">{{ $t('search') }}</a-button>
          <a-button @click="rightSearchReset(1)" style="margin-left: 20px" icon="redo">{{ $t('reset') }}</a-button>
          <!--用户列表-->
          <a-table
            ref="table"
            :scroll="scrollTrigger"
            size="middle"
            rowKey="id"
            :columns="rightColumns"
            :dataSource="rightDataSource"
            :pagination="rightIpagination"
            :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type: getType,onSelect:onSelect}"
            :loading="rightLoading"
            @change="handleTableChange">
          </a-table>
        </a-card>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { filterObj } from 'dbcp-frontends-common/lib/utils'
import { getAction } from 'dbcp-frontends-common/lib/api'
import i18n from '@/locales'

export default {
  name: 'CommonUrlSelectModal',
  components: {},
  props: ['modalWidth', 'multi', 'userIds', 'deptId', 'type', 'leftTreeUrl', 'rightTreeUrl', 'leftQueryMark',
    'rightQueryMark', 'rightColumns', 'leftColumns', 'leftSearchQuery', 'rightSearchQuery','leftTitle','rightTitle'],
  data() {
    return {
      rightQueryParam: {},
      leftQueryParam: {},
      scrollTrigger: {},
      rightDataSource: [],
      selectedRowKeys: [],
      selectRightRows: [],
      rightIpagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return `${range[0]} - ${range[1]} ${this.$i18n.tc('total')} ${total} ${this.$i18n.tc('item')}`
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      leftIpagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return `${range[0]} - ${range[1]} ${this.$i18n.tc('total')} ${total} ${this.$i18n.tc('item')}`
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      selectedLeftIds: [],
      departTree: [],
      visible: false,
      form: this.$form.createForm(this),
      rightLoading: false,
      leftLoading:false,
      expandedKeys: [],
      lastPage: 1,
      selectFlag: false,
      deptSelectable: true,
      selectRightItem: []
    }
  },
  computed: {
    // 计算属性的 getter
    getType: function() {
      return this.multi == true ? 'checkbox' : 'radio'
    }
  },
  watch: {
    userIds: {
      immediate: true,
      handler(newV) {
        console.log('newV', newV)
        if (!newV) {
          this.selectRightItem = []
        }
        this.init()
      }
    }
  },
  created() {
    // 该方法触发屏幕自适应
    this.resetScreenSize()
    this.loadData()
  },
  methods: {
    init() {
      if (this.userIds) {
        // 这里最后加一个 , 的原因是因为无论如何都要使用 in 查询，防止后台进行了模糊匹配，导致查询结果不准确
        let values = this.userIds.split(',') + ','
        console.log('values', values)
        getAction(this.rightTreeUrl, {
          id_IN: values,
          pageNo: 1,
          pageSize: values.length,
          status: 1
        }).then((res) => {
          if (res.success) {
            let selectedRowKeys = []
            let arr = []
            res.result.records.forEach(user => {
              arr.push(user[this.rightQueryMark])
              selectedRowKeys.push(user['id'])
            })
            this.selectedRowKeys = selectedRowKeys
            this.$emit('initComp', arr.join(','))
          }
        })
      } else {
        // JSelectUserByDep组件bug issues/I16634
        this.$emit('initComp', '')
      }
    },
    async loadData(arg) {
      if (arg === 1) {
        this.rightIpagination.current = 1
      }
      console.log(';selectedLeftIds',this.selectedLeftIds)
      if (this.selectedLeftIds && this.selectedLeftIds.length > 0) {
        await this.initQueryRightByLeft(this.selectedLeftIds)
      } else {
        this.rightLoading = true
        let params = this.getRightQueryParams()//查询条件
        console.log('params',params)
        await getAction(this.rightTreeUrl, { ...params, status: 1 }).then((res) => {
          if (res.success) {
            this.rightDataSource = res.result.records
            this.rightIpagination.total = res.result.total
          }
        }).finally(() => {
          this.rightLoading = false
        })
      }
    },
    // 触发屏幕自适应
    resetScreenSize() {
      let screenWidth = document.body.clientWidth
      if (screenWidth < 500) {
        this.scrollTrigger = { x: 800 }
      } else {
        this.scrollTrigger = {}
      }
    },
    showModal() {
      this.visible = true
      this.selectedRowKeys = []
      this.queryDepartTree()
      this.init()

      if (this.deptId) {
        this.selectedLeftIds = [this.deptId]
        this.deptSelectable = false
      } else {
        this.selectedLeftIds = []
        this.deptSelectable = true
      }

      this.loadData()
      this.form.resetFields()
    },
    getRightQueryParams() {
      let param = Object.assign({}, this.rightQueryParam, this.isorter)
      param.field = this.getRightQueryField()
      param.pageNo = this.rightIpagination.current
      param.pageSize = this.rightIpagination.pageSize
      return filterObj(param)
    },
    getRightQueryField() {
      let str = 'id,'
      for (let a = 0; a < this.rightColumns.length; a++) {
        str += ',' + this.rightColumns[a].dataIndex
      }
      return str
    },
    getLeftQueryParams() {
      let param = Object.assign({}, this.leftQueryParam, this.isorter)
      param.field = this.getRightQueryField()
      param.pageNo = this.rightIpagination.current
      param.pageSize = this.rightIpagination.pageSize
      return filterObj(param)
    },
    getLeftQueryField() {
      let str = 'id,'
      for (let a = 0; a < this.leftColumns.length; a++) {
        str += ',' + this.leftColumns[a].dataIndex
      }
      return str
    },
    rightSearchReset(num) {
      let that = this
      if (num !== 0) {
        that.rightQueryParam = {}
        that.selectUserIds = []
        that.selectRightItem = []
        that.selectedLeftIds = []
        that.loadData(1)
      }
      that.selectedRowKeys = []
      that.selectUserIds = []
      that.selectFlag = false
    },
    leftSearchReset(num){
      let that = this
      if (num !== 0) {
        that.leftQueryParam = {}
        that.onLeftSearch()
        that.selectUserIds = []
      }
      that.selectedLeftIds = []
    },
    close() {
      this.rightSearchReset(0)
      this.visible = false
    },
    handleTableChange(pagination, filters, sorter) {
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field
        this.isorter.order = 'ascend' === sorter.order ? 'asc' : 'desc'
      }
      this.rightIpagination = pagination
      this.loadData()
    },
    handleSubmit() {
      let that = this
      this.getSelectUserRows()
      let temp = {}   //用于id判断重复
      let result = []  //最后的新数组
      let arr = []
      this.selectRightItem.map(function(item, index) {
        if (!temp[item.id]) {
          result.push(item)
          temp[item.id] = true
        }
      })

      result.forEach(item => {
        arr.push(item.id)
      })
      this.selectUserIds = arr.join(',')
      that.$emit('ok', result, this.selectUserIds)
      that.rightSearchReset(0)
      that.close()
    },
    //获取选择用户信息
    getSelectUserRows(rowId) {
      let rightDataSource = this.rightDataSource
      let userIds = ''
      this.selectRightRows = []
      for (let i = 0, len = rightDataSource.length; i < len; i++) {
        if (this.selectedRowKeys.includes(rightDataSource[i].id)) {
          this.selectRightRows.push(rightDataSource[i])
          userIds = userIds + ',' + rightDataSource[i].id
        }
      }
      // this.selectUserIds = userIds.substring(1);

    },
    // 点击树节点,筛选出对应的用户
    onLeftSelect(selectedLeftIds, selected, selectedRows) {
      console.log('selectedLeftIds', selectedLeftIds)
      console.log('this.selectRightItem', this.selectRightItem)
      selectedLeftIds = [selectedLeftIds.id]
      if (selectedLeftIds[0] != null) {
        this.initQueryRightByLeft(selectedLeftIds[0]) // 调用方法根据选选择的id查询用户信息
        if (this.selectedLeftIds[0] !== selectedLeftIds[0]) {
          this.selectedLeftIds = [selectedLeftIds[0]]
        }
      }
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      // console.log(selectedRowKeys, selectionRows)
      this.selectedRowKeys = selectedRowKeys

      this.selectFlag = true
    },
    onSelect(record, selected, selectedRows, nativeEvent) {
      let deleteId = ''
      console.log(record, selected, selectedRows)
      console.log('this.selectRightItem1', this.selectRightItem)
      if (!selected) {
        deleteId = record.id
        // let indexArr = []
        this.selectRightItem = this.selectRightItem.filter((item, index) => {
          return item.id !== record.id
        })
        console.log('this.selectRightItem2', this.selectRightItem)
      }
      if (this.multi) {
        selectedRows.forEach(item => {
          this.selectRightItem.push(item)
        })
      } else {
        this.selectRightItem = selectedRows
      }

    },
    //右边查询
    onRightSearch() {
      this.loadData(1)
    },
    //左边查询
    onLeftSearch() {
      this.leftLoading = true
      let params = this.getLeftQueryParams()//查询条件
      console.log(params)
      getAction(this.leftTreeUrl, { ...params, status: 1 }).then((res) => {
        if (res.success) {
          this.departTree = res.result
          this.leftIpagination.total = this.departTree.length
        }
      }).finally(() => {
        this.leftLoading = false
      })
    },
    // 根据选择的id来查询用户信息
    initQueryRightByLeft(selectedLeftIds) {
      this.rightLoading = true
      console.log('selectedLeftIds', selectedLeftIds)
      let obj = {
        [this.leftQueryMark]:selectedLeftIds.toString(),
        status: 1, ...this.getRightQueryParams()
      }
      console.log('obj',obj)
      return getAction(this.rightTreeUrl, {
        [this.leftQueryMark]:selectedLeftIds.toString(),
        status: 1, ...this.getRightQueryParams()
      }).then((res) => {
        if (res.success) {
          this.rightDataSource = res.result.records
          this.rightIpagination.total = res.result.length || res.result.records.length || res.result.total
        }
      }).finally(() => {
        this.rightLoading = false
      })
    },
    queryDepartTree() {
      getAction(this.leftTreeUrl).then((res) => {
        if (res.success) {
          this.departTree = res.result
          // 默认展开父节点
          this.expandedKeys = this.departTree.map(item => item.id)
        }
      })
    },
    modalFormOk() {
      this.loadData()
    }

  }

}
</script>

<style scoped>
.ant-table-tbody .ant-table-row td {
  padding-top: 10px;
  padding-bottom: 10px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}
</style>
