<template>
  <j-modal
    :title='title'
    :width='width'
    :visible='visible'
    :confirmLoading='confirmLoading'
    switchFullscreen
    @ok='handleOk'
    @cancel='handleCancel'
    :cancelText="$t('cancel')">
    <a-spin :spinning='confirmLoading'>
      <a-form :form='form'>

        <a-form-item label='项目' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <!--                    <a-input-number v-decorator="['projectItemId', validatorRules.projectItemId]" placeholder="请输入项目明细" style="width: 100%"/>-->
          <InputSelect v-decorator="['projectId', validatorRules.projectId]" show-name='name' send-params='id'
                       url='dbcp/city/projectManagement/CumProjectList.vue' :isEdit='model.projectId?true:false'
                       type='radio'></InputSelect>
        </a-form-item>

        <a-form-item label='村属' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input v-decorator="['village']" placeholder='请输入村属'></a-input>
        </a-form-item>
        <a-form-item label='房屋性质' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <j-dict-select-tag type='list' v-decorator="['propertyType']"
                             :trigger-change='true' dictCode='cum_property_type' placeholder='请选择房屋性质' />
        </a-form-item>
        <a-form-item label='测绘编号' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input v-decorator="['surveyedNo',validatorRules.surveyedNo]" placeholder='请输入测绘编号'></a-input>
        </a-form-item>
        <a-form-item label='测绘面积(㎡)' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input-number v-decorator="['surveyedArea']" placeholder='请输入测绘面积' style='width: 100%' :step='0.01'
                          :max='99999999.99' />
        </a-form-item>
        <a-form-item label='房屋编号' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input v-decorator="['surveyNumber']" placeholder='请输入房屋编号'></a-input>
        </a-form-item>
        <a-form-item label='测量状态' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <j-dict-select-tag type='list' v-decorator="['surveyingStatus',validatorRules.surveyingStatus,{initialValue:0}]"
                             :trigger-change='true' dictCode='cum_surveying_status' placeholder='请选择测量状态' />
        </a-form-item>
        <a-form-item label='所属地块' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input v-decorator="['landNumber']" placeholder='请输入所属地块'></a-input>
        </a-form-item>
        <a-form-item label='权利人' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <!--          <common-selector url='/cum/cumOwner/list' showName='name' :showItem="['name']" title='权利人选择器'-->
          <!--                           v-decorator="['rightHolder', validatorRules.rightHolder]" :multi='false'></common-selector>-->
          <InputSelect v-decorator="['rightHolder']" show-name='name' send-params='id'
                       url='dbcp/city/cumOwner/CumOwnerList.vue' :isEdit='model.rightHolder?true:false'
                       type='radio'></InputSelect>
        </a-form-item>
        <a-form-item label='栋号' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input-number v-decorator="['buildingNumber']" placeholder='请输入栋号'
                          style='width: 100%' />
        </a-form-item>
        <a-form-item label='总楼层' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input-number v-decorator="['totalFloors', validatorRules.totalFloors]" placeholder='请输入总楼层'
                          style='width: 100%' />
        </a-form-item>
        <a-form-item label='产权证建筑面积' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input-number v-decorator="['certificateTitleArea']" placeholder='请输入产权证建筑面积'
                          style='width: 100%' />
        </a-form-item>
        <a-form-item label='结构类型' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <j-dict-select-tag type='list' v-decorator="['structureType', validatorRules.structureType]"
                             :trigger-change='true' dictCode='cum_structure_type' placeholder='请选择结构类型' />
        </a-form-item>
        <a-form-item label='是否祖屋' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <j-dict-select-tag type='list' v-decorator="['ancestralHouse']"
                             :trigger-change='true' dictCode='cum_ancestral_house' placeholder='请选择是否祖屋' />
        </a-form-item>
        <a-form-item label='房产证类型' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <j-dict-select-tag type='list' v-decorator="['realEstateLicenseType', validatorRules.realEstateLicenseType]"
                             :trigger-change='true' dictCode='cum_real_estate_license_type' placeholder='请选择房产证类型' />
        </a-form-item>
        <a-form-item label='地址' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input v-decorator="['surveyAddress']" placeholder='请输入地址'></a-input>
        </a-form-item>
        <a-form-item label='备注' :labelCol='labelCol' :wrapperCol='wrapperCol' style='width:100%'>
          <a-textarea v-decorator="['remark']" rows='4' placeholder='请输入备注' />
        </a-form-item>
        <a-form-item :label="$t('attachments')" :labelCol='labelCol' :wrapperCol='wrapperCol' style='width:100%'>
          <!--          <attachment v-decorator="['upload']" @handleFileRemove='handleFileRemove' @beforeFileUpload='beforeFileUpload'-->
          <!--                      :file-list='uploadFiles' :default-file-list='defaultFileList'></attachment>-->
          <multi-attachment @handleFileRemove='handleFileRemove' @beforeFileUpload='beforeFileUpload'
                            :upload-files='uploadFiles' :default-file-list='defaultFileList' :tab-option="[{
                               key: 'file',
        label: '文件'
                            },{
                             key:'other',
                             label:'其它'
                            },{
        key: 'img',
        label: '图片'
      },{
        key: 'model',
        label: '模型'
      }]" @deleteFile='handleDeleteFile'></multi-attachment>
        </a-form-item>

      </a-form>
    </a-spin>
    <template slot='footer'>
      <!--      <a-button type="primary" @click="handleOk(false)">{{ $t('saveDraft') }}</a-button>-->
      <a-button type='primary' @click='handleOk(true)'>{{ $t('submit') }}</a-button>
      <a-button @click='handleCancel'>{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
import { cumBuildingApi } from './api/CumBuilding'
import { httpAction } from 'dbcp-frontends-common/lib/api'
import pick from 'lodash.pick'
import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
import { JDictSelectTag } from 'dbcp-frontends-common/lib/components'
import { UploadFormMixin } from '@/mixins/UploadFormMixin'
import { Attachment } from 'dbcp-frontends-common/lib/components'
import { CommonSelector } from 'dbcp-frontends-common/lib/components'
import MultiAttachment from '@/components/dbcp/city/MultiAttachment'
import InputSelect from '@/components/dbcp/InputSelect'

export default {
  name: 'CumBuildingModal',
  components: {
    JDictSelectTag,
    Attachment,
    CommonSelector,
    MultiAttachment,
    InputSelect
  },
  mixins: [UploadFormMixin],
  data() {
    return {
      form: this.$form.createForm(this),
      title: this.$i18n.tc('action'),
      width: 1000,
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      validatorRules: {
        projectId: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        propertyType: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        surveyingStatus: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        rightHolder: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        // surveyedNo: {
        //   rules: [
        //     { required: true, message: this.$i18n.tc('fieldEmptyHint') }
        //   ]
        // },
        surveyAddress: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        buildingNumber: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        totalFloors: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        structureType: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        ancestralHouse: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        realEstateLicenseType: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        }
      },
      url: cumBuildingApi.url
    }
  },
  created() {
  },
  methods: {
    afterAdd(record) {
      this.edit({
        ...record
      })
    },
    afterEdit(record) {
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'certificateTitleArea', 'projectId', 'village', 'propertyType', 'surveyedNo', 'surveyAddress', 'surveyedArea', 'surveyNumber', 'surveyingStatus', 'landNumber', 'rightHolder', 'buildingNumber', 'totalFloors', 'structureType', 'ancestralHouse', 'realEstateLicenseType', 'remark', 'addBy', 'addTime', 'updateBy', 'updateTime'))
      })
    },
    afterClose() {
    },
    popupCallback(row) {
      this.form.setFieldsValue(pick(row, 'projectId', 'village', 'propertyType', 'surveyedNo', 'surveyAddress', 'surveyedArea', 'surveyNumber', 'surveyingStatus', 'landNumber', 'rightHolder', 'buildingNumber', 'totalFloors', 'structureType', 'ancestralHouse', 'realEstateLicenseType', 'remark', 'addBy', 'addTime', 'updateBy', 'updateTime'))
    },
    processFormData(formData, data) {
      return formData
    }


  }
}
</script>
<style scoped lang='less'>
/deep/ .ant-form-item {
  display: inline-block !important;
  width: 50%;

  .ant-form-item-label {
    width: 100px;
  }
}
</style>
