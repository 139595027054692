<template>
  <j-modal
    :title='title'
    :width='width'
    :visible='true'
    v-if='visible'
    switchFullscreen
    @cancel='handleCancel'
    :cancelText="$t('cancel')">
    <city-map ref='projectMap' can-edit></city-map>
    <template slot='footer'>
      <!--      <a-button type="primary" @click="handleOk(false)">{{ $t('saveDraft') }}</a-button>-->
      <a-button type='primary' @click='handleSubmit'>{{ $t('save') }}</a-button>
      <a-button @click='handleCancel'>{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
import { getAction, postAction } from 'dbcp-frontends-common/lib/api'
import CityMap from '../../../../../components/CityMap'
export default {
  name: 'BuildingMap',
  components:{
    CityMap
  },
  data() {
    return {
      title: this.$i18n.tc('action'),
      width: '100%',
      visible: false,
      id:''
    }
  },
  mounted() {


  },
  methods: {
    handleCancel() {
      this.visible = false
      this.$refs.projectMap.handleCancel()
    },
    show(record,type) {
      this.id = record.id
      this.visible = true
      this.$nextTick(() => {
        this.$refs.projectMap.show(record,type)
      })


    },
    /**
     * 提交表单
     */
    handleSubmit() {
      let temp = [...this.$refs.projectMap.canEditArr]
      console.log(temp)
      let arr = []
      temp.forEach(item => {
        let obj = {}
        obj.projectId = this.id
        obj.polygonId = item.id
        obj.paths = item.paths
        arr.push(obj)
      })
      postAction('/cum/cumProjectSurveyed/handleSurveyed', arr)

    },
  }
}
</script>

<style lang='less' scoped>
/deep/ .ant-modal {
  top: 0 !important;
}
</style>