<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="表名">
              <a-input placeholder="请输入表名" v-model="queryParam.name"></a-input>
            </a-form-item>
          </a-col>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="主键策略">
              <j-dict-select-tag placeholder="请选择主键策略" v-model="queryParam.primaryKeyStrategy" dictCode="workflow_form_pks"/>
            </a-form-item>
          </a-col>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a @click="handleToggleSearch" style="margin-left: 8px">
                {{ toggleSearchStatus ? $t('collapse') : $t('expend') }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus" v-has="'dbcpWorkflowForm:create'">{{ $t('create') }}</a-button>
      <a-button type="primary" icon="import" @click='handleImport'>{{ $t('import') }}</a-button>

    </div>

    <!-- table区域-begin -->
    <div>

      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        class="j-table-force-nowrap"
        @change="handleTableChange">

        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="imgSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noPicture') }}</span>
          <img v-else :src="getImgView(text)" height="25px" alt="" style="max-width:80px;font-size: 12px;font-style: italic;"/>
        </template>
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noFile') }}</span>
          <a-button
            v-else
            :ghost="true"
            type="primary"
            icon="download"
            size="small"
            @click="uploadFile(text)">
            {{ $t('download') }}
          </a-button>
        </template>

        <span slot="action" slot-scope="text, record">
           <div style='display: flex;justify-content: space-evenly'>
                <a @click='handleEdit(record)'>{{ $t('edit') }}</a>
                <list-actions
                  :record='record'
                  @view='handleView'
                  @edit='handleEdit'
                  @delete='handleDelete'
                  @action='$refs.detailForm.handleActionClick'
                  @copy='handleCopy'
                ></list-actions>
 <a-popconfirm :title="$t('confirmDeleteAlert')" @confirm="() => handleDelete(record.id)">
                  <a>{{ $t('delete') }}</a>
                </a-popconfirm>
            </div>
        </span>

      </a-table>
    </div>


    <dbcpWorkflowForm-modal ref="modalForm" @ok="modalFormOk"></dbcpWorkflowForm-modal>
    <dbcpWorkflowForm-detail ref="detailForm" @action="handleActionClick" @close="handleViewModalClose"
                                      @loadData="loadData" @clearRouteQuery="clearRouteQuery"></dbcpWorkflowForm-detail>

    <import-modal ref="importForm" @ok="modalFormOk"></import-modal>
  </a-card>
</template>

<script>
  import {  dbcpWorkflowFormApi } from './modules/api/DbcpWorkflowForm'
  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
  import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
  import DbcpWorkflowFormModal from './modules/DbcpWorkflowFormModal'
  import DbcpWorkflowFormDetail from './modules/DbcpWorkflowFormDetail'
  import { ListActions } from 'dbcp-frontends-common/lib/components'
  import { JDictSelectTag } from 'dbcp-frontends-common/lib/components'
  import importModal from './modules/importModal'

  export default {
    name: "DbcpWorkflowFormList",
    mixins:[JeecgListMixin, mixinDevice, ViewMixin],
    components: {
      JDictSelectTag,
      DbcpWorkflowFormModal,
      DbcpWorkflowFormDetail,
      ListActions,
      importModal
    },
    data () {
      return {
        description: '表单管理管理页面',
        // 表头
        columns: [
          {
            title: '#',
            dataIndex: '',
            key: 'rowIndex',
            width: 60,
            align: 'center',
            customRender: function (t,r,index) {
              return parseInt(index)+1;
            }
          },
          {
            title: '表名',
            align: 'center',
            dataIndex: 'name'
          },
          {
            title: '主键策略',
            align: 'center',
            dataIndex: 'primaryKeyStrategy_dictText'
          },
          {
            title: '表描述',
            align: 'center',
            dataIndex: 'description'
          },
          {
            title: '实体类名',
            align: 'center',
            dataIndex: 'entityName'
          },
          {
            title: '创建时间',
            align: 'center',
            dataIndex: 'addTime'
          },
          {
            title: this.$i18n.tc('action'),
            dataIndex: 'action',
            align: 'center',
            // fixed:"right",
            width: 147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: dbcpWorkflowFormApi.url,
        dictOptions:{},
      }
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
    },
    methods: {
      initDictConfig(){
      },
      handleImport(){
        this.$refs.importForm.add()
      },
      importFormOk(){

      }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>
