<template>
  <div>
    <a-form :form='form'>
      <a-descriptions bordered size='small' :column='2'>
        <a-descriptions-item>
          <div slot='label'><span class='required' v-if='validatorRules.name&&clickMap.name'>*</span>{{ $t('name') }}
          </div>
          <EditInput :attrName="'name'" :data='logisticsData.name' @allEditClick='changeAllEdit' :allEdit='allEdit'
                     :isAdd='isAdd' :isEdit='true'>
            <a-form-item v-if='clickMap.name||isAdd'>
              <a-input v-decorator="['name', validatorRules.name]" placeholder='请输入项目名称'></a-input>
            </a-form-item>
          </EditInput>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot='label'><span class='required' v-if='validatorRules.owner&&clickMap.owner'>*</span>{{ $t('owner') }}
          </div>
          <EditInput :attrName="'owner'" :data='logisticsData.owner_dictText' @allEditClick='changeAllEdit'
                     :allEdit='allEdit' :isAdd='isAdd' :isEdit='true'>
            <a-form-item v-if='clickMap.owner||isAdd'>
              <j-select-user-by-dep :hideDept='true' v-decorator="['owner', validatorRules.owner]" :multi='false'
                                    title='选择用户' :hide-dept='true' />
            </a-form-item>
          </EditInput>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot='label'><span class='required'
                                  v-if='validatorRules.demolitionDirector&&clickMap.demolitionDirector'>*</span>拆迁总监
          </div>
          <EditInput :attrName="'demolitionDirector'" :data='logisticsData.demolitionDirector_dictText'
                     @allEditClick='changeAllEdit' :allEdit='allEdit' :isAdd='isAdd' :isEdit='true'>
            <a-form-item v-if='clickMap.demolitionDirector||isAdd'>
              <j-select-user-by-dep :hideDept='true'
                                    v-decorator="['demolitionDirector', validatorRules.demolitionDirector]"
                                    :multi='false' />
            </a-form-item>
          </EditInput>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot='label'><span class='required'
                                  v-if='validatorRules.demolitionDeputy&&clickMap.demolitionDeputy'>*</span>拆迁副总
          </div>
          <EditInput :attrName="'demolitionDeputy'" :data='logisticsData.demolitionDeputy_dictText'
                     @allEditClick='changeAllEdit' :allEdit='allEdit' :isAdd='isAdd' :isEdit='true'>
            <a-form-item v-if='clickMap.demolitionDeputy||isAdd'>
              <j-select-user-by-dep :hideDept='true' v-decorator="['demolitionDeputy', validatorRules.demolitionDeputy]"
                                    :multi='false' />
            </a-form-item>
          </EditInput>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot='label'><span class='required' v-if='validatorRules.legalAffairs&&clickMap.legalAffairs'>*</span>法务
          </div>
          <EditInput :attrName="'legalAffairs'" :data='logisticsData.legalAffairs_dictText'
                     @allEditClick='changeAllEdit' :allEdit='allEdit' :isAdd='isAdd' :isEdit='true'>
            <a-form-item v-if='clickMap.legalAffairs||isAdd'>
              <j-select-user-by-dep :hideDept='true' v-decorator="['legalAffairs', validatorRules.legalAffairs]"
                                    :multi='false' />
            </a-form-item>
          </EditInput>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot='label'><span class='required' v-if='validatorRules.finance&&clickMap.finance'>*</span>财务</div>
          <EditInput :attrName="'finance'" :data='logisticsData.finance_dictText' @allEditClick='changeAllEdit'
                     :allEdit='allEdit' :isAdd='isAdd' :isEdit='true'>
            <a-form-item v-if='clickMap.finance||isAdd'>
              <j-select-user-by-dep :hideDept='true' v-decorator="['finance', validatorRules.finance]" :multi='false' />
            </a-form-item>
          </EditInput>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot='label'><span class='required'
                                  v-if='validatorRules.demolitionOwner&&clickMap.demolitionOwner'>*</span>集团拆迁负责人
          </div>
          <EditInput :attrName="'demolitionOwner'" :data='logisticsData.demolitionOwner_dictText'
                     @allEditClick='changeAllEdit' :allEdit='allEdit' :isAdd='isAdd' :isEdit='true'>
            <a-form-item v-if='clickMap.demolitionOwner||isAdd'>
              <j-select-user-by-dep :hideDept='true' v-decorator="['demolitionOwner', validatorRules.demolitionOwner]"
                                    :multi='false' />
            </a-form-item>
          </EditInput>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot='label'><span class='required' v-if='validatorRules.planArea&&clickMap.planArea'>*</span>规划面积(m²)
          </div>
          <EditInput :attrName="'planArea'" :data='logisticsData.planArea' @allEditClick='changeAllEdit'
                     :allEdit='allEdit' :isAdd='isAdd' :isEdit='true'>
            <a-form-item v-if='clickMap.planArea||isAdd'>
              <a-input-number v-decorator="['planArea', validatorRules.planArea]" style='width:100%' :step='0.01'
                              :max='99999999.99'></a-input-number>
            </a-form-item>
          </EditInput>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot='label'><span class='required' v-if='validatorRules.description&&clickMap.description'>*</span>项目描述
          </div>
          <EditInput :attrName="'description'" :data='logisticsData.description' @allEditClick='changeAllEdit'
                     :allEdit='allEdit' :isAdd='isAdd' :isEdit='true'>
            <a-form-item v-if='clickMap.description||isAdd'>
              <a-textarea v-decorator="['description']" rows='4' placeholder='请输入项目描述' />
            </a-form-item>
          </EditInput>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot='label'><span class='required' v-if='validatorRules.status&&clickMap.status'>*</span>项目状态</div>
          <EditInput :attrName="'status'" :data='logisticsData.status_dictText' @allEditClick='changeAllEdit'
                     :allEdit='allEdit'
                     :isAdd='isAdd' :isEdit='true'>
            <a-form-item v-if='clickMap.status||isAdd'>
              <j-dict-select-tag type='list' v-decorator="['status', validatorRules.status]" :trigger-change='true'
                                 dictCode='cum_project_status' placeholder='请选择项目状态' />
            </a-form-item>
          </EditInput>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot='label'>{{ $t('attachments') }}</div>
          <a-form-item>
            <attachment v-decorator="['upload']" @handleFileRemove='handleFileRemove'
                        @beforeFileUpload='beforeFileUpload'
                        :file-list='uploadFiles' :default-file-list='defaultFileList'></attachment>
          </a-form-item>
        </a-descriptions-item>

      </a-descriptions>
    </a-form>
    <div class='detail-actions' v-if='allEdit'>
      <a-button type='primary' @click='cancelEdit' class='detail-actions-button' v-if='!isAdd'>取消</a-button>
      <a-button type='primary' @click='saveEdit' class='detail-actions-button'>保存</a-button>
      <!--      <a-button type='primary' @click='cancelEdit' class='detail-actions-button' v-if='!isAdd'>删除</a-button>-->
    </div>
    <div class='detail-actions'>
      <a-button type='primary' @click='handleView' class='detail-actions-button'>详情</a-button>
      <a-button type='primary' @click='handlePrint' class='detail-actions-button'>测绘</a-button>
      <a-button type='primary' @click='handleShowItem' class='detail-actions-button'>明细</a-button>
      <a-button type='primary' @click='turnTemplate' class='detail-actions-button'>核实表模板</a-button>
      <a-button type='primary' @click='turnPayment' class='detail-actions-button'>收楼付款</a-button>
      <a-button type='primary' @click='handleProjectStandard' class='detail-actions-button'>项目补偿</a-button>
      <a-button type='primary' @click='handleStandard' class='detail-actions-button'>补偿标准</a-button>
    </div>
  </div>
</template>

<script>
import { getAction } from 'dbcp-frontends-common/lib/api'
import EditInput from './EditInput'
import { UploadFormMixin } from 'dbcp-frontends-common/lib/mixins'
import pick from 'lodash.pick'
import { cumProjectApi } from './modules/api/CumProject'
import { JDate } from 'dbcp-frontends-common/lib/components'
import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'
import { Attachment } from 'dbcp-frontends-common/lib/components'

export default {
  name: 'simpleProjectTemplate',
  mixins: [UploadFormMixin, JeecgListMixin],
  components: {
    getAction,
    EditInput,
    JDate,
    JSelectUserByDep,
    Attachment
  },
  data() {
    return {
      width: 1200,
      form: this.$form.createForm(this),
      model: {},
      confirmLoading: false,
      logVisible: false,
      currentId: '',
      clickMap: {},
      isAdd: false,
      allEdit: false,
      logisticsData: {},
      validatorRules: {
        name: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        deptId: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        owner: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        demolitionDirector: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        // demolitionDeputy:{
        //   rules: [
        //     { required: true, message: this.$i18n.tc('fieldEmptyHint')},
        //   ]
        // },
        legalAffairs: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        finance: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        // demolitionOwner:{
        //   rules: [
        //     { required: true, message: this.$i18n.tc('fieldEmptyHint')},
        //   ]
        // },
        status: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        }
      },
      url: cumProjectApi.url,
      clickRecotd: {},
      /* 禁用created列表数据加载 */
      disableMixinCreated: true
    }
  },
  created() {
    this.getLogistics()
  },
  methods: {
    getLogistics() {
      this.logisticsData = {}
      getAction('/cum/cumProject/list', {
        pageSize: 1
      }).then(res => {
        console.log('物流跟踪数据', res)
        if (res.result.records.length > 0) {
          this.logisticsData = JSON.parse(JSON.stringify(res.result.records[0]))
          this.model = JSON.parse(JSON.stringify(this.logisticsData))
          this.isAdd = false
          this.$emit('childData', this.logisticsData)
        } else {
          this.logisticsData = {}
          this.isAdd = true
          this.allEdit = true
          for (let key in this.validatorRules) {
            this.$set(this.clickMap, key, true)
          }
        }
      })

    },
    afterEdit(record) {
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'planArea', 'name', 'deptId', 'owner', 'demolitionDirector', 'demolitionDeputy', 'legalAffairs', 'finance', 'demolitionOwner', 'description', 'status', 'updateBy', 'updateTime'))
      })
    },
    changeAllEdit(isClick, attrName) {
      this.afterEdit()
      this.$set(this.clickMap, attrName, !this.clickMap[attrName] || false)
      if (!this.allEdit) {
        this.allEdit = true
      }
    },
    cancelEdit() {
      this.model = JSON.parse(JSON.stringify(this.logisticsData))
      if (this.isAdd) {
        this.allEdit = true
      } else {
        this.allEdit = false
        this.clickMap = {}
      }
    },
    processFormData(formData, data) {
      formData.set('orderId', this.currentId)
      formData.delete('recordList')
      return formData
    },
    saveEdit() {
      console.log('add', this.url.add)
      this.handleOk(false)
      if (this.isAdd) {
        this.isAdd = false
      }
      this.allEdit = false
      this.clickMap = {}
    },
    handleSuccess(data) {
      //data 编辑之后的data
      console.log('data===>', data)
      this.getLogistics(this.currentId)
      // this.logisticsData = JSON.parse(JSON.stringify(data));
    },
    handlePrint() {
      this.$emit('handlePrint', this.logisticsData)
    },
    handleShowItem() {
      this.$emit('handleShowItem', this.logisticsData)
    },
    turnTemplate(){
      this.$emit('turnTemplate',this.logisticsData)
    },
    turnPayment() {
      this.$emit('turnPayment', this.logisticsData)
    },
    handleExport() {
      this.$emit('handleExport', this.logisticsData.id)
    },
    handleStandard() {
      this.$emit('handleStandard', this.logisticsData)
    },
    handleView() {
      this.$emit('handleView', this.logisticsData)
    },
    handleProjectStandard(){
      this.$emit('handleProjectStandard',this.logisticsData)
    }
  }
}
</script>

<style lang='less' scoped>
.record-title {
  font-size: 16px;
}

.required {
  color: red;
  margin-right: 5px;
}

/deep/ .description {
  padding: 0 !important;
}

/deep/ .ant-form-item {
  margin-bottom: 0 !important;
}

/deep/ .ant-descriptions-item-label {
  width: 140px !important;
}

/deep/ .ant-descriptions-item-content {
  width: 342px !important;
  height: 52px !important;
}

.remark {
  color: #aaa;
  font-size: 12px;
}

.addTime {
  color: #ccc;
  font-size: 12px;
}

.detail-actions {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
</style>
