<template>
  <j-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('cancel')"
    :footer="isFooterShow"
  >
    <div style="margin-bottom: 10px" v-if="isShowTime">
      <h3>工时：{{ currentTime | MinuteToHour }}</h3>
    </div>
    <a-spin :spinning="confirmLoading">
      <a-form :form="form" v-if="isOnlyShow">

        <a-form-item label="工时" :labelCol="labelCol" :wrapperCol="wrapperCol">
          {{ currentTime | MinuteToHour }}
        </a-form-item>
        <a-form-item label="类型" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if="isOnlyShow">
          <a-radio-group v-model="type" @change="onChange">
            <a-radio :value="1">
              增加
            </a-radio>
            <a-radio :value="0">
              减少
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="时长" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['changeTime', validatorRules.changeTime]" placeholder="请输入变化时长" style="width: 100%"
                   type="number">
            <a-select slot="addonAfter" default-value="H" style="width: 80px" @change="handleSelectChange">
              <a-select-option value="H">
                H
              </a-select-option>
              <a-select-option value="Min">
                Min
              </a-select-option>
            </a-select>
          </a-input>
        </a-form-item>
        <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea v-decorator="['remark', validatorRules.remark]" placeholder="请输入备注"
                      :auto-size="{ minRows: 4 }"></a-textarea>
        </a-form-item>
      </a-form>
    </a-spin>
    <a-card style="width: 100%">
      <a-list item-layout="vertical" :data-source="listData" :pagination="pagination">
        <a-list-item slot="renderItem" slot-scope="item, index">
          <template>
            <a-list-item-meta
            >
              <div slot="description">
                <p :style="{color:item.type == 0 ? 'green' : 'red'}">
                  {{ item.type === 0 ? '-' : '+' }}{{ item.hours | MinuteToHour }}</p>
                <p>{{ item.beforeHours | MinuteToHour }} -> {{ item.afterHours | MinuteToHour }}</p>
                <div v-html="'备注：'+getContent(item.remark)"></div>
              </div>
              <a slot="title">{{ `#${index + 1} ${item.addBy_dictText}` }}</a>
            </a-list-item-meta>
            <div>
              <div>
              </div>
              <div class="time">
                {{ item.time || item.addTime }}
              </div>
            </div>
          </template>
        </a-list-item>
      </a-list>
    </a-card>

    <template slot="footer">
      <a-button type="primary" @click="handleOk">{{ $t('submit') }}</a-button>
      <a-button @click="handleCancel">{{ $t('cancel') }}</a-button>
    </template>

  </j-modal>
</template>

<script>

import { httpAction, uploadAction } from 'dbcp-frontends-common/lib/api'
import pick from 'lodash.pick'
import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'
import { getAction } from 'dbcp-frontends-common/lib/api'
import { JRadio } from 'dbcp-frontends-common/lib/components'
import { cacheBank } from 'dbcp-frontends-common/lib/utils'

export default {
  name: 'DbcpReposeModal',
  props: {
    isOnlyShow: {
      type: Boolean,
      default: true
    },
    isShowTime: {
      type: Boolean,
      default: false
    },
    isFooterShow:{
      type: Boolean,
      default: undefined
    }
  },
  components: {
    JSelectUserByDep, JRadio
  },
  data() {
    return {
      form: this.$form.createForm(this),
      title: this.$i18n.tc('action'),
      width: 800,
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      validatorRules: {
        changeTime: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        remark: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        }
      },
      url: {
        add: '/dbcp/dbcpRepose/add',
        list: '/dbcp/dbcpRepose/list',
        edit: '/dbcp/dbcpRepose/edit'
      },
      currentName: '',
      currentTime: 0,
      changeTime: '',
      type: 1,
      hOrMin: 'H',
      pagination: {
        onChange: page => {
          console.log(page)
        },
        pageSize: 3,
        hideOnSinglePage: true

      },
      listData: []
    }
  },
  created() {
  },
  methods: {
    afterAdd() {
      this.edit()
    },
    edit(id, username) {
      this.queryTime(id)
      this.queryHistory(id)
      this.currentName = username
    },
    queryTime(id) {
      console.log("aaaaa")
      getAction('/oa/dbcp/dbcpRepose/queryReposeHours', { userId: id }).then(res => {
        if (res.success) {
          this.currentTime = res.result
          this.form.resetFields()
          this.visible = true
        }
      })
    },
    queryHistory(id) {
      getAction(this.url.list, { username:id }).then(res => {
        if (res.success) {
          this.listData = res.result.records
          this.form.resetFields()
          this.visible = true
        }
      })
    },
    handleOk(submit = false) {
      const that = this
      let changeTime = 0
      this.form.validateFields((err, values) => {
        if (!err) {
          // 自定义校验
          console.log('run validate')
          that.confirmLoading = true
          let httpurl = '/dbcp/dbcpRepose/add'
          let method = 'post'
          let formData = new FormData()
          if (this.hOrMin == 'H') {
            changeTime = values.changeTime * 60
          } else {
            changeTime = values.changeTime * 1
          }
          console.log('values', values)
          formData.append('submit', submit)
          formData.append('hours', changeTime)
          formData.append('username', this.currentName)
          formData.append('remark', values.remark)
          formData.append('type', this.type)
          // let formData = Object.assign(this.model, values);
          console.log('run uploadAction')
          uploadAction(httpurl, formData, method).then((res) => {
            if (res.success) {

            } else {

            }
          }).finally(() => {
            that.confirmLoading = false
            that.visible = false
          })
        }
      })
    },
    handleCancel() {
      this.$emit('close')
      this.visible = false
    },
    onChange(e) {
      this.type = e.target.value
    },
    handleSelectChange(value) {
      this.hOrMin = value
    },
    getContent(content) {
      if (content) {
        return content.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\n/g, '<br/>')
      }
      return content ? content.replace(/\r\n/g, '<br/>') : ''
    }
  }
}
</script>
