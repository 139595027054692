<template>
  <j-modal
    :title='title'
    :width='width'
    :visible='visible'
    :confirmLoading='confirmLoading'
    switchFullscreen
    @ok='handleOk'
    @cancel='handleCancel'
    :cancelText="$t('cancel')">
    <a-spin :spinning='confirmLoading'>
      <a-tabs v-model='activeKey' v-if='!model.type'>
        <a-tab-pane key='1' tab='个人'>
        </a-tab-pane>
        <a-tab-pane key='2' tab='集体'>
        </a-tab-pane>
      </a-tabs>
      <a-form :form='form'>
        <a-form-item label='权利人' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input v-decorator="['name', validatorRules.name]" placeholder='请输入权利人'></a-input>
        </a-form-item>
        <a-form-item label='证件类型' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <j-dict-select-tag type='list' v-decorator="['idType']" :trigger-change='true'
                             dictCode='cum_owner_id_type'
                             placeholder='请选择证件类型' />
        </a-form-item>
        <a-form-item label='证件号码' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input v-decorator="['idNo']" placeholder='请输入身份证号码'></a-input>
        </a-form-item>
        <template v-if='activeKey === "2"'>
          <a-form-item label='性质' :labelCol='labelCol' :wrapperCol='wrapperCol'>
            <j-dict-select-tag type='list' v-decorator="['nature', validatorRules.nature]" :trigger-change='true'
                               dictCode='cum_owner_nature'
                               placeholder='请选择证件类型' />
          </a-form-item>
          <a-form-item label='企业主要经营范围' :labelCol='labelCol' :wrapperCol='wrapperCol'>
            <a-input v-decorator="['mainBusinessScope']" placeholder='请输入企业主要经营范围'></a-input>
          </a-form-item>
          <a-form-item label='企业详情' :labelCol='labelCol' :wrapperCol='wrapperCol'>
            <a-textarea v-decorator="['companyDetails']" rows='4' placeholder='请输入企业详情' />
          </a-form-item>

        </template>
        <a-form-item label='联系方式' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-textarea v-decorator="['phone']" placeholder='请输入联系方式'></a-textarea>
        </a-form-item>

        <template v-if='activeKey === "1"'>
          <a-form-item label='性别' :labelCol='labelCol' :wrapperCol='wrapperCol'>
            <j-dict-select-tag type='list' v-decorator="['sex', validatorRules.sex]" :trigger-change='true'
                               dictCode='sex'
                               placeholder='请选择性别' />
          </a-form-item>

          <a-form-item label='性格' :labelCol='labelCol' :wrapperCol='wrapperCol'>
            <a-input v-decorator="['character']" placeholder='请输入性格'></a-input>
          </a-form-item>
          <a-form-item label='婚姻状况' :labelCol='labelCol' :wrapperCol='wrapperCol'>
            <j-dict-select-tag type='list' v-decorator="['marriage']" :trigger-change='true' dictCode='marital_status'
                               placeholder='请选择婚姻状况' />
          </a-form-item>
        </template>
        <a-form-item label='授权委托人' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input v-decorator="['consigner']" placeholder='请输入授权委托人'></a-input>
        </a-form-item>
        <a-form-item label='备注' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-textarea v-decorator="['remark']" rows='4' placeholder='请输入备注' />
        </a-form-item>
        <!--        <a-form-item :label="$t('attachments')" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
        <!--          <attachment v-decorator="['upload']" @handleFileRemove="handleFileRemove" @beforeFileUpload="beforeFileUpload" :file-list="uploadFiles" :default-file-list="defaultFileList"></attachment>-->
        <!--        </a-form-item>-->

      </a-form>
    </a-spin>

    <template slot='footer'>
      <a-button type='primary' @click='handleOk(true)'>{{ $t('submit') }}</a-button>
      <a-button @click='handleCancel'>{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
import { cumOwnerApi } from './api/CumOwner'
import { httpAction } from 'dbcp-frontends-common/lib/api'
import pick from 'lodash.pick'
import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
import { JDictSelectTag } from 'dbcp-frontends-common/lib/components'
import { UploadFormMixin } from 'dbcp-frontends-common/lib/mixins'
import { Attachment } from 'dbcp-frontends-common/lib/components'

export default {
  name: 'CumOwnerModal',
  components: {
    JDictSelectTag, Attachment
  },
  mixins: [UploadFormMixin],
  data() {
    return {
      form: this.$form.createForm(this),
      title: this.$i18n.tc('action'),
      width: 800,
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      validatorRules: {
        name: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        sex: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        idType: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        idNo: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        nature: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        }
      },
      activeKey: '1',
      url: cumOwnerApi.url
    }
  },
  created() {
  },
  methods: {
    afterAdd(record) {
      this.edit({
        ...record
      })
    },
    afterEdit(record) {
      if (record.id) {
        record.type === '0' ? this.activeKey = '1' : this.activeKey = '2'
      }
      setTimeout(() => {

      })
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'nature', 'idNo', 'idType', 'consigner', 'type', 'companyDetails', 'mainBusinessScope', 'businessLicense', 'name', 'sex', 'idNo', 'phone', 'character', 'marriage', 'remark', 'updateBy', 'updateTime'))
      })
    },
    afterClose() {
      if(!this.model.id){
        this.activeKey = '1'
      }

    },
    popupCallback(row) {
      this.form.setFieldsValue(pick(row, 'nature', 'name', 'sex', 'idNo', 'phone', 'character', 'marriage', 'remark', 'updateBy', 'updateTime'))
    },
    processFormData(formData) {
      formData.set('type', this.activeKey === '1' ? '0' : '1')
      return formData
    }

  }
}
</script>
