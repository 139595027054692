<template>
  <div>
    <a-modal
      :title="title"
      :width="width"
      placement="right"
      @cancel="close"
      :footer="null"
      :visible="visible">
      <a-descriptions :column="3" bordered size="small">
              <a-descriptions-item :label="$t('name')">
          {{ data.name }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('code')">
          {{ data.code }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('description')">
          {{ data.description }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addBy')">
          {{ data.addBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addTime')">
          {{ data.addTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateBy')">
          {{ data.updateBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateTime')">
          {{ data.updateTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('status')">
          {{ data.status_dictText }}
        </a-descriptions-item>
      </a-descriptions>

    </a-modal>

  </div>
</template>

<script>
  import {  dbcpWorkflowDesignApi } from './api/DbcpWorkflowDesignWorkFlow'
  import { DetailHistory } from 'dbcp-frontends-common/lib/components'
  import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ActionMixin } from 'dbcp-frontends-common/lib/mixins'

  export default {
    name: "DbcpWorkflowDesignDetail",
    components: { DetailHistory },
    mixins: [ DetailMixin,ActionMixin ],
    data() {
      return {
        url: dbcpWorkflowDesignApi.url,
      }
    },
    methods:{
      view(data){
        this.data = data
        this.visible = true
      }
    }
  }
</script>
