<template>
  <j-select-biz-component :width="1000" v-bind="configs" v-on="$listeners" :returnId="true" :multiple="false" :is-dict="false"/>
<!--  <j-select-biz-component-->
<!--    :value="value"-->

<!--    :name="$t('attendanceTime')"-->
<!--    displayKey="id"-->
<!--    :multiple="false"-->
<!--    :returnKeys="returnKeys"-->
<!--    :listUrl="url.list"-->
<!--    :columns="columns"-->
<!--    queryParamText="id"-->

<!--    v-on="$listeners"-->
<!--    v-bind="$attrs"-->
<!--  />-->
</template>

<script>
import { JSelectBizComponent } from 'dbcp-frontends-common/lib/components'

export default {
  name: 'DAttendanceTime',
  components: { JSelectBizComponent },
  props: ['value'],
  data() {
    return {
      settings: {
        name: this.$i18n.tc('job'),
        displayKey: 'id',
        returnKeys: ['id'],
        listUrl: '/dbcp/dbcpAttendanceConfig/list',
        queryParamCode: 'id',
        queryParamText: 'ID',
        columns: [
          { title: 'ID', dataIndex: 'id', align: 'center', width: 120 },
          { title:  this.$i18n.tc('morningWorkTime'), dataIndex: 'morningWorkingTime', align: 'center', width: 120 },
          { title:  this.$i18n.tc('MorningOffTime'), dataIndex: 'morningOffTime', align: 'center', width: 120 },
          { title:  this.$i18n.tc('AfternoonWorkTime'), dataIndex: 'afternoonWorkingTime', align: 'center', width: 120 },
          { title: this.$i18n.tc('AfternoonOffTime'), dataIndex: 'afternoonOffTime', align: 'center', width: 120 }
        ]
      }
    }
  },
  computed: {
    configs() {
      return Object.assign({ value: this.value }, this.settings, this.$attrs)
    }
  }
}
</script>

<style lang="less" scoped></style>
