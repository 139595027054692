<template>
  <div>
    <slot></slot>
    <div @mouseenter="handleMove"
         @mouseleave="handleMove" class='edit' v-if='(!isClick && !isAdd)'>
      <span>{{ data }}</span>
      <a-icon type="edit"  v-if='flag&&isEdit' @click.native="handleClick"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditInput',
  props:['attrName','data','allEdit','isAdd','isEdit'],
  data(){
    return{
      flag:false,
      isClick:false
    }
  },

  watch:{
    allEdit:function(val){
      if(val===false){
        this.flag = false ;
        this.isClick = false ;
      }
    }
  },
  methods:{
    handleClick(){
      this.isClick = true ;
      this.$emit('allEditClick',this.isClick,this.attrName);

    },
    handleMove(){
      this.flag = !this.flag
    },
  }
}
</script>

<style scoped lang='less'>
.edit{
  padding:10px 10px;
  height:100%;
  display:flex;
  justify-content: space-between;
}
</style>