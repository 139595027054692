<template>
  <a-card :bordered='false'>
    <!-- 查询区域 -->
    <div class='table-page-search-wrapper'>
      <a-form layout='inline' @keyup.enter.native='searchQuery'>
        <a-row :gutter='24'>
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <a-form-item label='测量状态'>
              <j-dict-select-tag type='list' v-model="queryParam.surveyingStatus" :showManage='false'
                                 dictCode='cum_surveying_status' placeholder='请选择测量状态' />
            </a-form-item>
          </a-col>
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <a-form-item label='权利人'>
              <InputSelect v-model="queryParam.rightHolder" show-name='name' send-params='id'
                           url='dbcp/city/cumOwner/CumOwnerList.vue' :isEdit='queryParam.rightHolder?true:false'
                           type='radio'></InputSelect>
            </a-form-item>
          </a-col>
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <a-form-item label='栋号'>
              <a-input-number v-model="queryParam.buildingNumber" placeholder='请输入查询栋号'
                              style='width: 100%' />
            </a-form-item>
          </a-col>
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>查询</a-button>
              <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset')
                }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class='table-operator'>
      <a-button @click='handleAdd({"rightHolder":queryParam.rightHolder,"projectId":queryParam.projectId})' type='primary' icon='plus' v-has="'cumBuilding:create'">{{ $t('create') }}</a-button>
      <!--      <a-button type="primary" icon="download" @click="handleExportXls('楼栋管理')">{{ $t('export') }}</a-button>-->
      <!--      <a-upload name="file" :showUploadList="false" :multiple="false" :headers="tokenHeader" :action="importExcelUrl" @change="handleImportExcel">-->
      <!--        <a-button type="primary" icon="import">{{ $t('import') }}</a-button>-->
      <!--      </a-upload>-->
      <!--      <a-dropdown v-if="selectedRowKeys.length > 0">-->
      <!--        <a-menu slot="overlay">-->
      <!--          <a-menu-item key="1" @click="batchDel"><a-icon type="delete"/>{{ $t('delete') }}</a-menu-item>-->
      <!--        </a-menu>-->
      <!--        <a-button style="margin-left: 8px"> {{ $t('batchOperation') }} <a-icon type="down" /></a-button>-->
      <!--      </a-dropdown>-->
    </div>

    <!-- table区域-begin -->
    <div>

      <a-table
        ref='table'
        size='middle'
        bordered
        rowKey='id'
        :columns='columns'
        :dataSource='dataSource'
        :pagination='ipagination'
        :loading='loading'
        :rowSelection="type?{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type,onSelect:handleSelection,onSelectAll:handleSelectAll}:null"
        class='j-table-force-nowrap'
        @change='handleTableChange'>

        <template slot='surveyingStatus' slot-scope='text,record' v-if='text'>
          <a-tag :color='getSurveyingStatusColor(record.surveyingStatus)'>{{text}}</a-tag>
        </template>
        <template slot='projectId' slot-scope='text,record'>
          <a @click='turnProjectDetail(record.projectId)' >{{text}}</a>
        </template>
        <template slot='htmlSlot' slot-scope='text'>
          <div v-html='text'></div>
        </template>
        <template slot='imgSlot' slot-scope='text'>
          <span v-if='!text' style='font-size: 12px;font-style: italic;'>{{ $t('noPicture') }}</span>
          <img v-else :src='getImgView(text)' height='25px' alt=''
               style='max-width:80px;font-size: 12px;font-style: italic;' />
        </template>
        <template slot='fileSlot' slot-scope='text'>
          <span v-if='!text' style='font-size: 12px;font-style: italic;'>{{ $t('noFile') }}</span>
          <a-button
            v-else
            :ghost='true'
            type='primary'
            icon='download'
            size='small'
            @click='uploadFile(text)'>
            {{ $t('download') }}
          </a-button>
        </template>

        <span slot='action' slot-scope='text, record'>
          <div style='display: flex;justify-content: space-evenly;align-items: center'>
            <a @click='handlePrint(record)'>测绘</a>
            <a-divider type="vertical" />
               <a @click='handleEdit(record)'>{{ $t('edit') }}</a>
            <a-divider type="vertical" />
              <list-actions
                :record='record'
                @view='handleView'
                @edit='handleEdit'
                @delete='handleDelete'
                @action='$refs.detailForm.handleActionClick'
                @copy='handleCopy'
              ></list-actions>
            <a-divider type="vertical" />
            <a @click='handleTurnUnit(record)'>单元管理</a>
            <a-divider type="vertical" />
            <a-popconfirm :title="$t('confirmDeleteAlert')" @confirm="() => handleDelete(record.id)">
              <a>{{ $t('delete') }}</a>
            </a-popconfirm>
          </div>

        </span>

      </a-table>
    </div>



    <cum-project-detail ref='projectDetailForm'></cum-project-detail>
    <cumBuilding-modal ref='modalForm' @ok='modalFormOk'></cumBuilding-modal>
    <cumBuilding-detail ref='detailForm' @action='handleActionClick' @close='handleViewModalClose'
                        @loadData='loadData' @clearRouteQuery='clearRouteQuery'></cumBuilding-detail>
  <building-map ref='modalMap'></building-map>

    <TurnUnitList ref='turnUnitList'></TurnUnitList>
  </a-card>
</template>

<script>
import { cumBuildingApi } from './modules/api/CumBuilding'
import '@/assets/less/TableExpand.less'
import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
import CumBuildingModal from './modules/CumBuildingModal'
import CumBuildingDetail from './modules/CumBuildingDetail'
import { ListActions } from 'dbcp-frontends-common/lib/components'
import { filterMultiDictText } from '@/components/dict/JDictSelectUtil'
import BuildingMap from './modules/BuildingMap'
import { SelectorMixin } from 'dbcp-frontends-common/lib/mixins'
import InputSelect  from '@/components/dbcp/InputSelect'
import CumUnitModal from '../unitList/modules/CumUnitModal'
import TurnUnitList from './modules/TurnUnitList'
import { getSurveyingStatusColor } from '@/utils/util'
import CumProjectDetail from '../../projectManagement/modules/CumProjectDetail'
import { getAction } from 'dbcp-frontends-common/lib/api'

export default {
  name: 'CumBuildingList',
  mixins: [JeecgListMixin, mixinDevice, ViewMixin,SelectorMixin],
  components: {
    TurnUnitList,
    CumUnitModal,
    CumBuildingModal,
    CumBuildingDetail,
    ListActions,
    BuildingMap,
    InputSelect,
    CumProjectDetail
  },
  props:{
    type:{
      type:String,
    }
  },
  data() {
    return {
      description: '楼栋管理管理页面',
      // 表头
      columns: [
        {
          title: '栋号',
          dataIndex: 'buildingNumber',
          // key: 'rowIndex',
          width: 60,
          align: 'center'
        },
        {
          title: '项目',
          align: 'center',
          dataIndex: 'projectId_dictText',
          scopedSlots: { customRender: 'projectId' }
        },
        {
          title: '村属',
          align: 'center',
          dataIndex: 'village'
        },
        {
          title: '房屋性质',
          align: 'center',
          dataIndex: 'propertyType_dictText'
        },
        {
          title: '测绘编号',
          align: 'center',
          dataIndex: 'surveyedNo'
        },
        {
          title: '测绘面积(㎡)',
          align: 'center',
          dataIndex: 'surveyedArea'
        },
        {
          title: '房屋编号',
          align: 'center',
          dataIndex: 'surveyNumber'
        },
        {
          title: '测量状态',
          align: 'center',
          dataIndex: 'surveyingStatus_dictText',
          scopedSlots: { customRender: 'surveyingStatus' }
        },
        {
          title: '所属地块',
          align: 'center',
          dataIndex: 'landNumber'
        },
        {
          title: '权利人',
          align: 'center',
          dataIndex: 'rightHolder_dictText'
        },
        {
          title: '总楼层',
          align: 'center',
          dataIndex: 'totalFloors',
          // customRender:function(text){
          //   return "#" + text
          // }
        },
        {
          title: '地址',
          align: 'center',
          dataIndex: 'surveyAddress',
        },
        {
          title: '结构类型',
          align: 'center',
          dataIndex: 'structureType_dictText'
        },
        {
          title: '是否祖屋',
          align: 'center',
          dataIndex: 'ancestralHouse_dictText'
        },
        {
          title: '房产证类型',
          align: 'center',
          dataIndex: 'realEstateLicenseType_dictText'
        },
        // {
        //   title: '创建人',
        //   align: 'center',
        //   dataIndex: 'addBy_dictText'
        // },
        // {
        //   title: '创建时间',
        //   align: 'center',
        //   dataIndex: 'addTime'
        // },
        {
          title: '更新人',
          align: 'center',
          dataIndex: 'updateBy_dictText'
        },
        {
          title: '更新时间',
          align: 'center',
          dataIndex: 'updateTime'
        },
        {
          title: this.$i18n.tc('action'),
          dataIndex: 'action',
          align: 'center',
          // fixed:"right",
          width: 180,
          scopedSlots: { customRender: 'action' }
        }
      ],
      url: cumBuildingApi.url,
      dictOptions: {},
      selectedRowKeys:[],
      selectedList:[],
      getSurveyingStatusColor
    }
  },
  computed: {
    importExcelUrl: function() {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    }
  },
  watch:{
    '$attrs.rightHolder':{
      immediate:true ,
      handler : function(val){
        if(val){
          this.queryParam.rightHolder = val ;
          this.loadData();
        }
      }
    },
    '$attrs.projectId':{
      immediate:true,
      handler:function(val){
        console.log("attrs.projectId",val)
        if(val){
          this.queryParam.projectId = val;
          this.loadData();
        }
      }
    }
  },
  created() {
    this.selectedRowKeys = [];
  },
  methods: {
    initDictConfig() {
    },
    handlePrint(record){
      this.$refs.modalMap.show(record)
    },
    handleBuildingDetail(record){
      this.$refs.unitDetailForm.view(record)
    },
    handleTurnUnit(record){
      this.$nextTick(()=>{
        this.$refs.turnUnitList.show({ 'buildingId': record.id , 'rightHolder':record.rightHolder})
      })
    },
    searchReset(){
      this.queryParam = {};
      let record = {rightHolder:this.$attrs.rightHolder};
      this.queryParam = Object.assign({},record,this.queryParam);
      this.loadData();
    },
    turnProjectDetail(id){
      getAction("/cum/cumProject/list",{id}).then(res=>{
        let data = res.result.records[0];
        this.$nextTick(()=>{
          this.$refs.projectDetailForm.view(data);
        })

      })
    }
  }
}
</script>
<style scoped>
@import '~@assets/less/common.less';
</style>
