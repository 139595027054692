<template>
  <a-modal
    v-model='itemListVisible'
    width='80%'
  >
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus" v-has="'cumContractTemplate:create'">{{ $t('create') }}</a-button>
<!--      <a-button type="primary" icon="download" @click="handleExportXls('合同模板管理')">{{ $t('export') }}</a-button>-->
<!--      <a-upload name="file" :showUploadList="false" :multiple="false" :headers="tokenHeader" :action="importExcelUrl" @change="handleImportExcel">-->
<!--        <a-button type="primary" icon="import">{{ $t('import') }}</a-button>-->
<!--      </a-upload>-->
      <a-dropdown v-if="selectedRowKeys.length > 0">
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="batchDel"><a-icon type="delete"/>{{ $t('delete') }}</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> {{ $t('batchOperation') }} <a-icon type="down" /></a-button>
      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>
     <!-- <approval-tab @change="handleGroupChange" permission="cumContractTemplate:view:all"></approval-tab> -->
      <setting-columns :columns='columns' @changeRealColumns='(data) => columns = data'></setting-columns>
      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type}"
        class="j-table-force-nowrap"
        @change="handleTableChange">

        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="imgSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noPicture') }}</span>
          <img v-else :src="getImgView(text)" height="25px" alt="" style="max-width:80px;font-size: 12px;font-style: italic;"/>
        </template>
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noFile') }}</span>
          <a-button
            v-else
            :ghost="true"
            type="primary"
            icon="download"
            size="small"
            @click="uploadFile(text)">
            {{ $t('download') }}
          </a-button>
        </template>

        <span slot="action" slot-scope="text, record">
          <div style='display: flex;justify-content: space-evenly'>
            <a @click='handleEdit(record)'>{{ $t('edit') }}</a>
            <a-popconfirm :title="$t('confirmDeleteAlert')" @confirm="() => handleDelete(record.id)">
              <a>{{ $t('delete') }}</a>
            </a-popconfirm>
            <a @click='handleUploadFiles(record.fileList)' :disabled='record.fileList.length==0'>下载</a>
          </div>
          <div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);">
            <a-progress type="circle" :percent="downloadPercent" v-show="multiVisible"/>
          </div>
<!--          <list-actions-->
<!--            :record="record"-->
<!--            @view="handleView"-->
<!--            @edit="handleEdit"-->
<!--            @delete="handleDelete"-->
<!--            @action="$refs.detailForm.handleActionClick"-->
<!--            @copy="handleCopy"-->
<!--          ></list-actions>-->
        </span>

      </a-table>
    </div>


    <cumContractTemplate-modal ref="modalForm" @ok="modalFormOk"></cumContractTemplate-modal>
    <cumContractTemplate-detail ref="detailForm" @action="handleActionClick" @close="handleViewModalClose"
                                      @loadData="loadData" @clearRouteQuery="clearRouteQuery"></cumContractTemplate-detail>
  </a-modal>
</template>

<script>
  import {  cumContractTemplateApi } from './modules/api/CumContractTemplate'
  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
  import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
  import { SettingColumns } from 'dbcp-frontends-common/lib/components'
  import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
  import CumContractTemplateModal from './modules/CumContractTemplateModal'
  import CumContractTemplateDetail from './modules/CumContractTemplateDetail'
  // import { ApprovalTab } from 'dbcp-frontends-common/lib/components'
  import { ListActions } from 'dbcp-frontends-common/lib/components'
  import { SelectorMixin } from 'dbcp-frontends-common/lib/mixins'
  import JSzip from 'jszip'
  import axios from 'axios'
  import FileSaver from 'file-saver'

  export default {
    name: "CumContractTemplateList",
    mixins:[JeecgListMixin, mixinDevice, ViewMixin, ApprovalListMixin,SelectorMixin],
    components: {
      CumContractTemplateModal,
      CumContractTemplateDetail,
      ListActions,
      SettingColumns,

    },
    data () {
      return {
        description: '合同模板管理管理页面',
        // 表头
        columns: [
          {
            title: '#',
            dataIndex: '',
            key: 'rowIndex',
            width: 60,
            align: 'center',
            customRender: function (t,r,index) {
              return parseInt(index)+1;
            }
          },
          {
            title: '模板名称',
            align: 'center',
            dataIndex: 'name'
          },
          {
            title: '合同模板版本号',
            align: 'center',
            dataIndex: 'version'
          },
          {
            title: '创建人',
            align: 'center',
            dataIndex: 'addBy_dictText'
          },
          {
            title: '创建时间',
            align: 'center',
            dataIndex: 'addTime'
          },
          {
            title: this.$i18n.tc('action'),
            dataIndex: 'action',
            align: 'center',
            // fixed:"right",
            width: 147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: cumContractTemplateApi.url,
        dictOptions:{},
        multiVisible:false,
        downloadPercent:0,
        itemListVisible:false
      }
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
    },
    methods: {
      initDictConfig(){
      },
      handleUploadFiles(list){
        let successNumber = 0
        const fileList = [...list];
        const zip = new JSzip();
        const cache = {}
        const promises = []
        this.multiVisible = true
        // this.spinning = true
        this.downloadPercent = 0

        const getFile = url => {
          return new Promise((resolve, reject) => {
            axios({
              method: 'get',
              url,
              responseType: 'blob'
            }).then(data => {
              successNumber++
              //解决进度条OK的时候 还没下载完成
              if (successNumber < fileList.length) {
                this.downloadPercent = 100 * (successNumber / fileList.length)
              }
              resolve(data.data)
            }).catch(error => {
              this.$message.error("下载失败");
              this.multiVisible = false ;
              reject(error.toString())
            })
          })
        }


        fileList.forEach(item => {
          const promise = getFile(item.path).then(data => {
            // 下载文件, 并存成ArrayBuffer对象
            const arr_name = item.path.split('/')
            const file_name = arr_name[arr_name.length - 1] // 获取文件名
            // zip.file(file_name, data, { binary: true }) // 逐个添加文件
            cache[file_name] = data
          })
          promises.push(promise)
        })
        Promise.all(promises).then(() => {
          // zip.generateAsync({ type: 'blob' }).then(content => {
          // 生成二进制流
          Object.keys(cache).forEach(item => {
            FileSaver.saveAs(cache[item], item) // 利用file-saver保存文件  自定义文件名
          })

          //解决进度条OK的时候 还没下载完成
          if (successNumber = fileList.length) {
            this.downloadPercent = 100
          }
          setTimeout(() => {
            this.multiVisible = false
            this.spinning = false
          }, 500)
          // })
        })
      },
      show(){
        this.itemListVisible = true
      }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>
