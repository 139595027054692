<template>
  <a-modal
    v-model='visible'
    @ok='visible = false'
    width='1400px'
    @cancel='handleCancel'
  >
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
<!--          <a-col :xl="6" :lg="7" :md="8" :sm="24">-->
<!--            <a-form-item label="项目细项">-->
<!--              <common-selector url='/cum/cumProjectItem/list' showName='name' :showItem="['name']" title='项目细项选择器'-->
<!--                               v-model="queryParam.projectItemId" :multi='false'></common-selector>-->
<!--            </a-form-item>-->
<!--          </a-col>-->
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="业主">
<!--              <a-input placeholder="请输入业主" v-model="queryParam.ownerId"></a-input>-->
              <InputSelect v-model='queryParam.ownerId' show-name='name' send-params='id'
                           url='dbcp/city/cumOwner/CumOwnerList.vue'
                           type='radio'></InputSelect>
            </a-form-item>
          </a-col>
          <template v-if="toggleSearchStatus">
            <a-col :xl="10" :lg="11" :md="12" :sm="24">
              <a-form-item label="走访时间">
                <j-date :show-time="true" date-format="YYYY-MM-DD HH:mm:ss" :placeholder="$t('selectStartTimeHint')" class="query-group-cust" v-model="queryParam.visitTime_begin"></j-date>
                <span class="query-group-split-cust"></span>
                <j-date :show-time="true" date-format="YYYY-MM-DD HH:mm:ss" :placeholder="$t('selectEndTimeHint')" class="query-group-cust" v-model="queryParam.visitTime_end"></j-date>
              </a-form-item>
            </a-col>
            <a-col :xl="6" :lg="7" :md="8" :sm="24">
              <a-form-item label="走访人员">
                <j-select-user-by-dep :hideDept="true" placeholder="请选择走访人员" v-model="queryParam.visitStaff"/>
              </a-form-item>
            </a-col>
          </template>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a @click="handleToggleSearch" style="margin-left: 8px">
                {{ toggleSearchStatus ? $t('collapse') : $t('expend') }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd(handleAddRecord)" type="primary" icon="plus" v-has="'cumFollowUpRecord:create'">{{ $t('create') }}</a-button>
<!--      <a-button type="primary" icon="download" @click="handleExportXls('cum_follow_up_record')">{{ $t('export') }}</a-button>-->
<!--      <a-upload name="file" :showUploadList="false" :multiple="false" :headers="tokenHeader" :action="importExcelUrl" @change="handleImportExcel">-->
<!--        <a-button type="primary" icon="import">{{ $t('import') }}</a-button>-->
<!--      </a-upload>-->
<!--      <a-button type="primary" icon="download"-->
<!--                @click="toHandleExportXlsProgress('业主明细谈判进度表')"-->
<!--                v-has='"cumProject:export:detailsOfOwnerNegotiationProgress"'-->
<!--      >导出业主明细谈判进度表</a-button>-->
      <a-dropdown>
        <a-menu slot="overlay" @click="e => handleMenuClick(e,'业主明细谈判进度表')">
          <a-menu-item key="excel" v-has='"cumProject:export:detailsOfOwnerNegotiationProgress"'>导出excel</a-menu-item>
          <a-menu-item key="pdf" v-has='"cumProject:export:detailsOfOwnerNegotiationProgressPDF"'>导出pdf</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px">导出业主明细谈判进度表<a-icon type="down" /> </a-button>
      </a-dropdown>

      <a-dropdown v-if="selectedRowKeys.length > 0">
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="batchDel"><a-icon type="delete"/>{{ $t('delete') }}</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> {{ $t('batchOperation') }} <a-icon type="down" /></a-button>
      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>
     <!-- <approval-tab @change="handleGroupChange" permission="cumFollowUpRecord:view:all"></approval-tab> -->
      <setting-columns :columns='columns' @changeRealColumns='(data) => columns = data'></setting-columns>
      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">
<!--        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type}"-->

        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="imgSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noPicture') }}</span>
          <img v-else :src="getImgView(text)" height="25px" alt="" style="max-width:80px;font-size: 12px;font-style: italic;"/>
        </template>
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noFile') }}</span>
          <a-button
            v-else
            :ghost="true"
            type="primary"
            icon="download"
            size="small"
            @click="uploadFile(text)">
            {{ $t('download') }}
          </a-button>
        </template>

        <span slot="action" slot-scope="text, record">
         <div style='display: flex;justify-content: space-evenly;align-items: center'>

           <a @click='handleEdit(record)'>{{ $t('edit') }}</a>
            <a-divider type="vertical" />
                <list-actions
                  :record='record'
                  @view='handleView'
                  @edit='handleEdit'
                  @delete='handleDelete'
                  @action='$refs.detailForm.handleActionClick'
                  @copy='handleCopy'
                ></list-actions>
            <a-divider type="vertical" />
             <a-popconfirm :title="$t('confirmDeleteAlert')" @confirm="() => handleDelete(record.id)">
                  <a>{{ $t('delete') }}</a>
                </a-popconfirm>
            </div>
        </span>

      </a-table>
    </div>


    <cumFollowUpRecord-modal ref="modalForm" @ok="modalFormOk"></cumFollowUpRecord-modal>
    <cumFollowUpRecord-detail ref="detailForm" @action="handleActionClick" @close="handleViewModalClose"
                                      @loadData="loadData" @clearRouteQuery="clearRouteQuery"></cumFollowUpRecord-detail>
  </a-card>
  </a-modal>
</template>

<script>
  import {  cumFollowUpRecordApi } from './modules/api/CumFollowUpRecord'
  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
  import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
  import { SettingColumns } from 'dbcp-frontends-common/lib/components'
  import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
  import CumFollowUpRecordModal from './modules/CumFollowUpRecordModal'
  import CumFollowUpRecordDetail from './modules/CumFollowUpRecordDetail'
  import { ListActions } from 'dbcp-frontends-common/lib/components'
  import { CommonSelector } from 'dbcp-frontends-common/lib/components'
  import { SelectorMixin } from 'dbcp-frontends-common/lib/mixins'
  import { JDate } from 'dbcp-frontends-common/lib/components'
  import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'
  import InputSelect  from '@/components/dbcp/InputSelect'
  import { downFile } from 'dbcp-frontends-common/lib/api'

  export default {
    name: "CumFollowUpRecordList",
    mixins:[JeecgListMixin, mixinDevice, ViewMixin, ApprovalListMixin,SelectorMixin],
    components: {
      JDate,
      JSelectUserByDep,
      CumFollowUpRecordModal,
      CumFollowUpRecordDetail,
      ListActions,
      SettingColumns,
      CommonSelector,
      InputSelect
    },
    data () {
      return {
        description: 'cum_follow_up_record管理页面',
        visible: false,
        // 表头
        columns: [
          // {
          //   title: '#',
          //   dataIndex: '',
          //   key: 'rowIndex',
          //   width: 60,
          //   align: 'center',
          //   customRender: function (t,r,index) {
          //     return parseInt(index)+1;
          //   }
          // },
          // {
          //   title: '项目细项',
          //   align: 'center',
          //   dataIndex: 'projectItemId'
          // },
          {
            title: '业主',
            align: 'center',
            dataIndex: 'ownerId_dictText'
          },
          {
            title: '走访时间',
            align: 'center',
            dataIndex: 'visitTime'
          },
          {
            title: '走访人员',
            align: 'center',
            dataIndex: 'visitStaff_dictText'
          },
          {
            title: '下次走访时间',
            align: 'center',
            dataIndex: 'nextVisitTime'
          },
          {
            title: '创建人',
            align: 'center',
            dataIndex: 'addBy_dictText'
          },
          {
            title: '创建时间',
            align: 'center',
            dataIndex: 'addTime'
          },
          {
            title: '更新人',
            align: 'center',
            dataIndex: 'updateBy_dictText'
          },
          {
            title: '更新时间',
            align: 'center',
            dataIndex: 'updateTime'
          },
          {
            title: this.$i18n.tc('action'),
            dataIndex: 'action',
            align: 'center',
            // fixed:"right",
            width: 147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: cumFollowUpRecordApi.url,
        dictOptions:{},
        /* 禁用created列表数据加载 */
        disableMixinCreated: true,
        currentRecord:{},
        //新建时传过去的数据
        handleAddRecord:{},
      }
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
    },
    methods: {
      initDictConfig(){
      },
      handleCancel() {
        this.$emit('close')
      },
      show(record) {
        console.log("record",record)
        this.visible = true
        this.currentRecord = record
        this.queryParam = {};
        this.queryParam.projectItemId = record.id ;
        this.queryParam.ownerId = record.rightHolder ;
        this.handleAddRecord = {
          projectItemId:record.id,
          ownerId:record.rightHolder ,
          visitStaff:record.follower

        }
        // this.queryParam = Object.assign({},record,this.queryParam)
        this.loadData();

      },
      searchReset(){
        this.queryParam = {} ;
        this.queryParam.ownerId = this.currentRecord.rightHolder ;
        // this.queryParam = Object.assign({},this.currentRecord.ownerId,this.queryParam)
        this.loadData();
      },
      toHandleExportXlsProgress(fileName){
        downFile('/cum/cumProject/getOwnerNegotiationProgress',{
          projectItemId:this.queryParam.projectItemId
        }).then((data)=>{
          if (!data) {
            return
          }
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(new Blob([data],{type: 'application/vnd.ms-excel'}), fileName+'.xlsx')
          }else{
            let url = window.URL.createObjectURL(new Blob([data],{type: 'application/vnd.ms-excel'}))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName+'.xlsx')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link); //下载完成移除元素
            window.URL.revokeObjectURL(url); //释放掉blob对象
          }
        })
      },
      handleMenuClick(event,fileName){
        console.log("event",event.key,fileName);
        if(event.key === "excel") {
          this.toHandleExportXlsProgress(fileName);
        }
        if (event.key === "pdf") {
          let pdfUrl = "/cum/cumProject/getOwnerNegotiationProgressPDF";
          let param = {};
          param["projectItemId"] = this.queryParam.projectItemId ;

          this.toHandleExportPdf(pdfUrl, param, fileName);
        }
      },
      toHandleExportPdf(pdfUrl,param,fileName){
        console.log("导出参数",param)
        downFile(pdfUrl,param).then((data)=>{
          if (!data) {
            return
          }
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(new Blob([data],{type: 'application/vnd.ms-excel'}), fileName+'.pdf')
          }else{
            let url = window.URL.createObjectURL(new Blob([data],{type: 'application/vnd.ms-excel'}))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName+'.pdf')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link); //下载完成移除元素
            window.URL.revokeObjectURL(url); //释放掉blob对象
          }
        })
      },
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>
