<template>
  <a-card :bordered='false'>
    <!-- 查询区域 -->
    <div class='table-page-search-wrapper'>
      <a-form layout='inline' @keyup.enter.native='searchQuery'>
        <a-row :gutter='24'>
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <a-form-item label='楼栋'>
              <!--                <common-selector url='/cum/cumBuilding/list' showName='id' :showItem="['id']" title='楼栋选择器'-->
              <!--                                 v-model='queryParam.buildingId' :multi='false'></common-selector>-->
              <InputSelect v-model='queryParam.buildingId' show-name='#${id}' send-params='id'
                           url='dbcp/city/propertyManagement/buildingList/CumBuildingList.vue'
                           type='radio'
              ></InputSelect>
            </a-form-item>
          </a-col>
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <a-form-item label='单元'>
              <!--                <common-selector url='/cum/cumUnit/list' showName='id' :showItem="['id']" title='单元选择器'-->
              <!--                                 v-model='queryParam.unitId' :multi='false'></common-selector>-->
              <InputSelect v-model='queryParam.unitId' show-name='#${id}' send-params='id'
                           url='dbcp/city/propertyManagement/unitList/CumUnitList.vue'
                           type='radio'></InputSelect>
            </a-form-item>
          </a-col>
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <a-form-item label='跟进人员'>
              <j-select-user-by-dep :hideDept="true" placeholder='请选择跟进人员' v-model='queryParam.follower' />
            </a-form-item>
          </a-col>
          <a-col>
            <span style="float: left;overflow: hidden;margin-left: 15px" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{
                  $t('reset')
                }}</a-button>
                <a @click="handleToggleSearch" style="margin-left: 8px">
                {{ toggleSearchStatus ? $t('collapse') : $t('expand') }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
              </a>
            </span>
          </a-col>
          <template v-if='toggleSearchStatus'>

            <a-col :xl='6' :lg='7' :md='8' :sm='24'>
              <a-form-item label='权利人' class='rightHolderForm'>
                <!--                  <common-selector url='/cum/cumOwner/list' showName='name' :showItem="['name']" title='权利人选择器'-->
                <!--                                   v-model='queryParam.rightHolder' :multi='false'></common-selector>-->
                <InputSelect v-model='queryParam["rightHolder_CONCAT_LIKE_IN_,"]' show-name='name' send-params='id'
                             url='dbcp/city/cumOwner/CumOwnerList.vue'
                             type='checkbox'></InputSelect>
              </a-form-item>
            </a-col>
            <a-col :xl='4' :lg='7' :md='8' :sm='24'>
              <a-form-item label='拆迁谈判难易等级'>
                <j-dict-select-tag type="list" v-model='queryParam.negotiationDifficultyLevel'
                                   dictCode="cum_negotiation_difficulty_level"
                                   placeholder="请输入拆迁谈判难易等级"
                                   :showManage='false'
                />
<!--                <a-input placeholder='请输入拆迁谈判难易等级' v-model='queryParam.negotiationDifficultyLevel'></a-input>-->
              </a-form-item>
            </a-col>
<!--            <a-col :xl='4' :lg='7' :md='8' :sm='24'>-->
<!--              <a-form-item label='状态'>-->
<!--                <j-dict-select-tag placeholder='请选择状态' v-model='queryParam.status'-->
<!--                                   dictCode='cum_project_item_status' :showManage='false' />-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <a-col :xl='4' :lg='7' :md='8' :sm='24'>
              <a-form-item label='是否委托公证'>
                <j-dict-select-tag placeholder='请选择是否委托公证' v-model='queryParam.isEntrustedNotarization'
                                   dictCode='yn' :showManage='false' />
              </a-form-item>
            </a-col>
            <a-col :xl='4' :lg='7' :md='8' :sm='24'>
              <a-form-item label='房产证是否已注销'>
                <j-dict-select-tag placeholder='请选择房产证是否已注销' v-model='queryParam.isCancellationRealEstateLicense'
                                   dictCode='yn' :showManage='false' />
              </a-form-item>
            </a-col>
          </template>

        </a-row>
        <a-row :gutter="24" v-show="toggleSearchStatus">
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <a-form-item label='合同评审状态'>
              <j-dict-select-tag type="list" v-model='queryParam.contractStatus' :open-search="true" dictCode="cum_project_item_contract_status" :showManage='false' style='width: 100%'/>
            </a-form-item>
          </a-col>
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <a-form-item label='谈判条件状态'>
              <j-dict-select-tag type="list" v-model='queryParam["bargainingPowerStatus_CONCAT_LIKE_IN_,"]' :open-search="true" dictCode="cum_project_item_bargaining_power_status" :showManage='false' style='width: 100%'/>
            </a-form-item>
          </a-col>
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <a-form-item label='收楼付款状态'>
              <j-dict-select-tag type="list" v-model='queryParam["collectionPaymentTargetStatus_CONCAT_LIKE_IN_,"]' :open-search="true" dictCode="cum_project_item_collection_payment_status" :showManage='false' style='width: 100%'/>
            </a-form-item>
          </a-col>
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <a-form-item label='签约状态'>
              <j-dict-select-tag type="list" v-model='queryParam["contractedStatus"]' :open-search="true" dictCode="cum_project_item_contracted_status" :showManage='false' style='width: 100%'/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" v-show="toggleSearchStatus">

          <a-col :xl='8' :lg='7' :md='8' :sm='24'>
            <a-form-item label='合同评审完成时间'>
              <a-range-picker  v-model='queryParam.contractDoneTime' format='YYYY-MM-DD HH:mm:ss' :show-time='true'/>
            </a-form-item>
          </a-col>
          <a-col :xl='8' :lg='7' :md='8' :sm='24'>
            <a-form-item label='条件谈判完成时间'>
              <a-range-picker  v-model='queryParam.bargainingPowerTime' format='YYYY-MM-DD HH:mm:ss' :show-time='true'/>
            </a-form-item>
          </a-col>
          <a-col :xl='8' :lg='7' :md='8' :sm='24'>
            <a-form-item label='最近支付时间'>
              <a-range-picker  v-model='queryParam.firstPayTime' format='YYYY-MM-DD HH:mm:ss' :show-time='true'/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" v-show="toggleSearchStatus">
          <a-col :xl='8' :lg='7' :md='8' :sm='24'>
            <a-form-item label='收楼付款时间'>
              <a-range-picker  v-model='queryParam.collectionPaymentTargetTime' format='YYYY-MM-DD HH:mm:ss' :show-time='true'/>
            </a-form-item>
          </a-col>
          <a-col :xl='8' :lg='7' :md='8' :sm='24'>
            <a-form-item label='签约时间'>
              <a-range-picker  v-model='queryParam.contractedTime' format='YYYY-MM-DD HH:mm:ss' :show-time='true'/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class='table-operator'>
      <!--        v-has="'cumProjectItem:create'"-->
      <a-button @click='handleAdd(currentRecord)' type='primary' icon='plus'>{{ $t('create') }}
      </a-button>
<!--      <a-button type="primary" icon="download"-->
<!--                @click="toHandleExportXls('权利人核实信息表')"-->
<!--                v-has='"cumProjectItem:exportOwnerPropertyInformation"'-->
<!--                v-if='selectedRowKeys.length > 0'-->
<!--      >导出权利人核实信息表</a-button>-->
      <a-dropdown  v-if='selectedRowKeys.length > 0'>
        <a-menu slot="overlay" @click="e => handleMenuClick(e,'权利人核实信息表')">
          <a-menu-item key="excel" v-has='"cumProjectItem:exportOwnerPropertyInformation"'>导出excel</a-menu-item>
          <a-menu-item key="pdf" v-has='"cumProjectItem:exportOwnerPropertyInformationPDF"'>导出pdf</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px">导出权利人核实信息表<a-icon type="down" /> </a-button>
      </a-dropdown>

<!--      <a-button type="primary" icon="download"-->
<!--                @click="toHandleExportXlsNoSign('未签约业主明细表')"-->
<!--                v-has='"cumProject:export:detailsOfNonContractedOwners"'-->
<!--      >导出未签约业主明细表</a-button>-->
      <a-dropdown>
        <a-menu slot="overlay" @click="e => handleMenuClick(e,'未签约业主明细表')">
          <a-menu-item key="excel" v-has='"cumProject:export:detailsOfNonContractedOwners"'>导出excel</a-menu-item>
          <a-menu-item key="pdf" v-has='"cumProject:export:detailsOfNonContractedOwnersPDF"'>导出pdf</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px">导出未签约业主明细表<a-icon type="down" /> </a-button>
      </a-dropdown>

<!--      <a-button type="primary" icon="download"-->
<!--                @click="toHandleExportXlsSign('已签约业主统计表')"-->
<!--                v-has='"cumProject:export:statisticsOfContractedOwners"'-->
<!--                v-if='selectedRowKeys.length > 0'-->
<!--      >导出已签约业主统计表</a-button>-->

      <a-dropdown  v-if='selectedRowKeys.length > 0'>
        <a-menu slot="overlay" @click="e => handleMenuClick(e,'已签约业主统计表')">
          <a-menu-item key="excel" v-has='"cumProject:export:statisticsOfContractedOwners"'>导出excel</a-menu-item>
          <a-menu-item key="pdf" v-has='"cumProject:export:statisticsOfContractedOwnersPDF"'>导出pdf</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px">导出已签约业主统计表<a-icon type="down" /> </a-button>
      </a-dropdown>

<!--      <a-button type="primary" icon="download"-->
<!--                @click="toHandleExportXlsOwner('项目业主明细表')"-->
<!--                v-has='"cumProject:export:detailsOfProjectOwner"'-->
<!--      >导出项目业主明细表</a-button>-->

      <a-dropdown>
        <a-menu slot="overlay" @click="e => handleMenuClick(e,'项目业主明细表')">
          <a-menu-item key="excel" v-has='"cumProject:export:detailsOfProjectOwner"'>导出excel</a-menu-item>
          <a-menu-item key="pdf" v-has='"cumProject:export:detailsOfProjectOwnerPDF"'>导出pdf</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px">导出项目业主明细表<a-icon type="down" /> </a-button>
      </a-dropdown>

<!--      <a-button type="primary" icon="download"-->
<!--                @click="toHandleExportXlsPay('导出支付统计表')"-->
<!--                v-has='"cumProject:export:detailsOfPaymentStatistics"'-->
<!--                v-if='selectedRowKeys.length > 0'-->
<!--      >导出支付统计表</a-button>-->

      <a-dropdown  v-if='selectedRowKeys.length > 0'>
        <a-menu slot="overlay" @click="e => handleMenuClick(e,'支付统计表')">
          <a-menu-item key="excel" v-has='"cumProject:export:detailsOfPaymentStatistics"'>导出excel</a-menu-item>
          <a-menu-item key="pdf" v-has='"cumProject:export:detailsOfPaymentStatisticsPDF"'>导出pdf</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px">导出支付统计表<a-icon type="down" /> </a-button>
      </a-dropdown>


      <!--      <a-upload name="file" :showUploadList="false" :multiple="false" :headers="tokenHeader" :action="importExcelUrl" @change="handleImportExcel">-->
      <!--        <a-button type="primary" icon="import">{{ $t('import') }}</a-button>-->
      <!--      </a-upload>-->
      <a-dropdown v-if='selectedRowKeys.length > 0'>
        <a-menu slot='overlay'>
          <a-menu-item key='1' @click='batchDel'>
            <a-icon type='delete' />
            {{ $t('delete') }}
          </a-menu-item>
        </a-menu>
        <a-button style='margin-left: 8px'> {{ $t('batchOperation') }}
          <a-icon type='down' />
        </a-button>

      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>
      <setting-columns :columns='columns' @changeRealColumns='(data) => columns = data'></setting-columns>
      <a-table
        ref='table'
        size='middle'
        bordered
        rowKey='id'
        :columns='columns'
        :dataSource='dataSource'
        :pagination='ipagination'
        :loading='loading'
        :rowSelection='type?{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type,onSelect:handleSelection,onSelectAll:handleSelectAll}:null'
        class='j-table-force-nowrap'
        @change='handleTableChange'>

        <template slot='buildingId' slot-scope='text,record'>
          <div v-if='text'>
              <span v-for='(item,index) in text.split(",")' :key='index'>
                <a @click='handleBuildingDetail(item)' style='display: block'>#{{item}}</a>
              </span>
          </div>
          <!--            <a @click='handleBuildingDetail(text)'>#{{text}}</a>-->
        </template>

        <span slot='unitId' slot-scope='text,record'>
            <div v-if='text'>
              <span v-for='(item,index) in text.split(",")' :key='index'>
                <a @click='handleUnitDetail(item)' style='display: block'>#{{item}}</a>
              </span>
            </div>
          <!--            <a @click='handleUnitDetail(text)'>#{{text}}</a>-->
          </span>

        <span slot='status' slot-scope='text,record' v-if='text'>
            <a-tag :color='getProjectItemStatusColor(record.status)'>{{text}}</a-tag>
        </span>

        <span slot='rightHolderChecked' slot-scope='text,record' v-if='text'>
            <a-tag :color='getRightHolderCheckedStatusColor(record.rightHolderChecked)'>{{text}}</a-tag>
        </span>

        <span slot='isEntrustedNotarization' slot-scope='text,record' v-if='text'>
            <a-tag :color='getIsEntrustedNotarizationColor(record.isEntrustedNotarization)'>{{text}}</a-tag>
        </span>

        <span slot='isCancellationRealEstateLicense' slot-scope='text,record' v-if='text'>
            <a-tag :color='getIsCancellationRealEstateLicenseColor(record.isCancellationRealEstateLicense)'>{{text}}</a-tag>
        </span>

        <span slot='contractStatus' slot-scope='text,record' v-if='text'>
            <a-tag :color='getContractStatusStatusColor(record.contractStatus)'>{{text}}</a-tag>
        </span>
        <span slot='bargainingPowerStatus' slot-scope='text,record' v-if='text'>
            <a-tag :color='getContractStatusStatusColor(record.bargainingPowerStatus)'>{{text}}</a-tag>
        </span>
        <span slot='firstPayStatus' slot-scope='text,record' v-if='text'>
            <a-tag :color='getFirstPayStatusColor(record.firstPayStatus)'>{{text}}</a-tag>
        </span>
        <span slot='collectionPaymentTargetStatus' slot-scope='text,record' v-if='text'>
            <a-tag :color='getFirstPayStatusColor(record.collectionPaymentTargetStatus)'>{{text}}</a-tag>
        </span>
        <span slot='contractedStatus' slot-scope='text,record' v-if='text'>
            <a-tag :color='getSurveyingStatusColor(record.contractedStatus)'>{{text}}</a-tag>
        </span>

        <span slot='action' slot-scope='text, record'>
            <div style='display: flex;justify-content: space-evenly;align-items: center'>
               <list-actions
                 :record='record'
                 @view='handleView'
                 @edit='handleEdit'
                 @delete='handleDelete'
                 @action='$refs.detailForm.handleActionClick'
                 @copy='handleCopy'
               ></list-actions>
              <a-divider type="vertical" />
              <a @click='handleEdit(record)'>{{ $t('edit') }}</a>
              <a-divider type="vertical" />
              <a @click='handleRightHolderChecked(record)'>权利人核实信息</a>
              <a-divider type="vertical" />
               <a @click='handleShowItem(record)'>访谈记录</a>
              <a-divider type="vertical" />
              <a @click='turnBargainingPower(record)'>谈判条件</a>
              <a-divider type="vertical" />
              <a @click='turnContractAdd(record)'>合同</a>
<!--              <a-divider type="vertical" />-->
<!--              <a @click='turnFirstPay(record)'>首付审批</a>-->
              <a-divider type="vertical" />
              <a @click='handleShowIncome(record)'>价值表</a>
              <a-divider type="vertical" />
              <a @click='handleMoveBack(record)'>回迁</a>
            </div>

        </span>

      </a-table>
    </div>


    <cumProjectItem-modal ref='modalForm' @ok='modalFormOk'></cumProjectItem-modal>
    <cumProjectItem-detail ref='detailForm' @action='handleActionClick' @close='handleViewModalClose'
                           @loadData='loadData' @clearRouteQuery='clearRouteQuery'></cumProjectItem-detail>
    <cum-follow-up-record-list ref='followUp'></cum-follow-up-record-list>

    <cum-move-back-info-list ref='moveBackForm'></cum-move-back-info-list>

    <cumBuilding-detail ref='buildingDetailForm'></cumBuilding-detail>

    <cumUnit-detail ref='unitDetailForm' ></cumUnit-detail>
    <cum-income-value-contrast-modal ref='incomeModal'></cum-income-value-contrast-modal>

    <cumContract-modal ref="contractForm" @ok="modalContractFormFormOk"></cumContract-modal>
    <cumContract-detail ref='contractDetail'></cumContract-detail>
    <cum-bargaining-power-modal ref="bargainingPowerForm" @ok="bargainingPowerFormFormOk"></cum-bargaining-power-modal>
    <cum-bargaining-power-detail ref='bargainningPowerDetail'></cum-bargaining-power-detail>
    <cum-first-pay-modal ref='firstPayForm' @ok='firstPayFormFormOk'></cum-first-pay-modal>

    <cum-first-pay-detail ref='firstPayDetail' @action='handleActionClick'></cum-first-pay-detail>

    <turn-list-modal ref='turnListModal' :url='turnUrl'></turn-list-modal>

    <RightHolderCheckedModal ref='rightholderCheckedForm' @ok='loadData'></RightHolderCheckedModal>
  </a-card>
</template>

<script>

import { cumProjectItemApi } from './modules/api/CumProjectItem'
import '@/assets/less/TableExpand.less'
import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
import CumProjectItemModal from './modules/CumProjectItemModal'
import CumProjectItemDetail from './modules/CumProjectItemDetail'
import { ListActions } from 'dbcp-frontends-common/lib/components'
import { JDictSelectTag } from 'dbcp-frontends-common/lib/components'
import { CommonSelector } from 'dbcp-frontends-common/lib/components'
import { filterMultiDictText } from '@/components/dict/JDictSelectUtil'
import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'
import CumFollowUpRecordList from '../followUpRecord/CumFollowUpRecordList'
import CumMoveBackInfoList from '../moveBack/CumMoveBackInfoList'
import InputSelect from '@/components/dbcp/InputSelect'
import { getAction,downFile } from 'dbcp-frontends-common/lib/api'
import CumBuildingDetail from '../propertyManagement/buildingList/modules/CumBuildingDetail'
import CumUnitDetail from '../propertyManagement/unitList/modules/CumUnitDetail'
import CumIncomeValueContrastModal from '../incomeValueContrest/modules/CumIncomeValueContrastModal'
import { SelectorMixin } from 'dbcp-frontends-common/lib/mixins'
import CumContractModal from '../contractApprove/modules/CumContractModal'
import CumContractDetail from '../contractApprove/modules/CumContractDetail'
import CumBargainingPowerModal from '../bargainingPower/modules/CumBargainingPowerModal'
import CumBargainingPowerDetail from '../bargainingPower/modules/CumBargainingPowerDetail'
import CumFirstPayModal from '../firstPay/modules/CumFirstPayModal'
import CumFirstPayDetail from '../firstPay/modules/CumFirstPayDetail'
import { SettingColumns } from 'dbcp-frontends-common/lib/components'
import {
  getProjectItemStatusColor,
  getRightHolderCheckedStatusColor,
  getIsEntrustedNotarizationColor,
  getIsCancellationRealEstateLicenseColor,
  getContractStatusStatusColor,
  getFirstPayStatusColor,
  getSurveyingStatusColor
} from '@/utils/util'
import TurnListModal from '../../../../components/TurnListModal'
import RightHolderCheckedModal from './modules/RightHolderCheckedModal'

export default {
  name: 'CumProjectItemTable',
  mixins: [JeecgListMixin, mixinDevice, ViewMixin, ApprovalListMixin,SelectorMixin],
  components: {
    RightHolderCheckedModal,
    TurnListModal,
    CumBargainingPowerModal,
    CumBargainingPowerDetail,
    CumMoveBackInfoList,
    JDictSelectTag,
    CumProjectItemModal,
    CumProjectItemDetail,
    ListActions,
    CommonSelector,
    CumFollowUpRecordList,
    InputSelect,
    CumBuildingDetail,
    CumUnitDetail,
    CumIncomeValueContrastModal,
    CumContractModal,
    CumContractDetail,
    CumFirstPayModal,
    CumFirstPayDetail,
    JSelectUserByDep,
    SettingColumns

  },
  props:{
    type:{
      type: String,
      default:'checkbox',
    }
  },
  data(){
    return {
      description: '项目细项管理表格',
      // 表头
      columns:  [
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function(t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '项目',
          align: 'center',
          dataIndex: 'projectId_dictText'
        },
        {
          title: '楼栋',
          align: 'center',
          dataIndex: 'buildingId',
          // customRender: function(text){
          //   return "#" + text
          // }
          scopedSlots: { customRender: 'buildingId' }
        },
        {
          title: '单元',
          align: 'center',
          dataIndex: 'unitId',
          // customRender: function(text){
          //   return "#" + text
          // }
          scopedSlots: { customRender: 'unitId' }
        },
        {
          title: '权利人',
          align: 'center',
          dataIndex: 'rightHolder_dictText'
        },
        {
          title: '跟进人员',
          align: 'center',
          dataIndex: 'follower_dictText'
        },
        {
          title: '权利人核实',
          align: 'center',
          dataIndex: 'rightHolderChecked_dictText',
          scopedSlots: { customRender: 'rightHolderChecked' },
        },
        {
          title: '拆迁谈判难易等级',
          align: 'center',
          dataIndex: 'negotiationDifficultyLevel_dictText'
        },
        {
          title: '签约状态',
          align: 'center',
          dataIndex: 'contractedStatus_dictText',
          scopedSlots: { customRender: 'contractedStatus' }
        },
        {
          title: '是否委托公证',
          align: 'center',
          dataIndex: 'isEntrustedNotarization_dictText',
          scopedSlots: { customRender: 'isEntrustedNotarization' },
        },
        {
          title: '谈判条件状态',
          align: 'center',
          dataIndex: 'bargainingPowerStatus_dictText',
          scopedSlots: { customRender: 'bargainingPowerStatus' }
        },
        {
          title: '谈判条件完成时间',
          align: 'center',
          dataIndex: 'bargainingPowerTime'
        },
        {
          title: '合同评审状态',
          align: 'center',
          dataIndex: 'contractStatus_dictText',
          scopedSlots: { customRender: 'contractStatus' }
        },
        {
          title: '合同评审完成时间',
          align: 'center',
          dataIndex: 'contractDoneTime'
        },
        {
          title: '签约时间',
          align: 'center',
          dataIndex: 'contractedTime'
        },


        {
          title: '付款状态',
          align: 'center',
          dataIndex: 'firstPayStatus_dictText',
          scopedSlots: { customRender: 'firstPayStatus' }
        },
        {
          title: '最近支付时间',
          align: 'center',
          dataIndex: 'firstPayTime'
        },
        {
          title: '收楼审批状态',
          align: 'center',
          dataIndex: 'collectionPaymentTargetStatus_dictText',
          scopedSlots: { customRender: 'collectionPaymentTargetStatus' }
        },
        {
          title: '收楼审批时间',
          align: 'center',
          dataIndex: 'collectionPaymentTargetTime'
        },
        {
          title: '房产证是否已注销',
          align: 'center',
          dataIndex: 'isCancellationRealEstateLicense_dictText',
          scopedSlots: {  customRender: 'isCancellationRealEstateLicense' }
        },
        {
          title: '更新人',
          align: 'center',
          dataIndex: 'updateBy_dictText'
        },
        {
          title: '更新时间',
          align: 'center',
          dataIndex: 'updateTime'
        },
        {
          title: this.$i18n.tc('action'),
          dataIndex: 'action',
          align: 'center',
          // fixed:"right",
          width: 147,
          scopedSlots: { customRender: 'action' }
        }
      ],
      url: cumProjectItemApi.url,
      dictOptions: {},
      /* 禁用created列表数据加载 */
      disableMixinCreated: true,
      currentRecord: {},
      flag:false,
      ipagination:{
        current: 1,
        pageSize: localStorage.getItem('TABLE_SIZE') ? parseInt(localStorage.getItem('TABLE_SIZE')) : 10,
        pageSizeOptions: ['10', '20', '30','100'],
        showTotal: (total, range) => {
          return `${range[0]} - ${range[1]} ${this.$i18n.tc('total')} ${total} ${this.$i18n.tc('item')}`
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
        //pageSize 变化的回调
        onShowSizeChange:(current,size) => {
          //current  当前页
          //size     当前一页的总条数
          localStorage.setItem('TABLE_SIZE',size)
        }
      },
      turnUrl:"",
      getProjectItemStatusColor,
      getRightHolderCheckedStatusColor,
      getIsEntrustedNotarizationColor,
      getIsCancellationRealEstateLicenseColor,
      getContractStatusStatusColor,
      getFirstPayStatusColor,
      getSurveyingStatusColor
    }
  },
  watch:{
    '$attrs.projectId':{
      immediate:true,
      handler:function(val){
        if(val){
          this.flag = true;
          this.queryParam.projectId = val;
          this.currentRecord.projectId = val
          this.loadData();
        }
      }
    }
  },
  created() {
  },
  methods:{
    initListData(record){
      this.currentRecord = record
      this.queryParam = {};
      this.queryParam = Object.assign({},record,this.queryParam);
      this.loadData();
    },
    //权利人核实信息
    handleRightHolderChecked(record){
      this.$refs.rightholderCheckedForm.show(record);
    },
    handleShowItem(record) {
      console.log("record.id",record.id);
      // this.$refs.followUp.show({ projectItemId: record.id })
      this.$refs.followUp.show(record)
    },
    handleMoveBack(record){
      console.log("record",record)
      this.$refs.moveBackForm.show({ projectId:record.projectId ,projectItemId: record.id,ownerId:record.rightHolder})
    },
    handleBuildingDetail(id){
      getAction("/cum/cumBuilding/list",{id:id}).then(res => {
        console.log("获取楼栋信息",res.result.records);
        this.$refs.buildingDetailForm.view(res.result.records[0])
      })
    },
    handleUnitDetail(id){
      getAction("/cum/cumUnit/list",{id:id}).then(res=>{
        console.log("获取单位信息",res.result.records);
        this.$refs.unitDetailForm.view(res.result.records[0])
      })
    },
    handleShowIncome(record){
      console.log(' this.$refs.incomeModal', this.$refs.incomeModal)
      this.$refs.incomeModal.show(record.id)
    },
    handleSearchReset(){
      this.queryParam = {};
      this.queryParam = Object.assign({},this.currentRecord,this.queryParam);
      this.loadData();
    },
    turnContractAdd(record){
      console.log("审批",record);
      // if(record.contractId){
      //   getAction('/cum/cumContract/list',{id:record.contractId}).then(res=>{
      //     this.$nextTick(()=> {
      //       this.$refs.contractDetail.view(res.result.records[0])
      //     })
      //   })
      // }else{
      //   let data = {};
      //   data.projectItemId = record.id ;
      //   data.projectId = record.projectId ;
      //   console.log("dsjaodaos",record)
      //   this.$nextTick(()=>{
      //     this.$refs.contractForm.add(data);
      //   })
      // }
      this.turnUrl = "dbcp/city/contractApprove/CumContractList.vue";
      this.$nextTick(()=>{
        this.$refs.turnListModal.show({ projectItemId: record.id, projectId:record.projectId});
      })
    },
    turnBargainingPower(record){
      this.turnUrl = "dbcp/city/bargainingPower/CumBargainingPowerList.vue";
      // if(record.bargainingPowerId){
      //   getAction("/cum/cumBargainingPower/list",{id:record.bargainingPowerId}).then(res=>{
      //     console.log("谈判条件",res);
      //     this.$refs.bargainningPowerDetail.view(res.result.records[0]);
      //   })
      // }else{
      //   let data = {} ;
      //   data.projectItemId = record.id ;
      //   console.log("project",record.projectId)
      //   data.projectId = record.projectId ;
      //   this.$refs.bargainingPowerForm.add(data);
      // }
      this.$nextTick(()=>{
        this.$refs.turnListModal.show({ projectItemId: record.id, projectId:record.projectId});
      })

    },
    turnFirstPay(record){
      this.turnUrl = "dbcp/city/firstPay/CumFirstPayList.vue";
      // if(record.firstPayMoneyId){
      //   getAction("/cum/cumFirstPay/list",{id:record.firstPayMoneyId}).then(res=>{
      //     console.log("获取首付审批",res.result);
      //     this.$refs.firstPayDetail.view(res.result.records[0])
      //   })
      // }else{
      //   //传两个值
      //   let data = {};
      //   data.projectItemId = record.id;
      //   data.projectId = record.projectId;
      //   this.$refs.firstPayForm.add(data);
      // }
      this.$nextTick(()=>{
        this.$refs.turnListModal.show({ projectItemId: record.id, projectId:record.projectId});
      })

    },
    modalContractFormFormOk(){
      this.$emit('closeModal');
      this.$router.push('/cum/contract-approve');
    },
    bargainingPowerFormFormOk(){
      this.$emit('closeModal');
      this.$router.push('/cum/bargaining-power');
    },
    firstPayFormFormOk(){
      this.loadData();
      this.$emit('closeModal');
      this.$router.push('/cum/first-pay');
    },
    toHandleExportXls(fileName){
        let param = {};
        if(this.selectedRowKeys && this.selectedRowKeys.length>0){
          param['ids'] = this.selectedRowKeys.join(",")
        }
        console.log("导出参数",param)
        downFile("/cum/cumProjectItem/exportVerificationInformationExcel",param).then((data)=>{
          console.log("data",data,data instanceof Blob ,data.type,window.navigator.msSaveBlob)
          if (!data) {
            return
          }
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(new Blob([data],{type: 'application/vnd.ms-excel'}), fileName+'.xlsx')
          }else{
            let url = window.URL.createObjectURL(new Blob([data],{type: 'application/vnd.ms-excel'}))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName+'.xlsx')
            console.log("link",link)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link); //下载完成移除元素
            window.URL.revokeObjectURL(url); //释放掉blob对象
          }
        })
    },
    toHandleExportPdf(pdfUrl,param,fileName){
      console.log("导出参数",param)
      downFile(pdfUrl,param).then((data)=>{
        // console.log("data231232",data,data instanceof Blob)
        if (!data) {
          return
        }
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(new Blob([data],{type: 'application/pdf'}), fileName+'.pdf')
        }else{
          let url = window.URL.createObjectURL(new Blob([data],{type: 'application/pdf'}))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', fileName+'.pdf')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      })
    },
    handleMenuClick(event,fileName){
      console.log("event",event.key,fileName);
      if(event.key === "excel") {
        switch (fileName) {
          case "权利人核实信息表" :
            this.toHandleExportXls(fileName);
            break
          case "未签约业主明细表" :
            this.toHandleExportXlsNoSign(fileName);
            break
          case "已签约业主统计表" :
            this.toHandleExportXlsSign(fileName)
            break
          case "项目业主明细表":
            this.toHandleExportXlsOwner(fileName)
            break
          case "支付统计表":
            this.toHandleExportXlsPay(fileName)
            break
          default:
            break
        }
      }
      if (event.key === "pdf") {
        let pdfUrl = "";
        let param = {};
        console.log("odfnmae", fileName)
        switch (fileName) {
          case "权利人核实信息表" :
            if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
              param['ids'] = this.selectedRowKeys.join(",")
            }
            pdfUrl = "/cum/cumProjectItem/exportVerificationInformationPdf"
            break
          case "未签约业主明细表" :
            param['projectId'] = this.queryParam.projectId
            pdfUrl = "/cum/cumProject/getUnsignedOwnersDetailsPDF"
            break
          case "已签约业主统计表" :
            if(this.selectedRowKeys && this.selectedRowKeys.length>0){
              param['projectItemIds'] = this.selectedRowKeys.join(",")
            }
            pdfUrl = "cum/cumProject/getSignedOwnersDetailsPDF"
            break
          case "项目业主明细表":
            param['projectId'] = this.queryParam.projectId
            pdfUrl = "/cum/cumProject/getDetailsOfProjectOwnerPDF"
            break
          case "支付统计表":
            if(this.selectedRowKeys && this.selectedRowKeys.length>0){
              param['projectItemIds'] = this.selectedRowKeys.join(",")
            }
            pdfUrl = "/cum/cumProject/getPaymentStatisticsPDF"
            break
          default:
            break
        }
        console.log(pdfUrl, param, fileName);
        this.toHandleExportPdf(pdfUrl, param, fileName);
      }
    },
    toHandleExportXlsNoSign(fileName){
      downFile('/cum/cumProject/getUnsignedOwnersDetails',{
        projectId:this.queryParam.projectId
      }).then((data)=>{
        console.log("data===>",data)
        if (!data) {
          return
        }
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(new Blob([data],{type: 'application/vnd.ms-excel'}), fileName+'.xlsx')
        }else{
          let url = window.URL.createObjectURL(new Blob([data],{type: 'application/vnd.ms-excel'}))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', fileName+'.xlsx')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      })
    },

    toHandleExportXlsSign(fileName){
      let param = { };
      if(this.selectedRowKeys && this.selectedRowKeys.length>0){
        param['projectItemIds'] = this.selectedRowKeys.join(",")
      }
      console.log("导出参数",param)
      downFile('/cum/cumProject/getSignedOwnersDetails',param).then((data)=>{
        if (!data) {
          return
        }
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(new Blob([data],{type: 'application/vnd.ms-excel'}), fileName+'.xlsx')
        }else{
          let url = window.URL.createObjectURL(new Blob([data],{type: 'application/vnd.ms-excel'}))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', fileName+'.xlsx')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      })
    },
    initParams(params){
      if(params.contractDoneTime){
        params['contractDoneTime_begin'] = params.contractDoneTime[0].format("YYYY-MM-DD HH:mm:ss")
        params['contractDoneTime_end'] = params.contractDoneTime[1].format("YYYY-MM-DD HH:mm:ss")
        delete params.contractDoneTime
      }
      if(params.bargainingPowerTime){
        params['bargainingPowerTime_begin'] = params.bargainingPowerTime[0].format("YYYY-MM-DD HH:mm:ss")
        params['bargainingPowerTime_end'] = params.bargainingPowerTime[1].format("YYYY-MM-DD HH:mm:ss")
        delete params.bargainingPowerTime
      }
      if(params.firstPayTime){
        params['firstPayTime_begin'] = params.firstPayTime[0].format("YYYY-MM-DD HH:mm:ss")
        params['firstPayTime_end'] = params.firstPayTime[1].format("YYYY-MM-DD HH:mm:ss")
        delete params.firstPayTime
      }
      if(params.collectionPaymentTargetTime){
        params['collectionPaymentTargetTime_begin'] = params.collectionPaymentTargetTime[0].format("YYYY-MM-DD HH:mm:ss")
        params['collectionPaymentTargetTime_end'] = params.collectionPaymentTargetTime[1].format("YYYY-MM-DD HH:mm:ss")
        delete params.collectionPaymentTargetTime
      }

      if(params.contractedTime){
        params['contractedTime_begin'] = params.contractedTime[0].format("YYYY-MM-DD HH:mm:ss")
        params['contractedTime_end'] = params.contractedTime[1].format("YYYY-MM-DD HH:mm:ss")
        delete params.contractedTime
      }

      return params
    },
    close(){
      this.selectedRowKeys = []
    },
    toHandleExportXlsOwner(fileName){
      downFile('/cum/cumProject/getDetailsOfProjectOwner',{
        projectId:this.queryParam.projectId
      }).then((data)=>{
        if (!data) {
          return
        }
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(new Blob([data],{type: 'application/vnd.ms-excel'}), fileName+'.xlsx')
        }else{
          let url = window.URL.createObjectURL(new Blob([data],{type: 'application/vnd.ms-excel'}))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', fileName+'.xlsx')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      })
    },
    toHandleExportXlsPay(fileName){
      let param = {};
      if(this.selectedRowKeys && this.selectedRowKeys.length>0){
        param['projectItemIds'] = this.selectedRowKeys.join(",")
      }
      console.log("导出参数",param)
      downFile('/cum/cumProject/getPaymentStatistics',param).then((data)=>{
        if (!data) {
          return
        }
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(new Blob([data],{type: 'application/vnd.ms-excel'}), fileName+'.xlsx')
        }else{
          let url = window.URL.createObjectURL(new Blob([data],{type: 'application/vnd.ms-excel'}))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', fileName+'.xlsx')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      })
    },

  }
}
</script>

<style scoped>
/deep/.rightHolderForm .ant-form-item-label{
  min-width: 20%;
}
/deep/ .rightHolderForm .ant-form-item-control-wrapper{
  max-width:220px;
}
</style>