<template>
  <div>
    <a-modal
      :title="title"
      :width="width"
      placement="right"
      @cancel="close"
      :footer="null"
      :visible="visible">
      <a-descriptions :column="3" bordered size="small">
              <a-descriptions-item :label="$t('unitId')">
          #{{ data.unitId }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('type')">
          {{ data.type_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('area')">
          {{ data.area }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addBy')">
          {{ data.addBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addTime')">
          {{ data.addTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateBy')">
          {{ data.updateBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateTime')" :span='3'>
          {{ data.updateTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('remark')" :span='3'>
          {{ data.remark }}
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>

  </div>
</template>

<script>
  import {  cumUnitSubsidiaryApi } from './api/CumUnitSubsidiaryWorkFlow'
  import { DetailHistory } from 'dbcp-frontends-common/lib/components'
  import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ActionMixin } from 'dbcp-frontends-common/lib/mixins'


  export default {
    name: "CumUnitSubsidiaryDetail",
    components: { DetailHistory },
    mixins: [ DetailMixin,ActionMixin ],
    data() {
      return {
        url: cumUnitSubsidiaryApi.url,
      }
    },
    methods:{
      view(data){
        this.data = data
        this.visible = true
      }
    }
  }
</script>
