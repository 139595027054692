<template>
  <div>
    <a-modal
      :title="title"
      :width="width"
      placement="right"
      @cancel="close"
      :footer="null"
      :visible="visible">
      <a-descriptions :column="3" bordered size="small">
        <a-descriptions-item label="权利人">
          {{ data.name }}
        </a-descriptions-item>
        <a-descriptions-item label="证件类型">
          {{ data.idType_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label="证件号码">
          {{ data.idNo }}
        </a-descriptions-item>
        <template v-if='data.type === "1"'>
          <a-descriptions-item label="性质">
            {{ data.nature_dictText }}
          </a-descriptions-item>
          <a-descriptions-item label="企业主要经营范围">
            {{ data.mainBusinessScope }}
          </a-descriptions-item>
          <a-descriptions-item label="业主类型">
            {{ data.type_dictText }}
          </a-descriptions-item>
        </template>


        <a-descriptions-item label="联系方式" :span='3'>
          <span v-html="data.phone ? cutout(data.phone): ''"></span>
        </a-descriptions-item>
        <template v-if='data.type === "0"'>
          <a-descriptions-item :label="$t('character')">
            {{ data.character }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('marriage')">
            {{ data.marriage_dictText }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('sex')">
            {{ data.sex_dictText }}
          </a-descriptions-item>
        </template>
        <a-descriptions-item label="授权委托人">
          {{ data.consigner }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addBy')">
          {{ data.addBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addTime')">
          {{ data.addTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateBy')">
          {{ data.updateBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateTime')" :span='3'>
          {{ data.updateTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('remark')" :span='3'>
          <span v-html="data.remark ? cutout(data.remark): ''"></span>
        </a-descriptions-item>
        <a-descriptions-item label="企业详情" :span='3' v-if='data.type === "1"'>
          <span v-html="data.companyDetails ? cutout(data.companyDetails): ''"></span>
        </a-descriptions-item>
      </a-descriptions>

      <a-divider></a-divider>
    </a-modal>

  </div>
</template>

<script>
  import {  cumOwnerApi } from './api/CumOwnerWorkFlow'
  import { DetailHistory } from 'dbcp-frontends-common/lib/components'
  import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ActionMixin } from 'dbcp-frontends-common/lib/mixins'

  export default {
    name: "CumOwnerDetail",
    components: { DetailHistory },
    mixins: [ DetailMixin,ActionMixin ],
    data() {
      return {
        url: cumOwnerApi.url,
      }
    },
    methods:{
      view(data){
        this.visible = true
        this.data = data
      },
      // 截取数据
      cutout(cellValue) {
        if (cellValue) {
          return cellValue.replace(/\n/g, '</br>')
        }

      }
    }
  }
</script>
