import { uploadAction } from 'dbcp-frontends-common/lib/api'
import { cacheBank } from 'dbcp-frontends-common/lib/utils'

export const UploadFormMixin = {
  data() {
    return {
      //关于文件上传
      uploadFiles: {},
      uploadRule: {
        required: {
          rules: [
            { required: true, message: '请选择数据文件!' }
          ]
        }
      },
      defaultFileList: [],
      isFileListSpecial: true
    }
  },
  methods: {
    validate() {
      return true
    },
    handleOk(submit = false) {
      console.log('call handleOk')
      if (this.confirmLoading) {
        return
      }
      const that = this
      // 触发表单验证
      console.log('run form.ValidateFields')
      this.form.validateFields((err, values) => {
        this.sendError(err)
        if (!err) {
          // 自定义校验
          console.log('run validate')
          if (!this.validate()) return

          that.confirmLoading = true
          let httpurl = ''
          let method = 'post'
          if (!this.model.id) {
            httpurl += this.url.add
          } else {
            httpurl += this.url.edit
          }

          let formData = new FormData()
          formData.append('submit', submit)

          console.log('run Object.assign(this.model, values)')
          let data = Object.assign(this.model, values)

          console.log('run put data to formData')
          for (let k in data) {

            // 第二层嵌套转为JSON字符串处理
            if (data[k] instanceof Array) {
              formData.append(k, JSON.stringify(data[k]))
            } else if ((data[k] || data[k] === 0 || data[k] === false) && typeof data[k] !== 'object') {
              formData.append(k, data[k])
            } else {
              formData.append(k, '')
            }
          }
          // =============== process upload file ====================
          console.log('run put uploadFiles to formData')
          Object.keys(this.uploadFiles).forEach(item => {
            this.uploadFiles[item].forEach(childItem => {
              formData.append(item, childItem)
            })
          })




          formData.delete('upload')
          //删除fileList
          formData.delete('fileList')
          // 增强方法，用于处理非常规数据
          console.log('run processFormData')
          const submitForm = this.processFormData(formData, data)

          console.log('submitForm: ', submitForm)

          // let formData = Object.assign(this.model, values);
          console.log('run uploadAction')
          uploadAction(httpurl, submitForm, method).then((res) => {
            if (res.success) {
              cacheBank(data)
              this.handleSuccess(data)


              that.$emit('ok', data)
              console.log('data提交成功', data)
            } else {

            }
          }).finally(() => {
            that.confirmLoading = false
            that.close()
          })
        }
      })
    },
    processFormData(formData, data) {
      return formData
    },
    add(record) {
      console.log('call add', record)
      this.initFile()
      this.afterAdd(record)
    },
    afterAdd(record) {
      console.err('必须重载afterAdd()')
    },
    edit(record, special = false) {
      console.log('call edit', record)
      this.isFileListSpecial = special
      this.form.resetFields()
      this.model = Object.assign({}, record)
      this.visible = true
      // init file list
      if (record && record.fileList && record.fileList.length > 0) {
        this.defaultFileList = record.fileList
      }
      console.log('call afterEdit')
      this.afterEdit(record)
    },
    afterEdit() {
      console.err('必须重载afterEdit()')
    },
    copy(record) {
      console.log('call copy', record)
      delete record.id
      delete record.fileList
      this.edit(record)
    },
    close() {
      this.$emit('close')
      this.visible = false
      this.initFile()
      this.afterClose()
    },
    afterClose() {
      console.err('必须重载afterClose()')
    },
    handleCancel() {
      this.close()
    },

    initFile() {
      console.log('call innitFile')
      // this.files = []
      this.defaultFileList = []

      this.uploadFiles = {}
    },
    handleFileRemove(file, key) {
      console.log('call handleFileRemove', this.uploadFiles)
      const index = this.uploadFiles[key].indexOf(file)
      const newFileList = this.uploadFiles[key].slice()
      newFileList.splice(index, 1)
      // this.files = newFileList;
      this.uploadFiles[key] = newFileList
    },
    beforeFileUpload(file, key) {
      console.log('call beforeFileUpload', file)
      file.title = file.name
      let obj = { ...this.uploadFiles }
      let temp = this.uploadFiles[key] || []
      // this.$set(this.uploadFiles,key,[...temp, file])
      obj[key] = [...temp, file]
      this.uploadFiles = obj
      // console.log('beforeFileUpload', this.uploadFiles)
      return false
    },
    handleDeleteFile(index, key) {
      let num = 0
      this.defaultFileList.forEach((item,itemIndex) => {

        if (item.classify === key) {
          if(num === index){
            this.defaultFileList.splice(itemIndex, 1)
          }
          num++
        } else {

        }
      })
      console.log(this.defaultFileList)
    },
    sendError(error) {

    },
    handleSuccess(data) {

    }
  }
}