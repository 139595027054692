<template>
  <j-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('cancel')">
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">


        <a-form-item label="楼栋" :labelCol="labelCol" :wrapperCol="wrapperCol">
<!--          <common-selector url='/cum/cumBuilding/list' showName='id' :showItem="['id']" title='楼栋选择器'-->
<!--                           v-decorator="['buildingId', validatorRules.buildingId]" :multi='false'></common-selector>-->
          <InputSelect v-decorator="['buildingId', validatorRules.buildingId]" show-name='buildingNumber' send-params='id' other-show-name='#${id}'
                       url='dbcp/city/propertyManagement/buildingList/CumBuildingList.vue'
                       type='radio' :isEdit='model.buildingId?true:false'></InputSelect>
        </a-form-item>
        <a-form-item label="项目" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <!--                    <a-input-number v-decorator="['projectItemId', validatorRules.projectItemId]" placeholder="请输入项目明细" style="width: 100%"/>-->
          <InputSelect v-decorator="['projectId', validatorRules.projectId]" show-name='name' send-params='id'
                       url='dbcp/city/projectManagement/CumProjectList.vue' :isEdit='model.projectId?true:false'
                       type='radio'></InputSelect>
        </a-form-item>
        <a-form-item label="楼层" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['floor', validatorRules.floor]" placeholder="请输入楼层" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="房号" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['roomNo']" placeholder="请输入房号" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="产权证建筑面积(㎡)" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['certificateConstructionArea']" placeholder="请输入产权证建筑面积" style="width: 100%" :step='0.01' :max='99999999.99'/>
        </a-form-item>
        <a-form-item label="加建面积(㎡)" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['increasedConstructionArea']" placeholder="请输入加建面积" style="width: 100%" :step='0.01' :max='99999999.99'/>
        </a-form-item>
        <a-form-item label="权利人" :labelCol="labelCol" :wrapperCol="wrapperCol">
<!--          <common-selector url='/cum/cumOwner/list' showName='name' :showItem="['name']" title='权利人选择器'-->
<!--                           v-decorator="['rightHolder', validatorRules.rightHolder]" :multi='false'></common-selector>-->
          <InputSelect v-decorator="['rightHolder', validatorRules.rightHolder]" show-name='name' send-params='id'
                       url='dbcp/city/cumOwner/CumOwnerList.vue' :isEdit='model.rightHolder?true:false'
                       type='radio'></InputSelect>
        </a-form-item>
        <a-form-item label='测绘编号' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input v-decorator="['surveyedNo',validatorRules.surveyedNo]" placeholder='请输入测绘编号'></a-input>
        </a-form-item>
        <a-form-item label="测绘面积(㎡)" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['surveyedArea']" placeholder="请输入测绘面积" style="width: 100%" :step='0.01' :max='99999999.99'/>
        </a-form-item>
        <a-form-item label="测量状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-dict-select-tag type="list" v-decorator="['surveyingStatus', validatorRules.surveyingStatus]" :trigger-change="true" dictCode="cum_surveying_status" placeholder="请选择测量状态"/>
        </a-form-item>
        <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea v-decorator="['remark']" rows="4" placeholder="请输入备注"/>
        </a-form-item>
<!--        <a-form-item :label="$t('attachments')" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
<!--          <attachment v-decorator="['upload']" @handleFileRemove="handleFileRemove" @beforeFileUpload="beforeFileUpload" :file-list="uploadFiles" :default-file-list="defaultFileList"></attachment>-->
<!--        </a-form-item>-->

      </a-form>
    </a-spin>

    <template slot="footer">
<!--      <a-button type="primary" @click="handleOk(false)">{{ $t('saveDraft') }}</a-button>-->
      <a-button type="primary" @click="handleOk(true)">{{ $t('submit') }}</a-button>
      <a-button @click="handleCancel">{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
  import {  cumUnitApi } from './api/CumUnit'
  import { httpAction } from 'dbcp-frontends-common/lib/api'
  import pick from 'lodash.pick'
  import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
  import { JDictSelectTag } from 'dbcp-frontends-common/lib/components'
  import { UploadFormMixin } from 'dbcp-frontends-common/lib/mixins'
  import { Attachment } from 'dbcp-frontends-common/lib/components'
  import { CommonSelector } from 'dbcp-frontends-common/lib/components'
  import InputSelect  from '@/components/dbcp/InputSelect'
  export default {
    name: "CumUnitModal",
    components: {
      JDictSelectTag,Attachment,CommonSelector,
      InputSelect
    },
    mixins: [ UploadFormMixin ],
    data () {
      return {
        form: this.$form.createForm(this),
        title: this.$i18n.tc('action'),
        width: 800,
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules: {
          buildingId: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          projectId: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          floor: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          surveyedNo: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint') }
            ]
          },
          rightHolder: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          surveyingStatus: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
        },
        url: cumUnitApi.url,
      }
    },
    created () {
    },
    methods: {
      afterAdd (record) {
        this.edit({
            ...record
        })
      },
      afterEdit (record) {
        this.$nextTick(() => {
          this.form.setFieldsValue(pick(this.model,'projectId','buildingId','floor','roomNo','surveyedNo','certificateConstructionArea','increasedConstructionArea','rightHolder','surveyedArea','surveyingStatus','remark','addBy','addTime','updateBy','updateTime'))
        })
      },
      afterClose () {
      },
      popupCallback(row){
        this.form.setFieldsValue(pick(row,'projectId','buildingId','floor','roomNo','surveyedNo','certificateConstructionArea','increasedConstructionArea','rightHolder','surveyedArea','surveyingStatus','remark','addBy','addTime','updateBy','updateTime'))
      },


    }
  }
</script>
