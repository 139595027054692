import { deleteAction, getAction, uploadAction } from 'dbcp-frontends-common/lib/api'
export const dbcpWorkflowEventApi = {
  url: {
    action: "/workflow/dbcpWorkflowEvent/action",
    list: "/workflow/dbcpWorkflowEvent/list",
    delete: "/workflow/dbcpWorkflowEvent/delete",
    deleteBatch: "/workflow/dbcpWorkflowEvent/deleteBatch",
    exportXlsUrl: "/workflow/dbcpWorkflowEvent/exportXls",
    importExcelUrl: "workflow/dbcpWorkflowEvent/importExcel",
    add: "/workflow/dbcpWorkflowEvent/add",
    edit: "/workflow/dbcpWorkflowEvent/edit",
},
  execute: {
    list: function(params) {
      return getAction(dbcpWorkflowEventApi.url.list, params)
    },
    create: function(object) {
      return getAction(dbcpWorkflowEventApi.url.add, object)
    },
    retrieve: function(id, params = {}) {
      return getAction(dbcpWorkflowEventApi.url.queryById, { id, ...params })
    },
    update: function(object) {
      return uploadAction(dbcpWorkflowEventApi.url.edit, object)
    },
    destroy: function(id) {
      return deleteAction(dbcpWorkflowEventApi.url.delete, id)
    }
  }
}
