<template>
  <a-drawer
    :title="title"
    :width="width"
    placement="right"
    :closable="false"
    @close="close"
    :visible="visible">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item label="对象id" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['objectId', validatorRules.objectId]" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="工作流节点实体id" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['nodeEntityId']" style="width: 100%"/>
        </a-form-item>
        <a-form-item :label="$t('stateValue')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['status']" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="操作用户" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-select-user-by-dep :hideDept="true" v-decorator="['username', validatorRules.username]"/>
        </a-form-item>
        <a-form-item label="操作时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-date v-decorator="['createTime', validatorRules.createTime]" :trigger-change="true" :show-time="true" date-format="YYYY-MM-DD HH:mm:ss" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="备注/评论" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['comment']"></a-input>
        </a-form-item>
        <a-form-item label="拓展" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea v-decorator="['expand']" rows="4"/>
        </a-form-item>
        <a-form-item label="拓展类型(create:创建,edit:编辑,delete:删除)" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['expandType', validatorRules.expandType]"></a-input>
        </a-form-item>

      </a-form>
    </a-spin>
    <a-button type="primary" @click="handleOk">{{ $t('confirm') }}</a-button>
    <a-button type="primary" @click="handleCancel">{{ $t('cancel') }}</a-button>
  </a-drawer>
</template>

<script>

  import { httpAction } from 'dbcp-frontends-common/lib/api'
  import pick from 'lodash.pick'
  import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
  import { JDate } from 'dbcp-frontends-common/lib/components'
  import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'

  export default {
    name: "DbcpBusinessTripWorkflowModal",
    components: {
      JDate,
      JSelectUserByDep,
    },
    data () {
      return {
        form: this.$form.createForm(this),
        title: this.$i18n.tc('action'),
        width:800,
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules: {
          objectId: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          username: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          createTime: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          expandType: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
        },
        url: {
          add: "/oa/dbcp/dbcpBusinessTripWorkflow/add",
          edit: "/oa/dbcp/dbcpBusinessTripWorkflow/edit",
        }
      }
    },
    created () {
    },
    methods: {
      add () {
        this.edit({});
      },
      edit (record) {
        this.form.resetFields();
        this.model = Object.assign({}, record);
        this.visible = true;
        this.$nextTick(() => {
          this.form.setFieldsValue(pick(this.model,'objectId','nodeEntityId','status','username','createTime','comment','expand','expandType'))
        })
      },
      close () {
        this.$emit('close');
        this.visible = false;
      },
      handleOk () {
        const that = this;
        // 触发表单验证
        this.form.validateFields((err, values) => {
          if (!err) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
               method = 'put';
            }
            let formData = Object.assign(this.model, values);
            console.log("表单提交数据",formData)
            httpAction(httpurl,formData,method).then((res)=>{
              if(res.success){

                that.$emit('ok');
              }else{

              }
            }).finally(() => {
              that.confirmLoading = false;
              that.close();
            })
          }

        })
      },
      handleCancel () {
        this.close()
      },
      popupCallback(row){
        this.form.setFieldsValue(pick(row,'objectId','nodeEntityId','status','username','createTime','comment','expand','expandType'))
      }

    }
  }
</script>

<style lang="less" scoped>
/** Button按钮间距 */
  .ant-btn {
    margin-left: 30px;
    margin-bottom: 30px;
    float: right;
  }
</style>