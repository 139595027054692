<template>
  <a-modal width='80%' :visible='visible' @cancel='handleCancel' :footer='null'>
    <a-card :bordered='false'>
      <!-- 查询区域 -->
      <div class='table-page-search-wrapper'>
        <a-form layout='inline' @keyup.enter.native='searchQuery'>
          <a-row :gutter='24'>
          </a-row>
        </a-form>
      </div>
      <!-- 查询区域-END -->

      <!-- 操作按钮区域 -->
      <div class='table-operator'>
        <a-button @click='handleAdd(currentRecord)' type='primary' icon='plus' v-has="'cumCompensationType:create'">{{ $t('create')
          }}
        </a-button>
        <a-dropdown v-if='selectedRowKeys.length > 0'>
          <a-menu slot='overlay'>
            <a-menu-item key='1' @click='batchDel'>
              <a-icon type='delete' />
              {{ $t('delete') }}
            </a-menu-item>
          </a-menu>
          <a-button style='margin-left: 8px'> {{ $t('batchOperation') }}
            <a-icon type='down' />
          </a-button>
        </a-dropdown>
      </div>

      <!-- table区域-begin -->
      <div>
        <!-- <approval-tab @change="handleGroupChange" permission="cumCompensationType:view:all"></approval-tab> -->
        <setting-columns :columns='columns' @changeRealColumns='(data) => columns = data'></setting-columns>
        <a-table
          ref='table'
          size='middle'
          bordered
          rowKey='id'
          :columns='columns'
          :dataSource='dataSource'
          :pagination='ipagination'
          :loading='loading'
          :rowSelection='type?{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type,onSelect:handleSelection,onSelectAll:handleSelectAll}:null'
          class='j-table-force-nowrap'
          @change='handleTableChange'>

          <template slot='htmlSlot' slot-scope='text'>
            <div v-html='text'></div>
          </template>
          <template slot='imgSlot' slot-scope='text'>
            <span v-if='!text' style='font-size: 12px;font-style: italic;'>{{ $t('noPicture') }}</span>
            <img v-else :src='getImgView(text)' height='25px' alt=''
                 style='max-width:80px;font-size: 12px;font-style: italic;' />
          </template>
          <template slot='fileSlot' slot-scope='text'>
            <span v-if='!text' style='font-size: 12px;font-style: italic;'>{{ $t('noFile') }}</span>
            <a-button
              v-else
              :ghost='true'
              type='primary'
              icon='download'
              size='small'
              @click='uploadFile(text)'>
              {{ $t('download') }}
            </a-button>
          </template>

          <span slot='action' slot-scope='text, record'>
            <a @click='handleEdit({...record,...currentRecord})'>编辑</a>
            <a-divider type='vertical' />
            <a-popconfirm :title="$t('confirmDeleteAlert')" @confirm='() => handleDelete(record.id)'>
              <a>删除</a>
            </a-popconfirm>
        </span>

        </a-table>
      </div>


      <cumCompensationType-modal ref='modalForm' @ok='modalFormOk'></cumCompensationType-modal>
      <cumCompensationType-detail ref='detailForm' @action='handleActionClick' @close='handleViewModalClose'
                                  @loadData='loadData' @clearRouteQuery='clearRouteQuery'></cumCompensationType-detail>
    </a-card>
  </a-modal>
</template>

<script>
import { cumCompensationTypeApi } from './modules/api/CumCompensationType'
import '@/assets/less/TableExpand.less'
import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
import { SettingColumns } from 'dbcp-frontends-common/lib/components'
import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
import CumCompensationTypeModal from './modules/CumCompensationTypeModal'
import CumCompensationTypeDetail from './modules/CumCompensationTypeDetail'
// import { ApprovalTab } from 'dbcp-frontends-common/lib/components'
import { ListActions } from 'dbcp-frontends-common/lib/components'
import { SelectorMixin } from 'dbcp-frontends-common/lib/mixins'

export default {
  name: 'CumCompensationTypeList',
  mixins: [JeecgListMixin, mixinDevice, ViewMixin, ApprovalListMixin, SelectorMixin],
  components: {
    CumCompensationTypeModal,
    CumCompensationTypeDetail,
    ListActions,
    SettingColumns

  },
  data() {
    return {
      description: '补偿类型表管理页面',
      // 表头
      columns: [
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function(t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '补偿方式',
          align: 'center',
          dataIndex: 'compensateMethod_dictText'
        },
        {
          title: '补偿类型名称',
          align: 'center',
          dataIndex: 'name'
        },
        {
          title: '创建人',
          align: 'center',
          dataIndex: 'addBy_dictText'
        },
        {
          title: '创建时间',
          align: 'center',
          dataIndex: 'addTime'
        },
        {
          title: '更新人',
          align: 'center',
          dataIndex: 'updateBy_dictText'
        },
        {
          title: '更新时间',
          align: 'center',
          dataIndex: 'updateTime'
        },
        {
          title: this.$i18n.tc('action'),
          dataIndex: 'action',
          align: 'center',
          // fixed:"right",
          width: 147,
          scopedSlots: { customRender: 'action' }
        }
      ],
      url: cumCompensationTypeApi.url,
      dictOptions: {},
      visible: false,
      currentRecord: {},
      /* 禁用created列表数据加载 */
      disableMixinCreated: true
    }
  },
  computed: {
    importExcelUrl: function() {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    }
  },
  methods: {
    initDictConfig() {
    },
    initParams(params) {
      params.compensateMethod = this.currentRecord.compensateMethod
      params.projectId = this.currentRecord.projectId || undefined
      return params
    },
    show(record) {
      this.visible = true
      this.currentRecord = record
      this.loadData()
    },
    handleCancel() {
      this.visible = false
    }
  }
}
</script>
<style scoped>
@import '~@assets/less/common.less';
</style>
