<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <finance-search @searchChange="searchChange" :amount-show="false"></finance-search>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus">{{ $t('create') }}</a-button>
      <a-dropdown v-if="selectedRowKeys.length > 0">
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="batchDel">
            <a-icon type="delete"/>
            {{ $t('delete') }}
          </a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> {{ $t('batchOperation') }}
          <a-icon type="down"/>
        </a-button>
      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>
      <approval-tab @change="handleGroupChange" permission="businessTrip:view:all"></approval-tab>

      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        class="j-table-force-nowrap"
        @change="handleTableChange">

        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="imgSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noPicture') }}</span>
          <img v-else :src="getImgView(text)" height="25px" alt=""
               style="max-width:80px;font-size: 12px;font-style: italic;"/>
        </template>
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noFile') }}</span>
          <a-button
            v-else
            :ghost="true"
            type="primary"
            icon="download"
            size="small"
            @click="uploadFile(text)">
            {{ $t('download') }}
          </a-button>
        </template>

        <span slot="action" slot-scope="text, record">
          <list-actions
            :record="record"
            @view="handleView"
            @edit="handleEdit"
            @delete="handleDelete"
            @print="handlePrint"
            @action="$refs.detailForm.handleActionClick"
            @copy="handleCopy"
          ></list-actions>
        </span>

      </a-table>
    </div>


    <dbcpBusinessTrip-modal ref="modalForm" @ok="modalFormOk"></dbcpBusinessTrip-modal>
    <dbcpBusinessTrip-detail ref="detailForm" @action="handleActionClick"
                             @close="handleViewModalClose" @loadData="loadData" @clearRouteQuery="clearRouteQuery"></dbcpBusinessTrip-detail>
    <dbcpBusinessTrip-print ref="printForm"></dbcpBusinessTrip-print>
  </a-card>
</template>

<script>
import {  businessTripApi } from './modules/api/DbcpBusinessTrip'
import '@/assets/less/TableExpand.less'
import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
import { filterMultiDictText } from '@/components/dict/JDictSelectUtil'
import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
import { PrinterMixin } from 'dbcp-frontends-common/lib/mixins'
import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
import { ListActions } from 'dbcp-frontends-common/lib/components'
import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'

import ApprovalTab from '@comp/dbcp/approval/ApprovalTab'
import FinanceSearch from '@comp/dbcp/finance/FinanceSearch'
import DbcpBusinessTripModal from './modules/DbcpBusinessTripModal'
import DbcpBusinessTripDetail from './modules/DbcpBusinessTripDetail'
import DbcpBusinessTripPrint from './modules/DbcpBusinessTripPrint'


export default {
  name: 'DbcpBusinessTripList',
  mixins: [JeecgListMixin, mixinDevice, ViewMixin, PrinterMixin, ApprovalListMixin],
  components: {
    DbcpBusinessTripModal,
    DbcpBusinessTripPrint,
    DbcpBusinessTripDetail,
    ListActions, ApprovalTab,
    FinanceSearch,
  },
  data() {
    return {
      description: '公差申请管理页面',
      // 表头
      columns: [
        {
          title: this.$i18n.tc('serialNumber'),
          align: 'center',
          dataIndex: 'serialNumber'
        },
        {
          title: this.$i18n.tc('location'),
          align: 'center',
          dataIndex: 'location_dictText'
        },

        {
          title: this.$i18n.tc('applicant'),
          align: 'center',
          dataIndex: 'applicant_dictText'
        },
        {
          title: this.$i18n.tc('createTime'),
          align: 'center',
          dataIndex: 'addTime',
          sorter: true
        },
        {
          title: this.$i18n.tc('status'),
          align: 'center',
          dataIndex: 'status',
          customRender: (status) => {
            return this.$i18n.tc(`workflowStatus.${status}`)
          }
        },
        {
          title: this.$i18n.tc('currentProcessor'),
          align: 'center',
          dataIndex: 'approver_dictText'
        },
        {
          title: this.$i18n.tc('action'),
          dataIndex: 'action',
          align: 'center',
          // fixed:"right",
          width: 147,
          scopedSlots: { customRender: 'action' }
        }
      ],
      url: businessTripApi.url,
      dictOptions: {}
    }
  },
  computed: {
    importExcelUrl: function() {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    }
  },
  methods: {
    initDictConfig() {
    },
    searchChange(data) {
      this.queryParam = Object.assign({}, this.queryParam, data)
      console.log(this.queryParam)
      this.loadData(1)
    },
  }
}
</script>
<style scoped>
@import '~@assets/less/common.less';
</style>