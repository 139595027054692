<template>
  <a-drawer
    :title="title"
    :width="width"
    placement="right"
    :closable="false"
    @close="close"
    :visible="visible">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item label="项目id" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['projectId', validatorRules.projectId]" placeholder="请输入项目id" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="房屋性质" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['propertyType', validatorRules.propertyType]" placeholder="请输入房屋性质"></a-input>
        </a-form-item>
        <a-form-item label="结构类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['structureType', validatorRules.structureType]" placeholder="请输入结构类型"></a-input>
        </a-form-item>
        <a-form-item label="补偿方式" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['compensateMethod', validatorRules.compensateMethod]" placeholder="请输入补偿方式"></a-input>
        </a-form-item>
        <a-form-item label="补偿类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['compensateType', validatorRules.compensateType]" placeholder="请输入补偿类型"></a-input>
        </a-form-item>
        <a-form-item label="补偿金额" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['compensationAmount', validatorRules.compensationAmount]" placeholder="请输入补偿金额" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="补偿比例" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['compensationRate', validatorRules.compensationRate]" placeholder="请输入补偿比例" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="创建人" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-select-user-by-dep v-decorator="['addBy', validatorRules.addBy]"/>
        </a-form-item>
        <a-form-item label="创建时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-date placeholder="请选择创建时间" v-decorator="['addTime', validatorRules.addTime]" :trigger-change="true" :show-time="true" date-format="YYYY-MM-DD HH:mm:ss" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="更新人" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-select-user-by-dep v-decorator="['updateBy', validatorRules.updateBy]"/>
        </a-form-item>
        <a-form-item label="更新时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-date placeholder="请选择更新时间" v-decorator="['updateTime', validatorRules.updateTime]" :trigger-change="true" :show-time="true" date-format="YYYY-MM-DD HH:mm:ss" style="width: 100%"/>
        </a-form-item>

      </a-form>
    </a-spin>
    <a-button type="primary" @click="handleOk">{{ $t('confirm') }}</a-button>
    <a-button type="primary" @click="handleCancel">{{ $t('cancel') }}</a-button>
  </a-drawer>
</template>

<script>

  import {  cumCompensationStandardApi } from './api/CumCompensationStandard'
  import { httpAction } from 'dbcp-frontends-common/lib/api'
  import pick from 'lodash.pick'
  import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
  import { JDate } from 'dbcp-frontends-common/lib/components'
  import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'

  export default {
    name: "CumCompensationStandardModal",
    components: {
      JDate,
      JSelectUserByDep,
    },
    data () {
      return {
        form: this.$form.createForm(this),
        title: this.$i18n.tc('action'),
        width:800,
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules: {
          projectId: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          propertyType: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          structureType: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          compensateMethod: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          compensateType: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          compensationAmount: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          compensationRate: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          addBy: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          addTime: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          updateBy: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          updateTime: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
        },
        url: {
          add: "/cum/cumCompensationStandard/add",
          edit: "/cum/cumCompensationStandard/edit",
        }
      }
    },
    created () {
    },
    methods: {
      add () {
        this.edit({});
      },
      edit (record) {
        this.form.resetFields();
        this.model = Object.assign({}, record);
        this.visible = true;
        this.$nextTick(() => {
          this.form.setFieldsValue(pick(this.model,'projectId','propertyType','structureType','compensateMethod','compensateType','compensationAmount','compensationRate','addBy','addTime','updateBy','updateTime'))
        })
      },
      close () {
        this.$emit('close');
        this.visible = false;
      },
      handleOk () {
        const that = this;
        // 触发表单验证
        this.form.validateFields((err, values) => {
          if (!err) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
               method = 'put';
            }
            let formData = Object.assign(this.model, values);
            console.log("表单提交数据",formData)
            httpAction(httpurl,formData,method).then((res)=>{
              if(res.success){
                that.$message.success(res.message);
                that.$emit('ok');
              }else{
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
              that.close();
            })
          }

        })
      },
      handleCancel () {
        this.close()
      },
      popupCallback(row){
        this.form.setFieldsValue(pick(row,'projectId','propertyType','structureType','compensateMethod','compensateType','compensationAmount','compensationRate','addBy','addTime','updateBy','updateTime'))
      }

    }
  }
</script>

<style lang="less" scoped>
/** Button按钮间距 */
  .ant-btn {
    margin-left: 30px;
    margin-bottom: 30px;
    float: right;
  }
</style>
