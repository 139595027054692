import { render, staticRenderFns } from "./SysAnnouncementModal.vue?vue&type=template&id=3cc78266&scoped=true&"
import script from "./SysAnnouncementModal.vue?vue&type=script&lang=js&"
export * from "./SysAnnouncementModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cc78266",
  null
  
)

export default component.exports