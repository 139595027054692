<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd(currentRecord)" type="primary" icon="plus" v-has="'cumVerificationTemplate:create'">{{ $t('create') }}</a-button>
<!--      <a-button type="primary" icon="download" @click="handleExportXls('权利人核实表模板')">{{ $t('export') }}</a-button>-->
<!--      <a-upload name="file" :showUploadList="false" :multiple="false" :headers="tokenHeader" :action="importExcelUrl" @change="handleImportExcel">-->
<!--        <a-button type="primary" icon="import">{{ $t('import') }}</a-button>-->
<!--      </a-upload>-->
      <a-dropdown v-if="selectedRowKeys.length > 0">
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="batchDel"><a-icon type="delete"/>{{ $t('delete') }}</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> {{ $t('batchOperation') }} <a-icon type="down" /></a-button>
      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>
     <!-- <approval-tab @change="handleGroupChange" permission="cumVerificationTemplate:view:all"></approval-tab> -->
      <setting-columns :columns='columns' @changeRealColumns='(data) => columns = data'></setting-columns>
      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="type?{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type,onSelect:handleSelection,onSelectAll:handleSelectAll}:null"
        class="j-table-force-nowrap"
        @change="handleTableChange">

        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="imgSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noPicture') }}</span>
          <img v-else :src="getImgView(text)" height="25px" alt="" style="max-width:80px;font-size: 12px;font-style: italic;"/>
        </template>
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noFile') }}</span>
          <a-button
            v-else
            :ghost="true"
            type="primary"
            icon="download"
            size="small"
            @click="uploadFile(text)">
            {{ $t('download') }}
          </a-button>
        </template>

        <span slot="action" slot-scope="text, record" style='display: flex;justify-content: center;align-items: center'>
          <a @click='handleEdit(record)'>{{ $t('edit') }}</a>
<!--          <list-actions-->
<!--            :record="record"-->
<!--            @view="handleView"-->
<!--            @edit="handleEdit"-->
<!--            @delete="handleDelete"-->
<!--            @action="$refs.detailForm.handleActionClick"-->
<!--            @copy="handleCopy"-->
<!--          ></list-actions>-->
          <a-divider type='vertical'/>
          <a-popconfirm :title="$t('confirmDeleteAlert')" @confirm="() => handleDelete(record.id)">
            <a>{{ $t('delete') }}</a>
          </a-popconfirm>
        </span>

      </a-table>
    </div>


    <cumVerificationTemplate-modal ref="modalForm" @ok="modalFormOk"></cumVerificationTemplate-modal>
    <cumVerificationTemplate-detail ref="detailForm" @action="handleActionClick" @close="handleViewModalClose"
                                      @loadData="loadData" @clearRouteQuery="clearRouteQuery"></cumVerificationTemplate-detail>
  </a-card>
</template>

<script>
  import {  cumVerificationTemplateApi } from './modules/api/CumVerificationTemplate'
  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
  import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
  import { SettingColumns } from 'dbcp-frontends-common/lib/components'
  import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
  import CumVerificationTemplateModal from './modules/CumVerificationTemplateModal'
  import CumVerificationTemplateDetail from './modules/CumVerificationTemplateDetail'
  // import { ApprovalTab } from 'dbcp-frontends-common/lib/components'
  import { ListActions } from 'dbcp-frontends-common/lib/components'
  import { SelectorMixin } from 'dbcp-frontends-common/lib/mixins'
  import {filterMultiDictText} from '@/components/dict/JDictSelectUtil'

  export default {
    name: "CumVerificationTemplateList",
    mixins:[JeecgListMixin, mixinDevice, ViewMixin, ApprovalListMixin,SelectorMixin],
    components: {
      CumVerificationTemplateModal,
      CumVerificationTemplateDetail,
      ListActions,
      SettingColumns,

    },
    data () {
      return {
        description: '权利人核实表模板管理页面',
        // 表头
        columns: [
          {
            title: '#',
            dataIndex: '',
            key: 'rowIndex',
            width: 60,
            align: 'center',
            customRender: function (t,r,index) {
              return parseInt(index)+1;
            }
          },
          // {
          //   title: '项目id',
          //   align: 'center',
          //   dataIndex: 'projectId'
          // },
          {
            title: '模板表具体内容列',
            align: 'center',
            dataIndex: 'content'
          },
          {
            title: '类型',
            align: 'center',
            dataIndex: 'type_dictText'
          },
          // {
          //   title: '创建人',
          //   align: 'center',
          //   dataIndex: 'addBy_dictText'
          // },
          // {
          //   title: '创建时间',
          //   align: 'center',
          //   dataIndex: 'addTime'
          // },
          // {
          //   title: '更新人',
          //   align: 'center',
          //   dataIndex: 'updateBy_dictText'
          // },
          // {
          //   title: '更新时间',
          //   align: 'center',
          //   dataIndex: 'updateTime'
          // },
          // {
          //   title: '备注',
          //   align: 'center',
          //   dataIndex: 'remark'
          // },
          {
            title: this.$i18n.tc('action'),
            dataIndex: 'action',
            align: 'center',
            // fixed:"right",
            width: 147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: cumVerificationTemplateApi.url,
        dictOptions:{},
        /* 禁用created列表数据加载 */
        disableMixinCreated: true,
      }
    },
    props:{
      currentRecord: {
        type:Object,
        default:()=>{}
      }
    },
    watch:{
      currentRecord: {
        deep:true,
        handler:function(newV){
          for(let key in newV){
            this.queryParam[key] = newV[key]
          }
          console.log("this.queryParam",this.queryParam);
          this.dataSource = []
          this.loadData();
        }
      }
    },
    created(){
      console.log("this.currentRecord",this.currentRecord)
      for(let key in this.currentRecord){
        console.log("key",key)
        this.queryParam[key] = this.currentRecord[key]
      }
      this.dataSource = [] ;
      this.loadData();
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
    },
    methods: {
      initDictConfig(){
      }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>
