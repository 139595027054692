<template>
  <j-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('cancel')">
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">


        <a-form-item label="项目名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['name', validatorRules.name]" placeholder="请输入项目名称"></a-input>
        </a-form-item>
<!--        <a-form-item label="所属片区" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
<!--          <j-select-depart v-decorator="['deptId', validatorRules.deptId]" multi/>-->
<!--        </a-form-item>-->
        <a-form-item label="项目负责人" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-select-user-by-dep :hideDept="true" v-decorator="['owner', validatorRules.owner]" :multi='false' title="选择用户" :hide-dept='true'/>
        </a-form-item>
        <a-form-item label="拆迁总监" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-select-user-by-dep :hideDept="true" v-decorator="['demolitionDirector', validatorRules.demolitionDirector]" :multi='false'/>
        </a-form-item>
        <a-form-item label="拆迁副总" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-select-user-by-dep :hideDept="true" v-decorator="['demolitionDeputy', validatorRules.demolitionDeputy]" :multi='false'/>
        </a-form-item>
        <a-form-item label="法务" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-select-user-by-dep :hideDept="true" v-decorator="['legalAffairs', validatorRules.legalAffairs]" :multi='false'/>
        </a-form-item>
        <a-form-item label="财务" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-select-user-by-dep :hideDept="true" v-decorator="['finance', validatorRules.finance]" :multi='false'/>
        </a-form-item>
        <a-form-item label="集团拆迁负责人" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-select-user-by-dep :hideDept="true" v-decorator="['demolitionOwner', validatorRules.demolitionOwner]" :multi='false'/>
        </a-form-item>
        <a-form-item label="规划面积(m²)" :labelCol="labelCol" :wrapperCol="wrapperCol" >
          <a-input-number v-decorator="['planArea', validatorRules.planArea]" style='width:100%' :step='0.01' :max='99999999.99'></a-input-number>
        </a-form-item>
        <a-form-item label="项目描述" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea v-decorator="['description']" rows="4" placeholder="请输入项目描述"/>
        </a-form-item>
        <a-form-item label="项目状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-dict-select-tag type="list" v-decorator="['status', validatorRules.status]" :trigger-change="true" dictCode="cum_project_status" placeholder="请选择项目状态"/>
        </a-form-item>
        <a-form-item :label="$t('attachments')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <attachment v-decorator="['upload']" @handleFileRemove="handleFileRemove" @beforeFileUpload="beforeFileUpload" :file-list="uploadFiles" :default-file-list="defaultFileList"></attachment>
        </a-form-item>
      </a-form>
    </a-spin>

    <template slot="footer">
<!--      <a-button type="primary" @click="handleOk(false)">{{ $t('saveDraft') }}</a-button>-->
      <a-button type="primary" @click="handleOk(true)">{{ $t('submit') }}</a-button>
      <a-button @click="handleCancel">{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
  import {  cumProjectApi } from './api/CumProject'
  import { httpAction } from 'dbcp-frontends-common/lib/api'
  import pick from 'lodash.pick'
  import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
  import { JSelectDepart } from 'dbcp-frontends-common/lib/components'
  import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'
  import { JDictSelectTag } from 'dbcp-frontends-common/lib/components'
  import { UploadFormMixin } from 'dbcp-frontends-common/lib/mixins'
  import { Attachment } from 'dbcp-frontends-common/lib/components'

  export default {
    name: "CumProjectModal",
    components: {
      JSelectDepart,
      JSelectUserByDep,
      JDictSelectTag,
      Attachment
    },
    mixins: [ UploadFormMixin ],
    data () {
      return {
        form: this.$form.createForm(this),
        title: this.$i18n.tc('action'),
        width: 800,
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules: {
          name: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          deptId: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          owner: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          demolitionDirector:{
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          // demolitionDeputy:{
          //   rules: [
          //     { required: true, message: this.$i18n.tc('fieldEmptyHint')},
          //   ]
          // },
          legalAffairs:{
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          finance:{
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          // demolitionOwner:{
          //   rules: [
          //     { required: true, message: this.$i18n.tc('fieldEmptyHint')},
          //   ]
          // },
          status: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
        },
        url: cumProjectApi.url,
      }
    },
    created () {
    },
    methods: {
      afterAdd (record) {
        this.edit({
            ...record
        })
      },
      afterEdit (record) {
        this.$nextTick(() => {
          this.form.setFieldsValue(pick(this.model,'planArea','name','deptId','owner','demolitionDirector','demolitionDeputy','legalAffairs','finance','demolitionOwner','description','status','updateBy','updateTime'))
        })
      },
      afterClose () {
      },
      popupCallback(row){
        this.form.setFieldsValue(pick(row,'planArea','name','deptId','owner','demolitionDirector','demolitionDeputy','legalAffairs','finance','demolitionOwner','description','status','updateBy','updateTime'))
      },


    }
  }
</script>
