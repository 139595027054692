<template>
  <j-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('cancel')">
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="二级密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input type="password" v-decorator="[ 'secondaryPassword', validatorRules.secondaryPassword]" @pressEnter="handleOk"/>
        </a-form-item>
      </a-form>
    </a-spin>

    <template slot="footer">
      <a-button type="primary" @click="handleOk(true)">{{ $t('submit') }}</a-button>
      <a-button @click="handleCancel">{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
import { httpAction } from 'dbcp-frontends-common/lib/api'
import pick from 'lodash.pick'
import { UploadFormMixin } from 'dbcp-frontends-common/lib/mixins'
import {getAction} from 'dbcp-frontends-common/lib/api'

export default {
  name: "SecondaryPasswordSubmit",
  mixins: [ UploadFormMixin ],
  data () {
    return {
      form: this.$form.createForm(this),
      title: this.$i18n.tc('action'),
      width: 800,
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      validatorRules: {
        secondaryPassword: {
          rules: [{
            required: true,
            pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{8,}$/,
            message: this.$i18n.tc('inputPwdInfo')
          }]
        },
      },
      readonly:false
    }
  },
  created () {
  },
  methods: {
    show(){
      this.visible = true
    },
    handleOk(){
      let secondaryPassword = this.form.getFieldValue('secondaryPassword')
      this.$emit('ok',secondaryPassword)
    },
    handleCancel(){
      this.visible = false
      this.form.setFieldsValue({
        secondaryPassword : ''
      })
    }
  }
}
</script>
