import { deleteAction, getAction, uploadAction } from 'dbcp-frontends-common/lib/api'
export const businessTripApi = {
  url: {
        action: '/oa/dbcp/dbcpBusinessTrip/action',
        list: '/oa/dbcp/dbcpBusinessTrip/list',
        delete: '/oa/dbcp/dbcpBusinessTrip/delete',
        deleteBatch: '/oa/dbcp/dbcpBusinessTrip/deleteBatch',
        exportXlsUrl: '/oa/dbcp/dbcpBusinessTrip/exportXls',
        importExcelUrl: '/oa/dbcp/dbcpBusinessTrip/importExcel'
      },
  execute: {
    list: function(params) {
      return getAction(businessTripApi.url.list, params)
    },
    create: function(object) {
      return getAction(businessTripApi.url.add, object)
    },
    retrieve: function(id, params = {}) {
      return getAction(businessTripApi.url.queryById, { id, ...params })
    },
    update: function(object) {
      return uploadAction(businessTripApi.url.edit, object)
    },
    destroy: function(id) {
      return deleteAction(businessTripApi.url.delete, id)
    }
  }
}
