<template>
  <a-card :title="title" style="width: 100%">
    <a-list item-layout="vertical" :data-source="data">
      <a-list-item slot="renderItem" slot-scope="item, index">
        <template v-if="!item.expandType">
          <a-list-item-meta>
            <div slot="description">
              <div v-html="getContent(item.content)"></div>
            </div>
            <a slot="title">{{ `#${index+1} ${item.username_dictText}` }}</a>
          </a-list-item-meta>
          <div>
            <div v-if="item.fileList && item.fileList.length > 0">
              <div v-for="file in item.fileList">
                <a :href="file.path" :download="file.title" target="_blank">{{ file.title }}</a>
              </div>
            </div>
            <div class="time">
              {{ item.time || item.createTime }}
            </div>
          </div>
        </template>
        <template v-else>
          <a-list-item-meta
          >
            <div slot="description">
              <div v-html="getContent(item.content)"></div>
            </div>
            <a slot="title">{{ `#${index+1} ${item.username_dictText}` }}  {{ $t(`actionCode[\'${item.expandType}\']`) }}</a>
          </a-list-item-meta>
          <div>
            <div>
              <div>{{ item.comment }}</div>
              <div v-for="expand in getActions(item.expand)">
                {{ $i18n.tc(expand.field) }}: {{ getOldValue(expand) }} => {{ getNewValue(expand) }}
              </div>
            </div>
            <div class="time">
              {{ item.time || item.createTime }}
            </div>
          </div>
        </template>
      </a-list-item>
    </a-list>
  </a-card>
</template>

<script>
import { Files } from 'dbcp-frontends-common/lib/components'

  export default {
    name: 'Histories',
    components: { Files },
    props: {
      data: {
        type: Array,
        default: []
      },
      title: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
      }
    },
    methods:{
      getActions(expand) {
        if (expand) {
          return JSON.parse(expand)
        }
      },
      getValue(data, type) {
        let str
        if (data.field === 'status') {
          str = this.$i18n.tc(`workflowStatus.${this.lodash.get(data, type)}`)
        }

        return str ? str : this.lodash.get(data, type)
      },
      getOldValue(data) {
        return this.getValue(data, 'oldValue')
      },
      getNewValue(data) {
        return this.getValue(data, 'newValue')
      },
      getContent(content) {
        if (content) {
          return content.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\n/g, '<br/>')
        }
        return content ? content.replace(/\r\n/g, '<br/>') : ''
      }
    }
  }
</script>

<style scoped>
  .time {
    margin-top: 10px;
  }
  .ant-list-item-meta {
    margin-bottom: 0;
  }
</style>
