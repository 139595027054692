<template>
  <j-modal
    :title='title'
    :width='width'
    :visible='visible'
    :confirmLoading='confirmLoading'
    switchFullscreen
    @ok='handleOk'
    @cancel='handleCancel'
    :cancelText="$t('cancel')">
    <a-spin :spinning='confirmLoading'>
      <a-form :form='form'>


<!--        <a-form-item label='项目细项' :labelCol='labelCol' :wrapperCol='wrapperCol'>-->
<!--          <common-selector url='/cum/cumProjectItem/list' showName='id' :showItem="['id']" title='项目细项选择器'-->
<!--                           v-decorator="['projectItemId', validatorRules.projectItemId]" :multi='false'></common-selector>-->
<!--        </a-form-item>-->
        <a-form-item label='业主' :labelCol='labelCol' :wrapperCol='wrapperCol'>
<!--          <common-selector url='/cum/cumOwner/list' showName='name' :showItem="['name']" title='业主选择器'-->
<!--                           v-decorator="['ownerId', validatorRules.ownerId]" :multi='false'></common-selector>-->
          <InputSelect v-decorator="['ownerId', validatorRules.ownerId]" show-name='name' send-params='id'
                       url='dbcp/city/cumOwner/CumOwnerList.vue'
                       type='radio'></InputSelect>
        </a-form-item>
        <a-form-item label='走访时间' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <j-date placeholder='请选择走访时间' v-decorator="['visitTime', validatorRules.visitTime]" :trigger-change='true'
                  :show-time='true' date-format='YYYY-MM-DD HH:mm:ss' style='width: 100%' />
        </a-form-item>
        <a-form-item label='走访人员' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <j-select-user-by-dep :hideDept="true" v-decorator="['visitStaff', validatorRules.visitStaff]" />
        </a-form-item>
        <a-form-item label='走访目的' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-textarea v-decorator="['visitTarget']" rows='4' placeholder='请输入走访目的' />
        </a-form-item>
        <a-form-item label='携带礼物' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-textarea v-decorator="['carryGifts']" rows='4' placeholder='请输入携带礼物' />
        </a-form-item>
        <a-form-item label='主要问题及要求' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-textarea v-decorator="['problem']" rows='4' placeholder='请输入主要问题及要求' />
        </a-form-item>
        <a-form-item label='答复' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-textarea v-decorator="['reply']" rows='4' placeholder='请输入答复' />
        </a-form-item>
        <a-form-item label='请示领导及指示' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-textarea v-decorator="['instructions']" rows='4' placeholder='请输入请示领导及指示' />
        </a-form-item>
        <a-form-item label='下次走访时间' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <j-date placeholder='请选择下次走访时间' v-decorator="['nextVisitTime']" :trigger-change='true' :show-time='true'
                  date-format='YYYY-MM-DD HH:mm:ss' style='width: 100%' />
        </a-form-item>
        <a-form-item label='备注' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-textarea v-decorator="['remark']" rows='4' placeholder='请输入备注' />
        </a-form-item>
        <a-form-item :label="$t('attachments')" :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <attachment v-decorator="['upload']" @handleFileRemove='handleFileRemove' @beforeFileUpload='beforeFileUpload'
                      :file-list='uploadFiles' :default-file-list='defaultFileList'></attachment>
        </a-form-item>

      </a-form>
    </a-spin>

    <template slot='footer'>
      <a-button type='primary' @click='handleOk(true)'>{{ $t('submit') }}</a-button>
      <a-button @click='handleCancel'>{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
import { cumFollowUpRecordApi } from './api/CumFollowUpRecord'
import { httpAction } from 'dbcp-frontends-common/lib/api'
import pick from 'lodash.pick'
import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
import { Attachment } from 'dbcp-frontends-common/lib/components'
import { JDate } from 'dbcp-frontends-common/lib/components'
import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'
import { UploadFormMixin } from 'dbcp-frontends-common/lib/mixins'
import { CommonSelector } from 'dbcp-frontends-common/lib/components'
import InputSelect  from '@/components/dbcp/InputSelect'

export default {
  name: 'CumFollowUpRecordModal',
  components: {
    JDate,
    JSelectUserByDep,
    Attachment,
    CommonSelector,
    InputSelect
  },
  mixins: [UploadFormMixin],
  data() {
    return {
      form: this.$form.createForm(this),
      title: this.$i18n.tc('action'),
      width: 800,
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      validatorRules: {
        projectItemId: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        ownerId: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        visitTime: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        visitStaff: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        }
      },
      url: cumFollowUpRecordApi.url
    }
  },
  created() {
  },
  methods: {
    afterAdd(record) {
      this.edit({
        ...record
      })
    },
    afterEdit(record) {
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'projectItemId', 'ownerId', 'visitTime', 'visitStaff', 'visitTarget', 'carryGifts', 'problem', 'reply', 'instructions', 'nextVisitTime', 'remark', 'addBy', 'addTime', 'updateBy', 'updateTime'))
      })
    },
    afterClose() {
    },
    popupCallback(row) {
      this.form.setFieldsValue(pick(row, 'projectItemId', 'ownerId', 'visitTime', 'visitStaff', 'visitTarget', 'carryGifts', 'problem', 'reply', 'instructions', 'nextVisitTime', 'remark', 'addBy', 'addTime', 'updateBy', 'updateTime'))
    }


  }
}
</script>
