<template>
  <j-modal
    :title='title'
    :width='width'
    :visible='visible'
    :confirmLoading='confirmLoading'
    switchFullscreen
    @ok='handleOk'
    @cancel='handleCancel'
    :cancelText="$t('cancel')">
<!--    <a-button type="primary" icon="download" @click="handleExport" >导出项目补偿表</a-button>-->
    <a-dropdown style='margin-bottom: 10px'>
      <a-menu slot="overlay" @click="e => handleMenuClick(e,'项目补偿表')">
        <a-menu-item key="excel">导出excel</a-menu-item>
        <a-menu-item key="pdf" v-has='"cumProject:export:detailsOfProjectCompensationPDF"'>导出pdf</a-menu-item>
      </a-menu>
      <a-button style="margin-left: 8px">导出项目补偿表<a-icon type="down" /> </a-button>
    </a-dropdown>
    <br>
    <a-table ref='table'
             size='middle'
             bordered
             rowKey='id'
             :columns='columns'
             :dataSource='realDataSource'
             :pagination='false'
    >
        <template slot='detail' slot-scope='text, record,index'>
            <p v-for='item in record.cumPaymentAmountList'>
              {{ item.compensateTypeId_dictText }} : {{item.amount}}
            </p>
        </template>
         <span slot='action' slot-scope='text, record,index' v-if='index < dataSource.length'>
           <a @click='handlePay(record)'>查看</a>
         </span>
    </a-table>
    <turn-list-modal ref='turnListModal' :url='turnUrl' :special='true' :modalRecord='currentRecord'></turn-list-modal>
  </j-modal>
</template>

<script>
import { downFile, getAction } from 'dbcp-frontends-common/lib/api'
import TurnListModal from '../../../../../components/TurnListModal'
export default {
  name: 'CumProjectCompensation',
  components:{
    TurnListModal
  },
  data() {
    return {
      title: '项目补偿表',
      width: '85%',
      visible: false,
      columns: [
        {
          title: '项目细项',
          align: 'center',
          dataIndex: 'rightHolder_dictText'
        },
        {
          title: '金额信息',
          align: 'center',
          dataIndex: 'rightHolder_dictText',
          children: [
            {
              title: '详情',
              align: 'center',
              dataIndex: 'detail',
              scopedSlots: { customRender: 'detail' }
            },
            {
              title: '合计',
              align: 'center',
              dataIndex: 'payableMoney'
            }
          ]
        },
        {
          title: '已付款',
          align: 'center',
          dataIndex: 'rightHolder_dictText',
          children: [
            {
              title: '合计',
              align: 'center',
              dataIndex: 'paidMoney'
            }
          ]
        },
        {
          title: '未付款',
          align: 'center',
          dataIndex: 'rightHolder_dictText',
          children: [
            {
              title: '合计',
              align: 'center',
              dataIndex: 'pendingMoney'
            }
          ]
        },
        {
          title: this.$i18n.tc('action'),
          dataIndex: 'action',
          align: 'center',
          // fixed:"right",
          scopedSlots: { customRender: 'action' }
        }
      ],
      dataSource: [],
      turnUrl:'',
      currentRecord:{},
      projectId:''
    }
  },
  computed: {
    realDataSource() {
      if(this.dataSource.length > 0){
        let arr = [...this.dataSource]
        let allPayableMoney = arr.reduce((prev,next) => {
          return prev + next.payableMoney
        },0)

        let allTotalPaidMoney = arr.reduce((prev,next) => {
          return prev + next.paidMoney
        },0)

        let allTotalPendingMoney = arr.reduce((prev,next) => {
          return prev + next.pendingMoney
        },0)
        arr.push({
          rightHolder_dictText:'合计',
          payableMoney:allPayableMoney,
          paidMoney:allTotalPaidMoney,
          pendingMoney:allTotalPendingMoney
        })
        return arr
      }else{
        return []
      }

    }
  },
  methods: {
    show(record) {
      this.visible = true
      this.projectId = record.id
      this.getProjectCompensation(record)
    },
    handleOk() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
    },
    //  获取补偿表
    getProjectCompensation(record) {
      getAction('/cum/cumProjectCompensation/list', {
        projectId: record.id,
        pageSize: -1
      }).then(res => {
        if(res.success){
          this.dataSource = res.result
        }
      })
    },
    handlePay(record){
      this.turnUrl = "dbcp/city/firstPay/CumFirstPayList.vue";
      this.currentRecord = record
      this.$nextTick(()=>{
        this.$refs.turnListModal.show({ projectItemId: record.projectItemId, projectId:record.projectId});
      })
    },
    handleExport(id) {
      let fileName = '项目补偿表'
      downFile('/cum/cumProject/getProjectCompensation', {
        projectId: this.projectId
      }).then((data) => {
        if (!data) {
          return
        }
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(new Blob([data], { type: 'application/vnd.ms-excel' }), fileName + '.xlsx')
        } else {
          let url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', fileName + '.xlsx')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link) //下载完成移除元素
          window.URL.revokeObjectURL(url) //释放掉blob对象
        }
      })
    },
    handleMenuClick(event,fileName){
      console.log("event",event.key,fileName);
      if(event.key === "excel") {
        this.handleExport()
      }
      if (event.key === "pdf") {
        let pdfUrl = "/cum/cumProject/getProjectCompensationPDF";
        let param = {};
        param["projectId"] = this.projectId ;

        this.toHandleExportPdf(pdfUrl, param, fileName);
      }
    },
    toHandleExportPdf(pdfUrl,param,fileName){
      console.log("导出参数",param)
      downFile(pdfUrl,param).then((data)=>{
        if (!data) {
          return
        }
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(new Blob([data],{type: 'application/vnd.ms-excel'}), fileName+'.pdf')
        }else{
          let url = window.URL.createObjectURL(new Blob([data],{type: 'application/vnd.ms-excel'}))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', fileName+'.pdf')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      })
    },
  }
}
</script>

<style scoped lang='less'>

</style>