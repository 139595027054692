<template>
  <div>
    <a-modal
      :title="title"
      :width="width"
      placement="right"
      @cancel="close"
      :footer="null"
      :visible="visible">
      <a-descriptions :column="3" bordered size="small">
        <a-descriptions-item :label="$t('serialNumber')" :span="3">
          {{ data.serialNumber }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('type')">
          {{ data.type_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('createBy')">
          {{ data.addBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('createTime')">
          {{ data.addTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('applicant')">
          {{ data.applicant_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('dept')">
          {{ data.dept_dictText }} ({{ data.companyName }})
        </a-descriptions-item>
        <a-descriptions-item :label="$t('reason')">
          {{ data.reason }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('useDate')">
          {{ data.useDate }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('returnDate')">
          {{ data.returnDate }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('relatedPerson')">
          {{ data.relatedPerson_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('status')">
          {{ $t(`workflowStatus.${data.status}`) }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('currentProcessor')">
          {{ data.approver_dictText }}
        </a-descriptions-item>
      </a-descriptions>

      <a-divider></a-divider>

      <a-descriptions layout="vertical" size="small">
        <a-descriptions-item :label="$t('functionalSuperior')">
          {{ data.competencySuperior_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('administrativeSuperior')">
          {{ data.adminSuperior_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('signatory')">
          {{ data.signatory_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('financialOfficer')">
          {{ data.finance_dictText }}
        </a-descriptions-item>
      </a-descriptions>

      <!--   actions   -->
      <div class="detail-actions" v-if="data.action && Object.keys(data.action).length > 0">
        <a-divider></a-divider>
        <span v-for="action in Object.keys(data.action)">
          <a-button
            type="primary"
            @click="handleActionClick([data, action])"
            class="detail-actions-button"
          >
            {{ $t(`actionCode[\'${action}\']`) }}
          </a-button>
        </span>
      </div>

      <a-divider></a-divider>

      <detail-history :file-list="data.fileList" :history-list="histories"></detail-history>
    </a-modal>
  </div>
</template>

<script>
import DetailHistory from '@comp/common/DetailHistory'
import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'

export default {
  name: 'PendingTasksWorkflowViewDetail',
  components: { DetailHistory },
  mixins: [ DetailMixin ],
  data() {
    return {
      url: {
        list: "/workflow/pendingTasksWorkflowView/list"
      },
    }
  }
}
</script>

<style scoped>

</style>
