<template>
  <j-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('cancel')">
    <a-spin :spinning="confirmLoading">
      <div style='display: flex;align-items: center;margin-bottom: 10px'>
        <div style='width: 50px'>表名：</div>
        <a-input v-model='queryTableName' @keypress.enter='handleQuery' placeholder='请输入表名按回车查询'></a-input>
      </div>

      <a-table  :columns="columns"
                :dataSource="dataSource"
                rowKey="tableName"
                :pagination="false"
                :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}">

      </a-table>
    </a-spin>

    <template slot="footer">
      <a-button type="primary" @click="handleOk(true)">{{ $t('submit') }}</a-button>
      <a-button @click="handleCancel">{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
import {  dbcpWorkflowFormApi } from './api/DbcpWorkflowForm'
import { getAction } from 'dbcp-frontends-common/lib/api'
import pick from 'lodash.pick'
import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
import { JDictSelectTag } from 'dbcp-frontends-common/lib/components'
import { UploadFormMixin } from 'dbcp-frontends-common/lib/mixins'
import { CommonSelector } from 'dbcp-frontends-common/lib/components'
export default {
  name: "DbcpWorkflowFormModal",
  components: {
    JDictSelectTag,CommonSelector
  },
  mixins: [ UploadFormMixin ],
  data () {
    return {
      form: this.$form.createForm(this),
      title: this.$i18n.tc('action'),
      width: 800,
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      url: dbcpWorkflowFormApi.url,
      dataSource:[],
      columns:[
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function (t,r,index) {
            return parseInt(index)+1;
          }
        },
        {
          title: '表名',
          align: 'center',
          dataIndex: 'tableName'
        },
        {
          title: '表备注',
          align: 'center',
          dataIndex: 'tableComment'
        }
      ],
      queryTableName:'',
      selectedRowKeys:[]
    }
  },
  created () {
  },
  methods: {
    afterAdd (record) {
      getAction('/workflow/dbcpWorkflowForm/getTableList').then(res => {
        this.dataSource  = res.result
        this.cacheDataSource = res.result
      })
      this.edit({
        ...record
      })
    },
    afterEdit (record) {

    },
    afterClose () {
      this.selectedRowKeys = []
      this.queryTableName = ''
    },
    popupCallback(row){
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleQuery(){
      if(this.queryTableName.trim() === ''){
        this.dataSource = [...this.cacheDataSource]
      }
      let arr = [...this.cacheDataSource]
      this.dataSource = arr.filter(item => {
        return item.tableName.includes(this.queryTableName)
      })
    },
    handleOk(){
      if(this.selectedRowKeys.length === 0){
        this.$message.warning('请选择表')
        return
      }
      this.confirmLoading = true
      getAction('/workflow/dbcpWorkflowForm/importTableStructure',{
        tableName:this.selectedRowKeys.join(',')
      },true).then(res => {
        this.$emit('ok')
        console.log(res)
      }).finally(() => {
        this.confirmLoading = false
        this.close()
      })
    }

  }
}
</script>
