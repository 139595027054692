<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper" >
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :span='4'>
            <a-form-item label='姓名'>
              <a-input v-model='queryParam.name_like' placeholder='请输入查询的姓名'></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-button type='primary' @click='searchQuery' icon='search'>{{ $t('search') }}</a-button>
            <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{
                $t('reset')
              }}</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus" v-has="'cumOwner:create'">{{ $t('create') }}</a-button>
      <a-button type="primary" icon="download" @click="handleExportXls('业主管理')" v-has="'cumOwner:export'">{{ $t('export') }}</a-button>
      <a-upload name="file" :showUploadList="false" :multiple="false" :headers="tokenHeader" :action="importExcelUrl" @change="handleImportExcel">
        <a-button type="primary" icon="import" v-has="'cumOwner:import'">{{ $t('import') }}</a-button>
      </a-upload>
      <a-button @click='handleUploadTemplate("模板")' type='primary' v-has="'cumOwner:download:Template'">下载导入模板</a-button>
<!--      <a-dropdown v-if="selectedRowKeys.length > 0">-->
<!--        <a-menu slot="overlay">-->
<!--          <a-menu-item key="1" @click="batchDel"><a-icon type="delete"/>{{ $t('delete') }}</a-menu-item>-->
<!--        </a-menu>-->
<!--        <a-button style="margin-left: 8px"> {{ $t('batchOperation') }} <a-icon type="down" /></a-button>-->
<!--      </a-dropdown>-->
    </div>

    <!-- table区域-begin -->
    <div>

      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        :rowSelection="type?{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type}:null"
        @change="handleTableChange">
<!--        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"-->

        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="imgSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noPicture') }}</span>
          <img v-else :src="getImgView(text)" height="25px" alt="" style="max-width:80px;font-size: 12px;font-style: italic;"/>
        </template>
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noFile') }}</span>
          <a-button
            v-else
            :ghost="true"
            type="primary"
            icon="download"
            size="small"
            @click="uploadFile(text)">
            {{ $t('download') }}
          </a-button>
        </template>

        <span slot="action" slot-scope="text, record">
          <div style='display: flex;justify-content: space-evenly;align-items: center'>
                <a @click='handleEdit(record)'>{{ $t('edit') }}</a>
                <a-divider type="vertical" />
                <list-actions
                  :record='record'
                  @view='handleView'
                  @edit='handleEdit'
                  @delete='handleDelete'
                  @action='$refs.detailForm.handleActionClick'
                  @copy='handleCopy'
                ></list-actions>
             <a-divider type="vertical" />
            <a-popconfirm :title="$t('confirmDeleteAlert')" @confirm="() => handleDelete(record.id)">
              <a>{{ $t('delete') }}</a>
            </a-popconfirm>
            </div>
        </span>

      </a-table>
    </div>


    <cumOwner-modal ref="modalForm" @ok="modalFormOk"></cumOwner-modal>
    <cumOwner-detail ref="detailForm" @action="handleActionClick" @close="handleViewModalClose"
                                      @loadData="loadData" @clearRouteQuery="clearRouteQuery"></cumOwner-detail>
  </a-card>
</template>

<script>
  import {  cumOwnerApi } from './modules/api/CumOwner'
  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
  import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
  import { PrinterMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
  import CumOwnerModal from './modules/CumOwnerModal'
  import CumOwnerDetail from './modules/CumOwnerDetail'
  import { ListActions } from 'dbcp-frontends-common/lib/components'
  import {filterMultiDictText} from '@/components/dict/JDictSelectUtil'
  import { getAction,downFile } from 'dbcp-frontends-common/lib/api'

  export default {
    name: "CumOwnerList",
    mixins:[JeecgListMixin, mixinDevice, ViewMixin, PrinterMixin],
    components: {
      CumOwnerModal,
      CumOwnerDetail,
      ListActions,
    },
    props:{
      type:{
        type:String,
      }
    },
    data () {
      return {
        description: '业主管理管理页面',
        // 表头
        columns: [
          // {
          //   title: '#',
          //   dataIndex: '',
          //   key: 'rowIndex',
          //   width: 60,
          //   align: 'center',
          //   customRender: function (t,r,index) {
          //     return parseInt(index)+1;
          //   }
          // },
          {
            title: '权利人',
            align: 'center',
            dataIndex: 'name'
          },
          {
            title: '证件类型',
            align: 'center',
            dataIndex: 'idType_dictText'
          },
          {
            title: '证件号码',
            align: 'center',
            dataIndex: 'idNo'
          },
          {
            title: '备注',
            align: 'center',
            dataIndex: 'remark'
          },
          {
            title: '更新人',
            align: 'center',
            dataIndex: 'updateBy_dictText'
          },
          {
            title: '更新时间',
            align: 'center',
            dataIndex: 'updateTime'
          },
          {
            title: this.$i18n.tc('action'),
            dataIndex: 'action',
            align: 'center',
            // fixed:"right",
            width: 147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: cumOwnerApi.url,
        dictOptions:{},
        selectedRowKeys:[],
      }
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
    },
    methods: {
      initDictConfig(){
      },
      onSelectChange(selectedRowKeys, selectionRows) {
        this.selectedRowKeys = selectedRowKeys;
        this.$emit('childFn',selectionRows);
      },
      handleUploadTemplate(fileName) {
        if(!fileName || typeof fileName != "string"){
          fileName = 'data'
        }
        let param = {...this.queryParam};
        if(this.selectedRowKeys && this.selectedRowKeys.length>0){
          param['selections'] = this.selectedRowKeys.join(",")
        }
        console.log("导出参数",param)
        downFile('cum/cumOwner/downloadTemplate',param).then((data)=>{
          if (!data) {
            return
          }
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(new Blob([data],{type: 'application/vnd.ms-excel'}), fileName+'.xlsx')
          }else{
            let url = window.URL.createObjectURL(new Blob([data],{type: 'application/vnd.ms-excel'}))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName+'.xlsx')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link); //下载完成移除元素
            window.URL.revokeObjectURL(url); //释放掉blob对象
          }
        })
      }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>
