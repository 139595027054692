<template>
  <a-drawer
    :title="title"
    :width="width"
    placement="right"
    :closable="false"
    @close="close"
    :visible="visible">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item label="项目id" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['projectId', validatorRules.projectId]" placeholder="请输入项目id" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="楼栋id" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['buildingId']" placeholder="请输入楼栋id"></a-input>
        </a-form-item>
        <a-form-item label="单元id" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['unitId']" placeholder="请输入单元id"></a-input>
        </a-form-item>
        <a-form-item label="跟进人员" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-select-user-by-dep :hideDept="true" v-decorator="['follower', validatorRules.follower]"/>
        </a-form-item>
        <a-form-item label="权利人" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['rightHolder', validatorRules.rightHolder]" placeholder="请输入权利人"></a-input>
        </a-form-item>
        <a-form-item label="拆迁谈判难易等级" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['negotiationDifficultyLevel', validatorRules.negotiationDifficultyLevel]" placeholder="请输入拆迁谈判难易等级"></a-input>
        </a-form-item>
        <a-form-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-dict-select-tag type="list" v-decorator="['status', validatorRules.status]" :trigger-change="true" dictCode="cum_project_item_status" placeholder="请选择状态"/>
        </a-form-item>
        <a-form-item label="是否委托公证" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-dict-select-tag type="list" v-decorator="['isEntrustedNotarization', validatorRules.isEntrustedNotarization]" :trigger-change="true" dictCode="yn" placeholder="请选择是否委托公证"/>
        </a-form-item>
        <a-form-item label="房产证是否已注销" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-dict-select-tag type="list" v-decorator="['isCancellationRealEstateLicense', validatorRules.isCancellationRealEstateLicense]" :trigger-change="true" dictCode="yn" placeholder="请选择房产证是否已注销"/>
        </a-form-item>
        <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea v-decorator="['remark']" rows="4" placeholder="请输入备注"/>
        </a-form-item>

      </a-form>
    </a-spin>
    <a-button type="primary" @click="handleOk">{{ $t('confirm') }}</a-button>
    <a-button type="primary" @click="handleCancel">{{ $t('cancel') }}</a-button>
  </a-drawer>
</template>

<script>

  import {  cumProjectItemApi } from './api/CumProjectItem'
  import { httpAction } from 'dbcp-frontends-common/lib/api'
  import pick from 'lodash.pick'
  import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
  import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'
  import { JDictSelectTag } from 'dbcp-frontends-common/lib/components'

  export default {
    name: "CumProjectItemModal",
    components: {
      JSelectUserByDep,
      JDictSelectTag,
    },
    data () {
      return {
        form: this.$form.createForm(this),
        title: this.$i18n.tc('action'),
        width:800,
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules: {
          projectId: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          follower: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          rightHolder: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          negotiationDifficultyLevel: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          status: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          isEntrustedNotarization: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          isCancellationRealEstateLicense: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
        },
        url: {
          add: "/cum/cumProjectItem/add",
          edit: "/cum/cumProjectItem/edit",
        }
      }
    },
    created () {
    },
    methods: {
      add () {
        this.edit({});
      },
      edit (record) {
        this.form.resetFields();
        this.model = Object.assign({}, record);
        this.visible = true;
        this.$nextTick(() => {
          this.form.setFieldsValue(pick(this.model,'projectId','buildingId','unitId','follower','rightHolder','negotiationDifficultyLevel','status','isEntrustedNotarization','isCancellationRealEstateLicense','remark','updateBy','updateTime'))
        })
      },
      close () {
        this.$emit('close');
        this.visible = false;
      },
      handleOk () {
        const that = this;
        // 触发表单验证
        this.form.validateFields((err, values) => {
          if (!err) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
               method = 'put';
            }
            let formData = Object.assign(this.model, values);
            console.log("表单提交数据",formData)
            httpAction(httpurl,formData,method).then((res)=>{
              if(res.success){
                that.$message.success(res.message);
                that.$emit('ok');
              }else{
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
              that.close();
            })
          }

        })
      },
      handleCancel () {
        this.close()
      },
      popupCallback(row){
        this.form.setFieldsValue(pick(row,'projectId','buildingId','unitId','follower','rightHolder','negotiationDifficultyLevel','status','isEntrustedNotarization','isCancellationRealEstateLicense','remark','updateBy','updateTime'))
      }

    }
  }
</script>

<style lang="less" scoped>
/** Button按钮间距 */
  .ant-btn {
    margin-left: 30px;
    margin-bottom: 30px;
    float: right;
  }
</style>
