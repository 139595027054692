<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- table区域-begin -->
    <div>
      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="dwneId"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">

        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="imgSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noPicture') }}</span>
          <img v-else :src="getImgView(text)" height="25px" alt=""
               style="max-width:80px;font-size: 12px;font-style: italic;"/>
        </template>
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noFile') }}</span>
          <a-button
            v-else
            :ghost="true"
            type="primary"
            icon="download"
            size="small"
            @click="uploadFile(text)">
            {{ $t('download') }}
          </a-button>
        </template>

        <span slot="action" slot-scope="text, record">
          <a @click="handleView(record)">{{ $t('view') }}</a>
        </span>
      </a-table>
    </div>
    <component ref="detailComponent" :is="componentName" v-if="componentVisible"
               @action="componentAction" @close="clearRouteQuery"></component>

    <component ref="modalComponent" :is="componentModalName" v-if="componentModalVisible"
               @action="componentAction" @close="clearRouteQuery"></component>

    <component ref="printComponent" :is="componentPrintName" v-if="componentPrintVisible"
               @action="componentAction" @close="clearRouteQuery"></component>

  </a-card>
</template>

<script>

import '@assets/less/TableExpand.less'
import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
import {JeecgListMixin} from 'dbcp-frontends-common/lib/mixins'
import Vue from 'vue'
import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'
import { getAction,deleteAction } from 'dbcp-frontends-common/lib/api'

export default {
  name: 'PendingasksWorkflowViewList',
  mixins: [JeecgListMixin, mixinDevice],
  components: {},
  data() {
    return {
      description: 'pending_tasks_workflow_view管理页面',
      // 表头
      columns: [
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function(t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '摘要',
          align: 'center',
          dataIndex: 'serialNumber'
        },
        {
          title: '创建人',
          align: 'center',
          dataIndex: 'addBy_dictText'
        },
        {
          title: '申请时间',
          align: 'center',
          dataIndex: 'addTime',
          sorter: true,
          customRender: function(text) {
            return !text ? '' : (text.length > 10 ? text.substr(0, 10) : text)
          }
        },
        {
          title: '模块',
          align: 'center',
          dataIndex: 'moduleName'
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'status',
          customRender: (text) => {
            return this.$i18n.tc(`workflowStatus.${text}`)
          }
        },
        {
          title: this.$i18n.tc('action'),
          dataIndex: 'action',
          align: 'center',
          // fixed:"right",
          width: 147,
          scopedSlots: { customRender: 'action' }
        }
      ],
      url: {
        list: '/workflow/pendingTasksWorkflowView/list',
        delete: '/oa/dbcp/pendingTasksWorkflowView/delete',
        deleteBatch: '/oa/dbcp/pendingTasksWorkflowView/deleteBatch',
        exportXlsUrl: '/oa/dbcp/pendingTasksWorkflowView/exportXls',
        importExcelUrl: '/oa/dbcp/pendingTasksWorkflowView/importExcel'
      },
      dictOptions: {},
      componentName: '',
      componentVisible: false,
      componentModalVisible: false,
      componentModalName: '',
      componentPrintName: '',
      componentPrintVisible: '',
    }
  },
  computed: {
    importExcelUrl: function() {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    },
    todo:function() {
      return this.$store.state.user.todo.timestamp
    }
  },
  watch:{
    $route: {
      handler: function(val, oldVal){
        if(this.$route.query.id){
          this.showTask()
        }
      },
      // 深度观察监听
      deep: true
    },
    todo: function(val, oldVal){
      this.loadData()
    },
    deep: true
  },
  created() {
    this.showTask()
  },
  beforeDestroy() {
    this.$store.state.app.currentTask = []
  },
  methods: {
    initDictConfig() {
    },
    handleView(record) {
      this.$store.commit('SET_CURRENT_TASK',record)
      this.$router.push({
        path:'/public-platform/todo?id='+new Date().getTime(),
      })
    },
    showTask() {
      const currentTask = this.$store.state.app.currentTask
      const allRoute = this.$store.state.app.allRoute
      let targetItem
      // 1.判断缓存有没有，有就展示
      if (this.$store.state.app.currentTask) {
        //  通过当前task的路径找到对应菜单的component
        console.log(currentTask)
        console.log(allRoute)
        let fn = (currentTask, allRoute) => {
          allRoute.forEach(routeItem => {
            if (routeItem.path === currentTask.url) {

              targetItem = routeItem
            } else {
              if (routeItem.children) {
                fn(currentTask, routeItem.children)
              } else {
                return undefined
              }
            }
          })
        }
        fn(currentTask, allRoute)

        console.log('this.targetItem', targetItem)

        //处理url
        let url = targetItem && targetItem.component || undefined
        if(url) {


          let detailUrl = url.slice(0, url.lastIndexOf('/')) + '/modules' + url.slice(url.lastIndexOf('/'), url.length).replace('List', 'Detail')
          console.log('detailUrl', detailUrl)
          //找到相应的api
          let apiUrl = url.slice(0, url.lastIndexOf('/')) + '/modules/api' + url.slice(url.lastIndexOf('/'), url.length).replace('List', '')

          console.log('apiUrl', apiUrl)
          if (targetItem) {
            import(`@views/${detailUrl}`).then(detailValue => {
              let targetComponent = detailValue.default
              console.log('targetComponent', targetComponent)
              let apiModuleName = apiUrl.slice(apiUrl.lastIndexOf('/') + 1, apiUrl.length).replace('Dbcp', '') + 'Api'
              apiModuleName = apiModuleName.slice(0, 1).toLowerCase() + apiModuleName.slice(1)
              import(`@views/${apiUrl}`).then(apiValue => {
                console.log('apiValue', apiValue)
                console.log('apiModule', apiModuleName)
                let apiModule = apiValue[apiModuleName]
                console.log('apiModuleName', apiModule)

                Vue.component(targetComponent.name, targetComponent)

                this.componentName = targetComponent.name
                this.componentVisible = true

                this.$nextTick(() => {
                  console.log('this.$refs.detailComponent', this.$refs.detailComponent)
                  getAction(apiModule.url.list, { id: currentTask.objectId }).then((res) => {
                    if (res.result.records && res.result.records[0]) {
                      let record = res.result.records && res.result.records[0]
                      // this.handleView(record)
                      this.$refs.detailComponent.view(record)
                    }
                  })
                })
              })
            })
          }
        }
      }
    },
    componentAction(data, action) {
      const currentTask = this.$store.state.app.currentTask
      const allRoute = this.$store.state.app.allRoute
      let targetItem
      if (Array.isArray(data) && !action) {
        console.log('detail')
        action = data[1]
        data = data[0]
      }
      // 1.判断缓存有没有，有就展示
      if (this.$store.state.app.currentTask) {
        //  通过当前task的路径找到对应菜单的component
        console.log(currentTask)
        console.log(allRoute)
        let fn = (currentTask, allRoute) => {
          allRoute.forEach(routeItem => {
            if (routeItem.path === currentTask.url) {
              targetItem = routeItem
            } else {
              if (routeItem.children) {
                fn(currentTask, routeItem.children)
              } else {
                return undefined
              }
            }
          })
        }
        fn(currentTask, allRoute)

        console.log('this.targetItem', targetItem)
        //处理url
        let url = targetItem.component
        let modalUrl = url.slice(0, url.lastIndexOf('/')) + '/modules' + url.slice(url.lastIndexOf('/'), url.length).replace('List', 'Modal')
        let listUrl = url.slice(0, url.lastIndexOf('/')) + url.slice(url.lastIndexOf('/'), url.length)
        let apiUrl = url.slice(0, url.lastIndexOf('/')) + '/modules/api' + url.slice(url.lastIndexOf('/'), url.length).replace('List', '')
        console.log('detailUrl', apiUrl)
        // //找到相应的api
        // let apiUrl = url.slice(0, url.lastIndexOf('/')) + '/modules/api' + url.slice(url.lastIndexOf('/'), url.length).replace('List', '')

        // console.log('apiUrl', apiUrl)
        if (targetItem) {
          let apiName = (apiUrl.slice(apiUrl.lastIndexOf('/') + 1, apiUrl.length) + 'Api').replace('Dbcp', '')
          apiName = apiName.slice(0, 1).toLowerCase() + apiName.slice(1)
          let promiseImportAll = [import(`@views/${modalUrl}`),import(`@views/${listUrl}`),import(`@views/${apiUrl}`)]
          Promise.all(promiseImportAll).then(values => {
            let { default: targetComponent } = values[0]
            let { default: listComponent } = values[1]
            let apiJs = values[2][apiName]

            Vue.component(targetComponent.name, targetComponent)

            console.log(targetComponent)
            console.log('listComponent', listComponent)
            console.log('apiJs', apiJs)
            this.componentModalName = targetComponent.name
            this.componentModalVisible = true
            // console.log("listComponent.data",listComponent.data())
            //找到正确的mixin
            let targetMixin = listComponent.mixins.filter((item) => {
              return item && item.methods && item.methods.hasOwnProperty('handleAdd')
            })[0]

            console.log('targetMixin', targetMixin)

            this.$nextTick(() => {
              console.log('action', action)
              console.log('data', data)
              //teamwork
              if (targetComponent.name === 'DbcpTeamworkTaskModal') {
                switch (action) {
                  case 'edit':
                    data.panelId = this.panelIdCurrent
                    this.$refs.modalComponent.edit(data)
                    return
                  case 'delete':
                    deleteAction(apiJs.url.deleteTask, { id : data.id }).then(() => {
                      listComponent.methods.getTasks()
                    })
                    return
                }
              } else {
                switch (action) {
                  case 'edit':
                    if (targetComponent.name === 'DbcpPerformanceAppraisalModal') {
                      this.$refs.modalComponent.showModal('edit', data)
                    } else {
                      this.$refs.modalComponent.edit(data)
                    }
                    return
                  case 'delete':
                    if (targetComponent.name === 'DbcpTeamworkTaskModal') {
                      data.panelId = this.panelIdCurrent
                      this.$refs.modalComponent.edit(data)
                    } else {
                      targetMixin.methods.handleDelete(data, apiJs.url)
                    }

                    return
                  case 'print':
                    this.registerPrintComponent(url, data)
                    return
                  case 'copy':
                    if (targetComponent.name === 'DbcpPerformanceAppraisalModal') {
                      this.$refs.modalComponent.showModal('copy', data)
                    } else {
                      this.$refs.modalComponent.copy(data)
                    }
                    return
                  case 'encrypt':
                    targetMixin.methods.handleEncrypt(data)
                    return
                  case 'decrypt':
                    targetMixin.methods.handleDecrypt(data)
                    return
                }
              }

              //特殊处理绩效考核

              this.$refs.detailComponent.close()
            })
          })

        }
      }
    },
    //  注册打印组件
    registerPrintComponent(url, data) {
      let printUrl = url.slice(0, url.lastIndexOf('/')) + '/modules' + url.slice(url.lastIndexOf('/'), url.length).replace('List', 'Print')

      import(`@views/${printUrl}`).then(printValue => {
        let { default: printComponent } = printValue
          console.log('printComponent', printComponent)
        Vue.component('printComponent', printComponent)
        this.componentPrintName = 'printComponent'
        this.componentPrintVisible = true

        this.$nextTick(() => {
          this.$refs.printComponent.view(data)
          this.$refs.detailComponent.close()
        })
      })

    },

    clearRouteQuery() {
      if (this.$route.query.id) {
        this.$store.state.app.currentTask = []
        delete this.$route.query.id
        // 参数变化才会触发更新，增加 { _: +new Date() }
        this.$router.push({
          query: Object.assign({}, this.$route.query, { _: +new Date() })
        })
      }
    }
  }
}
</script>
<style scoped>
@import '~@assets/less/common.less';
</style>
