<template>
  <a-modal
    title="修改Json"
    :visible="hidden"
    :confirm-loading="confirmJsonDataLoading"
    @ok="handleJsonDataOk"
    @cancel="handleJsonDataCancel"
  >
    <JsonEditor
      :options="{
            confirmText: 'confirm',
            cancelText: 'cancel',
        }"
      :objData="newJsonData"
      v-model="newJsonData">
    </JsonEditor>
  </a-modal>
</template>

<script>
export default {
  name: 'DbcpWorkflowNodeJsonEdit',
  props: {
    jsonData: {
      type:Array | Object,
      default: null
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      newJsonData:null,
      visibleJsonData:false,
      confirmJsonDataLoading:false
    }
  },
  watch:{
    jsonData(newV){
      this.newJsonData = newV
    }
  },
  methods:{
    handleJsonDataOk(){
      this.$emit('jsonDataChange',this.newJsonData['data'])
    },
    handleJsonDataCancel(){
      this.$emit('closeJsonDataChange')
    }
  }
}
</script>

<style scoped lang="less">
.json-edit{
  width: 300px;
}
</style>
