<template>
  <div>
    <a-modal
      :title="title"
      placement="right"
      @cancel="close"
      width="60%"
      :footer="null"
      :visible="visible">
      <a-descriptions :column="3" bordered size="small">
        <a-descriptions-item label="谈判条件" :span='3' v-if='data.bargainingPowerId'>
          <a @click='turnBargainingPowerDetail(data.bargainingPowerId)'>{{ data.bargainingPowerSerialNumber }}</a>
        </a-descriptions-item>
              <a-descriptions-item label="合同编号" :span='3'>
          {{ data.serialNumber }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('projectId')">
          <a @click='turnProjectDetail(data.projectId)'>{{ data.projectId_dictText }}</a>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('projectItemId')">
          <a @click='turnProjectItemDetail(data)'>{{ data.rightHolder_dictText }}</a>
        </a-descriptions-item>
<!--        <a-descriptions-item :label="$t('dept')">-->
<!--          {{ data.dept_dictText }}-->
<!--        </a-descriptions-item>-->
        <a-descriptions-item :label="$t('handoverTime')">
          {{ data.handoverTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('contractedArea')">
          {{ data.contractedArea }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('payee')">
          {{ data.payee }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('openingBank')">
          {{ data.openingBank }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('bankAccount')">
          {{ data.bankAccount }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('status')" >
          {{ data.status_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addBy')">
          {{ data.addBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addTime')" :span='3'>
          {{ data.addTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('remark')" :span='3'>
          {{ data.remark }}
        </a-descriptions-item>
        <a-descriptions-item label="当前处理人" :span='3'>
          {{ data.approver_dictText }}
        </a-descriptions-item>
      </a-descriptions>
      应付款:
      <a-table :columns='realColumns' :dataSource='[{}]' :pagination='false'>

        <template
          v-for='(col,index) in realColumns'
          :slot="'amount'+index"
          slot-scope='text, record'
        >
          {{ data.cumPaymentAmountList[index].amount }}
        </template>
      </a-table>
<!--      首付款:-->
<!--      <a-table :columns='firstPayMoneyColumns' :dataSource='firstPayMoneyDataSource' :pagination='false' size='small'>-->
<!--      </a-table>-->
      <a-divider></a-divider>
      <detail-step :url="`/cum/cumContract/workflowVisual?id=${data.id}`" :visible="visible" ref="detailStep"></detail-step>

        <!--   actions   -->
        <div class="detail-actions" v-if="data.action && Object.keys(data.action).length > 0">
          <a-divider></a-divider>
          <span v-for="action in Object.keys(data.action)">
            <a-button
              type="primary"
              @click="handleActionClick([data, action.replace('_dictText','')])"
              class="detail-actions-button"
              v-if="action.includes('_dictText')"
            >
              {{ data.action[action]}}
<!--              {{ $t('actionCode["' + action + '"]') }}-->
            </a-button>
          </span>
        </div>

      <a-divider></a-divider>
      <detail-history :file-list="data.fileList" :history-list="histories"></detail-history>
    </a-modal>

    <a-modal
      :visible="actionFormVisible"
      :title="$t('actionCode[\'' + action +'\']')"
      @ok="performAction"
      @cancel="actionFormVisible = false"
    >
      <a-form>
        <a-form-item :label="$t('comment')">
          <a-textarea v-model="actionForm.comment"></a-textarea>
        </a-form-item>
      </a-form>

    </a-modal>

    <cumProjectItem-detail ref='projectItemDetailForm' ></cumProjectItem-detail>

    <cum-bargaining-power-detail ref='bargainingPowerDetail'></cum-bargaining-power-detail>

    <cum-project-detail ref='projectDetailForm'></cum-project-detail>

  </div>
</template>

<script>
  import {  cumContractApi } from './api/CumContractWorkFlow'
  import { DetailHistory } from 'dbcp-frontends-common/lib/components'
  import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ActionMixin } from 'dbcp-frontends-common/lib/mixins'
  import DetailStep from '@/components/dbcp/detail/DetailStep'
  import { getAction } from 'dbcp-frontends-common/lib/api'
  import CumProjectItemDetail from '../../projectManagement/modules/CumProjectItemDetail'
  import CumBargainingPowerDetail from '../../bargainingPower/modules/CumBargainingPowerDetail'
  import CumProjectDetail from '../../projectManagement/modules/CumProjectDetail'


  export default {
    name: "CumContractDetail",
    components: {
      CumBargainingPowerDetail,
      DetailStep,
      DetailHistory,
      CumProjectItemDetail,
      CumProjectDetail
    },
    mixins: [ DetailMixin,ActionMixin ],
    data() {
      return {
        url: cumContractApi.url,
        payableMoneyColumns:[
          {
            title: '货币补偿价格',
            align: 'center',
            dataIndex: 'payableMoney0',
            scopedSlots: { customRender: 'payableMoney0' }
          },
          {
            title: '装修补偿',
            align: 'center',
            dataIndex: 'payableMoney1',
            scopedSlots: { customRender: 'payableMoney1' }
          },
          {
            title: '附属物补偿',
            align: 'center',
            dataIndex: 'payableMoney2',
            scopedSlots: { customRender: 'payableMoney2' }
          },
          {
            title: '临时安置补助费',
            align: 'center',
            dataIndex: 'payableMoney3',
            scopedSlots: { customRender: 'payableMoney3' }
          },
          {
            title: '其它',
            align: 'center',
            dataIndex: 'payableMoney4',
            scopedSlots: { customRender: 'payableMoney4' }
          },
        ],
        firstPayMoneyColumns:[
          {
            title: '货币补偿价格',
            align: 'center',
            dataIndex: 'firstPayMoney0',
            scopedSlots: { customRender: 'firstPayMoney0' }
          },
          {
            title: '装修补偿',
            align: 'center',
            dataIndex: 'firstPayMoney1',
            scopedSlots: { customRender: 'firstPayMoney1' }
          },
          {
            title: '附属物补偿',
            align: 'center',
            dataIndex: 'firstPayMoney2',
            scopedSlots: { customRender: 'firstPayMoney2' }
          },
          {
            title: '临时安置补助费',
            align: 'center',
            dataIndex: 'firstPayMoney3',
            scopedSlots: { customRender: 'firstPayMoney3' }
          },
          {
            title: '其它',
            align: 'center',
            dataIndex: 'firstPayMoney4',
            scopedSlots: { customRender: 'firstPayMoney4' }
          },
        ],
        payableMoneyDataSource:[{}]
      }
    },
    computed:{
      payableMoneyDataSource(){
        return [{
          payableMoney0:this.data.payableMoney0,
          payableMoney1:this.data.payableMoney1,
          payableMoney2:this.data.payableMoney2,
          payableMoney3:this.data.payableMoney3,
          payableMoney4:this.data.payableMoney4,
        }]
      },
      realColumns() {
        let arr = this.data.cumPaymentAmountList || []
        let columns = []
        arr.forEach((item, index) => {
          let obj = {
            title: item.compensateTypeId_dictText,
            align: 'center',
            scopedSlots: { customRender: 'amount' + index }
          }
          columns.push(obj)
        })

        return columns
      }
    },
    methods:{
      turnProjectItemDetail(record){
        getAction("/cum/cumProjectItem/list",{id:record.projectItemId}).then(res=>{
          let data = res.result.records[0];
          this.$refs.projectItemDetailForm.view(data);
        })
      },
      turnBargainingPowerDetail(id){
        getAction("/cum/cumBargainingPower/list",{id}).then(res=>{
          let record = res.result.records[0];
          this.$refs.bargainingPowerDetail.view(record);
        })
      },
      turnProjectDetail(id){
        getAction("/cum/cumProject/list",{id}).then(res=>{
          let data = res.result.records[0];
          this.$nextTick(()=>{
            this.$refs.projectDetailForm.view(data);
          })

        })
      },
    }
  }
</script>
