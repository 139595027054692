import { render, staticRenderFns } from "./CumOwnerList.vue?vue&type=template&id=44bb63c7&scoped=true&"
import script from "./CumOwnerList.vue?vue&type=script&lang=js&"
export * from "./CumOwnerList.vue?vue&type=script&lang=js&"
import style0 from "./CumOwnerList.vue?vue&type=style&index=0&id=44bb63c7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44bb63c7",
  null
  
)

export default component.exports