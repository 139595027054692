<template>
  <div>
    <a-modal
      :title="title"
      :width="width"
      placement="right"
      @cancel="close"
      :footer="null"
      :visible="visible">
      <a-descriptions :column="3" bordered size="small">
              <a-descriptions-item :label="$t('projectId')">
          {{ data.projectId }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('propertyType')">
          {{ data.propertyType }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('structureType')">
          {{ data.structureType }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('compensateMethod')">
          {{ data.compensateMethod }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('compensateType')">
          {{ data.compensateType }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('compensationAmount')">
          {{ data.compensationAmount }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('compensationRate')">
          {{ data.compensationRate }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addBy')">
          {{ data.addBy }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addTime')">
          {{ data.addTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateBy')">
          {{ data.updateBy }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateTime')">
          {{ data.updateTime }}
        </a-descriptions-item>
      </a-descriptions>

        <!--   actions   -->
        <div class="detail-actions" v-if="data.action && Object.keys(data.action).length > 0">
          <a-divider></a-divider>
          <span v-for="action in Object.keys(data.action)">
            <a-button
              type="primary"
              @click="handleActionClick([data, action])"
              class="detail-actions-button"
            >
              {{ $t('actionCode["' + action + '"]') }}
            </a-button>
          </span>
        </div>

      <a-divider></a-divider>
      <detail-history :file-list="data.fileList" :history-list="histories"></detail-history>
    </a-modal>

    <a-modal
      :visible="actionFormVisible"
      :title="$t('actionCode[\'' + action +'\']')"
      @ok="performAction"
      @cancel="actionFormVisible = false"
    >
      <a-form>
        <a-form-item :label="$t('comment')">
          <a-textarea v-model="actionForm.comment"></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import {  cumCompensationStandardApi } from './api/CumCompensationStandardWorkFlow'
  import { DetailHistory } from 'dbcp-frontends-common/lib/components'
  import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ActionMixin } from 'dbcp-frontends-common/lib/mixins'

  export default {
    name: "CumCompensationStandardDetail",
    components: { DetailHistory },
    mixins: [ DetailMixin,ActionMixin ],
    data() {
      return {
        url: cumCompensationStandardApi.url,
      }
    }
  }
</script>
