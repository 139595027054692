<template>
  <j-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('cancel')">
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item :label="$t('functionId')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['moduleId', validatorRules.moduleId]" style="width: 100%"/>
        </a-form-item>
        <a-form-item :label="$t('stateValue')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['status', validatorRules.status]"></a-input>
        </a-form-item>
        <a-form-item :label="$t('upperStateValue')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['superiorStatus']" style="width: 100%"/>
        </a-form-item>
        <a-form-item :label="$t('subStateJson')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <div @click="showEdit('actions')">{{ this.model.actions }}</div>
        </a-form-item>
        <a-form-item :label="$t('sort')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['sort', validatorRules.sort]" style="width: 100%"/>
        </a-form-item>
        <a-form-item :label="$t('approverUsername')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <div @click="showEdit('approverRule')">{{ this.model.approverRule }}</div>
        </a-form-item>
        <a-form-item :label="$t('alternateApprover')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['standbyApproverRule']"></a-input>
        </a-form-item>
        <a-form-item :label="$t('nodeType')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['type', validatorRules.type]"
                          style="width: 100%"/>
        </a-form-item>
      </a-form>
    </a-spin>

    <dbcp-workflow-node-json-edit :hidden="hide" :jsonData="jsonData" @jsonDataChange="jsonDataChange"
                                  @closeJsonDataChange="closeJsonDataChange"></dbcp-workflow-node-json-edit>

  </j-modal>
</template>

<script>

import { httpAction } from 'dbcp-frontends-common/lib/api'
import pick from 'lodash.pick'
import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
import DbcpWorkflowNodeJsonEdit from '@views/dbcp/core/workflowNode/modules/DbcpWorkflowNodeJsonEdit'


export default {
  name: 'DbcpWorkflowNodeModal',
  components: {
    DbcpWorkflowNodeJsonEdit
  },
  data() {
    return {
      hide: false,
      form: this.$form.createForm(this),
      title: this.$i18n.tc('action'),
      width: 800,
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      validatorRules: {
        moduleId: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        status: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint')}
          ]
        },
        sort: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint')}
          ]
        },
        approverRule: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        type: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        }
      },
      url: {
        add: '/workflow/dbcpWorkflowNode/add',
        edit: '/workflow/dbcpWorkflowNode/edit'
      },
      jsonData: {},
      whichList: 'actions'
    }
  },
  created() {
  },
  methods: {
    add() {
      this.edit({})
    },
    edit(record) {
      this.form.resetFields()
      this.model = Object.assign({}, record)
      this.visible = true
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'moduleId', 'status', 'superiorStatus', 'actions', 'sort', 'approverRule', 'standbyApproveUsername', 'type'))
      })
    },
    close() {
      this.$emit('close')
      this.visible = false
    },
    handleOk() {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          let httpurl = ''
          let method = ''
          if (!this.model.id) {
            httpurl += this.url.add
            method = 'post'
          } else {
            httpurl += this.url.edit
            method = 'put'
          }
          let formData = Object.assign(this.model, values)
          console.log('表单提交数据', formData)
          httpAction(httpurl, formData, method).then((res) => {
            if (res.success) {

              that.$emit('ok')
            } else {

            }
          }).finally(() => {
            that.confirmLoading = false
            that.close()
          })
        }

      })
    },
    handleCancel() {
      this.close()
    },
    popupCallback(row) {
      this.form.setFieldsValue(pick(row, 'moduleId', 'status', 'superiorStatus', 'actions', 'sort', 'approverRule', 'standbyApproveUsername', 'type'))
    },
    showEdit(data) {
      this.hide = true
      this.jsonData = JSON.parse(this.model[data])
      let obj = {
        'data': this.jsonData
      }
      this.jsonData = obj
      this.whichList = data
    },
    //修改了JSON
    jsonDataChange(newJson) {
      this.model[this.whichList] = JSON.stringify(newJson)
      this.hide = false
    },
    //关闭JSON修改
    closeJsonDataChange() {
      this.hide = false
    }
  }
}
</script>
