<template>
  <a-radio-group v-model="groupMode" @change="handleGroupChange" :style="{ marginBottom: '8px' }">
    <a-radio-button value="application">
      {{ $t('applicationHistory') }}
    </a-radio-button>
    <a-radio-button value="approval">
      {{ $t('approvalHistory') }}
    </a-radio-button>
    <a-radio-button value="cc">
      {{ $t('ccToMe') }}
    </a-radio-button>
    <a-radio-button value="all" v-has="permission">
      {{ $t('allRecords') }}
    </a-radio-button>
  </a-radio-group>
</template>

<script>

export default {
  name: 'OfficialSealTab',
  props: {
    permission: {
      type: String,
      default: ''
    },

  },
  data() {
    return {
      groupMode: 'application'
    }
  },
  methods: {
    handleGroupChange() {
      this.$emit('change', this.groupMode)
    }
  }

}
</script>

<style scoped>

</style>
