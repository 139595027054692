import { deleteAction, getAction, uploadAction } from 'dbcp-frontends-common/lib/api'
export const fileApi = {
  url: {
          list: "/oa/dbcp/dbcpFile/list",
          delete: "/oa/dbcp/dbcpFile/delete",
          deleteBatch: "/oa/dbcp/dbcpFile/deleteBatch",
          exportXlsUrl: "/oa/dbcp/dbcpFile/exportXls",
          importExcelUrl: "/oa/dbcp/dbcpFile/importExcel",
        },
  execute: {
    list: function(params) {
      return getAction(fileApi.url.list, params)
    },
    create: function(object) {
      return getAction(fileApi.url.add, object)
    },
    retrieve: function(id, params = {}) {
      return getAction(fileApi.url.queryById, { id, ...params })
    },
    update: function(object) {
      return uploadAction(fileApi.url.edit, object)
    },
    destroy: function(id) {
      return deleteAction(fileApi.url.delete, id)
    }
  }
}
