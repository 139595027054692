<template>
  <a-modal
    v-model='itemListVisible'
    @ok='itemListVisible = false'
    width='90%'
    @cancel='handleCancel'
    :footer='null'
  >
    <cum-project-item-table ref='itemTable' @closeModal='closeModal'></cum-project-item-table>
  </a-modal>
</template>

<script>


import CumProjectItemTable from './CumProjectItemTable'
export default {
  name: 'CumProjectItemList',
  components: { CumProjectItemTable },
  data() {
    return {
      itemListVisible: false,
      description: '项目细项管理页面',
      currentRecord:{},
      queryParam:{},
    }
  },
  computed: {
    importExcelUrl: function() {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    }
  },
  methods: {
    initDictConfig() {
    },
    show(record) {
      this.itemListVisible = true ;
      this.$nextTick(()=>{
        this.$refs.itemTable.initListData(record);
      })
    },
    handleCancel() {
      // this.$emit('close');
      this.$refs.itemTable.close();
      this.itemListVisible = false ;
    },
    closeModal(){
      this.itemListVisible = false ;
    }


  }
}
</script>
<style scoped>
@import '~@assets/less/common.less';
</style>
