import { render, staticRenderFns } from "./CumVerificationTemplateList.vue?vue&type=template&id=6bb8e4bf&scoped=true&"
import script from "./CumVerificationTemplateList.vue?vue&type=script&lang=js&"
export * from "./CumVerificationTemplateList.vue?vue&type=script&lang=js&"
import style0 from "./CumVerificationTemplateList.vue?vue&type=style&index=0&id=6bb8e4bf&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bb8e4bf",
  null
  
)

export default component.exports