<template>
  <j-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('cancel')">
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

<!--        <a-form-item label="合同" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
<!--          <a-input-number v-decorator="['contractId', validatorRules.contractId]" placeholder="请输入合同id" style="width: 100%"/>-->
<!--        </a-form-item>-->
        <a-form-item label="项目" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <InputSelect v-decorator="['projectId', validatorRules.projectId]" show-name='name' send-params='id'
                       url='dbcp/city/projectManagement/CumProjectList.vue'
                       type='radio' @ok='handleSelectProject' :isEdit='model.projectId?true:false'></InputSelect>
        </a-form-item>

        <a-table   ref="table"
                   size="middle"
                   bordered
                   rowKey="id"
                   :columns="columns"
                   :dataSource="realDataSource"
                   :pagination="false"

                   >
          <template slot='detail' slot-scope='text, record,index'>
            <p v-for='item in record.cumPaymentAmountList'>
              {{ item.compensateTypeId_dictText }} : {{item.amount}}
            </p>
          </template>
<!--          v-if='form.getFieldValue("projectId")'-->
        </a-table>
        <br>

        <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea v-decorator="['remark']" rows="4" placeholder="请输入备注"/>
        </a-form-item>
        <a-form-item :label="$t('attachments')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <attachment v-decorator="['upload']" @handleFileRemove="handleFileRemove" @beforeFileUpload="beforeFileUpload" :file-list="uploadFiles" :default-file-list="defaultFileList"></attachment>
        </a-form-item>

      </a-form>
    </a-spin>

    <template slot="footer">
      <a-button type="primary" @click="handleOk(false)">{{ $t('saveDraft') }}</a-button>
      <a-button type="primary" @click="handleOk(true)">{{ $t('submit') }}</a-button>
      <a-button @click="handleCancel">{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
  import { cumCollectionPaymentApi } from './api/CumCollectionPayment'
  import { httpAction,getAction } from 'dbcp-frontends-common/lib/api'
  import pick from 'lodash.pick'
  import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
  import { Attachment } from 'dbcp-frontends-common/lib/components'
  import { JDate } from 'dbcp-frontends-common/lib/components'
  import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'
  import { UploadFormMixin } from 'dbcp-frontends-common/lib/mixins'
  import InputSelect from '../../../../../components/dbcp/InputSelect'

  export default {
    name: "CumCollectionPaymentModal",
    components: {
      JDate,
      JSelectUserByDep,
        Attachment,
      InputSelect
    },
    mixins: [ UploadFormMixin ],
    data () {
      return {
        form: this.$form.createForm(this),
        title: this.$i18n.tc('action'),
        width: 1200,
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules: {
          serialNumber: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          contractId: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          addBy: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          addTime: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          status: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          projectId: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
        },
        url: cumCollectionPaymentApi.url,
        dataSource:[],
        columns:[
          {
            title: '业主',
            align: 'center',
            dataIndex: 'rightHolder_dictText',
          },
          {
            title: '应付款',
            align: 'center',
            dataIndex: 'rightHolder_dictText',
            children:[
              {
                title: '详情',
                align: 'center',
                dataIndex: 'detail',
                scopedSlots: { customRender: 'detail' }
              },
              {
                title: '合计',
                align: 'center',
                dataIndex: 'payableMoney',
              }
            ]
          },
          {
            title: '已付款',
            align: 'center',
            dataIndex: 'rightHolder_dictText',
            children:[
              {
                title: '合计',
                align: 'center',
                dataIndex: 'paidMoney',
              }
            ]
          },
          {
            title: '未付款',
            align: 'center',
            dataIndex: 'rightHolder_dictText',
            children:[
              {
                title: '合计',
                align: 'center',
                dataIndex: 'pendingMoney',
              }
            ]
          },
        ]
      }
    },
    computed:{
      realDataSource(){
        let arr = [...this.dataSource]
        let payableMoney = arr.reduce((prev, current) => {
          return prev + (current.payableMoney || 0)
        }, 0)
        let paidMoney = arr.reduce((prev, current) => {
          return prev + (current.paidMoney || 0)
        }, 0)
        let pendingMoney = arr.reduce((prev, current) => {
          return prev + (current.pendingMoney || 0)
        }, 0)
        arr.push({
          id:0 - arr.length,
          rightHolder_dictText:'合计',
          payableMoney:payableMoney,
          paidMoney:paidMoney,
          pendingMoney:pendingMoney
        })
        console.log('arr',arr)
        return arr
      }
    },
    created () {
    },
    methods: {
      afterAdd (record) {
        this.edit({
            ...record
        })
      },
      afterEdit (record) {
        this.dataSource = [];
        console.log("record",record)
        if(record.projectId){
          this.handleSelectProject(record.projectId);
        }
        this.$nextTick(() => {
          this.form.setFieldsValue(pick(this.model,'serialNumber','contractId','remark','addBy','addTime','status','projectId'))
        })
      },
      afterClose () {
      },
      popupCallback(row){
        this.form.setFieldsValue(pick(row,'serialNumber','contractId','remark','addBy','addTime','status','projectId'))
      },
      handleSelectProject(data){
        getAction('/cum/cumProject/getContractInformation',{
          projectId: (data.id || data ),
        }).then(res => {
          if(res.success){
           this.dataSource = res.result
          }
        })
      },
      processFormData(formData){
        formData.set('targetList',JSON.stringify(this.dataSource))
        return formData
      }

    }
  }
</script>
