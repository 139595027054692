<template>
  <j-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('cancel')">
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item :label="$t('loginPassword')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input type="password" v-decorator="[ 'loginPassword', validatorRules.loginPassword]" />
        </a-form-item>
<!--        修改二级密码的时候-->
        <a-form-item label="旧二级密码" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if="$store.state.user.hasSecondaryPassword">
          <a-input type="password" @blur="handleConfirmBlur"
                   v-decorator="[ 'oldSecondaryPassword', validatorRules.oldSecondaryPassword]"/>
        </a-form-item>
        <a-form-item label="二级密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input type="password" @blur="handleConfirmBlur"
                   v-decorator="[ 'secondaryPassword', validatorRules.secondaryPassword]"/>
        </a-form-item>
        <a-form-item :label="$t('confirmPassword')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input type="password" @blur="handleConfirmBlur" v-decorator="[ 'confirmPassword',validatorRules.confirmPassword]" />
        </a-form-item>
      </a-form>
    </a-spin>

    <template slot="footer">
      <a-button type="primary" @click="handleOk(true)">{{ $t('submit') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
import { httpAction } from 'dbcp-frontends-common/lib/api'
import pick from 'lodash.pick'
import { UploadFormMixin } from 'dbcp-frontends-common/lib/mixins'
import { Attachment } from 'dbcp-frontends-common/lib/components'

export default {
  name: "SecondaryPasswordSet",
  components: {
    Attachment
  },
  mixins: [ UploadFormMixin ],
  data () {
    return {
      form: this.$form.createForm(this),
      title: '设置二级密码',
      width: 800,
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      validatorRules: {
        secondaryPassword: {
          rules: [{
            required: true,
            pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{8,}$/,
            message: this.$i18n.tc('inputPwdInfo')
          }, {
            validator: this.validateSecondaryPassword
          }]
        },
        oldSecondaryPassword:{
          rules: [{
            required: true,
            pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{8,}$/,
            message: this.$i18n.tc('inputPwdInfo')
          }]
        },
        confirmPassword:{
          rules: [{
            required: true, message: this.$i18n.tc('inputPwdInfo'),
            pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{8,}$/,
          }, {
            validator: this.validateToNextPassword
          }]
        },
        loginPassword: {
          rules: [{
            required: true, message: this.$i18n.tc('fieldEmptyHint')
          }]
        },
      },
    }
  },
  created () {
  },
  computed:{
    url(){
      if(this.$store.state.user.hasSecondaryPassword){
        return {add:'/sys/user/changeSecondaryPassword'}
      }else{
        return {add:'/sys/user/setSecondaryPassword'}
      }
    }
  },
  methods: {
    afterAdd (record) {
      this.edit({
        ...record
      })
    },
    afterEdit (record) {
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model))
      })
    },
    afterClose () {
    },
    popupCallback(row){
      this.form.setFieldsValue(pick(row))
    },
    handleConfirmBlur(e){

    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form
      const secondaryPassword = form.getFieldValue('secondaryPassword')
      if (value && secondaryPassword && value !== secondaryPassword) {
        callback('二级密码跟确认密码不一样！')
      }
      callback()
    },
    validateSecondaryPassword(rule, value, callback){
      const form = this.form
      if(this.$store.state.user.hasSecondaryPassword){
        const oldSecondaryPassword = form.getFieldValue('oldSecondaryPassword')
        if (value && oldSecondaryPassword && value === oldSecondaryPassword) {
          callback('二级密码不能更旧密码一样')
        }
      }
      if (value && value === form.getFieldValue('loginPassword')) {
        callback('二级密码不能跟登录密码一样！')
      } else {
        callback()
      }
    }
  }
}
</script>
