import { deleteAction, getAction, uploadAction } from 'dbcp-frontends-common/lib/api'
export const cumProjectItemApi = {
  url: {
    action: "/cum/cumProjectItem/action",
    list: "/cum/cumProjectItem/list",
    delete: "/cum/cumProjectItem/delete",
    deleteBatch: "/cum/cumProjectItem/deleteBatch",
    exportXlsUrl: "/cum/cumProjectItem/export",
    importExcelUrl: "cum/cumProjectItem/importExcel",
    add: "/cum/cumProjectItem/add",
    edit: "/cum/cumProjectItem/edit",
},
  execute: {
    list: function(params) {
      return getAction(cumProjectItemApi.url.list, params)
    },
    create: function(object) {
      return getAction(cumProjectItemApi.url.add, object)
    },
    retrieve: function(id, params = {}) {
      return getAction(cumProjectItemApi.url.queryById, { id, ...params })
    },
    update: function(object) {
      return uploadAction(cumProjectItemApi.url.edit, object)
    },
    destroy: function(id) {
      return deleteAction(cumProjectItemApi.url.delete, id)
    }
  }
}
