<template>
  <div>
    <a-modal
      :title='title'
      width="80%"
      placement='right'
      @cancel='close'
      :footer='null'
      :visible='visible'>
      <a-descriptions :column='3' bordered size='small'>
        <a-descriptions-item :label="$t('serialNumber')">
          {{ data.serialNumber }}
        </a-descriptions-item>
<!--        <a-descriptions-item label='合同'>-->
<!--          {{ data.contractId }}-->
<!--        </a-descriptions-item>-->
        <a-descriptions-item :label="$t('projectId')">
          <a @click='turnProjectDetail(data)'>{{ data.projectId_dictText }}</a>
        </a-descriptions-item>

        <a-descriptions-item :label="$t('addBy')">
          {{ data.addBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addTime')">
          {{ data.addTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('status')">
          {{ data.status_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label='当前处理人' :span='3'>
          {{ data.approver_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('remark')" :span='3'>
          {{ data.remark }}
        </a-descriptions-item>

      </a-descriptions>
      <a-divider></a-divider>
      <a-table ref='table'
               size='middle'
               bordered
               rowKey='id'
               :columns='realColumns'
               :dataSource='realDataSource'
               :pagination='false'
               >
        <template slot='detail' slot-scope='text, record,index'>
          <p v-for='item in record.cumPaymentAmountList'>
            {{ item.compensateTypeId_dictText }} : {{item.amount}}
          </p>
        </template>
         <span slot='action' slot-scope='text, record,index'>

             <a-popconfirm title='是否确认收楼' @confirm='() => handlePay(record.id)' v-if='index < realDataSource.length  && record.status === 35'>
                  <a>确认收楼</a>
                </a-popconfirm>
             <span style='color: rgba(0,0,0,0.3)' v-if='record.status === 5'>
                已收楼
             </span>
        </span>
      </a-table>
      <!--   actions   -->
      <div class='detail-actions' v-if='data.action && Object.keys(data.action).length > 0'>
        <a-divider></a-divider>
        <span v-for='action in Object.keys(data.action)'>
            <a-button
              type='primary'
              @click="handleActionClick([data, action.replace('_dictText','')])"
              class='detail-actions-button'
              v-if="action.includes('_dictText') "
            >
<!--              && !action.includes('confirmationPayment')-->
              {{ data.action[action] }}
              <!--              {{ $t('actionCode["' + action + '"]') }}-->
            </a-button>
          </span>
      </div>

      <a-divider></a-divider>
      <detail-step :url='`/cum/cumCollectionPayment/workflowVisual?id=${data.id}`' :visible='visible'
                   ref='detailStep'></detail-step>
      <detail-history :file-list='data.fileList' :history-list='histories'></detail-history>
    </a-modal>

    <a-modal
      :visible='actionFormVisible'
      :title="$t('actionCode[\'' + action +'\']')"
      @ok='performAction'
      @cancel='actionFormVisible = false'
    >
      <a-form>
        <a-form-item :label="$t('comment')">
          <a-textarea v-model='actionForm.comment'></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
    <cum-project-detail ref='projectDetailForm'></cum-project-detail>
  </div>
</template>

<script>
import { cumCollectionPaymentWorkflowApi } from './api/CumCollectionPaymentWorkFlow'
import { DetailHistory } from 'dbcp-frontends-common/lib/components'
import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'
import { ActionMixin } from 'dbcp-frontends-common/lib/mixins'
import CumProjectDetail from '../../projectManagement/modules/CumProjectDetail'
import { getAction } from 'dbcp-frontends-common/lib/api'
import DetailStep from '@/components/dbcp/detail/DetailStep'

export default {
  name: 'CumCollectionPaymentDetail',
  components: { DetailHistory, CumProjectDetail, DetailStep },
  mixins: [DetailMixin, ActionMixin],
  data() {
    return {
      url: cumCollectionPaymentWorkflowApi.url,
      dataSource: [],
      columns:[
        {
          title: '业主',
          align: 'center',
          dataIndex: 'rightHolder_dictText',
        },
        {
          title: '应付款',
          align: 'center',
          dataIndex: 'rightHolder_dictText',
          children:[
            {
              title: '详情',
              align: 'center',
              dataIndex: 'detail',
              scopedSlots: { customRender: 'detail' }
            },
            {
              title: '合计',
              align: 'center',
              dataIndex: 'payableMoney',
            }
          ]
        },
        {
          title: '已付款',
          align: 'center',
          dataIndex: 'rightHolder_dictText',
          children:[
            {
              title: '合计',
              align: 'center',
              dataIndex: 'paidMoney',
            }
          ]
        },
        {
          title: '未付款',
          align: 'center',
          dataIndex: 'rightHolder_dictText',
          children:[
            {
              title: '合计',
              align: 'center',
              dataIndex: 'pendingMoney',
            }
          ]
        },
      ]
    }
  },
  computed: {
    realColumns() {
      let arr = [...this.columns]

      if (this.data.status === 35) {
        arr.push({
          title: '操作',
          align: 'center',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        })
      }

      return arr
    },
    realDataSource() {
      let arr = [...this.dataSource]
      let allPayableMoney = arr.reduce((prev,next) => {
        return prev + next.payableMoney
      },0)

      let allTotalPaidMoney = arr.reduce((prev,next) => {
        return prev + next.paidMoney
      },0)

      let allTotalPendingMoney = arr.reduce((prev,next) => {
        return prev + next.pendingMoney
      },0)
      arr.push({
        rightHolder_dictText:'合计',
        payableMoney:allPayableMoney,
        paidMoney:allTotalPaidMoney,
        pendingMoney:allTotalPendingMoney
      })
      return arr
    }
  },
  methods: {
    async view(data) {
      this.data = data
      this.visible = true
      await this.getHistories()
      await this.getData()
    },
    getData(){
      getAction('/cum/cumCollectionPayment/list',{
        id:this.data.id
      }).then(res => {
        if(res.success){
          this.data = res.result.records[0];
          this.dataSource = res.result.records[0].targetList;
        }
      })
    },
    turnProjectDetail(record) {
      getAction('/cum/cumProject/list', { id: record.projectId }).then(res => {
        let data = res.result.records[0]
        this.$refs.projectDetailForm.view(data)
      })
    },
    handlePay(id) {
      getAction('/cum/cumCollectionPayment/modifySingleContractStatus', {
        id
      }).then(res => {
        if(res.success){
          this.$message.success('确认付款成功')
          this.getData()
        }
      })
    },
    // handleActionClick(data, action,title) {
    //   this.visible = false
    //   console.log('call handleActionClick', data,action)
    //   // 处理来自detail的参数
    //   if (Array.isArray(data) && !action) {
    //     console.log('detail')
    //     action = data[1]
    //     data = data[0]
    //   }
    //   this.actionTitle = data.action[`${action}_dictText`]
    //   switch (action) {
    //     case 'confirmationPayment':
    //       this.visible = true
    //       this.view(data)
    //       return
    //     case 'edit':
    //     case 'delete':
    //     case 'print':
    //     case 'copy':
    //       this.$emit('action',data,action)
    //       return
    //   }
    //   console.log('action',action)
    //   this.initActionForm()
    //   this.action = action
    //   this.actionForm.id = data.id
    //   this.actionForm.action = action
    //   this.actionForm.comment = data.comment
    //   this.actionFormVisible = true
    //
    //   this.actionClick(data, action)
    // },
  }
}
</script>
