<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus" v-has="'cumIncomeValueContrast:create'">{{ $t('create') }}</a-button>
      <a-button type="primary" icon="download" @click="handleExportXls('收入与价值对照表')">{{ $t('export') }}</a-button>
      <a-upload name="file" :showUploadList="false" :multiple="false" :headers="tokenHeader" :action="importExcelUrl" @change="handleImportExcel">
        <a-button type="primary" icon="import">{{ $t('import') }}</a-button>
      </a-upload>
      <a-dropdown v-if="selectedRowKeys.length > 0">
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="batchDel"><a-icon type="delete"/>{{ $t('delete') }}</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> {{ $t('batchOperation') }} <a-icon type="down" /></a-button>
      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>
     <!-- <approval-tab @change="handleGroupChange" permission="cumIncomeValueContrast:view:all"></approval-tab> -->
      <setting-columns :columns='columns' @changeRealColumns='(data) => columns = data'></setting-columns>
      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type}"
        class="j-table-force-nowrap"
        @change="handleTableChange">

        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="imgSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noPicture') }}</span>
          <img v-else :src="getImgView(text)" height="25px" alt="" style="max-width:80px;font-size: 12px;font-style: italic;"/>
        </template>
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noFile') }}</span>
          <a-button
            v-else
            :ghost="true"
            type="primary"
            icon="download"
            size="small"
            @click="uploadFile(text)">
            {{ $t('download') }}
          </a-button>
        </template>

        <span slot="action" slot-scope="text, record">
          <list-actions
            :record="record"
            @view="handleView"
            @edit="handleEdit"
            @delete="handleDelete"
            @action="$refs.detailForm.handleActionClick"
            @copy="handleCopy"
          ></list-actions>
        </span>

      </a-table>
    </div>


    <cumIncomeValueContrast-modal ref="modalForm" @ok="modalFormOk"></cumIncomeValueContrast-modal>
    <cumIncomeValueContrast-detail ref="detailForm" @action="handleActionClick" @close="handleViewModalClose"
                                      @loadData="loadData" @clearRouteQuery="clearRouteQuery"></cumIncomeValueContrast-detail>
  </a-card>
</template>

<script>
  import {  cumIncomeValueContrastApi } from './modules/api/CumIncomeValueContrast'
  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
  import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
  import { SettingColumns } from 'dbcp-frontends-common/lib/components'
  import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
  import CumIncomeValueContrastModal from './modules/CumIncomeValueContrastModal'
  import CumIncomeValueContrastDetail from './modules/CumIncomeValueContrastDetail'
  // import { ApprovalTab } from 'dbcp-frontends-common/lib/components'
  import { ListActions } from 'dbcp-frontends-common/lib/components'
  import { SelectorMixin } from 'dbcp-frontends-common/lib/mixins'
  
  export default {
    name: "CumIncomeValueContrastList",
    mixins:[JeecgListMixin, mixinDevice, ViewMixin, ApprovalListMixin,SelectorMixin],
    components: {
      CumIncomeValueContrastModal,
      CumIncomeValueContrastDetail,
      ListActions,
      SettingColumns,
     
    },
    data () {
      return {
        description: '收入与价值对照表管理页面',
        // 表头
        columns: [
          {
            title: '#',
            dataIndex: '',
            key: 'rowIndex',
            width: 60,
            align: 'center',
            customRender: function (t,r,index) {
              return parseInt(index)+1;
            }
          },
          {
            title: '项目细项',
            align: 'center',
            dataIndex: 'projectItemId'
          },
          {
            title: '条目',
            align: 'center',
            dataIndex: 'entry'
          },
          {
            title: '签约前',
            align: 'center',
            dataIndex: 'beforeSigning'
          },
          {
            title: '签约后',
            align: 'center',
            dataIndex: 'afterSigning'
          },
          {
            title: '回迁后',
            align: 'center',
            dataIndex: 'afterFetching'
          },
          {
            title: this.$i18n.tc('action'),
            dataIndex: 'action',
            align: 'center',
            // fixed:"right",
            width: 147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: cumIncomeValueContrastApi.url,
        dictOptions:{},
      }
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
    },
    methods: {
      initDictConfig(){
      }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>
