<template>
  <div>
    <a-modal
      :title='title'
      :width='width'
      placement='right'
      @cancel='close'
      :footer='null'
      :visible='visible'>
      <a-descriptions :column='3' bordered size='small'>
        <a-descriptions-item :label="$t('name')">
          {{ data.name }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('primaryKeyStrategy')">
          {{ data.primaryKeyStrategy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('description')">
          {{ data.description }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addBy')">
          {{ data.addBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addTime')">
          {{ data.addTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateBy')">
          {{ data.updateBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateTime')">
          {{ data.updateTime }}
        </a-descriptions-item>
      </a-descriptions>
      <br>
      <a-table :columns='columns'
               :dataSource='dataSource'
               rowKey='id'
               :pagination='false'
      >

      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { dbcpWorkflowFormApi } from './api/DbcpWorkflowFormWorkFlow'
import { dbcpWorkflowFormFieldApi } from './api/DbcpWorkflowFormField'
import { DetailHistory } from 'dbcp-frontends-common/lib/components'
import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'
import { ActionMixin } from 'dbcp-frontends-common/lib/mixins'
import { getAction } from 'dbcp-frontends-common/lib/api'

export default {
  name: 'DbcpWorkflowFormDetail',
  components: { DetailHistory },
  mixins: [DetailMixin, ActionMixin],
  data() {
    return {
      url: dbcpWorkflowFormApi.url,
      columns: [
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function(t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '列名',
          align: 'center',
          dataIndex: 'columnName'
        },
        {
          title: '字段名',
          align: 'center',
          dataIndex: 'fieldName'
        },
        {
          title: '是否为主键',
          align: 'center',
          dataIndex: 'isPk_dictText'
        },
        {
          title: '字段类型',
          align: 'center',
          dataIndex: 'type'
        },
        {
          title: '字段描述',
          align: 'center',
          dataIndex: 'description'
        },
        {
          title: '创建人',
          align: 'center',
          dataIndex: 'addBy_dictText'
        },
        {
          title: '创建时间',
          align: 'center',
          dataIndex: 'addTime'
        },
        {
          title: '更新人',
          align: 'center',
          dataIndex: 'updateBy_dictText'
        },
        {
          title: '更新时间',
          align: 'center',
          dataIndex: 'updateTime'
        },
      ],
      dataSource: [],
      width:'1400px'
    }
  },
  methods: {
    view(data) {
      this.data = data
      this.visible = true
      this.getFieldsListById(data.id)
    },
    getFieldsListById(id) {
      getAction(dbcpWorkflowFormFieldApi.url.list,{tableId:id}).then(res => {
        if (res.success) {
          this.dataSource = res.result.records
        }
      })
    }
  }
}
</script>
