<template>
  <j-modal
    :title='title'
    :width='width'
    :visible='visible'
    :confirmLoading='confirmLoading'
    switchFullscreen
    @ok='handleOk'
    @cancel='handleCancel'
    :cancelText="$t('cancel')">
    <a-spin :spinning='confirmLoading'>
      <a-form :form='form'>


        <a-form-item label='事件包' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input v-decorator="['package', validatorRules.package]" placeholder='请输入事件包'></a-input>
        </a-form-item>
        <a-form-item label='事件名' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input v-decorator="['name', validatorRules.name]" placeholder='请输入事件名'></a-input>
        </a-form-item>
        <a-form-item label='参数' :labelCol='labelCol' :wrapperCol='wrapperCol' @click.native="showEdit('params')">
          <div>{{ this.model.params }}</div>
        </a-form-item>
        <a-form-item label='操作类型' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input-number v-decorator="['type', validatorRules.type]" placeholder='请输入操作类型' style='width: 100%' />
        </a-form-item>
        <a-form-item label='操作条件' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-textarea v-decorator="['condition']" rows='4' placeholder='请输入操作条件' />
        </a-form-item>
        <a-form-item label='是否为异步' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <j-switch v-decorator="['isAsyn', , validatorRules.isAsyn, {initialValue: false}]" checked-children='是'
                    un-checked-children='否' />
        </a-form-item>

      </a-form>
    </a-spin>

    <template slot='footer'>
      <a-button type='primary' @click='handleOk(false)'>{{ $t('saveDraft') }}</a-button>
      <a-button type='primary' @click='handleOk(true)'>{{ $t('submit') }}</a-button>
      <a-button @click='handleCancel'>{{ $t('cancel') }}</a-button>
    </template>
    <dbcp-workflow-node-json-edit :hidden='hide' :jsonData='jsonData' @jsonDataChange='jsonDataChange'
                                  @closeJsonDataChange='closeJsonDataChange'></dbcp-workflow-node-json-edit>
  </j-modal>
</template>

<script>
import { dbcpWorkflowEventApi } from './api/DbcpWorkflowEvent'
import pick from 'lodash.pick'
import { UploadFormMixin } from 'dbcp-frontends-common/lib/mixins'
import { JSwitch } from 'dbcp-frontends-common/lib/components'
import DbcpWorkflowNodeJsonEdit from '@views/dbcp/core/workflowNode/modules/DbcpWorkflowNodeJsonEdit'

export default {
  name: 'DbcpWorkflowEventModal',
  components: {
    JSwitch, DbcpWorkflowNodeJsonEdit
  },
  mixins: [UploadFormMixin],
  data() {
    return {
      form: this.$form.createForm(this),
      title: this.$i18n.tc('action'),
      width: 800,
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      validatorRules: {
        package: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        name: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        params: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        type: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        isAsyn: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        }
      },
      url: dbcpWorkflowEventApi.url,
      jsonData: {},
      hide: false,
      whichList: 'actions'
    }
  },
  created() {
  },
  methods: {
    afterAdd(record) {
      this.edit({
        ...record
      })
    },
    afterEdit(record) {
      this.model = Object.assign({}, record)
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'package', 'name', 'params', 'type', 'condition', 'isAsyn'))
      })
    },
    afterClose() {
    },
    popupCallback(row) {
      this.form.setFieldsValue(pick(row, 'package', 'name', 'params', 'type', 'condition', 'isAsyn'))
    },
    showEdit(data) {
      this.hide = true

      this.jsonData = JSON.parse(this.model[data] || '{}')
      let obj = {
        'data': this.jsonData
      }
      this.jsonData = obj
      this.whichList = data
    },
    //修改了JSON
    jsonDataChange(newJson) {
      this.model[this.whichList] = JSON.stringify(newJson)
      this.hide = false
    },
    //关闭JSON修改
    closeJsonDataChange() {
      this.hide = false
    }

  }
}
</script>
