<template>
  <div>
    <a-modal
      :title="title"
      :width="width"
      placement="right"
      @cancel="close"
      :footer="null"
      :visible="visible">
      <a-descriptions :column="3" bordered size="small">
              <a-descriptions-item :label="$t('projectItemId')">
          {{ data.projectItemId }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('ownerId')">
          {{ data.ownerId_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('visitTime')">
          {{ data.visitTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('visitStaff')">
          {{ data.visitStaff_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('visitTarget')">
          {{ data.visitTarget }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('carryGifts')">
          {{ data.carryGifts }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('problem')">
          {{ data.problem }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('reply')">
          {{ data.reply }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('instructions')">
          {{ data.instructions }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('nextVisitTime')">
          {{ data.nextVisitTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('remark')">
          {{ data.remark }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addBy')">
          {{ data.addBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addTime')">
          {{ data.addTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateBy')">
          {{ data.updateBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateTime')">
          {{ data.updateTime }}
        </a-descriptions-item>
      </a-descriptions>
      <a-divider></a-divider>
    </a-modal>

  </div>
</template>

<script>
  import {  cumFollowUpRecordApi } from './api/CumFollowUpRecordWorkFlow'
  import { DetailHistory } from 'dbcp-frontends-common/lib/components'
  import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ActionMixin } from 'dbcp-frontends-common/lib/mixins'

  export default {
    name: "CumFollowUpRecordDetail",
    components: { DetailHistory },
    mixins: [ DetailMixin,ActionMixin ],
    data() {
      return {
        url: cumFollowUpRecordApi.url,
      }
    },
    methods:{
      view(data){
        this.data = data
        this.visible = true
      }
    }
  }
</script>
