import { deleteAction, getAction, uploadAction } from 'dbcp-frontends-common/lib/api'
export const dbcpHomeCarouselApi = {
  url: {
    action: "/sys/dbcpHomeCarousel/action",
    list: "/sys/dbcpHomeCarousel/list",
    delete: "/sys/dbcpHomeCarousel/delete",
    deleteBatch: "/sys/dbcpHomeCarousel/deleteBatch",
    exportXlsUrl: "/sys/dbcpHomeCarousel/exportXls",
    importExcelUrl: "/sys/dbcpHomeCarousel/importExcel",
    add: "/sys/dbcpHomeCarousel/add",
    edit: "/sys/dbcpHomeCarousel/edit",
},
  execute: {
    list: function(params) {
      return getAction(dbcpHomeCarouselApi.url.list, params)
    },
    create: function(object) {
      return getAction(dbcpHomeCarouselApi.url.add, object)
    },
    retrieve: function(id, params = {}) {
      return getAction(dbcpHomeCarouselApi.url.queryById, { id, ...params })
    },
    update: function(object) {
      return uploadAction(dbcpHomeCarouselApi.url.edit, object)
    },
    destroy: function(id) {
      return deleteAction(dbcpHomeCarouselApi.url.delete, id)
    }
  }
}
