<template>
  <j-select-biz-component
    :value="value"
    :ellipsisLength="25"
    :listUrl="getListUrl()"
    :multiple="false"
    :columns="columns"
    v-on="$listeners"
    v-bind="attrs"
    :returnId="true"
  />
  <!--    :value="value"  -->
</template>

<script>
  import { JSelectBizComponent } from 'dbcp-frontends-common/lib/components'
  export default {
    name: 'OfferSelector',
    components: { JSelectBizComponent },
    props: {
      value: {},
      filterUsed: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        url: { list: '/oa/dbcp/dbcpOffer/getOnboardingOfficeList' },
        columns: [
          { title: this.$i18n.tc('name'), align: 'center', width: '25%', widthRight: '70%', dataIndex: 'name' },
          { title: this.$i18n.tc('gender'), align: 'center', width: '25%', dataIndex: 'sex_dictText' },
          { title: this.$i18n.tc('company'), align: 'center', width: '25%', widthRight: '70%', dataIndex: 'company_dictText' },
          { title: this.$i18n.tc('department'), align: 'center', width: '25%', dataIndex: 'dept_dictText' },
        ],
        // 定义在这里的参数都是可以在外部传递覆盖的，可以更灵活的定制化使用的组件
        default: {
          name: 'Offer',
          width: 1200,
          displayKey: 'name',
          returnKeys: ['id', 'name'],
          queryParamText: this.$i18n.tc('name'),
        },
      }
    },
    computed: {
      attrs() {
        console.log("value attrs", this.value ,typeof this.value)
        console.log('attrs', this.$attrs)
        console.log("this.default",this.default)
        return Object.assign(this.default, this.$attrs)
      }
    },
    methods: {
      getListUrl() {
        if (this.filterUsed) {
          // return this.url.list + '?filterUsed=true&status=5'
          return this.url.list + '?filterUsed=true'
        } else {
          return this.url.list
        }
      }
    }
  }
</script>

<style scoped>

</style>
