<template>
  <a-tabs :default-active-key='tabOption[0].key'>
    <a-tab-pane :key='item.key' :tab='item.label' v-for='item in tabOption'>
      <div v-if='!view'>
        <attachment @handleFileRemove='(file) => handleFileRemove(file, item.key)' @beforeFileUpload='(file) => beforeFileUpload(file, item.key)'
                    :file-list='uploadFiles[item.key]' :default-file-list='filterFilesList(item.key)' @deleteFile='(index) => handleDeleteFile(index, item.key)'></attachment>
      </div>
      <div v-else>
        <div>
          <files :files="fileList[item.key]" :isDelete='false'></files>
        </div>
      </div>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import { Attachment } from 'dbcp-frontends-common/lib/components'
import Files from '../../common/Files'

export default {
  name: 'MultiAttachment',
  components: {
    Attachment,
    Files
  },
  data(){
    return {
      fileList:{},
    }
  },
  props: {
    tabOption: {
      type: Array,
      default: () => [{
        key: 'files',
        label: '附件'
      }]
    },
    defaultFileList: {
      type: Array,
      default: () => []
    },
    uploadFiles: {
      type: Object,
      default: () => ({})
    },
    view: {
      type:Boolean,
      default:false,
    },
    files: {
      type: Array,
      default:() => []
    }
  },
  watch:{
    'files':{
      immediate:true,
      deep:true,
      handler(val){
        if(val){
          if(this.view){
            this.tabOption.forEach(tabItem=>{
              let arr = [];
              this.files.forEach(fileItem=>{
                if(tabItem.key == fileItem.classify){
                  arr.push(fileItem)
                }
              })
              this.fileList[tabItem.key] = arr ;
            })
          }
        }
      }
    }
  },
  methods: {
    handleFileRemove(file, key) {
      this.$emit('handleFileRemove', file, key)
    },
    beforeFileUpload(file, key){
      this.$emit('beforeFileUpload', file, key)
    },
    filterFilesList(key){
      console.log('this.defaultFileList',this.defaultFileList)
      return this.defaultFileList.filter(item => {
        return item.classify === key
      })
    },
    handleDeleteFile(index,key){
      this.$emit('deleteFile', index,key)
    }
  }
}
</script>

<style scoped>

</style>