<template>
  <div>
    <a-input-search @click="showModal" v-model="selectedNameString"></a-input-search>

    <common-url-select-modal ref="selectModal" modal-width="100%" :multi="multi" @ok="selectOK"
                             :user-ids="value" @initComp="initComp" :left-tree-url="leftTreeUrl"
                             :left-query-mark="leftQueryMark"
                             :right-tree-url="rightTreeUrl" :right-query-mark="rightQueryMark"
                             :left-columns="leftColumns" :right-columns="rightColumns"
                             :left-search-query="leftSearchQuery"
                             :right-search-query="rightSearchQuery" :left-title="leftTitle" :right-title="rightTitle"></common-url-select-modal>
  </div>
</template>

<script>
import CommonUrlSelectModal from '@comp/common/commonUrlSelect/modal/CommonUrlSelectModal'

export default {
  name: 'CommonUrlSelect',
  components: {
    CommonUrlSelectModal
  },
  props: {
    //  左侧树形url
    leftTreeUrl: {
      type: String,
      required: true
    },
    //  右侧对应url
    rightTreeUrl: {
      type: String,
      required: true
    },
    //  点击左侧查询右侧的关键字:{
    leftQueryMark: {
      type: String,
      default: 'id'
    },
    //  选择右侧回填的字段
    rightQueryMark: {
      type: String,
      default: 'name'
    },
    //  多选
    multi: {
      type: Boolean,
      default: false
    },
    //右侧列表
    rightColumns: {
      type: Array,
      default: () => []
    },
    //左侧列表
    leftColumns: {
      type: Array,
      default: () => []
    },
    //左侧搜索关键字段
    leftSearchQuery: {
      type: String,
      default: 'id'
    },
    //右侧搜索关键字段
    rightSearchQuery: {
      type: String,
      default: 'id'
    },
    //左边名字
    leftTitle: {
      type: String,
      default: ''
    },
    //右边名字
    rightTitle: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      userIds: '',
      selectedNameString: ''
    }
  },
  computed: {
    getType: function() {
      return this.multi == true ? 'checkbox' : 'radio'
    }
  },
  mounted() {
    this.userIds = this.value
  },
  watch: {
    value(val) {
      this.userIds = val
      console.log('this.userIds', this.userIds)
    },
    selectedNameString(val) {
      console.log(val)
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  methods: {
    initComp(userNames) {
      this.selectedNameString = userNames
    },
    showModal() {
      this.$refs.selectModal.showModal()
    },
    selectOK(rows, idstr) {
      console.log('当前选中用户', rows)
      console.log('当前选中用户ID', idstr)
      if (!rows) {
        this.selectedNameString = ''
        this.userIds = ''
      } else {
        let temp = ''
        for (let item of rows) {
          temp += ',' + item[this.rightQueryMark]
        }
        this.userIds = idstr
        this.selectedNameString = temp.substring(1)
      }
      console.log('this.userIds', this.userIds)
      this.$emit('change', this.deleteMuti(this.userIds))
      this.$emit('ok', rows)
    },
    //  去重
    deleteMuti(str) {
      let arr = str.split(',')
      let set = new Set(arr)
      return Array.from(set).join(',')
    }
  }
}
</script>

<style scoped>

</style>
