<template>
  <a-modal
    :width="width"
    v-model="visible"
    :footer="null"
  >
    <a-card :bordered="false" :class="{'content':true}" class="print-content">
      <div class="no-print" style="text-align: right;margin-right:80px">
        <a-button v-print="'#printContent'" ghost type="primary">打印</a-button>
      </div>
      <section ref="print" id="printContent" class="content">
        <div class="contrllayout">
          <a-row>
            <div class="print_header">
              <img class="logo" :src="printInfo[0].companyLogo" alt="" v-if="printInfo && printInfo[0] && printInfo[0].companyLogo">
              <img class="logo" src="../../../assets/images/logo.png" alt="" v-else>
              <div class="table_title">公差申请表</div>
              <div style="width: 100%">
                <a-col :span="8"><div style="text-align: left">{{ data.companyName }}</div></a-col>
                <a-col :span="8"><span>申请日期：</span><span>{{ data.addTime }}</span></a-col>
                <a-col :span="8"><div style="text-align: right">单据及附件共&emsp;&emsp;页</div></a-col>
              </div>
            </div>
          </a-row>
          <a-row>
            <table>
              <tbody>
              <tr><td>公差单号</td><td>{{ data.serialNumber }}</td></tr>
              <tr><td>{{$t('applicant')}}</td><td>{{ data.applicant_dictText }}</td></tr>
              <tr><td>申请人部门</td><td>{{ data.dept_dictText }}</td></tr>
              <tr><td>出差人员</td><td>{{ data.personnel_dictText }}</td></tr>
              <tr><td>详细地点</td><td><span v-html="data.address ? cutout(data.address): '' "/></td></tr>
              <tr><td>出差时间</td><td>{{ data.beginTime }} 至  {{ data.endTime }}</td></tr>
              <tr><td>行程安排</td><td> <span v-html="data.schedule ? cutout(data.schedule): '' "/></td></tr>
              <tr><td>出差事由</td><td> <span v-html="data.cause ? cutout(data.cause): '' "/></td></tr>
              <tr><td>交通类型</td><td>{{ data.trafficType_dictText }}</td></tr>
              <tr><td>备注(交通)</td><td> <span v-html="data.trafficRemark ? cutout(data.trafficRemark): '' "/></td></tr>
              <tr><td>住宿类型</td><td>{{ data.accommodationType_dictText }}</td></tr>
              <tr><td>备注(住宿)</td><td><span v-html="data.accommodationRemark ? cutout(data.accommodationRemark): '' "/></td></tr>
              <tr><td>借款需求</td><td><span v-html="data.loanDemand ? cutout(data.useing): '否' "/></td></tr>
              <tr v-if="competencySuperior"><td>职能上级</td><td>{{ competencySuperior && competencySuperior.approver_dictText }} &emsp; {{competencySuperior.remark}} &emsp; {{ competencySuperior && competencySuperior.time }}</td></tr>
              <tr v-if="adminSuperior"><td>行政上级</td><td>{{ adminSuperior && adminSuperior.approver_dictText }} &emsp; {{adminSuperior.remark}} &emsp; {{ adminSuperior && adminSuperior.time }}</td></tr>
              <tr v-if="signatory"><td>权签人</td><td>{{ signatory && signatory.approver_dictText }} &emsp; {{signatory.remark}} &emsp;{{ signatory && signatory.time }}</td></tr>
              <tr v-if="finance"><td>财务</td><td>{{ finance && finance.approver_dictText }} &emsp; {{finance.remark}} &emsp;{{ finance && finance.time }}</td></tr>
              </tbody>
            </table>
          </a-row>
          <div class="table_footer">
            <div class="print_day">
              <div>打印日期：</div>
              <div>{{ toDay }}</div>
            </div>
            <div class="print_person">
              <div>打印人：</div>
              <div>{{ printer }}</div>
            </div>
          </div>
        </div>
      </section>
    </a-card>
  </a-modal>
  <!--</page-layout>-->
</template>
<script>
  import { PrinterMixin } from 'dbcp-frontends-common/lib/mixins'
  // import PrinterTemplate from '@/components/dbcp/finance/PrinterTemplate'
  import dayjs from 'dayjs'

  export default {
    components: {  },
    data(){
      return {
        url: {
          get: '/oa/dbcp/dbcpBusinessTrip/getPrintInfo'
        },
        toDay: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      }
    },
    mixins: [ PrinterMixin ],
    computed: {
      total() {
        let amount = 0
        if (this.data.lineItem) {
          let items = JSON.parse(this.data.lineItem)
          if (Array.isArray(items)) {
            items.forEach(item => {
              amount += parseFloat(item.amount)
            })
          }
        }
        return amount
      },
      items() {
        // let items = []
        if (this.data.lineItem) {
          return JSON.parse(this.data.lineItem)
        } else {
          return []
        }
      }
    }
  }
</script>
<style scoped>
  /*update_begin author:scott date:20191203 for:打印机打印的字体模糊问题 */
  * {
    color: #000000!important;
    -webkit-tap-highlight-color: #000000!important;
  }
  @page {
    size: auto;
    margin: 0mm;
  }
  /*update_end author:scott date:20191203 for:打印机打印的字体模糊问题 */
  /* @page { margin: 0; } */
  .content .ant-card-body{
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 1%;
    border:0px solid black;
    min-width: 800px;
    color:#000000!important;
  }
  .contrllayout{
    padding: 50px 80px;
  }
  /* you can make up upload button and sample style by using stylesheets */
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
  .print_header{position: relative;}
  .print_header .logo{position: absolute;left: 0px;top: 0px;width: 150px;}
  .print_header .table_title{
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    height: 80px;
    box-sizing: border-box;
    padding-top: 20px;
  }
  .print_header{
    text-align: center;
    display: inline-block;
    width: 100%;
  }
  .print_header{
    text-align: left;
  }
  .print_header .filing_date+div{
    display: inline-block;
  }
  .print_header{
    text-align: right;
  }
  table{
    border: 1px solid rgb(85,85,85);
    border-collapse: collapse;
    width: 100%;
    margin: 14px 0;
  }
  table td{
    border: 1px solid rgb(85,85,85);
    padding: 5px 10px;
    line-height: 2em;
  }
  table tr:nth-child(1)>:first-child{
    width:16%;
  }
  .table_footer div{
    display: inline-block;
  }
  .table_footer>div{
    margin-left: 50px;
  }
  .table_footer>div:first-child{
    margin-left: 20px;
  }
  .table_footer{
    margin-bottom: 60px;
  }
</style>