<template>
  <j-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('cancel')">
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
<!--        <a-form-item label="项目" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
<!--          <a-input-number v-decorator="['projectId', validatorRules.projectId]" placeholder="请输入项目id" style="width: 100%"/>-->
<!--        </a-form-item>-->
        <a-form-item label="权利人" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <InputSelect v-decorator="['rightHolder', validatorRules.rightHolder]" show-name='name' send-params='id'
                       url='dbcp/city/cumOwner/CumOwnerList.vue'
                       type='radio' @ok='handleRightHolder'></InputSelect>
        </a-form-item>
        <a-form-item label="楼栋" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <InputSelect v-decorator="['buildingId', validatorRules.buildingId]" show-name='#${id}' send-params='id'
                       url='dbcp/city/propertyManagement/buildingList/CumBuildingList.vue' :projectId='model.projectId'
                       :rightHolder='form.getFieldValue("rightHolder")'
                       type='checkbox'></InputSelect>
          </a-form-item>
        <a-form-item label="单元" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <InputSelect  v-decorator="['unitId']" show-name='#${id}' send-params='id'
                       url='dbcp/city/propertyManagement/unitList/CumUnitList.vue'
                       :rightHolder='form.getFieldValue("rightHolder")' :projectId='model.projectId' disabled='disabledUnitIds'
                       type='checkbox'></InputSelect>
        </a-form-item>
        <a-form-item label="跟进人员" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-select-user-by-dep :hideDept="true" v-decorator="['follower', validatorRules.follower]" :multi='false'  />
        </a-form-item>

        <a-form-item label="权利人核实" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-dict-select-tag type="list" v-decorator="['rightHolderChecked', validatorRules.rightHolderChecked]" :trigger-change="true" dictCode="cum_project_item_right_holder_checked" placeholder="请选择权利人核实"/>
        </a-form-item>
        <a-form-item label="拆迁谈判难易等级" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-dict-select-tag type="list" v-decorator="['negotiationDifficultyLevel', validatorRules.negotiationDifficultyLevel]" :trigger-change="true" dictCode="cum_negotiation_difficulty_level" placeholder="请输入拆迁谈判难易等级"/>
        </a-form-item>
<!--        <a-form-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
<!--          <j-dict-select-tag type="list" v-decorator="['status', validatorRules.status]" :trigger-change="true" dictCode="cum_project_item_status" placeholder="请选择状态"/>-->
<!--        </a-form-item>-->
        <a-form-item label="是否委托公证" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-dict-select-tag type="list" v-decorator="['isEntrustedNotarization', validatorRules.isEntrustedNotarization]" :trigger-change="true" dictCode="yan" placeholder="请选择是否委托公证"/>
        </a-form-item>
        <a-form-item label="房产证是否已注销" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-dict-select-tag type="list" v-decorator="['isCancellationRealEstateLicense', validatorRules.isCancellationRealEstateLicense]" :trigger-change="true" dictCode="yan" placeholder="请选择房产证是否已注销"/>
        </a-form-item>
        <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea v-decorator="['remark']" rows="4" placeholder="请输入备注"/>
        </a-form-item>
        <a-form-item :label="$t('attachments')" :labelCol="labelCol" :wrapperCol="wrapperCol">
<!--          <attachment v-decorator="['upload']" @handleFileRemove="handleFileRemove" @beforeFileUpload="beforeFileUpload" :file-list="uploadFiles" :default-file-list="defaultFileList"></attachment>-->
          <multi-attachment @handleFileRemove='handleFileRemove' @beforeFileUpload='beforeFileUpload'
                            :upload-files='uploadFiles' :default-file-list='defaultFileList' :tab-option="[{
        key: 'ownership',
        label: '权属资料'
      },{
        key: 'other',
        label: '其他'
      }]" @deleteFile='handleDeleteFile'></multi-attachment>
        </a-form-item>

      </a-form>
    </a-spin>

    <template slot="footer">
      <a-button type="primary" @click="handleOk(true)">{{ $t('submit') }}</a-button>
      <a-button @click="handleCancel">{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
  import {  cumProjectItemApi } from './api/CumProjectItem'
  import { getAction, httpAction } from 'dbcp-frontends-common/lib/api'
  import pick from 'lodash.pick'
  import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
  import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'
  import { JDictSelectTag } from 'dbcp-frontends-common/lib/components'
  import { UploadFormMixin } from '@/mixins/UploadFormMixin'
  import { CommonSelector } from 'dbcp-frontends-common/lib/components'
  import { Attachment } from 'dbcp-frontends-common/lib/components'
  import InputSelect  from '@/components/dbcp/InputSelect'
  import MultiAttachment from '@/components/dbcp/city/MultiAttachment'
  export default {
    name: "CumProjectItemModal",
    components: {
      JSelectUserByDep,
      JDictSelectTag,
      CommonSelector,
      Attachment,
      InputSelect,
      MultiAttachment
    },
    mixins: [ UploadFormMixin ],
    data () {
      return {
        form: this.$form.createForm(this),
        title: this.$i18n.tc('action'),
        width: 800,
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules: {
          projectId: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          follower: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          rightHolder: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          rightHolderChecked: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          negotiationDifficultyLevel: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          status: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          isEntrustedNotarization: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          isCancellationRealEstateLicense: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
        },
        url: cumProjectItemApi.url,
        disabledBuildingIds:false,
        disabledUnitIds:false
      }
    },
    created () {
    },
    methods: {
      afterAdd (record) {
        this.edit({
            ...record
        })
      },
      afterEdit (record) {
        this.$nextTick(() => {
          this.form.setFieldsValue(pick(this.model,'projectId','buildingId','unitId','follower','rightHolder','rightHolderChecked','negotiationDifficultyLevel','status','isEntrustedNotarization','isCancellationRealEstateLicense','remark','updateBy','updateTime'))
        })
      },
      afterClose () {
      },
      popupCallback(row){
        this.form.setFieldsValue(pick(row,'projectId','buildingId','unitId','follower','rightHolder','rightHolderChecked','negotiationDifficultyLevel','status','isEntrustedNotarization','isCancellationRealEstateLicense','remark','updateBy','updateTime'))
      },
      validate(){
        if(!this.form.getFieldValue("buildingId") && !this.form.getFieldValue("unitId")){
          this.$message.warning("楼栋和单元必填其中一项！")
          return false
        }
        return true
      },
      handleRightHolder(data){
        if(data.id){
          getAction('/cum/cumProject/getBuildingInformation',{
            projectId:this.model.projectId,
            rightHolders:data.id
          }).then(res=>{
            if(res.success){
              let buildingId = "";
              let unitId = "";
              res.result.buildingIds.forEach((item,index)=>{
                if(index === 0){
                  buildingId = item
                }else{
                  buildingId = buildingId + "," + item;
                }
              })
              res.result.unitIds.forEach((item,index)=>{
                if(index === 0){
                  unitId = item
                }else{
                  unitId = unitId + "," + item;
                }
              })
              this.$nextTick(()=>{
                this.form.setFieldsValue({
                  buildingId:buildingId,
                  unitId:unitId
                })
                this.disabledBuildingIds = res.result.buildingIds.length !==0 ? true : false;
                this.disabledUnitIds = res.result.unitIds.length !==0 ? true : false;
              })
            }
          })
        }
      }
    }
  }
</script>
