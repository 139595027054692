<template>
  <j-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('cancel')">
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item :label="$t('applicant')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-select-user-by-dep :hideDept="true" v-decorator="['applicant', validatorRules.applicant]" :multi="false" :disabled="isDisabledAuth('businessTrip:edit:unit')"/>
        </a-form-item>
        <a-form-item :label="$t('department')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-select-depart v-decorator="['dept', validatorRules.dept]" :multi="false" :disabled="isDisabledAuth('businessTrip:edit:unit')"/>
        </a-form-item>
        <a-form-item :label="$t('personnel')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-select-user-by-dep :hideDept="true" v-decorator="['personnel', validatorRules.personnel]"/>
        </a-form-item>
        <a-form-item :label="$t('location')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-dict-select-tag type="list" v-decorator="['location', validatorRules.location]" :trigger-change="true" :open-search="true" dictCode="business_trip_personnel" placeholder="请选择出差地点"/>
        </a-form-item>
        <a-form-item :label="$t('address')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea
            v-decorator="['address', validatorRules.address]" style="width: 100%; margin-right: 8px"
          />
        </a-form-item>
        <a-form-item :label="$t('schedule')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea
            v-decorator="['schedule', validatorRules.schedule]" style="width: 100%; margin-right: 8px"
          />
        </a-form-item>
        <a-form-item :label="$t('startTime')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-date v-decorator="['beginTime', validatorRules.beginTime]" :trigger-change="true" :show-time="true" date-format="YYYY-MM-DD HH:mm:ss" style="width: 100%"/>
        </a-form-item>
        <a-form-item :label="$t('endTime')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-date v-decorator="['endTime', validatorRules.endTime]" :trigger-change="true" :show-time="true" date-format="YYYY-MM-DD HH:mm:ss" style="width: 100%"/>
        </a-form-item>
        <a-form-item :label="$t('cause')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea
            v-decorator="['cause', validatorRules.cause]" style="width: 100%; margin-right: 8px"
          />
        </a-form-item>
        <a-form-item :label="$t('trafficType')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-dict-select-tag type="list" v-decorator="['trafficType',validatorRules.trafficType]" :trigger-change="true" :open-search="true" dictCode="business_trip_traffic_type"/>
        </a-form-item>
        <a-form-item :label="$t('trafficRemark')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea
            v-decorator="['trafficRemark',validatorRules.trafficRemark]" style="width: 100%; margin-right: 8px"
          />
        </a-form-item>
        <a-form-item :label="$t('accommodationType')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-dict-select-tag type="list" v-decorator="['accommodationType', validatorRules.accommodationType]" :trigger-change="true" :open-search="true" dictCode="business_trip_accommodation_type"/>
        </a-form-item>
        <a-form-item :label="$t('accommodationRemark')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea
            v-decorator="['accommodationRemark', validatorRules.accommodationRemark]" style="width: 100%; margin-right: 8px"
          />
        </a-form-item>
        <a-form-item :label="$t('loanDemand')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-switch v-decorator="['loanDemand', validatorRules.loanDemand]" @change="onChange"/>
        </a-form-item>


        <a-form-item :label="$t('useing')" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if="form.getFieldValue('loanDemand') !== false">
          <a-textarea
            v-decorator="['useing', validatorRules.useing]" style="width: 100%; margin-right: 8px"
          />
        </a-form-item>
        <a-form-item :label="$t('attachments')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <attachment v-decorator="['upload']" @handleFileRemove="handleFileRemove" @beforeFileUpload="beforeFileUpload" :file-list="uploadFiles" :default-file-list="defaultFileList"></attachment>
        </a-form-item>

      </a-form>
    </a-spin>
    <template slot="footer">
      <a-button type="primary" @click="handleOk(false)">{{ $t('saveDraft') }}</a-button>
      <a-button type="primary" @click="handleOk(true)">{{ $t('submit') }}</a-button>
      <a-button @click="handleCancel">{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>

  import { httpAction } from 'dbcp-frontends-common/lib/api'
  import pick from 'lodash.pick'
  import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
  import { JDate } from 'dbcp-frontends-common/lib/components'
  import { JSelectDepart } from 'dbcp-frontends-common/lib/components'
  import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'
  import { JDictSelectTag } from "dbcp-frontends-common/lib/components"
  import { UploadFormMixin } from 'dbcp-frontends-common/lib/mixins'
  import { DisabledAuthFilterMixin } from 'dbcp-frontends-common/lib/mixins'
  import { Attachment } from 'dbcp-frontends-common/lib/components'


  export default {
    name: "DbcpBusinessTripModal",
    components: {
      JDate,
      JSelectDepart,
      JSelectUserByDep,
      JDictSelectTag,
      Attachment
    },
    mixins: [ UploadFormMixin, DisabledAuthFilterMixin ],
    data () {
      return {
        form: this.$form.createForm(this),
        title: this.$i18n.tc('action'),
        width: 800,
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules: {
          dept: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          personnel: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          location: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          address: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          schedule: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          beginTime: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          endTime: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          cause: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          trafficType: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          accommodationType: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          loanDemand: {
            valuePropName: 'checked',
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ],
            // initialValue: false
          },
          applicant: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          trafficRemark: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          accommodationRemark: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          useing: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          validatorRules: {
            type: {
              rules: [
                { required: true, message: this.$i18n.tc('fieldEmptyHint')},
              ]
            },
            upload: {
              rules: [
                { required: true, message: this.$i18n.tc('fieldEmptyHint')},
              ]
            }
          },
        },
        url: {
          add: "/oa/dbcp/dbcpBusinessTrip/add",
          edit: "/oa/dbcp/dbcpBusinessTrip/edit",
        }
      }
    },
    created () {
    },
    methods: {
      afterAdd () {
        this.edit({
          dept: this.$store.getters.userInfo.departId,
          applicant: this.$store.getters.userInfo.id
        });
      },
      afterEdit (record) {
        this.form.setFieldsValue({loanDemand: true})
        this.$nextTick(() => {
          this.form.setFieldsValue(pick(this.model,'dept','personnel','location','address','schedule','beginTime','endTime','cause','trafficType','trafficRemark','accommodationType','accommodationRemark','loanDemand','useing','applicant','addBy','addTime','status'))
        })
      },
      afterClose () {
      },
      popupCallback(row){
        this.form.setFieldsValue(pick(row,'dept','personnel','location','address','schedule','beginTime','endTime','cause','trafficType','trafficRemark','accommodationType','accommodationRemark','loanDemand','useing','applicant','addBy','addTime','status'))
      },
      onChange(checked) {

      },

    }
  }
</script>
<style>
  .show{
    display: none;
  }
</style>