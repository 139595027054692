<template>
  <div>
    <a-modal
      :title='title'
      :width='width'
      placement='right'
      @cancel='close'
      :footer='null'
      :visible='visible'>
      <a-descriptions :column='3' bordered size='small'>
        <a-descriptions-item :label="$t('serialNumber')">
          {{ data.serialNumber }}
        </a-descriptions-item>
        <a-descriptions-item label='项目'>
          <a @click='turnProjectDetail(data)'>{{ data.projectId_dictText }}</a>
        </a-descriptions-item>
        <a-descriptions-item label='项目明细'>
          <a @click='turnProjectItemDetail(data)'>{{ data.rightHolder_dictText }}</a>
        </a-descriptions-item>
        <a-descriptions-item label="此次付款">
          {{ data.thisPayment }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addBy')">
          {{ data.addBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addTime')">
          {{ data.addTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('status')">
          {{ data.status_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label='当前处理人' :span='3'>
          {{ data.approver_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label='备注' :span='3'>
          {{ data.remark }}
        </a-descriptions-item>
      </a-descriptions>
      <br>
      <a-table ref='table'
               size='middle'
               bordered
               rowKey='id'
               :columns='columns'
               :dataSource='realDataSource'
               :pagination='false'
      >
        <template slot='detail' slot-scope='text, record,index'>
          <p v-for='item in record.cumPaymentAmountList'>
            {{ item.compensateTypeId_dictText }} : {{item.amount}}
          </p>
        </template>
      </a-table>
      <br>
      <!--   actions   -->
      <div class='detail-actions' v-if='data.action && Object.keys(data.action).length > 0'>
        <a-divider></a-divider>
        <span v-for='action in Object.keys(data.action)'>
            <a-button
              type='primary'
              @click="handleActionClick([data, action.replace('_dictText','')])"
              class='detail-actions-button'
              v-if="action.includes('_dictText')"
            >
              {{ data.action[action] }}
              <!--              {{ $t('actionCode["' + action + '"]') }}-->
            </a-button>
          </span>
      </div>

      <a-divider></a-divider>
      <detail-step :url='`/cum/cumFirstPay/workflowVisual?id=${data.id}`' :visible='visible'
                   ref='detailStep'></detail-step>
      <detail-history :file-list='data.fileList' :history-list='histories'></detail-history>
    </a-modal>

    <a-modal
      :visible='actionFormVisible'
      :title="$t('actionCode[\'' + action +'\']')"
      @ok='performAction'
      @cancel='actionFormVisible = false'
    >
      <a-form>
        <a-form-item :label="$t('comment')">
          <a-textarea v-model='actionForm.comment'></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
    <cumProjectItem-detail ref='projectItemDetailForm'></cumProjectItem-detail>
    <cum-project-detail ref='projectDetailForm'></cum-project-detail>
  </div>
</template>

<script>
import { cumFirstPayApi } from './api/CumFirstPayWorkflow'
import { DetailHistory } from 'dbcp-frontends-common/lib/components'
import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'
import { ActionMixin } from 'dbcp-frontends-common/lib/mixins'
import DetailStep from '@/components/dbcp/detail/DetailStep'
import CumProjectItemDetail from '../../projectManagement/modules/CumProjectItemDetail'
import CumProjectDetail from '../../projectManagement/modules/CumProjectDetail'
import { getAction } from 'dbcp-frontends-common/lib/api'

export default {
  name: 'CumFirstPayDetail',
  components: {
    DetailHistory,
    DetailStep,
    CumProjectItemDetail,
    CumProjectDetail
  },
  mixins: [DetailMixin, ActionMixin],
  data() {
    return {
      url: cumFirstPayApi.url,
      dataSource:[],
      columns: [
        {
          title: '项目细项',
          align: 'center',
          dataIndex: 'rightHolder_dictText'
        },
        {
          title: '应付款',
          align: 'center',
          dataIndex: 'rightHolder_dictText',
          children: [
            {
              title: '详情',
              align: 'center',
              dataIndex: 'detail',
              scopedSlots: { customRender: 'detail' }
            },
            {
              title: '合计',
              align: 'center',
              dataIndex: 'payableMoney'
            }
          ]
        },
        {
          title: '已付款',
          align: 'center',
          dataIndex: 'rightHolder_dictText',
          children: [
            {
              title: '合计',
              align: 'center',
              dataIndex: 'paidMoney'
            }
          ]
        },
      ],
    }
  },
  computed:{
    realDataSource() {
      let arr = [...this.dataSource]
      let allPayableMoney = arr.reduce((prev,next) => {
        return prev + next.payableMoney
      },0)

      let allTotalPaidMoney = arr.reduce((prev,next) => {
        return prev + next.paidMoney
      },0)

      arr.push({
        rightHolder_dictText:'合计',
        payableMoney:allPayableMoney,
        paidMoney:allTotalPaidMoney,
      })
      return arr
    }
  },
  methods: {
    turnProjectItemDetail(record) {
      getAction('/cum/cumProjectItem/list', { id: record.projectItemId }).then(res => {
        let data = res.result.records[0]
        this.$refs.projectItemDetailForm.view(data)
      })
    },
    turnProjectDetail(record) {
      getAction('/cum/cumProject/list', { id: record.projectId }).then(res => {
        let data = res.result.records[0]
        this.$refs.projectDetailForm.view(data)
      })
    },
    getData(){

      let arr = [this.data]
      this.dataSource = arr
    }
  }
}
</script>
