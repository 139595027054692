<template>
  <div>
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" :tab="$t('historyRecords')">
        <histories :data="historyList"></histories>
      </a-tab-pane>
      <a-tab-pane key="2">
        <template slot="tab">
          <a-badge :count="fileList && fileList.length || 0" :number-style="{ backgroundColor: '#52c41a' }" >
            <span style="padding:0 13px">{{$t('attachments')}}</span>
          </a-badge>
        </template>
        <files :files="fileList"></files>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
  import Histories from '@/components/common/Histories'
import { Files } from 'dbcp-frontends-common/lib/components'
  export default {
    name: 'DetailHistory',
    components: {
      Histories,
      Files
    },
    props: {
      historyList: {
        type: Array,
        default: []
      },
      fileList: {
        type: Array,
        default: []
      }
    }
  }
</script>

<style scoped>

</style>
