<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="字段名">
              <a-input placeholder="请输入字段名" v-model="queryParam.name"></a-input>
            </a-form-item>
          </a-col>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a @click="handleToggleSearch" style="margin-left: 8px">
                {{ toggleSearchStatus ? $t('collapse') : $t('expend') }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus" v-has="'dbcpWorkflowFormField:create'">{{ $t('create') }}</a-button>
      <a-button type="primary" icon="download" @click="handleExportXls('表单字段')">{{ $t('export') }}</a-button>
      <a-upload name="file" :showUploadList="false" :multiple="false" :headers="tokenHeader" :action="importExcelUrl" @change="handleImportExcel">
        <a-button type="primary" icon="import">{{ $t('import') }}</a-button>
      </a-upload>
      <a-dropdown v-if="selectedRowKeys.length > 0">
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="batchDel"><a-icon type="delete"/>{{ $t('delete') }}</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> {{ $t('batchOperation') }} <a-icon type="down" /></a-button>
      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>

      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        class="j-table-force-nowrap"
        @change="handleTableChange">

        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="imgSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noPicture') }}</span>
          <img v-else :src="getImgView(text)" height="25px" alt="" style="max-width:80px;font-size: 12px;font-style: italic;"/>
        </template>
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noFile') }}</span>
          <a-button
            v-else
            :ghost="true"
            type="primary"
            icon="download"
            size="small"
            @click="uploadFile(text)">
            {{ $t('download') }}
          </a-button>
        </template>

        <span slot="action" slot-scope="text, record">
           <div style='display: flex;justify-content: space-evenly'>
                <a @click='handleEdit(record)'>{{ $t('edit') }}</a>
                <list-actions
                  :record='record'
                  @view='handleView'
                  @edit='handleEdit'
                  @delete='handleDelete'
                  @action='$refs.detailForm.handleActionClick'
                  @copy='handleCopy'
                ></list-actions>
            </div>
        </span>

      </a-table>
    </div>


    <dbcpWorkflowFormField-modal ref="modalForm" @ok="modalFormOk"></dbcpWorkflowFormField-modal>
    <dbcpWorkflowFormField-detail ref="detailForm" @action="handleActionClick" @close="handleViewModalClose"
                                      @loadData="loadData" @clearRouteQuery="clearRouteQuery"></dbcpWorkflowFormField-detail>
  </a-card>
</template>

<script>
  import {  dbcpWorkflowFormFieldApi } from './modules/api/DbcpWorkflowFormField'
  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
  import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
  import { PrinterMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
  import DbcpWorkflowFormFieldModal from './modules/DbcpWorkflowFormFieldModal'
  import DbcpWorkflowFormFieldDetail from './modules/DbcpWorkflowFormFieldDetail'
  import { ListActions } from 'dbcp-frontends-common/lib/components'

  export default {
    name: "DbcpWorkflowFormFieldList",
    mixins:[JeecgListMixin, mixinDevice, ViewMixin],
    components: {
      DbcpWorkflowFormFieldModal,
      DbcpWorkflowFormFieldDetail,
      ListActions
    },
    data () {
      return {
        description: '表单字段管理页面',
        // 表头
        columns: [
          {
            title: '#',
            dataIndex: '',
            key: 'rowIndex',
            width: 60,
            align: 'center',
            customRender: function (t,r,index) {
              return parseInt(index)+1;
            }
          },
          {
            title: '表单id',
            align: 'center',
            dataIndex: 'tableId'
          },
          {
            title: '字段名',
            align: 'center',
            dataIndex: 'name'
          },
          {
            title: '是否为主键',
            align: 'center',
            dataIndex: 'isPk'
          },
          {
            title: '字段类型',
            align: 'center',
            dataIndex: 'type'
          },
          {
            title: '字段描述',
            align: 'center',
            dataIndex: 'description'
          },
          {
            title: '创建人',
            align: 'center',
            dataIndex: 'addBy_dictText'
          },
          {
            title: '创建时间',
            align: 'center',
            dataIndex: 'addTime'
          },
          {
            title: '更新人',
            align: 'center',
            dataIndex: 'updateBy_dictText'
          },
          {
            title: '更新时间',
            align: 'center',
            dataIndex: 'updateTime'
          },
          {
            title: this.$i18n.tc('action'),
            dataIndex: 'action',
            align: 'center',
            // fixed:"right",
            width: 147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: dbcpWorkflowFormFieldApi.url,
        dictOptions:{},
      }
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
    },
    methods: {
      initDictConfig(){
      }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>
