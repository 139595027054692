<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="员工">
              <j-select-user-by-dep :hideDept="true" placeholder="请选择员工" v-model="queryParam.addBy" />
            </a-form-item>
          </a-col>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="考勤时间">
              <a-range-picker format="YYYY-MM-DD"  @change='handleDateChange' v-model='defaultDate'></a-range-picker>
<!--              <j-date :show-time="true" date-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择考勤时间" v-model="queryParam.addTime"></j-date>-->
            </a-form-item>
          </a-col>
<!--          <template v-if="toggleSearchStatus">-->
<!--            <a-col :xl="6" :lg="7" :md="8" :sm="24">-->
<!--              <a-form-item label="考勤地点">-->
<!--                <a-input placeholder="请输入考勤地点" v-model="queryParam.location"></a-input>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--          </template>-->
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="handleSearchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a @click="handleToggleSearch" style="margin-left: 8px">
                {{ toggleSearchStatus ? $t('collapse') : $t('expend') }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-dropdown v-if="selectedRowKeys.length > 0">
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="batchDel"><a-icon type="delete"/>{{ $t('delete') }}</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> {{ $t('batchOperation') }} <a-icon type="down" /></a-button>
      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>

      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">
<!--        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type}"-->

        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="imgSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noPicture') }}</span>
          <img v-else :src="getImgView(text)" height="25px" alt="" style="max-width:80px;font-size: 12px;font-style: italic;"/>
        </template>
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noFile') }}</span>
          <a-button
            v-else
            :ghost="true"
            type="primary"
            icon="download"
            size="small"
            @click="uploadFile(text)">
            {{ $t('download') }}
          </a-button>
        </template>

        <span slot="action" slot-scope="text, record">
          <div style='display: flex;justify-content: space-evenly'>
                <list-actions
                  :record='record'
                  @view='handleView'
                  @edit='handleEdit'
                  @delete='handleDelete'
                  @action='$refs.detailForm.handleActionClick'
                  @copy='handleCopy'
                ></list-actions>
            </div>
        </span>

      </a-table>
    </div>


    <cumAttendance-modal ref="modalForm" @ok="modalFormOk"></cumAttendance-modal>
    <cumAttendance-detail ref="detailForm" @action="handleActionClick" @close="handleViewModalClose"
                                      @loadData="loadData" @clearRouteQuery="clearRouteQuery"></cumAttendance-detail>
  </a-card>
</template>

<script>
  import {  cumAttendanceApi } from './modules/api/CumAttendance'
  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
  import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
  import CumAttendanceModal from './modules/CumAttendanceModal'
  import CumAttendanceDetail from './modules/CumAttendanceDetail'
  import { ApprovalTab } from 'dbcp-frontends-common/lib/components'
  import { ListActions } from 'dbcp-frontends-common/lib/components'
  import { SelectorMixin } from 'dbcp-frontends-common/lib/mixins'
  import { JDate } from 'dbcp-frontends-common/lib/components'
  import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'

  export default {
    name: "CumAttendanceList",
    mixins:[JeecgListMixin, mixinDevice, ViewMixin, ApprovalListMixin,SelectorMixin],
    components: {
      JDate,
      JSelectUserByDep,
      CumAttendanceModal,
      CumAttendanceDetail,
      ListActions,
      ApprovalTab

    },
    data () {
      return {
        description: '考勤管理管理页面',
        // 表头
        columns: [
          // {
          //   title: '#',
          //   dataIndex: '',
          //   key: 'rowIndex',
          //   width: 60,
          //   align: 'center',
          //   customRender: function (t,r,index) {
          //     return parseInt(index)+1;
          //   }
          // },
          {
            title: '员工',
            align: 'center',
            dataIndex: 'addBy_dictText'
          },
          {
            title: '考勤时间',
            align: 'center',
            dataIndex: 'addTime'
          },
          {
            title: '考勤地点',
            align: 'center',
            dataIndex: 'location'
          },
          // {
          //   title: '备注',
          //   align: 'center',
          //   dataIndex: 'remark'
          // },
          {
            title: this.$i18n.tc('action'),
            dataIndex: 'action',
            align: 'center',
            // fixed:"right",
            width: 147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: cumAttendanceApi.url,
        dictOptions:{},
        defaultDate:null,
      }
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
    },
    methods: {
      initDictConfig(){
      },
      handleDateChange(date,b){
        this.queryParam.addTime_begin = b[0]
        this.queryParam.addTime_end = b[1]
      },
      handleSearchReset(){
        this.defaultDate = null;
        this.searchReset();
      }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>
