<template>
  <j-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('cancel')">
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item :label="$t('moduleName')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['moduleName', validatorRules.moduleName]"></a-input>
        </a-form-item>
        <a-form-item :label="$t('moduleKey')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['moduleKey', validatorRules.moduleKey]"></a-input>
        </a-form-item>
        <a-form-item label="表单描述" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea v-decorator="['description', validatorRules.description]"></a-textarea>
        </a-form-item>
        <a-form-item label="模块表单" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <common-selector url='/workflow/dbcpWorkflowForm/list' showName='entityName' :showItem="['entityName','description']" title='表单选择器'
                           v-decorator="['formId', validatorRules.formId]"></common-selector>
        </a-form-item>
        <a-form-item label="机构获取" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-radio-group  v-decorator="['deptRuleType',{rules: validatorRules.deptRuleType.rules,initialValue: 'department'}]" @change='handleDeptChange' :default-value='"department"'>
            <a-radio :value="'department'">
              所属机构
            </a-radio>
            <a-radio :value="'table'">
              自定义
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <div v-if='showOther'>
          <a-form-item label="机构表单" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <common-selector url='/workflow/dbcpWorkflowForm/list' showName='entityName' :showItem="['entityName','description']" title='表单选择器'
                             v-decorator="['deptFormId', validatorRules.deptFormId]" ></common-selector>
          </a-form-item>
          <a-form-item label="机构字段" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <common-selector url='/workflow/dbcpWorkflowFormField/list' showName='fieldName' :showItem="['fieldName']" title='表单选择器'
                             v-decorator="['deptFieldName', validatorRules.deptFieldName]" :queryParams='{tableId:form.getFieldValue("deptFormId"),pageSize:-1}' sendParams='fieldName'></common-selector>
          </a-form-item>
          <a-form-item label="关联字段" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if='form.getFieldValue("formId") !== form.getFieldValue("deptFormId")'>
            <common-selector url='/workflow/dbcpWorkflowFormField/list' showName='fieldName' :showItem="['fieldName']" title='表单选择器'
                             v-decorator="['relateField', validatorRules.relateField]" :queryParams='{tableId:form.getFieldValue("deptFormId"),pageSize:-1}' sendParams='fieldName'></common-selector>
          </a-form-item>
        </div>

      </a-form>
    </a-spin>
  </j-modal>
</template>

<script>

  import { httpAction } from 'dbcp-frontends-common/lib/api'
  import pick from 'lodash.pick'
  import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
  import { CommonSelector } from 'dbcp-frontends-common/lib/components'


  export default {
    name: "DbcpWorkflowModuleModal",
    components: {
      CommonSelector
    },
    data () {
      return {
        form: this.$form.createForm(this),
        title: this.$i18n.tc('action'),
        width: 800,
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules: {
          moduleName: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          moduleKey: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          description: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          formId: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          deptRuleType: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          deptFormId: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          deptFieldName: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          relateField: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          }
        },
        url: {
          add: "/workflow/dbcpWorkflowModule/add",
          edit: "/workflow/dbcpWorkflowModule/edit",
        },
        showOther:false
      }
    },
    created () {
    },
    methods: {
      add () {
        this.edit({});
      },
      edit (record) {
        this.form.resetFields();
        this.model = Object.assign({}, record);
        this.visible = true;
        this.showOther = this.model.deptRuleType === 'table'
        this.$nextTick(() => {
          this.form.setFieldsValue(pick(this.model,'moduleName','moduleKey','description','formId','deptRuleType','deptFormId','deptFieldName','relateField'))
        })
      },
      close () {
        this.$emit('close');
        this.visible = false;
      },
      handleOk () {
        const that = this;
        // 触发表单验证
        this.form.validateFields((err, values) => {
          if (!err) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
               method = 'put';
            }
            let formData = Object.assign(this.model, values);
            console.log("表单提交数据",formData)
            httpAction(httpurl,formData,method).then((res)=>{
              if(res.success){
                that.$emit('ok');
              }
            }).finally(() => {
              that.confirmLoading = false;
              that.close();
            })
          }

        })
      },
      handleCancel () {
        this.close()
      },
      popupCallback(row){
        this.form.setFieldsValue(pick(row,'moduleName','moduleKey'))
      },

      handleDeptChange(e){
        console.log('radio checked', e.target.value)
        this.form.setFieldsValue({
          'deptRuleType': e.target.value
        })
        if(e.target.value === 'table'){
          this.$nextTick(() => {
            this.form.setFieldsValue({
              'deptFormId':this.form.getFieldValue('formId')
            })
          })

        }
      }
    }
  }
</script>
