<template>
  <j-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('cancel')">
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">


        <a-form-item label="表单id" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['tableId', validatorRules.tableId]" placeholder="请输入表单id" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="字段名" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['name', validatorRules.name]" placeholder="请输入字段名"></a-input>
        </a-form-item>
        <a-form-item label="是否为主键" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-switch v-decorator="['isPk', , validatorRules.isPk, {initialValue: false}]" checked-children="是" un-checked-children="否"/>
        </a-form-item>
        <a-form-item label="字段类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['type', validatorRules.type]" placeholder="请输入字段类型"></a-input>
        </a-form-item>
        <a-form-item label="字段描述" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['description']" placeholder="请输入字段描述"></a-input>
        </a-form-item>
        <a-form-item :label="$t('attachments')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <attachment v-decorator="['upload']" @handleFileRemove="handleFileRemove" @beforeFileUpload="beforeFileUpload" :file-list="uploadFiles" :default-file-list="defaultFileList"></attachment>
        </a-form-item>

      </a-form>
    </a-spin>

    <template slot="footer">
      <a-button type="primary" @click="handleOk(false)">{{ $t('saveDraft') }}</a-button>
      <a-button type="primary" @click="handleOk(true)">{{ $t('submit') }}</a-button>
      <a-button @click="handleCancel">{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
  import {  dbcpWorkflowFormFieldApi } from './api/DbcpWorkflowFormField'
  import { httpAction } from 'dbcp-frontends-common/lib/api'
  import pick from 'lodash.pick'
  import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
  import { UploadFormMixin } from 'dbcp-frontends-common/lib/mixins'
     import { JSwitch } from 'dbcp-frontends-common/lib/components'


  export default {
    name: "DbcpWorkflowFormFieldModal",
    components: {
        JSwitch,
    },
    mixins: [ UploadFormMixin ],
    data () {
      return {
        form: this.$form.createForm(this),
        title: this.$i18n.tc('action'),
        width: 800,
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules: {
          tableId: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          name: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          isPk: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          type: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
        },
        url: dbcpWorkflowFormFieldApi.url,
      }
    },
    created () {
    },
    methods: {
      afterAdd (record) {
        this.edit({
            ...record
        })
      },
      afterEdit (record) {
        this.$nextTick(() => {
          this.form.setFieldsValue(pick(this.model,'tableId','name','isPk','type','description','addBy','addTime','updateBy','updateTime'))
        })
      },
      afterClose () {
      },
      popupCallback(row){
        this.form.setFieldsValue(pick(row,'tableId','name','isPk','type','description','addBy','addTime','updateBy','updateTime'))
      },


    }
  }
</script>
