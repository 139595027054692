<template>
  <a-modal
    v-model='visible'
    @ok='visible = false'
    width='1400px'
    @cancel='handleCancel'
  >
    <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="业主">
<!--              <a-input placeholder="请输入业主" v-model="queryParam.ownerId"></a-input>-->
              <InputSelect v-model="queryParam.ownerId" show-name='name' send-params='id'
                           url='dbcp/city/cumOwner/CumOwnerList.vue'
                           type='radio' :isEdit='currentRecord.ownerId?true:false'></InputSelect>
            </a-form-item>
          </a-col>
<!--          <a-col :xl="10" :lg="11" :md="12" :sm="24">-->
<!--            <a-form-item label="创建人">-->
<!--              <a-input :placeholder="$t('selectMinValue')" class="query-group-cust" v-model="queryParam.addBy_begin"></a-input>-->
<!--              <span class="query-group-split-cust"></span>-->
<!--              <a-input :placeholder="$t('selectMaxValue')" class="query-group-cust" v-model="queryParam.addBy_end"></a-input>-->
<!--            </a-form-item>-->
<!--          </a-col>-->
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a @click="handleToggleSearch" style="margin-left: 8px">
                {{ toggleSearchStatus ? $t('collapse') : $t('expend') }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd(currentRecord)" type="primary" icon="plus" v-has="'cumMoveBackInfo:create'">{{ $t('create') }}</a-button>
<!--      <a-button type="primary" icon="download" @click="handleExportXls('回迁管理')">{{ $t('export') }}</a-button>-->
<!--      <a-upload name="file" :showUploadList="false" :multiple="false" :headers="tokenHeader" :action="importExcelUrl" @change="handleImportExcel">-->
<!--        <a-button type="primary" icon="import">{{ $t('import') }}</a-button>-->
<!--      </a-upload>-->
      <a-dropdown v-if="selectedRowKeys.length > 0">
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="batchDel"><a-icon type="delete"/>{{ $t('delete') }}</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> {{ $t('batchOperation') }} <a-icon type="down" /></a-button>
      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>
     <!-- <approval-tab @change="handleGroupChange" permission="cumMoveBackInfo:view:all"></approval-tab> -->
      <setting-columns :columns='columns' @changeRealColumns='(data) => columns = data'></setting-columns>
      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type}"
        class="j-table-force-nowrap"
        @change="handleTableChange">

        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="imgSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noPicture') }}</span>
          <img v-else :src="getImgView(text)" height="25px" alt="" style="max-width:80px;font-size: 12px;font-style: italic;"/>
        </template>
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noFile') }}</span>
          <a-button
            v-else
            :ghost="true"
            type="primary"
            icon="download"
            size="small"
            @click="uploadFile(text)">
            {{ $t('download') }}
          </a-button>
        </template>

        <span slot="action" slot-scope="text, record">
          <list-actions
            :record="record"
            @view="handleView"
            @edit="handleEdit"
            @delete="handleDelete"
            @action="$refs.detailForm.handleActionClick"
            @copy="handleCopy"
          ></list-actions>
        </span>

      </a-table>
    </div>


    <cumMoveBackInfo-modal ref="modalForm" @ok="modalFormOk"></cumMoveBackInfo-modal>
    <cumMoveBackInfo-detail ref="detailForm" @action="handleActionClick" @close="handleViewModalClose"
                                      @loadData="loadData" @clearRouteQuery="clearRouteQuery"></cumMoveBackInfo-detail>
  </a-card>
  </a-modal>
</template>

<script>
  import {  cumMoveBackInfoApi } from './modules/api/CumMoveBackInfo'
  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
  import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
  import { SettingColumns } from 'dbcp-frontends-common/lib/components'
  import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
  import CumMoveBackInfoModal from './modules/CumMoveBackInfoModal'
  import CumMoveBackInfoDetail from './modules/CumMoveBackInfoDetail'
  // import { ApprovalTab } from 'dbcp-frontends-common/lib/components'
  import { ListActions } from 'dbcp-frontends-common/lib/components'
  import { SelectorMixin } from 'dbcp-frontends-common/lib/mixins'
  import {filterMultiDictText} from '@/components/dict/JDictSelectUtil'
  import { CommonSelector } from 'dbcp-frontends-common/lib/components'
  import InputSelect  from '@/components/dbcp/InputSelect'

  export default {
    name: "CumMoveBackInfoList",
    mixins:[JeecgListMixin, mixinDevice, ViewMixin, ApprovalListMixin,SelectorMixin],
    components: {
      CumMoveBackInfoModal,
      CumMoveBackInfoDetail,
      ListActions,
      SettingColumns,
      CommonSelector,
      InputSelect

    },
    data () {
      return {
        description: '回迁管理管理页面',
        // 表头
        columns: [
          {
            title: '#',
            dataIndex: '',
            key: 'rowIndex',
            width: 60,
            align: 'center',
            customRender: function (t,r,index) {
              return parseInt(index)+1;
            }
          },
          {
            title: '项目细项',
            align: 'center',
            dataIndex: 'projectItemId_dictText'
          },
          {
            title: '业主',
            align: 'center',
            dataIndex: 'ownerId_dictText'
          },
          {
            title: '拆迁面积',
            align: 'center',
            dataIndex: 'demolitionArea'
          },
          {
            title: '可回迁面积(m²)',
            align: 'center',
            dataIndex: 'fetchableArea'
          },
          {
            title: '已选房号',
            align: 'center',
            dataIndex: 'selectedRoomNo'
          },
          {
            title: '实际回迁面积(m²)',
            align: 'center',
            dataIndex: 'actualFetchArea'
          },
          {
            title: '面积补差金额',
            align: 'center',
            dataIndex: 'areaCompensationAmount'
          },
          {
            title: '面积补差支付情况',
            align: 'center',
            dataIndex: 'areaCompensationPaymentStatus_dictText'
          },
          {
            title: '回迁确认书',
            align: 'center',
            dataIndex: 'relocationConfirmed_dictText'
          },
          {
            title: '备注',
            align: 'center',
            dataIndex: 'remark'
          },
          {
            title: '创建人',
            align: 'center',
            dataIndex: 'addBy_dictText'
          },
          {
            title: '创建时间',
            align: 'center',
            dataIndex: 'addTime'
          },
          {
            title: '更新人',
            align: 'center',
            dataIndex: 'updateBy_dictText'
          },
          {
            title: '更新时间',
            align: 'center',
            dataIndex: 'updateTime'
          },
          // {
          //   title: '逻辑删除',
          //   align: 'center',
          //   dataIndex: 'deleted'
          // },
          {
            title: this.$i18n.tc('action'),
            dataIndex: 'action',
            align: 'center',
            // fixed:"right",
            width: 147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: cumMoveBackInfoApi.url,
        dictOptions:{},
        visible: false,
        /* 禁用created列表数据加载 */
        disableMixinCreated: true,
        currentRecord:{},
      }
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
    },
    methods: {
      initDictConfig(){
      },
      handleCancel() {
        this.$emit('close')
      },
      show(record) {
        console.log("record",record)
        this.visible = true;
        this.currentRecord = record ;
        this.queryParam = {};
        this.queryParam = Object.assign({},record,this.queryParam)
        this.loadData();
      },
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>
