<template>
  <div>
    <a-modal
      :title='title'
      :width='width'
      placement='right'
      @cancel='close'
      :footer='null'
      :visible='visible'>
      <a-descriptions :column='3' bordered size='small'>
        <a-descriptions-item :label="$t('serialNumber')">
          {{ data.serialNumber }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('projectId')">
          <a @click='turnProjectDetail(data.projectId)'>{{ data.projectId_dictText }}</a>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('projectItemId')">
          <a @click='turnProjectItemDetail(data.projectItemId)'>{{ data.rightHolder_dictText }}</a>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('status')">
          {{ data.status_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addBy')">
          {{ data.addBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addTime')">
          {{ data.addTime }}
        </a-descriptions-item>
      </a-descriptions>
      <a-divider></a-divider>
      <div v-if='listData'>
        <a-table
          bordered
          :columns='columns'
          :dataSource='realDataSource'
          :pagination='false'
        >
          <template slot='compensateType'
                    slot-scope='text,record,index'>
            {{ filterValue(record) }}
          </template>
          <template slot='money'
                    slot-scope='text,record,index'>
            {{ record.money || record.area }}
          </template>
        </a-table>
      </div>

      <a-divider></a-divider>
      <detail-step :url='`/cum/cumBargainingPower/workflowVisual?id=${data.id}`' :visible='visible'
                   ref='detailStep'></detail-step>

      <!--   actions   -->
      <div class='detail-actions' v-if='data.action && Object.keys(data.action).length > 0'>
        <a-divider></a-divider>
        <span v-for='action in Object.keys(data.action)'>
            <a-button
              type='primary'
              @click="handleActionClick([data,action.replace('_dictText','')])"
              class='detail-actions-button'
              v-if="action.includes('_dictText')"
            >
              {{ data.action[action] }}
              <!--              {{ $t('actionCode["' + action + '"]') }}-->
            </a-button>
          </span>
      </div>

      <a-divider></a-divider>
      <detail-history :file-list='data.fileList' :history-list='histories'></detail-history>
    </a-modal>

    <a-modal
      :visible='actionFormVisible'
      :title="$t('actionCode[\'' + action +'\']')"
      @ok='performAction'
      @cancel='actionFormVisible = false'
    >
      <a-form>
        <a-form-item :label="$t('comment')">
          <a-textarea v-model='actionForm.comment'></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>

    <cumProjectItem-detail ref='projectItemDetailForm'></cumProjectItem-detail>

    <cum-project-detail ref='projectDetailForm'></cum-project-detail>
  </div>
</template>

<script>
import { cumBargainingPowerApi } from './api/CumBargainingPowerWorkflow'
import { DetailHistory } from 'dbcp-frontends-common/lib/components'
import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'
import { ActionMixin } from 'dbcp-frontends-common/lib/mixins'
import DetailStep from '@/components/dbcp/detail/DetailStep'
import CumProjectItemDetail from '../../projectManagement/modules/CumProjectItemDetail'
import { getAction } from 'dbcp-frontends-common/lib/api'
import CumProjectDetail from '../../projectManagement/modules/CumProjectDetail'

export default {
  name: 'CumBargainingPowerDetail',
  components: { CumProjectDetail, DetailHistory, DetailStep, CumProjectItemDetail },
  mixins: [DetailMixin, ActionMixin],
  data() {
    return {
      url: cumBargainingPowerApi.url,
      listData: [],
      columns: [
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function(t, r, index) {
            return parseInt(index) + 1
          }
        },
        // {
        //   title: '谈判条件ID',
        //   align: 'center',
        //   dataIndex: 'bargainingPowerId',
        //
        // },
        {
          title: '补偿方式',
          align: 'center',
          dataIndex: 'compensateMethod_dictText'
        },
        {
          title: '补偿类型',
          align: 'center',
          dataIndex: 'compensateType',
          scopedSlots: { customRender: 'compensateType' }
        },
        {
          title: '补偿标准',
          align: 'center',
          dataIndex: 'compensation',
          scopedSlots: { customRender: 'compensation' }
        },
        {
          title: '总额',
          align: 'center',
          dataIndex: 'money',
          scopedSlots: { customRender: 'money' }
        },
        {
          title: '备注',
          align: 'center',
          dataIndex: 'remark'

        }
      ]
    }
  },
  computed: {
    realDataSource() {
      let arr = this.listData || []
      arr.forEach(item => {
        if (item.compensateMethod === '1' || item.periodicity === '0') {
          item.periodicity = '/'
        }
      })

      return arr
    }
  },
  methods: {
    async view(data) {
      console.log('view', data)
      this.data = data
      this.listData = data.targetList
      this.visible = true
      await this.getHistories()
    },
    turnProjectItemDetail(id) {
      getAction('/cum/cumProjectItem/list', { id }).then(res => {
        let data = res.result.records[0]
        this.$nextTick(() => {
          this.$refs.projectItemDetailForm.view(data)
        })
      })
    },
    turnProjectDetail(id) {
      getAction('/cum/cumProject/list', { id }).then(res => {
        let data = res.result.records[0]
        this.$nextTick(() => {
          this.$refs.projectDetailForm.view(data)
        })

      })
    },
    filterValue(record) {
      let str = ''
      if (record.structureType_dictText) {
        str += record.structureType_dictText + '-'
      }
      if (record.propertyType_dictText) {
        str += record.propertyType_dictText + '-'
      }
      str += record.compensateType_dictText
      return str
    }
  }
}
</script>
