<template>
  <a-modal
    v-model='itemListVisible'
    @ok='itemListVisible = false'
    width='80%'
    @cancel='closeModal'
    :footer='null'
  >
    <CumUnitList ref='itemTable' :building-id='currentRecord.buildingId' :rightHolder='currentRecord.rightHolder' @closeModal='closeModal'></CumUnitList>
  </a-modal>
</template>

<script>

import CumUnitList from '../../unitList/CumUnitList'
export default {
  name: 'TurnUnitList',
  components: {
    CumUnitList,
  },
  data() {
    return {
      itemListVisible: false,
      description: '前往单元管理界面',
      currentRecord:{},
      queryParam:{},
    }
  },
  computed: {
    importExcelUrl: function() {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    }
  },
  methods: {
    initDictConfig() {
    },
    show(record) {
      console.log("TurnUnitList record",record)
      this.currentRecord = record ;
      this.itemListVisible = true ;

      // this.$nextTick(()=>{
      //   this.$refs.itemTable.initListData(record);
      // })
    },
    closeModal(){
      this.itemListVisible = false ;
    }


  }
}
</script>
<style scoped>
@import '~@assets/less/common.less';
</style>
