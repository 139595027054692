<template>
  <div id="app" style='width:100%;height:calc(100vh - 150px);'>
    <div class="headers">
      <a-menu mode="horizontal" @select="onMenu" background-color="#f8f8f8">
        <a-menu-item class="logo">
          <img src="/favicon.ico"/>
        </a-menu-item>
        <a-sub-menu key="file">
          <template slot="title">文件</template>
          <a-menu-item key="new">新建文件</a-menu-item>
          <a-menu-item key="open">打开本地文件（新建）</a-menu-item>
          <a-menu-item key="replace">导入本地文件...</a-menu-item>
          <a-menu-item class="separator"></a-menu-item>
          <a-menu-item key="save">保存到本地</a-menu-item>
          <a-menu-item key="savePng">下载为PNG</a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="edit">
          <template slot="title">编辑</template>
          <a-menu-item key="undo">撤消</a-menu-item>
          <a-menu-item key="redo">重做</a-menu-item>
          <a-menu-item class="separator"></a-menu-item>
          <a-menu-item key="copy">复制</a-menu-item>
          <a-menu-item key="cut">剪切</a-menu-item>
          <a-menu-item key="parse">粘贴</a-menu-item>
        </a-sub-menu>
      </a-menu>

      <a-menu mode="horizontal" background-color="#f8f8f8">
        <a-menu-item>视图：{{ scale }}%</a-menu-item>
        <a-sub-menu key="state" title="默认连线类型">
          <template slot="title">
            <i :class="`iconfont icon-${lineName}`"></i>
          </template>
          <a-menu-item
            v-for="(item, index) in lineNames"
            :key="`line-${item}`"
            :index="`line-${item}`"
            @click="onState('lineName', item)"
          >
            <i :class="`iconfont icon-${item}`"></i>
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
      <a-menu mode="horizontal" background-color="#f8f8f8">
        <a-sub-menu index="state" title="默认起点箭头">
          <template slot="title">
            <i :class="`iconfont icon-from-${fromArrowType}`"></i>
          </template>
          <a-menu-item
            v-for="(item, index) in arrowTypes"
            :key="`fromArrow-${item}`"
            :index="`fromArrow-${item}`"
            @click="onState('fromArrow', item)"
          >
            <i :class="`iconfont icon-from-${item}`"></i>
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
      <a-menu mode="horizontal" background-color="#f8f8f8">
        <a-sub-menu index="state" title="默认终点箭头">
          <template slot="title">
            <i :class="`iconfont icon-to-${toArrowType}`"></i>
          </template>
          <a-menu-item
            v-for="(item, index) in arrowTypes"
            :key="`toArrow-${item}`"
            :index="`toArrow-${item}`"
            @click="onState('toArrow', item)"
          >
            <i :class="`iconfont icon-to-${item}`"></i>
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </div>
    <div class="body">
      <home ref='home'></home>
    </div>
  </div>
</template>

<script>
import '../../../assets/less/base.less'
import Home from '@views/dbcp/process/module/Home'
export default {
name: "process",
  components:{
    Home
  },
  computed: {
    scale() {
      return Math.floor(this.$store.state.canvas.data.scale * 100)
    },
    lineName() {
      return this.$store.state.canvas.data.lineName
    },
    fromArrowType() {
      return this.$store.state.canvas.data.fromArrowType
    },
    toArrowType() {
      return this.$store.state.canvas.data.toArrowType
    },
  },
  data() {
    return {
      lineNames: ['curve', 'polyline', 'line'],
      arrowTypes: [
        '',
        'triangleSolid',
        'triangle',
        'diamondSolid',
        'diamond',
        'circleSolid',
        'circle',
        'line',
        'lineUp',
        'lineDown'
      ],
    }
  },
  methods: {
    onMenu({item, key, keyPath}) {
      if (!key || key.indexOf('/') === 0) {
        return
      }

      switch (key) {
        case 'new':
          // this.$router.push('/')
          break
        case 'open':
          // this.$router.push('/')
          setTimeout(() => {
            this.$store.commit('emit', {
              name: key
            })
          }, 100)
          break
        case 'about':
        case 'about2':
          this.about = true
          break
        case 'license':
          this.license = true
          break
        case 'joinin':
          this.joinin = true
          break
        default:
          this.$store.commit('emit', {
            name: key
          })
          break
      }
    },
    onState(key, value) {
      console.log(key, value)
      this.$store.commit('emit', {
        name: 'state',
        data: {
          key,
          value
        }
      })
    },
    save(){
      return this.$refs.home.canvas.data
    },
    open(data){
      // console.log('this.$refs.home.canvas',this.$refs.home.canvas.open)
      console.log('JSON.parse(data)',JSON.parse(data))
      this.$refs.home.canvas.open(JSON.parse(data))
    }

  }
}
</script>

<style scoped lang="less">
.headers {
  display: flex;
  background-color: white;
  font-size: 13px;
  height: 50px;
  .full {
    flex: 1;
  }

  .logo {
    img {
      height: 22px;
      position: relative;
      top: -4px;
    }
  }
}

.ant-menu-item,
.ant-menu-submenu {
  color: #314659 !important;
  font-size: 13px;
  padding: 0 10px;
  height: 39px !important;
  line-height: 39px !important;

  &.separator {
    border-top: 1px solid #e8e8e8;
    margin: 0 10px;
    height: 1px !important;
    line-height: 1px !important;
  }

  a {
    text-decoration: none;
    color: #314659;
  }

  .iconfont {
    color: #314659;
    font-size: 26px;
  }
}

.body {
  //height: calc(100vh - 40px);
}
</style>