import { deleteAction, getAction, uploadAction } from 'dbcp-frontends-common/lib/api'
export const dbcpWorkflowFormApi = {
  url: {
    action: "/workflow/dbcpWorkflowForm/action",
    list: "/workflow/dbcpWorkflowForm/list",
    delete: "/workflow/dbcpWorkflowForm/delete",
    deleteBatch: "/workflow/dbcpWorkflowForm/deleteBatch",
    exportXlsUrl: "/workflow/dbcpWorkflowForm/exportXls",
    importExcelUrl: "workflow/dbcpWorkflowForm/importExcel",
    add: "/workflow/dbcpWorkflowForm/add",
    edit: "/workflow/dbcpWorkflowForm/edit",
},
  execute: {
    list: function(params) {
      return getAction(dbcpWorkflowFormApi.url.list, params)
    },
    create: function(object) {
      return getAction(dbcpWorkflowFormApi.url.add, object)
    },
    retrieve: function(id, params = {}) {
      return getAction(dbcpWorkflowFormApi.url.queryById, { id, ...params })
    },
    update: function(object) {
      return uploadAction(dbcpWorkflowFormApi.url.edit, object)
    },
    destroy: function(id) {
      return deleteAction(dbcpWorkflowFormApi.url.delete, id)
    }
  }
}
