<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus" v-has="'dbcpWorkflowEvent:create'">{{
          $t('create')
        }}
      </a-button>
    </div>

    <!-- table区域-begin -->
    <div>
      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        class="j-table-force-nowrap"
        @change="handleTableChange">

        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="imgSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noPicture') }}</span>
          <img v-else :src="getImgView(text)" height="25px" alt=""
               style="max-width:80px;font-size: 12px;font-style: italic;"/>
        </template>
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noFile') }}</span>
          <a-button
            v-else
            :ghost="true"
            type="primary"
            icon="download"
            size="small"
            @click="uploadFile(text)">
            {{ $t('download') }}
          </a-button>
        </template>

        <span slot="action" slot-scope="text, record">
        <div style="display: flex;justify-content: space-evenly;width: 150px">
                 <a @click="handleEdit(record)">{{ $t('edit') }}</a>
          <list-actions
            :record="record"
            @view="handleView"
            @edit="handleEdit"
            @delete="handleDelete"
            @action="$refs.detailForm.handleActionClick"
            @copy="handleCopy"
          ></list-actions>
             <a-popconfirm :title="$t('confirmDeleteAlert')" @confirm="() => handleDelete(record.id)">
                  <a>{{ $t('delete') }}</a>
                </a-popconfirm>
            </div>

        </span>

      </a-table>
    </div>


    <dbcpWorkflowEvent-modal ref="modalForm" @ok="modalFormOk"></dbcpWorkflowEvent-modal>
    <dbcpWorkflowEvent-detail ref="detailForm" @action="handleActionClick" @close="handleViewModalClose"
                              @loadData="loadData" @clearRouteQuery="clearRouteQuery"></dbcpWorkflowEvent-detail>
  </a-card>
</template>

<script>
import '@/assets/less/TableExpand.less'
import {mixinDevice} from 'dbcp-frontends-common/lib/utils'
import {JeecgListMixin} from 'dbcp-frontends-common/lib/mixins'
import {dbcpWorkflowEventApi} from './modules/api/DbcpWorkflowEvent'
import '@/assets/less/TableExpand.less'
import {ViewMixin} from 'dbcp-frontends-common/lib/mixins'
import {ApprovalListMixin} from 'dbcp-frontends-common/lib/mixins'
import DbcpWorkflowEventModal from './modules/DbcpWorkflowEventModal'
import DbcpWorkflowEventDetail from './modules/DbcpWorkflowEventDetail'
import { ApprovalTab } from "dbcp-frontends-common/lib/components";
import { ListActions } from "dbcp-frontends-common/lib/components";

export default {
  name: "DbcpWorkflowEventList",
  mixins: [JeecgListMixin, mixinDevice, ViewMixin, ApprovalListMixin],
  components: {
    DbcpWorkflowEventModal,
    DbcpWorkflowEventDetail,
    ListActions,
    ApprovalTab
  },
  data() {
    return {
      description: '流程事件管理页面',
      // 表头
      columns: [
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1;
          }
        },
        {
          title: '事件包',
          align: 'center',
          dataIndex: 'package'
        },
        {
          title: '事件名',
          align: 'center',
          dataIndex: 'name'
        },
        {
          title: '参数',
          align: 'center',
          dataIndex: 'params'
        },
        {
          title: '操作类型',
          align: 'center',
          dataIndex: 'type'
        },
        {
          title: '操作条件',
          align: 'center',
          dataIndex: 'condition'
        },
        {
          title: '是否为异步',
          align: 'center',
          dataIndex: 'isAsyn'
        },
        {
          title: this.$i18n.tc('action'),
          dataIndex: 'action',
          align: 'center',
          // fixed:"right",
          width: 250,
          scopedSlots: {customRender: 'action'}
        }
      ],
      url: dbcpWorkflowEventApi.url,
      dictOptions: {},
    }
  },
  computed: {
    importExcelUrl: function () {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
    },
  },
  methods: {
    initDictConfig() {
    }
  }
}
</script>
<style scoped>
@import '~@assets/less/common.less';
</style>
