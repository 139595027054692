<template>
  <!-- 查询区域 -->
  <a-form layout="inline">
    <a-row :gutter="24">
      <a-col :span="4">
        <a-form-item :label="$t('serialNumber')">
          <a-input v-model="groupQueryParams.serialNumber" :trigger-change="true" customReturnField="id"/>
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item :label="$t('status')">
          <a-select v-model="groupQueryParams.status" allowClear :placeholder="$t('queryByStatus')">
            <a-select-option v-for="(item,index) in statusArr" :key="item">
              {{ $tc(`workflowStatus.${item}`) }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="4" v-if="showCreate">
        <a-form-item :label="$t('createBy')">
          <j-select-user-by-dep :hideDept="true" v-model="groupQueryParams.addBy" :multi="false" />
        </a-form-item>
      </a-col>
      <a-col :span="4" v-if="showEmployee">
        <a-form-item label="员工">
          <j-select-user-by-dep :hideDept="true" v-model="groupQueryParams.employee" :multi="false" type="userType"/>
        </a-form-item>
      </a-col>
      <a-col>
            <span style="float: left;overflow: hidden;margin-left: 15px" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchGroupQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchGroupReset" icon="reload" style="margin-left: 8px">{{
                  $t('reset')
                }}</a-button>
                <a @click="handleToggleSearch" style="margin-left: 8px">
                {{ toggleSearchStatus ? $t('collapse') : $t('expand') }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
              </a>
            </span>
      </a-col>
    </a-row>

    <a-row :gutter="24" v-show="toggleSearchStatus">
      <a-col :span="4" v-if="appraisalMonth">
        <a-form-item :label="$t('appraisalMonth')">
          <a-month-picker @change="monthChange" :value="defaultMonthDate"/>
        </a-form-item>
      </a-col>
      <a-col :span="6" style="margin-left: 15px">
        <a-form-item :label="$t('applicationDate')">
          <a-range-picker @change="dateChange" :value="defaultDate"/>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'

export default {
  name: 'HumanSearch',
  components: {
    JSelectUserByDep
  },
  props: {
    statusArr: {
      type: Array,
      default: null
    },
  //  是否显示考核月
    appraisalMonth:{
      type:Boolean,
      default: false
    },
  //  是否显示员工选择器
    showEmployee:{
      type:Boolean,
      default: false
    },
    //是否显示创建人
    showCreate:{
      type:Boolean,
      default: true
    },
  //  员工选择器 还是普工选择器
    userType:{
      type:String,
      default:'staff'
    }
  },
  data() {
    return {
      groupQueryParams: {
        serialNumber: '',
        addBy: '',
        status: null,
        addTime_begin: '',
        addTime_end: '',
        beginTime: '',
        employee:''
      },
      defaultDate: null,
      defaultMonthDate: null,
      /* 查询折叠 */
      toggleSearchStatus: false
    }
  },
  methods: {
    searchGroupQuery() {
      this.$emit('searchChange', this.groupQueryParams)
    },
    searchGroupReset() {
      this.groupQueryParams = {
        serialNumber: '',
        addBy: '',
        status: null,
        addTime_begin: '',
        addTime_end: '',
        beginTime: '',
        employee:''
      }
      console.log()
      this.defaultDate = null
      this.defaultMonthDate = null
      console.log('this.groupQueryParams', this.groupQueryParams)
      this.$emit('searchChange', this.groupQueryParams)
    },
    dateChange(date, dateString) {
      this.defaultDate = date
      this.groupQueryParams.addTime_begin = dateString[0]
      this.groupQueryParams.addTime_end = dateString[1]
    },
    handleToggleSearch() {
      this.toggleSearchStatus = !this.toggleSearchStatus
    },
    monthChange(date, dataString) {
      this.defaultMonthDate = date
      if(dataString){
        this.groupQueryParams.beginTime_begin = dataString + '-01'
        this.groupQueryParams.beginTime_end = dataString + '-02'
      }else{
        this.groupQueryParams.beginTime_begin = undefined
        this.groupQueryParams.beginTime_end = undefined
      }


    }
  }
}
</script>

<style scoped>

</style>
