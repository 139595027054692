import { deleteAction, getAction, uploadAction } from 'dbcp-frontends-common/lib/api'
export const cumContractTemplateApi = {
url: {
  action: "/cum/cumContractTemplate/action",
  list: "/cum/cumContractTemplateWorkflow/list"
},
  execute: {
    list: function(params) {
      return getAction(cumContractTemplateApi.url.list, params)
    },
    create: function(object) {
      return getAction(cumContractTemplateApi.url.add, object)
    },
    retrieve: function(id, params = {}) {
      return getAction(cumContractTemplateApi.queryById, { id, ...params })
    },
    update: function(object) {
      return uploadAction(cumContractTemplateApi.url.edit, object)
    },
    destroy: function(id) {
      return deleteAction(cumContractTemplateApi.url.delete, id)
    }
  }
}
