<template>
  <div class="page-header-index-wide page-header-wrapper-grid-content-main">
    <a-row :gutter="24">
      <a-col :md="24" :lg="7">
        <a-card :bordered="false">
          <div class="account-center-avatarHolder">
            <div class="avatar">
              <img :src="getAvatar()"/>
            </div>
            <div class="username">{{ nickname() }}</div>
          </div>
          <a-divider />
          <div class="account-center-detail">
            <p>工号：{{userProfile.workNo}}</p>
            <p>性别：{{userProfile.sex_dictText}}</p>
            <p>生日：{{userProfile.birthday}}</p>
            <p>电话：{{userProfile.phone}}</p>
            <p>电子邮件：{{userProfile.email}}</p>
            <p>已婚状态：{{userProfile.married_dictText}}</p>
            <p>职位：{{userProfile.post_dictText}}</p>
            <p>部门：{{userProfile.dept_dictText}}</p>
            <p>负责部门：{{userProfile.depart_dictText}}</p>
            <p>公司：{{userProfile.companyName}}</p>
            <p>入职时间：{{userProfile.join}}</p>
            <p>职能上级：{{userProfile.competencySuperior_dictText}}</p>
            <p>行政上级：{{userProfile.adminSuperior_dictText}}</p>
            <p>导师：{{userProfile.tutor_dictText}}</p>
            <p @click="showTimeModal" style="cursor: pointer">存休工时：<a>{{userProfile.reposeHours | MinuteToHour}}</a></p>

          </div>
          <a-divider :dashed="true" />

        </a-card>
      </a-col>

<!--      待办-->
      <a-col  :md="24" :lg="16">
        <pending-tasks-workflow-view-list></pending-tasks-workflow-view-list>
      </a-col>
    </a-row>
    <dbcp-repose-modal ref="timeModalForm" :is-only-show="false" :is-footer-show="null"></dbcp-repose-modal>
  </div>
</template>

<script>
  import { PageLayout } from 'dbcp-frontends-common/lib/components'
  import RouteView from "@/components/layouts/RouteView"
  import { mapGetters } from 'vuex'
  import { getFileAccessHttpUrl } from 'dbcp-frontends-common/lib/api';
  import {getAction} from 'dbcp-frontends-common/lib/api'
  import PendingTasksWorkflowViewList from '@views/dbcp/publicPlatform/pendingTasks/PendingTasksWorkflowViewList'
  import DbcpReposeModal from '@views/system/modules/DbcpReposeModal'
  export default {
    components: {
      PendingTasksWorkflowViewList,
      RouteView,
      PageLayout,
      DbcpReposeModal
    },
    data() {
      return {
        tagInputVisible: false,
        tagInputValue: '',
        teams: [],
        teamSpinning: true,
        noTitleKey: 'app',
        url:{
          list:'/sys/sysUserProfile/myProfile'
        },
        userProfile:{}
      }
    },
    mounted () {
      this.getUserDetail()
    },
    methods: {
      ...mapGetters(["nickname", "avatar"]),
      getAvatar(){
          return getFileAccessHttpUrl(this.avatar());
      },
      getUserDetail(){
        getAction(this.url.list).then(res => {
          if(res.success){
            this.userProfile = res.result
          }
        })
      },
      handleInputChange (e) {
        this.tagInputValue = e.target.value
      },
      showTimeModal(){
        this.$refs.timeModalForm.edit(this.userProfile.id,this.userProfile.username);
        this.$refs.timeModalForm.title = "查看工时";
        this.$refs.timeModalForm.disableSubmit = false;
      }
    },
  }
</script>

<style lang="less" scoped>
  .page-header-wrapper-grid-content-main {
    width: 100%;
    height: 100%;
    min-height: 100%;
    transition: .3s;

    .account-center-avatarHolder {
      text-align: center;
      margin-bottom: 24px;

      & > .avatar {
        margin: 0 auto;
        width: 104px;
        height: 104px;
        margin-bottom: 20px;
        border-radius: 50%;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
        }
      }

      .username {
        color: rgba(0, 0, 0, 0.85);
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        margin-bottom: 4px;
      }
    }

    .account-center-detail {

      p {
        margin-bottom: 8px;
        padding-left: 26px;
        position: relative;
      }

      i {
        position: absolute;
        height: 14px;
        width: 14px;
        left: 0;
        top: 4px;
        background: url(https://gw.alipayobjects.com/zos/rmsportal/pBjWzVAHnOOtAUvZmZfy.svg)
      }

      .title {
        background-position: 0 0;
      }
      .group {
        background-position: 0 -22px;
      }
      .address {
        background-position: 0 -44px;
      }
    }

    .account-center-tags {
      .ant-tag {
        margin-bottom: 8px;
      }
    }

    .account-center-team {

      .members {
        a {
          display: block;
          margin: 12px 0;
          line-height: 24px;
          height: 24px;
          .member {
            font-size: 14px;
            color: rgba(0, 0, 0, .65);
            line-height: 24px;
            max-width: 100px;
            vertical-align: top;
            margin-left: 12px;
            transition: all 0.3s;
            display: inline-block;
          }
          &:hover {
            span {
              color: #1890ff;
            }
          }
        }
      }
    }

    .tagsTitle, .teamTitle {
      font-weight: 500;
      color: rgba(0,0,0,.85);
      margin-bottom: 12px;
    }

  }

</style>
