<template>

  <a-modal width='80%' :visible='visible' @cancel='handleCancel' :footer='null'>
    <a-tabs default-active-key='1' @change='handleTabChange' v-model='activeKey'>
      <a-tab-pane key='1' tab='货币补偿标准'>
      </a-tab-pane>
      <a-tab-pane key='2' tab='产权置换标准'>
      </a-tab-pane>
    </a-tabs>
    <a-card :bordered='false'>
      <!-- 查询区域 -->
      <div class='table-page-search-wrapper'>
        <a-form layout='inline' @keyup.enter.native='searchQuery'>
          <a-row :gutter='24'>
          </a-row>
        </a-form>
      </div>
      <!-- 查询区域-END -->

      <!-- 操作按钮区域 -->
      <div class='table-operator'>
        <a-button @click='handleAdd' type='primary' icon='plus' v-has="'cumCompensationStandard:create'">{{ $t('create')
          }}
        </a-button>
        <a-button @click='handleCompensationType' type='primary' icon='plus'>补偿类型</a-button>
        <a-dropdown v-if='selectedRowKeys.length > 0'>
          <a-menu slot='overlay'>
            <a-menu-item key='1' @click='batchDel'>
              <a-icon type='delete' />
              {{ $t('delete') }}
            </a-menu-item>
          </a-menu>
          <a-button style='margin-left: 8px'> {{ $t('batchOperation') }}
            <a-icon type='down' />
          </a-button>
        </a-dropdown>
      </div>

      <!-- table区域-begin -->
      <div>
        <!-- <approval-tab @change="handleGroupChange" permission="cumCompensationStandard:view:all"></approval-tab> -->
        <setting-columns :columns='columns' @changeRealColumns='(data) => columns = data'></setting-columns>
        <a-table
          ref='table'
          size='middle'
          bordered
          rowKey='id'
          :columns='columns'
          :dataSource='dataSource'
          :pagination='ipagination'
          :loading='loading'
          :rowSelection='type?{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type,onSelect:handleSelection,onSelectAll:handleSelectAll}:null'
          class='j-table-force-nowrap'
          @change='handleTableChange'>

          <template slot='htmlSlot' slot-scope='text'>
            <div v-html='text'></div>
          </template>
          <template slot='imgSlot' slot-scope='text'>
            <span v-if='!text' style='font-size: 12px;font-style: italic;'>{{ $t('noPicture') }}</span>
            <img v-else :src='getImgView(text)' height='25px' alt=''
                 style='max-width:80px;font-size: 12px;font-style: italic;' />
          </template>
          <template slot='fileSlot' slot-scope='text'>
            <span v-if='!text' style='font-size: 12px;font-style: italic;'>{{ $t('noFile') }}</span>
            <a-button
              v-else
              :ghost='true'
              type='primary'
              icon='download'
              size='small'
              @click='uploadFile(text)'>
              {{ $t('download') }}
            </a-button>
          </template>

          <span slot='action' slot-scope='text, record'>
          <div style='display: flex;justify-content: space-evenly'>
            <a @click='handleEdit(record)'>{{ $t('edit') }}</a>
            <a-popconfirm :title="$t('confirmDeleteAlert')" @confirm='() => handleDelete(record.id)'>
              <a>{{ $t('delete') }}</a>
            </a-popconfirm>
          </div>
        </span>

        </a-table>
      </div>


      <cumCompensationStandard-modal ref='modalForm' @ok='modalFormOk'></cumCompensationStandard-modal>
      <cumCompensationStandard-detail ref='detailForm' @action='handleActionClick' @close='handleViewModalClose'
                                      @loadData='loadData'
                                      @clearRouteQuery='clearRouteQuery'></cumCompensationStandard-detail>

      <cum-compensation-type-list ref='typeList'></cum-compensation-type-list>
    </a-card>
  </a-modal>
</template>

<script>
import { cumCompensationStandardApi } from './modules/api/CumCompensationStandard'
import '@/assets/less/TableExpand.less'
import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
import { SettingColumns } from 'dbcp-frontends-common/lib/components'
import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
import CumCompensationStandardModal from './modules/CumCompensationStandardModal'
import CumCompensationStandardDetail from './modules/CumCompensationStandardDetail'
// import { ApprovalTab } from 'dbcp-frontends-common/lib/components'
import { ListActions } from 'dbcp-frontends-common/lib/components'
import { SelectorMixin } from 'dbcp-frontends-common/lib/mixins'
import { filterMultiDictText } from '@/components/dict/JDictSelectUtil'
import CumCompensationTypeList from './compensationStandardType/CumCompensationTypeList'

export default {
  name: 'CumCompensationStandardList',
  mixins: [JeecgListMixin, mixinDevice, ViewMixin, ApprovalListMixin, SelectorMixin],
  components: {
    CumCompensationStandardModal,
    CumCompensationStandardDetail,
    ListActions,
    SettingColumns,
    CumCompensationTypeList
  },
  data() {
    return {
      description: '项目标准补偿信息管理页面',
      // 表头
      columns: [
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function(t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '结构类型',
          align: 'center',
          dataIndex: 'structureType_dictText'
        },
        {
          title: '房屋性质',
          align: 'center',
          dataIndex: 'propertyType_dictText'
        },
        {
          title: '补偿类型',
          align: 'center',
          dataIndex: 'compensateType_dictText'
        },
        {
          title: '补偿标准',
          align: 'center',
          dataIndex: 'compensation'
        },
        {
          title: '备注',
          align: 'center',
          dataIndex: 'remark'
        },
        {
          title: this.$i18n.tc('action'),
          dataIndex: 'action',
          align: 'center',
          // fixed:"right",
          width: 147,
          scopedSlots: { customRender: 'action' }
        }
      ],
      url: cumCompensationStandardApi.url,
      dictOptions: {},
      visible: false,
      /* 禁用created列表数据加载 */
      disableMixinCreated: true,
      currentRecord: {},
      activeKey: '1'
    }
  },
  computed: {
    importExcelUrl: function() {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    }
  },
  methods: {
    initDictConfig() {
    },
    show(record) {
      this.activeKey = '1'
      this.visible = true
      this.currentRecord = record
      this.loadData()
    },

    handleTabChange(key) {
      this.activeKey = key
      this.loadData()
    },
    initParams(params) {
      params.compensateMethod = this.activeKey === '1' ? '0' : '1'
      params.projectId = this.currentRecord.projectId || undefined
      return params
    },
    handleAdd: function(record) {
      this.$refs.modalForm.add({ ...this.currentRecord, compensateMethod: this.activeKey === '1' ? '0' : '1' })
      this.$refs.modalForm.title = this.$i18n.tc('create')
      this.$refs.modalForm.disableSubmit = false
    },
    handleCancel() {
      this.visible = false
    },
    /**
     * 打开补偿类型
     */
    handleCompensationType() {
      this.$refs.typeList.show({ ...this.currentRecord, compensateMethod: this.activeKey === '1' ? '0' : '1' })
    }
  }
}
</script>
<style scoped>
@import '~@assets/less/common.less';
</style>
