<template>
  <a-steps :current="stepData.currentIndex" :status="currentStatus" style="margin-bottom: 20px;flex-wrap:wrap;justify-content: flex-start" size="small">
    <template v-for="(item,index) in stepData.nodeList" >
      <a-step :title="item.role_dictText || $t(`workflowStatus.${item.status}`)">
        <template slot="description" v-if="item.role_dictText">
          <p>{{ item.approver_dictText }}</p>
          <p v-if="index >= stepData.currentIndex && item.status !== 14">{{ $t(`workflowStatus.${item.status}`) }}</p>
          <p v-if="item.status === 14">等待系统开放自评</p>
        </template>
      </a-step>
    </template>
  </a-steps>
</template>

<script>
import { getAction } from 'dbcp-frontends-common/lib/api'

export default {
  name: 'DetailStep',
  props: {
    url: {
      type: String,
      default: ''
    },
    // 传这个原因是 关闭打开的时候再请求一次
    visible: {
      type: Boolean,
      default: ''
    }
  },
  data() {
    return {
      stepData: {},
      currentStatus:'process'
    }
  },
  mounted() {
    this.queryData()
  },
  watch: {
    visible(newV) {
      console.log('change')
      this.queryData()
      console.log(this.stepData)
    }
  },
  methods: {
    queryData() {
      if (this.visible) {
        getAction(this.url).then(res => {
          if (res.success) {
            this.stepData = res.result
            let status = this.stepData.nodeList[this.stepData.currentIndex].status
            if(status === 6){
              this.currentStatus = 'error'
            }else if(status === 5){
              this.currentStatus = 'finish'
            } else{
              this.currentStatus = 'process'
            }
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
p{
  margin: 0 !important;
}
/deep/ .ant-steps-item {
  margin-right: 8px !important;
  margin-top: 15px !important;
  overflow: visible;
  flex: none;
  width: 19%;
  .ant-steps-item-container {
    overflow: hidden;

    .ant-steps-item-title::after {
      left: 90%;
    }
  }
}

</style>
<style lang="less">

</style>
