<template>
  <a-modal
    :title="title"
    :width="width"
    placement="right"
    @cancel="close"
    :footer="null"
    :visible="visible">
    <template></template>
  </a-modal>
</template>

<script>
  export default {
    name: 'DetailModal'
  }
</script>

<style scoped>

</style>
