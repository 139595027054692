<template>
  <div>
    <a-modal
      :title='title'
      :width='width'
      placement='right'
      @cancel='close'
      :footer='null'
      :visible='visible'>
      <a-descriptions :column='3' bordered size='small'>
        <a-descriptions-item label="员工" :span='2'>
          {{ data.addBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label="考勤时间" :span='2'>
          {{ data.addTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('location')">
          {{ data.location }}
        </a-descriptions-item>
          <a-descriptions-item :label="$t('remark')" :span='24'>
            {{ data.remark }}
          </a-descriptions-item>
      </a-descriptions>

    </a-modal>

  </div>
</template>

<script>
import { cumAttendanceApi } from './api/CumAttendanceWorkFlow'
import { DetailHistory } from 'dbcp-frontends-common/lib/components'
import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'
import { ActionMixin } from 'dbcp-frontends-common/lib/mixins'

export default {
  name: 'CumAttendanceDetail',
  components: { DetailHistory },
  mixins: [DetailMixin, ActionMixin],
  data() {
    return {
      url: cumAttendanceApi.url
    }
  },
  methods: {
    view(data) {
      this.data = data
      this.visible = true
    }
  }
}
</script>
