<template>
  <div>
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="历史公告" force-render>
        <announcement-list></announcement-list>
      </a-tab-pane>
      <a-tab-pane key="2" tab="我创建的">
        <sys-announcement-list></sys-announcement-list>
      </a-tab-pane>
    </a-tabs>

  </div>

</template>

<script>

import '@assets/less/TableExpand.less'
import { SysAnnouncementList } from 'dbcp-frontends-common/lib/views'
import AnnouncementList from '@views/dbcp/publicPlatform/announcement/AnnouncementList'
export default {
  name: 'AnnouncementWorkflowViewList',
  components:{
    SysAnnouncementList,AnnouncementList
  }
}
</script>
<style scoped>
@import '~@assets/less/common.less'
</style>
