<template>
  <div>
    <!-- 选中为空 -->
    <div v-if='!props.node && !props.line && !props.multi'>
      <div class='bottom'>
        <div class='title'>小提示</div>
        <ul class='group'>
          <li>方向键：控制节点移动5个像素</li>
          <li>Ctrl + 方向键：控制节点移动1个像素</li>
          <li>Ctrl + 鼠标移动：移动整个画布</li>
          <li>Ctrl + 鼠标滚轮：缩放</li>
          <li>添加或选中节点，右侧属性支持上传各种图片哦</li>
        </ul>
      </div>
    </div>
    <!-- 选中节点 -->
    <div v-if='props.node'>
      <a-tabs default-active-key='1'>
        <a-tab-pane key='1' tab='流程配置'>
          <div class='items' v-if='props.node.name==="swimlaneH"'>
            <div class='flex grid'>
              <div class='ml5'>状态</div>
            </div>
            <a-row>
              <a-col :span='24'>
                <j-dict-select-tag placeholder='请选择附属类型' v-model='props.node.nodeData.value'
                                   dictCode='dbcp_workflow_status' :showManage='false' @change='handleStatusChange'
                                   style='width: 100%' :trigger-change='true' type='list' />
              </a-col>
            </a-row>
          </div>
          <div class='items' v-if='props.node.name==="swimlaneV"'>
            <div class='flex grid'>
              <div class='ml5'>角色</div>
            </div>
            <a-row>
              <a-col :span='24'>
                <j-dict-select-tag placeholder='请选择附属类型' v-model='props.node.nodeData.value'
                                   dictCode='workflow_node_remark' :showManage='false' @change='handleStatusChange'
                                   style='width: 100%' :trigger-change='true' type='list' />
              </a-col>
            </a-row>
          </div>
          <div v-if="props.node.name == 'rectangle'" class='items'>

            <a-radio v-model='props.node.isStart' label='0'>开始节点</a-radio>
            <a-radio v-model='props.node.isStart' label='1'>结束节点</a-radio>
          </div>
        </a-tab-pane>
        <a-tab-pane key='2' tab='位置和大小'>
          <div class='items'>
            <div class='flex grid'>
              <div>X（px）</div>
              <div class='ml5'>Y（px）</div>
            </div>
            <div class='flex grid'>
              <div>
                <a-input-number
                  v-model='props.node.rect.x'
                  @change='onChange'
                ></a-input-number>
              </div>
              <div class='ml5'>
                <a-input-number
                  v-model='props.node.rect.y'
                  @change='onChange'
                ></a-input-number>
              </div>
            </div>
          </div>
          <div class='items'>
            <div class='flex grid'>
              <div>宽（px）</div>
              <div class='ml5'>高（px）</div>
            </div>
            <div class='flex grid'>
              <div>
                <a-input-number
                  v-model='props.node.rect.width'
                  @change='onChange'
                ></a-input-number>
              </div>
              <div class='ml5'>
                <a-input-number
                  v-model='props.node.rect.height'
                  @change='onChange'
                ></a-input-number>
              </div>
            </div>
          </div>
          <div class='items' v-if='props.node.is3D'>
            <div class='flex grid'>
              <div>Z（px）</div>
            </div>
            <div class='flex grid'>
              <div>
                <a-input-number
                  v-model='props.node.z'
                  @change='onChange'
                ></a-input-number>
              </div>
            </div>
          </div>


          <div class='items'>
            <div class='flex grid'>
              <div title='百分比%对应的小数值'>圆角（0 - 1）</div>
              <div class='ml5'>旋转（°）</div>
            </div>
            <div class='flex grid'>
              <div>
                <a-input-number
                  v-model='props.node.borderRadius'
                  @change='onChange'
                  :min='0'
                  :max='1'
                  :step='0.1'
                ></a-input-number>
              </div>
              <div class='ml5'>
                <a-input-number
                  v-model='props.node.rotate'
                  @change='onChange'
                ></a-input-number>
              </div>
            </div>
          </div>
          <div class='items'>
            <div class='flex grid'>
              <div title='padding-left'>内边距-左</div>
              <div title='padding-right' class='ml5'>内边距-右</div>
            </div>
            <div class='flex grid'>
              <div>
                <a-input
                  size='small'
                  v-model='props.node.paddingLeft'
                  @change='onChange'
                ></a-input>
              </div>
              <div class='ml5'>
                <a-input
                  size='small'
                  v-model='props.node.paddingRight'
                  @change='onChange'
                ></a-input>
              </div>
            </div>
          </div>
          <div class='items'>
            <div class='flex grid'>
              <div title='padding-top'>内边距-上</div>
              <div title='padding-bottom' class='ml5'>内边距-下</div>
            </div>
            <div class='flex grid'>
              <div>
                <a-input
                  size='small'
                  v-model='props.node.paddingTop'

                  @change='onChange'
                ></a-input>
              </div>
              <div class='ml5'>
                <a-input
                  size='small'
                  v-model='props.node.paddingBottom'

                  @change='onChange'
                ></a-input>
              </div>
            </div>
          </div>
          <div class='items gray' style='line-height: 1.5'>
            内边距：输入数字表示像素；输入%表示百分比
          </div>
          <div class='title'></div>
          <div class='items'>
            <div class='flex grid'>
              <div class='custom-data'>自定义数据 <i :class="props.expand ? 'el-icon-zoom-out' : 'el-icon-zoom-in'"
                                                @click='changeExpand' size='small'>{{ props.expand ? '缩小' : '放大' }}</i>
              </div>
            </div>
            <div class='flex grid'>
              <div :class="props.expand ? 'expand-data' : ''">
                <a-input
                  type='textarea'
                  v-model='nodeData'
                  :rows='props.expand ? 15 : 3'
                  @change='onChange'
                ></a-input>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>


    </div>
  </div>
</template>

<script>
import JDictSelectTag from './dict/JDictSelectTag'

export default {
  components: {
    JDictSelectTag
  },
  data() {
    return {
      nodeId: null,
      nodeIsJson: false,
      nodeData: '',
      text:''
    }
  },
  props: {
    props: {
      type: Object,
      require: true
    }
  },
  watch:{
    props:{
      deep:true,
      handler:function(a){
        console.log(a)
      }
    }
  },
  updated() {
    if (!this.props.node || this.nodeId === this.props.node.id) {
      return
    }
    this.props.expand = false
    this.nodeId = this.props.node.id
    let originData = this.props.node.data
    this.nodeIsJson = this.isJson(originData)
    this.nodeData = this.nodeIsJson ?
      JSON.stringify(originData, null, 4) :
      this.nodeData = originData
  },
  methods: {
    onChange(value) {
      if (this.props.node) {
        this.props.node.data = this.nodeIsJson ? JSON.parse(this.nodeData) : this.nodeData
      }
      this.$emit('change', this.props.node)
    },
    changeExpand() {
      this.props.expand = !this.props.expand
    },
    isJson(obj) {
      return typeof (obj) == 'object' && Object.prototype.toString.call(obj).toLowerCase() == '[object object]' && !obj.length
    },
    handleStatusChange(data, data2) {
      this.props.node.text = data2
      this.$set(this.props.node.nodeData,'value',data)
      this.onChange()
    }
  }
}
</script>

<style lang='less' scoped>
.star {
  display: block;
  color: #f60 !important;
  text-decoration: underline;
  margin-bottom: 10px;
}

.title {
  color: #0d1a26;
  font-weight: 600;
  padding: 5px 15px;
  border-bottom: 1px solid #ccc;
}

.group {
  margin: 10px 0 20px 30px;
  padding: 0;

  a,
  li {
    line-height: 2;
  }

  li {
    list-style: initial;
  }
}

.bottom {
  position: absolute;
  top: 10px;
}

.items {
  padding: 5px 15px;

  .el-input-number {
    width: 102px;
    line-height: 32px;

    .el-input__inner {
      padding-left: 0;
      padding-right: 40px;
      height: 34px;
      line-height: 34px;
    }

    .el-input-number__increase {
      line-height: 16px;
    }
  }

  .custom-data i {
    cursor: pointer;
    float: right;
    color: #409eff;
    height: 200px;
    line-height: 200px;
  }

  .expand-data {
    position: absolute;
    right: 15px;
    width: 500px;
  }
}

.formItem {
  margin-bottom: 10px;
}

/deep/ .ant-select {
  width: 100% !important;
}
</style>
