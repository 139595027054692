<template>
  <a-modal
    :title="title"

    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk(false)"
    @cancel="handleCancel"
    :okButtonProps="{ props: {disabled: disabled} }"
    :cancelText="$t('cancel')">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-row style="width: 100%;">
          <a-col :span="24/2">
            <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              :label="$t('title')">
              <a-input :placeholder="$t('title')" v-decorator="['titile', validatorRules.title]"
                       :readOnly="disableSubmit"/>
            </a-form-item>
          </a-col>
<!--          ["26a0c2eb661e4372a452a61a06cf9388", "4b5d69e848594aae848649e38361250f"]-->
          <a-col :span="24/2">
            <a-form-item :label="$t('company')" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <company-selector v-decorator="['dept', validatorRules.dept,]" :multiple="'multiple'"
                                @input="selectCompany"></company-selector>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row style="width: 100%;">
          <a-col :span="24/2">
            <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              :label="$t('priority')">
              <a-select
                v-decorator="[ 'priority', {}]"
                :disabled="disableSubmit"
                :getPopupContainer="(target) => target.parentNode">
                <a-select-option value="L">{{ $t('low') }}</a-select-option>
                <a-select-option value="M">{{ $t('medium') }}</a-select-option>
                <a-select-option value="H">{{ $t('high') }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row style="width: 100%;">


        </a-row>
        <a-row style="width: 100%;">
          <a-col :span="24">
            <a-form-item
              :labelCol="labelColX1"
              :wrapperCol="wrapperColX1"
              :label="$t('content')"
              class="j-field-content">
              <j-editor v-decorator="[ 'msgContent', validatorRules.msgContent ]" triggerChange></j-editor>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row style="width: 100%">
          <a-col :span="12">
            <a-form-item :label="$t('attachments')" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <attachment v-decorator="['upload']" @handleFileRemove="handleFileRemove"
                          @beforeFileUpload="beforeFileUpload" :file-list="uploadFiles"
                          :default-file-list="defaultFileList"></attachment>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
    <select-user-list-modal ref="UserListModal" @choseUser="choseUser"></select-user-list-modal>
  </a-modal>
</template>

<script>
import { httpAction } from 'dbcp-frontends-common/lib/api'
import pick from 'lodash.pick'
import { getAction } from 'dbcp-frontends-common/lib/api'
import { JDate } from 'dbcp-frontends-common/lib/components'
import JEditor from '@comp/jeecg/JEditor'
import SelectUserListModal from '@views/system/modules/SelectUserListModal'
import CompanySelector from '@comp/dbcp/CompanySelector'
import { Attachment } from 'dbcp-frontends-common/lib/components'
import { UploadFormMixin } from 'dbcp-frontends-common/lib/mixins'
import moment from 'moment'

export default {
  components: { JEditor, JDate, SelectUserListModal, CompanySelector, Attachment },
  name: 'SysAnnouncementModal',
  mixins: [UploadFormMixin],
  data() {
    return {
      title: this.$i18n.tc('action'),
      visible: false,
      disableSubmit: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 }
      },
      labelColX1: {
        xs: { span: 24 },
        sm: { span: 3 }
      },
      wrapperColX1: {
        xs: { span: 24 },
        sm: { span: 21 }
      },

      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        title: { rules: [{ required: true, message: this.$i18n.tc('fieldEmptyHint') }] },
        msgCategory: { rules: [{ required: true, message: this.$i18n.tc('fieldEmptyHint') }] },
        msgAbstract: { rules: [{ required: true, message: this.$i18n.tc('fieldEmptyHint') }] },
        dept: { rules: [{ required: true, message: this.$i18n.tc('fieldEmptyHint') }] },
        msgContent: { rules: [{ required: true, message: this.$i18n.tc('fieldEmptyHint') }] }
      },
      url: {
        queryByIds: '/sys/user/queryByIds',
        add: '/sys/annountCement/add',
        edit: '/sys/annountCement/edit'
      },
      userType: false,
      userIds: [],
      selectedUser: [],
      disabled: false,
      msgContent: '',
      selectedComp: ''
    }
  },
  created() {
  },
  methods: {
    afterAdd() {
      this.edit({})
    },
    afterEdit(record) {
      this.model = {}
      this.disable = false
      this.getUser(record)
    },
    getUser(record) {
      this.model = Object.assign({}, record)
      if(record.dept){
        this.model.dept = record.dept.split(',')
      }
      console.log("this.model",this.model)
      // 指定用户
      if (record && record.msgType === 'USER') {
        this.userType = true
        this.userIds = record.userIds
        getAction(this.url.queryByIds, { userIds: this.userIds }).then((res) => {
          if (res.success) {
            for (var i = 0; i < res.result.length; i++) {
              this.selectedUser.push(res.result[i].realname)
            }
            this.$refs.UserListModal.edit(res.result, this.userIds)
          }
        })
      }
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'titile', 'msgContent', 'sender', 'priority', 'msgCategory', 'sendStatus', 'delFlag', 'msgAbstract', 'dept'))
      })
    },
    close() {
      this.$emit('close')
      this.selectedUser = []
      this.visible = false
    },
    processFormData(formData, data) {
      formData.set('dept', this.selectedComp)
      formData.set('msgType', 'USER')
      formData.set('msgCategory', 1)
      return formData
    },
    handleCancel() {
      this.visible = false
      this.$emit('close')
      this.resetUser()
    },
    resetUser() {
      this.userType = false
      this.userIds = []
      this.selectedUser = []
      this.disabled = false
      this.$refs.UserListModal.edit(null, null)
    },
    selectUserIds() {
      this.$refs.UserListModal.add(this.selectedUser, this.userIds)
    },
    chooseMsgType(value) {
      if ('USER' == value) {
        this.userType = true
      } else {
        this.userType = false
        this.selectedUser = []
        this.userIds = []
      }
    },
    // 子modal回调
    choseUser: function(userList) {
      this.selectedUser = []
      this.userIds = []
      for (var i = 0; i < userList.length; i++) {
        this.selectedUser.push(userList[i].realname)
        this.userIds += userList[i].id + ','
      }
    },
    //  选中公司
    selectCompany(comp) {
      this.selectedComp = comp.join(',')
    }
  }
}
</script>

<style scoped>

</style>
