<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div v-if='!showButtons'>
      <div class="table-page-search-wrapper" >
        <a-form layout="inline" @keyup.enter.native="searchQuery">
          <div :style='isBranch? "" : {display:"flex"}'>
          <a-row style='display: flex' >
            <a-col   v-if='showBuilding' style='margin-right:15px'>
              <a-form-item label="楼栋">
                <common-selector url='/cum/cumBuilding/list' showName='id' :showItem="['id']" title='楼栋选择器'
                                 v-model="queryParam.buildingId" :multi='false'></common-selector>
              </a-form-item>
            </a-col>
            <a-col style='margin-right:15px'>
              <a-form-item label="附属类型" style='width: 200px;'>
                <j-dict-select-tag placeholder="请选择附属类型" v-model="queryParam.type" dictCode="cum_surveyed_type" :showManage='false'/>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col >
              <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
                <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
                <!--                <a @click="handleToggleSearch" style="margin-left: 8px">-->
                <!--                  {{ toggleSearchStatus ? $t('collapse') : $t('expend') }}-->
                <!--                  <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>-->
                <!--                </a>-->
              </span>
            </a-col>
          </a-row>
          </div>
        </a-form>
      </div>
      <!-- 查询区域-END -->

      <!-- 操作按钮区域 -->
      <div class="table-operator">
        <a-button @click="handleAdd" type="primary" icon="plus" v-has="'cumBuildingSubsidiary:create'">{{ $t('create') }}</a-button>
<!--        <a-button type="primary" icon="download" @click="handleExportXls('楼栋附属管理')">{{ $t('export') }}</a-button>-->
<!--        <a-upload name="file" :showUploadList="false" :multiple="false" :headers="tokenHeader" :action="importExcelUrl" @change="handleImportExcel">-->
<!--          <a-button type="primary" icon="import">{{ $t('import') }}</a-button>-->
<!--        </a-upload>-->
        <a-dropdown v-if="selectedRowKeys.length > 0">
          <a-menu slot="overlay">
            <a-menu-item key="1" @click="batchDel"><a-icon type="delete"/>{{ $t('delete') }}</a-menu-item>
          </a-menu>
          <a-button style="margin-left: 8px"> {{ $t('batchOperation') }} <a-icon type="down" /></a-button>
        </a-dropdown>
      </div>
    </div>
    <a @click="handleShowButtons" style="margin-left: 8px">
      {{ showButtons ?  $t('expend') : $t('collapse') }}
      <a-icon :type="showButtons ?  'down' : 'up'"/>
    </a>

    <!-- table区域-begin -->
    <div>
      <setting-columns :columns='columns' @changeRealColumns='(data) => columns = data'></setting-columns>
      <a-table
        ref="table"
        :size="size"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type}"
        class="j-table-force-nowrap"
        @change="handleTableChange">

        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="imgSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noPicture') }}</span>
          <img v-else :src="getImgView(text)" height="25px" alt="" style="max-width:80px;font-size: 12px;font-style: italic;"/>
        </template>
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noFile') }}</span>
          <a-button
            v-else
            :ghost="true"
            type="primary"
            icon="download"
            size="small"
            @click="uploadFile(text)">
            {{ $t('download') }}
          </a-button>
        </template>

        <span slot="action" slot-scope="text, record">
           <div style='display: flex;justify-content: space-evenly;align-items: center'>
                <a @click='handleEdit(record)'>{{ $t('edit') }}</a>
             <a-divider type="vertical" />
                <list-actions
                  :record='record'
                  @view='handleView'
                  @edit='handleEdit'
                  @delete='handleDelete'
                  @action='$refs.detailForm.handleActionClick'
                  @copy='handleCopy'
                ></list-actions>
            </div>
        </span>

      </a-table>
    </div>


    <cumBuildingSubsidiary-modal ref="modalForm" @ok="modalFormOk"></cumBuildingSubsidiary-modal>
    <cumBuildingSubsidiary-detail ref="detailForm" @action="handleActionClick" @close="handleViewModalClose"
                                      @loadData="loadData" @clearRouteQuery="clearRouteQuery"></cumBuildingSubsidiary-detail>
  </a-card>
</template>

<script>
  import {  cumBuildingSubsidiaryApi } from './modules/api/CumBuildingSubsidiary'
  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
  import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
  import CumBuildingSubsidiaryModal from './modules/CumBuildingSubsidiaryModal'
  import CumBuildingSubsidiaryDetail from './modules/CumBuildingSubsidiaryDetail'
  import { ListActions } from 'dbcp-frontends-common/lib/components'
  import { SelectorMixin } from 'dbcp-frontends-common/lib/mixins'
  import { JDictSelectTag } from 'dbcp-frontends-common/lib/components'
  import {filterMultiDictText} from '@/components/dict/JDictSelectUtil'
  import { CommonSelector } from 'dbcp-frontends-common/lib/components'
  import { SettingColumns } from 'dbcp-frontends-common/lib/components'

  export default {
    name: "CumBuildingSubsidiaryList",
    mixins:[JeecgListMixin, mixinDevice, ViewMixin,SelectorMixin],
    props:{
      showBuilding:{
        type:Boolean,
        default:true
      },
      buildingId:{
        type:Number | String
      },
      size:{
        type:String,
        default: 'default'
      },
      //是否显示表上面的按钮
      showButtons:{
        type: Boolean,
        default: false
      },
      //是否表上按钮分行
      isBranch: {
        type: Boolean,
        default: false
      }
    },
    components: {
      JDictSelectTag,
      CumBuildingSubsidiaryModal,
      CumBuildingSubsidiaryDetail,
      ListActions,
      CommonSelector,
      SettingColumns
    },
    data () {
      return {
        description: '楼栋附属管理管理页面',
        // 表头
        columns: [
          {
            title: '#',
            dataIndex: '',
            key: 'rowIndex',
            width: 60,
            align: 'center',
            customRender: function (t,r,index) {
              return parseInt(index)+1;
            }
          },
          {
            title: '楼栋',
            align: 'center',
            dataIndex: 'buildingId',
            customRender:function(text){
              return "#" + text
            }
          },
          {
            title: '附属类型',
            align: 'center',
            dataIndex: 'type_dictText'
          },
          {
            title: '面积(㎡)',
            align: 'center',
            dataIndex: 'area'
          },
          {
            title: '更新人',
            align: 'center',
            dataIndex: 'updateBy_dictText',
            defaultHide:true
          },
          {
            title: '更新时间',
            align: 'center',
            dataIndex: 'updateTime',
            defaultHide:true
          },
          {
            title: this.$i18n.tc('action'),
            dataIndex: 'action',
            align: 'center',
            // fixed:"right",
            width: 147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: cumBuildingSubsidiaryApi.url,
        dictOptions:{},
        /* 禁用created列表数据加载 */
        disableMixinCreated: true
      }
    },
    watch:{
      buildingId(newV){
        console.log('newV',newV)
        this.queryParam.buildingId = newV
        this.dataSource = []
        this.loadData()
      }
    },
    created() {
      if(this.buildingId){
        this.queryParam.buildingId = this.buildingId
      }
      this.loadData()
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
    },
    methods: {
      initDictConfig(){
      },
      handleAdd: function (record) {
        if(this.buildingId){
          record.buildingId = this.buildingId
          this.$refs.modalForm.selectBuilding = false
        }

        this.$refs.modalForm.add(record);
        this.$refs.modalForm.title = this.$i18n.tc('create');
        this.$refs.modalForm.disableSubmit = false;
      },
      searchReset(){
        this.queryParam = {
          buildingId:this.buildingId
        }
        this.loadData(1);
      },
      handleShowButtons(){
        this.showButtons = !this.showButtons;
      }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>
