<template>
  <a-modal
    v-model='visible'
    width='70%'
    @ok='handleOk'
    @cancel='handleCancel'
  >
    <a-tabs default-active-key='1' @change='handleTabChange' v-model='activeKey'>
      <a-tab-pane key='1' tab='货币补偿标准'>
      </a-tab-pane>
      <a-tab-pane key='2' tab='产权置换标准'>
      </a-tab-pane>
    </a-tabs>
    <a-table :columns='realColumns' :dataSource='realDataSource' :pagination='false'>
      <template slot='periodicity' slot-scope='text,record,index'>
        <a-switch v-model='text' @change='(a) => handleRadioChange(a,index,"periodicity")'></a-switch>
      </template>
      <template slot='isAreaCalculation' slot-scope='text,record,index'>
        <a-switch v-model='text' @change='(a) => handleRadioChange(a,index,"isAreaCalculation")'></a-switch>
      </template>
      <template slot='compensationAmount' slot-scope='text,record,index'>
        <a-input-number v-model='text'
                        @change='(e) => handleInputChange(e,index,"compensationAmount")'></a-input-number>
      </template>
      <template slot='compensationRate' slot-scope='text,record,index'>
        <a-input-number v-model='text' @change='(e) => handleInputChange(e,index,"compensationRate")'></a-input-number>
      </template>

    </a-table>
  </a-modal>
</template>

<script>
import { ajaxGetDictItems, getAction } from 'dbcp-frontends-common/lib/api'
import { postAction } from 'dbcp-frontends-common/lib/api'

export default {
  name: 'CumStandardModal',
  data() {
    return {
      visible: false,
      activeKey: '1',
      propertyType: [],
      structureType: [],
      compensateMethodType: [],
      dataSource: {
        '1':[],
        '2':[]
      },
      currentProjectId: '',
      dataObj: {}
    }
  },
  computed: {
    realColumns() {
      let structureTypeLength = 0
      if (this.activeKey === '1') {
        structureTypeLength = 5
      } else {
        structureTypeLength = 6
      }
      // 房屋性质一项对应多少个结构类型再乘以5
      let structureTypeLengthAll = this.structureType.length * structureTypeLength

      let arr = [
        {
          title: '房屋性质',
          dataIndex: 'propertyTypeText',
          align: 'center',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {}
            }
            if (index === 0 || index % structureTypeLengthAll === 0) {
              obj.attrs.rowSpan = structureTypeLengthAll
            } else {
              obj.attrs.rowSpan = 0
            }
            return obj
          }
        },
        {
          title: '结构类型',
          dataIndex: 'structureTypeText',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {}
            }
            if (index === 0 || index % structureTypeLength === 0) {
              obj.attrs.rowSpan = structureTypeLength
            } else {
              obj.attrs.rowSpan = 0
            }
            return obj
          }
        },
        {
          title: '补偿类型',
          dataIndex: 'compensateTypeText',
          align: 'center'
        },
        {
          title: '非一次性支付',
          dataIndex: 'periodicity',
          align: 'center',
          scopedSlots: { customRender: 'periodicity' }
        },
        {
          title: '面积参与计算',
          dataIndex: 'isAreaCalculation',
          align: 'center',
          scopedSlots: { customRender: 'isAreaCalculation' }
        },
        {
          title: this.activeKey === '1' ? '补偿金额' : '置换比例',
          dataIndex: this.activeKey === '1' ? 'compensationAmount' : 'compensationRate',
          align: 'center',
          scopedSlots: { customRender: this.activeKey === '1' ? 'compensationAmount' : 'compensationRate' }
        }
      ]
      if(this.activeKey === '2'){
        arr = arr.filter(item => {
          return item.title !== '非一次性支付'
        })
      }
      return arr
    },
    realDataSource() {
      let structureTypeArr = [], structureTypeLength = 0

      let dataSource = this.dataSource[this.activeKey]

      if (this.activeKey === '1') {
        structureTypeArr = [
          {
            text: '代币补偿价格',
            value: '0'
          },
          {
            text: '装修补偿',
            value: '1'
          },
          {
            text: '附属物补偿',
            value: '2'
          },
          {
            text: '临时安置补助费',
            value: '3'
          },
          {
            text: '其它',
            value: '9'
          }
        ]
        structureTypeLength = 5
      } else {
        structureTypeArr = [
          {
            text: '产品置换面积',
            value: '4'
          },
          {
            text: '货币补偿面积',
            value: '5'
          },
          {
            text: '置换面积-毛胚',
            value: '6'
          },
          {
            text: '置换面积-精装',
            value: '7'
          },
          {
            text: '置换面积-商业',
            value: '8'
          },
          {
            text: '其它',
            value: '10'
          }
        ]
        structureTypeLength = 6
      }
      let length = this.propertyType.length * this.structureType.length * structureTypeLength
      let arr = []


      let propertyTypeNum = 0, structureTypeNum = 0
      for (let i = 0; i < length; i++) {
        if (i !== 0 && (i - this.structureType.length * structureTypeLength) % (this.structureType.length * structureTypeLength) === 0) {
          propertyTypeNum++
          structureTypeNum = 0
        }
        if (i !== 0 && (i - structureTypeLength) % structureTypeLength === 0 && i % (this.structureType.length * structureTypeLength) !== 0) {
          structureTypeNum++
        }




        let obj =
          {
            key: i,
            propertyTypeText: this.propertyType[propertyTypeNum] && this.propertyType[propertyTypeNum].text,
            propertyType: this.propertyType[propertyTypeNum] && this.propertyType[propertyTypeNum].value,
            structureTypeText: this.structureType[structureTypeNum] && this.structureType[structureTypeNum].text,
            structureType: this.structureType[structureTypeNum] && this.structureType[structureTypeNum].value,
            compensateTypeText: structureTypeArr[i % structureTypeLength].text,
            compensateType: structureTypeArr[i % structureTypeLength].value,
            isAreaCalculation: dataSource[i] && dataSource[i].isAreaCalculation,
            periodicity: dataSource[i] && dataSource[i].periodicity,
            compensationAmount: dataSource[i] && dataSource[i].compensationAmount,
            compensationRate: dataSource[i] && dataSource[i].compensationRate,
            compensateMethod: this.activeKey === '1' ? '0' : '1'
          }
        let temp = Object.assign({}, dataSource[i], obj)

        arr.push(temp)
      }
      this.dataObj[this.activeKey] = [...arr]
      return arr
    }
  },
  methods: {
    // 获取两个字典
    async getDict() {
      // 房屋性质
      let { result: propertyType } = await ajaxGetDictItems('cum_property_type')
      // 结构类型
      let { result: structureType } = await ajaxGetDictItems('cum_structure_type')
      // 补偿类型
      let { result: compensateMethodType } = await ajaxGetDictItems('cum_compensate_type')

      this.propertyType = propertyType

      this.structureType = structureType

      this.compensateMethodType = compensateMethodType
    },
    async show(id) {
      this.visible = true
      await this.getDict()
      this.getStandardData(id)

      this.currentProjectId = id
    },
    initDataSource() {
      let structureTypeLength = 0
      if (this.activeKey === '1') {
        structureTypeLength = 5
      } else {
        structureTypeLength = 6
      }
      let length = this.propertyType.length * this.structureType.length * structureTypeLength
      let arr = []
      for (let i = 0; i < length; i++) {
        if (this.activeKey === '1') {
          arr.push({
            isAreaCalculation: 0,
            periodicity: 0,
            compensationAmount: 0
          })
        } else {
          arr.push({
            isAreaCalculation: 0,
            periodicity: 0,
            compensationRate: 0
          })
        }
      }
      this.dataSource[this.activeKey] = arr
    },
    handleTabChange(key) {
      this.activeKey = key
      if(!this.dataSource[key] || this.dataSource[key].length === 0){
        this.initDataSource()
      }
    },
    handleRadioChange(checked, index, key) {
      let arr = [...this.dataSource[this.activeKey]]
      arr[index][key] = Number(checked)
      this.dataSource[this.activeKey] = arr
    },
    handleInputChange(e, index, key) {
      let arr = [...this.dataSource[this.activeKey]]
      arr[index][key] = e
      this.dataSource[this.activeKey] = arr
    },
    handleOk() {
      const formData = new FormData()
      // let obj = {
      //   cumCompensationStandardList:JSON.stringify(arr),
      //   projectId:this.currentProjectId
      // }

      let arr = [...this.dataObj['1']].concat([...this.dataObj['2'] || []])
      console.log('arr',arr)
      formData.set('projectId', this.currentProjectId)
      formData.set('cumCompensationStandardList', JSON.stringify(arr))
      postAction('/cum/cumCompensationStandard/addBatch', formData).then(res => {
        if (res.success) {
          this.visible = false
          this.handleCancel()
        }
      })
    },
    /**
     *  获取标准
     * @param id
     */
    getStandardData(id) {
      getAction('/cum/cumCompensationStandard/list', {
        projectId: id,
        pageSize: -1
      }).then(res => {
        if (res.success) {
          if (res.result.length > 0) {
            // 分成两个部分 金额 和 面积
            let jine = res.result.filter(item => item.compensateMethod === '0')
            let area = res.result.filter(item => item.compensateMethod === '1')
            this.dataSource = {
              '1':jine,
              '2':area
            }
          } else {
            this.initDataSource()
          }
        } else {
          this.initDataSource()

        }
      })
    },
    handleCancel(){
      this.activeKey = '1'
    }
  }
}
</script>

<style scoped>

</style>