<template>
  <j-select-biz-component :width="1000" v-bind="configs" v-on="$listeners" :returnId="true" :multiple="false" :is-dict="true"/>
  <!--  <j-select-biz-component-->
  <!--    :value="value"-->

  <!--    :name="$t('attendanceTime')"-->
  <!--    displayKey="id"-->
  <!--    :multiple="false"-->
  <!--    :returnKeys="returnKeys"-->
  <!--    :listUrl="url.list"-->
  <!--    :columns="columns"-->
  <!--    queryParamText="id"-->

  <!--    v-on="$listeners"-->
  <!--    v-bind="$attrs"-->
  <!--  />-->
</template>

<script>
import { JSelectBizComponent } from 'dbcp-frontends-common/lib/components'

export default {
  name: 'SalaryQueryFieldGroupSelector',
  components: { JSelectBizComponent },
  props: ['value'],
  data() {
    return {
      settings: {
        name: '薪资查询字段组',
        displayKey: 'name',
        returnKeys: ['id'],
        listUrl: '/dbcp/dbcpSalaryQueryFieldGroup/list',
        queryParamCode: 'name_like',
        queryParamText: '名字',
        columns: [
          {
            title: '组名称',
            align: 'center',
            dataIndex: 'name'
          },
          {
            title: '创建人',
            align: 'center',
            dataIndex: 'addBy_dictText'
          },
          {
            title: '创建时间',
            align: 'center',
            dataIndex: 'addTime'
          },
          {
            title: '更新人',
            align: 'center',
            dataIndex: 'updateBy_dictText'
          },
          {
            title: '更新时间',
            align: 'center',
            dataIndex: 'updateTime'
          },
        ],
      }
    }
  },
  computed: {
    configs() {
      return Object.assign({ value: this.value }, this.settings, this.$attrs)
    }
  }
}
</script>

<style lang="less" scoped></style>
