<template>
  <div>
    <a-modal
      :title="title"
      :width="width"
      placement="right"
      @cancel="close"
      :footer="null"
      :visible="visible">
      <a-descriptions :column="3" bordered size="small">
              <a-descriptions-item :label="$t('name')">
          {{ data.name }}
        </a-descriptions-item>
<!--        <a-descriptions-item :label="$t('deptId')">-->
<!--          {{ data.deptId_dictText }}-->
<!--        </a-descriptions-item>-->
        <a-descriptions-item :label="$t('owner')">
          {{ data.owner_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label="拆迁总监">
          {{ data.demolitionDirector_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label="拆迁副总">
          {{ data.demolitionDeputy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label="法务">
          {{ data.legalAffairs_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label="财务">
          {{ data.finance_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label="集团拆迁负责人">
          {{ data.demolitionOwner_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label="规划面积(m²)">
          {{ data.planArea }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('status')">
          {{ data.status_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addBy')">
          {{ data.addBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addTime')">
          {{ data.addTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateBy')">
          {{ data.updateBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateTime')" :span='3'>
          {{ data.updateTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('description')" :span='3'>
          {{ data.description }}
        </a-descriptions-item>
      </a-descriptions>

      <a-divider></a-divider>

      <a-tabs default-active-key="1">
        <a-tab-pane key="1">
          <template slot="tab">
            <a-badge :count="data.fileList && data.fileList.length || 0" :number-style="{ backgroundColor: '#52c41a' }" >
              <span style="padding:0 13px">{{$t('attachments')}}</span>
            </a-badge>
          </template>
          <files :files="data.fileList"></files>
        </a-tab-pane>
      </a-tabs>

    </a-modal>



  </div>
</template>

<script>
  import {  cumProjectApi } from './api/CumProjectWorkFlow'
  import { DetailHistory } from 'dbcp-frontends-common/lib/components'
  import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ActionMixin } from 'dbcp-frontends-common/lib/mixins'
  import Files from '../../../../../components/common/Files'

  export default {
    name: "CumProjectDetail",
    components: { DetailHistory ,Files },
    mixins: [ DetailMixin,ActionMixin ],
    data() {
      return {
        url: cumProjectApi.url,
      }
    },
    methods:{
      view(data){
        this.data = data
        this.visible = true
      }
    }
  }
</script>
