<template>
  <a-modal
    :title='title'
    width='60%'
    :visible='visible'
    @ok='handleOk'
    @cancel='handleCancel'
  >
    <a-form :form="form">
      <div v-if='formLabelArr.length>0'>
        <div v-for='item in formLabelArr' key='item.id'>
          <a-form-item :label='item.content' :labelCol="labelCol" :wrapperCol="wrapperCol">
            <div v-if='item.type === 0'>
              <a-input v-model="verifyInformation[item.content]">
              </a-input>
            </div>
            <div v-if='item.type === 1'>
              <a-textarea v-model="verifyInformation[item.content]"
              />
            </div>
          </a-form-item>
        </div>
        <div class='pageFooter'>
          <a-pagination
            show-size-changer
            :total="totalPage"
            @change="onPageChange"
            @showSizeChange="onShowSizeChange"
          />
        </div>
      </div>
    </a-form>


  </a-modal>
</template>

<script>
import { getAction, postAction } from 'dbcp-frontends-common/lib/api'
export default {
  name: 'RightHolderCheckedModal',
  data(){
    return {
      title: "权利人核实信息",
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      formLabelArr: [],
      //表单信息
      verifyInformation: {},
      model: {},
      totalPage: 0,
      recordData: {},

    }
  },
  methods: {
    show(record){
      console.log("record232",record)
      this.recordData = record ;
      getAction("/cum/cumVerificationTemplate/list",{ projectId: record.projectId , pageNo: 1}).then(res => {
        if(res.success){
          this.formLabelArr = res.result.records ;
          this.totalPage = res.result.total ;
          if(record.verifyInformation){
            let obj = JSON.parse(record.verifyInformation);
            this.verifyInformation = obj;

          }
          this.model.projectItemId = record.id;
          this.visible = true;
        }
      })
    },

    handleOk(){
      this.model.verifyInformation = JSON.stringify(this.verifyInformation);
      let formData = new FormData();
      for(let key in this.model){
        formData.set(key,this.model[key])
      }
      postAction("/cum/cumProjectItem/addVerificationInformation",formData).then(res => {
        console.log("res==>",res)
        if(res.success){
          this.handleCancel();
          this.$emit('ok')
        }
      })

    },
    handleCancel(){
      this.visible = false;
      this.verifyInformation = {};
      this.formLabelArr = [];
      this.recordData = {};
    },
    onPageChange(page, pageSize) {
      console.log(page, pageSize);
      getAction("/cum/cumVerificationTemplate/list",{ projectId: this.recordData.projectId , pageNo: page}).then(res => {
        if(res.success){
          this.formLabelArr = res.result.records ;
          this.totalPage = res.result.total ;
        }
      })
    },
    onShowSizeChange(current, pageSize){
      console.log(current, pageSize)
      getAction("/cum/cumVerificationTemplate/list",{ projectId: this.recordData.projectId , pageNo: 1 ,pageSize: pageSize}).then(res => {
        if(res.success){
          this.formLabelArr = res.result.records ;
        }
      })
    }
  }
}
</script>

<style scoped lang='less'>
.pageFooter {
  margin-right: 10%;
  display: flex;
  justify-content: end;
}
</style>