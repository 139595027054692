<template>
  <div ref="info">
    <a-spin :spinning="spinning">
      <a-carousel arrows class="banner" autoplay>
  <!--      箭头-->
  <!--      <div-->
  <!--        slot="prevArrow"-->
  <!--        slot-scope="props"-->
  <!--        class="custom-slick-arrow"-->
  <!--        style="left: 10px;zIndex: 1"-->
  <!--      >-->
  <!--        <a-icon type="left-circle"/>-->
  <!--      </div>-->
  <!--      <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">-->
  <!--        <a-icon type="right-circle"/>-->
  <!--      </div>-->
<!--        <div><img src="@/assets/images/banner/banner.jpg" alt="banner" class="banner-img" ref="imgInfo" :style="{height: picHeight+'px'}" @load="imageLoaded"></div>-->
<!--        <div><img src="@/assets/images/banner/banner1.jpg" alt="banner1" class="banner-img" :style="{height: picHeight+'px'}"></div>-->
<!--        <div><img src="@/assets/images/banner/banner2.jpg" alt="banner2" class="banner-img" :style="{height: picHeight+'px'}"></div>-->
<!--        <div><img src="@/assets/images/banner/banner3.jpg" alt="banner3" class="banner-img" :style="{height: picHeight+'px'}"></div>-->
<!--        <div><img src="@/assets/images/banner/banner4.jpg" alt="banner2" class="banner-img" :style="{height: picHeight+'px'}"></div>-->
<!--        <div><img src="@/assets/images/banner/banner5.jpg" alt="banner3" class="banner-img" :style="{height: picHeight+'px'}"></div>-->

        <div v-for='(item,index) in fileListx' :key='index'>
          <img :src="item.url" alt="banner3" class="banner-img" :style="{height: picHeight+'px'}">
        </div>

      </a-carousel>
    </a-spin>
  </div>
</template>

<script>
  import eventCommunicate from '@/assets/eventCommunicate'
  import { getAction } from 'dbcp-frontends-common/lib/api'
  import { dbcpHomeCarouselApi } from '@views/dbcp/dbcpHomeCarousel/modules/api/DbcpHomeCarousel'
  export default {
    name: 'Banner',
    data() {
      return {
        picHeight: '',
        spinning: true,
        fileListx:[],
      }
    },
    created() {
      //监听可视化窗口的宽度变化
      window.onresize =this.setBannerInfo;
      //创建页面时查询轮播图
      this.getFileList();
    },
    computed:{

    },
    methods: {
      //查询轮播图列表
      getFileList(){
        getAction(dbcpHomeCarouselApi.url.list,{
        }).then(res=>{
          this.fileListx=[]
          console.log("res",res,res.result.records.length)
          for (let i=0;i<res.result.records.length;i++){
            var element=res.result.records[i];

            this.fileListx.push({
              uid: element.id,
              name: element.id + "",
              status: 'done',
              url: element.fileList[0]!=null?element.fileList[0].path:null,
            })
          }
          console.log("this.fileListx:::",this.fileListx)
        })

      },
      imageLoaded(){
        this.spinning = false
      },
      setBannerInfo() {
        this.$nextTick(()=>{
          //设置轮播图高度
          const imgInfo = this.$refs.imgInfo && this.$refs.imgInfo.offsetWidth || 0;
          this.picHeight = imgInfo*5.0/8.0
          let elements = document.getElementsByClassName('slick-track')
          if(elements.length === 0){
            return
          }
          elements[0].style.height = this.picHeight+ 'px'

          //传递高度值给子组件Announcement自适应高度
          eventCommunicate.$emit('annHeight', this.picHeight)
        })

      },
    }
  }
</script>

<style scoped>
  .banner-img{
    width: 100%;
  }

  .banner{
    display: block;
    width: 100%;
  }

  .ant-carousel >>> .slick-slide {
    text-align: center;
    line-height: 160px;
    overflow: hidden;
  }

  .ant-carousel >>> .custom-slick-arrow {
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: #fff;
    opacity: 0.3;
  }
  .ant-carousel >>> .custom-slick-arrow:before {
    display: none;
  }
  .ant-carousel >>> .custom-slick-arrow:hover {
    opacity: 0.5;
  }

  .ant-carousel >>> .slick-slide h3 {
    color: #fff;
  }
</style>
