<template>
  <div>
    <a-modal
      :title="title"
      :width="width"
      placement="right"
      @cancel="close"
      :footer="null"
      :visible="visible">
      <a-descriptions :column="3" bordered size="small">
              <a-descriptions-item :label="$t('package')">
          {{ data.package }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('name')">
          {{ data.name }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('params')">
          {{ data.params }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('type')">
          {{ data.type }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('condition')">
          {{ data.condition }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('isAsyn')">
          {{ data.isAsyn }}
        </a-descriptions-item>
      </a-descriptions>



    </a-modal>
  </div>
</template>

<script>
  import {  dbcpWorkflowEventApi } from './api/DbcpWorkflowEventWorkFlow'
  import { DetailHistory } from "dbcp-frontends-common/lib/components";
  import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ActionMixin } from 'dbcp-frontends-common/lib/mixins'

  export default {
    name: "DbcpWorkflowEventDetail",
    components: { DetailHistory },
    mixins: [ DetailMixin,ActionMixin ],
    data() {
      return {
        url: dbcpWorkflowEventApi.url,
      }
    },
    methods:{
      view(data) {
        console.log('view', data)
        this.data = data
        this.visible = true
      },
    }
  }
</script>
