<template>
  <div style='position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%)'>
    <a-empty description='正在开发中' style='transform: scale(3)'></a-empty>
  </div>

</template>

<script>
export default {
  name: 'develop'
}
</script>

<style scoped>

</style>