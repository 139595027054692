<template>
  <a-card :bordered='false'>
    <!-- 查询区域 -->
    <div class='table-page-search-wrapper'>
      <a-form layout='inline' @keyup.enter.native='searchQuery'>
        <a-row :gutter='24'>
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <a-form-item label='流程名称'>
              <a-input placeholder='请输入流程名称' v-model='queryParam.name'></a-input>
            </a-form-item>
          </a-col>
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <a-form-item label='流程编码'>
              <a-input placeholder='请输入流程编码' v-model='queryParam.code'></a-input>
            </a-form-item>
          </a-col>
          <template v-if='toggleSearchStatus'>
            <a-col :xl='6' :lg='7' :md='8' :sm='24'>
              <a-form-item label='创建人'>
                <j-select-user-by-dep :hideDept="true" placeholder='请选择创建人' v-model='queryParam.addBy' />
              </a-form-item>
            </a-col>
            <a-col :xl='10' :lg='11' :md='12' :sm='24'>
              <a-form-item label='创建时间'>
                <j-date :show-time='true' date-format='YYYY-MM-DD HH:mm:ss' :placeholder="$t('selectStartTimeHint')"
                        class='query-group-cust' v-model='queryParam.addTime_begin'></j-date>
                <span class='query-group-split-cust'></span>
                <j-date :show-time='true' date-format='YYYY-MM-DD HH:mm:ss' :placeholder="$t('selectEndTimeHint')"
                        class='query-group-cust' v-model='queryParam.addTime_end'></j-date>
              </a-form-item>
            </a-col>
          </template>
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>查询</a-button>
              <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset')
                }}</a-button>
              <a @click='handleToggleSearch' style='margin-left: 8px'>
                {{ toggleSearchStatus ? $t('collapse') : $t('expend') }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'" />
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class='table-operator'>
      <a-button @click='handleAdd' type='primary' icon='plus' v-has="'dbcpWorkflowDesign:create'">{{ $t('create') }}
      </a-button>
      <!--      <a-button type="primary" icon="download" @click="handleExportXls('流程设计管理')">{{ $t('export') }}</a-button>-->
      <!--      <a-upload name="file" :showUploadList="false" :multiple="false" :headers="tokenHeader" :action="importExcelUrl" @change="handleImportExcel">-->
      <!--        <a-button type="primary" icon="import">{{ $t('import') }}</a-button>-->
      <!--      </a-upload>-->
      <a-dropdown v-if='selectedRowKeys.length > 0'>
        <a-menu slot='overlay'>
          <a-menu-item key='1' @click='batchDel'>
            <a-icon type='delete' />
            {{ $t('delete') }}
          </a-menu-item>
        </a-menu>
        <a-button style='margin-left: 8px'> {{ $t('batchOperation') }}
          <a-icon type='down' />
        </a-button>
      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>
      <!-- <approval-tab @change="handleGroupChange" permission="dbcpWorkflowDesign:view:all"></approval-tab> -->
      <setting-columns :columns='columns' @changeRealColumns='(data) => columns = data'></setting-columns>
      <a-table
        ref='table'
        size='middle'
        bordered
        rowKey='id'
        :columns='columns'
        :dataSource='dataSource'
        :pagination='ipagination'
        :loading='loading'
        :rowSelection='{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type}'
        class='j-table-force-nowrap'
        @change='handleTableChange'>

        <template slot='htmlSlot' slot-scope='text'>
          <div v-html='text'></div>
        </template>
        <template slot='imgSlot' slot-scope='text'>
          <span v-if='!text' style='font-size: 12px;font-style: italic;'>{{ $t('noPicture') }}</span>
          <img v-else :src='getImgView(text)' height='25px' alt=''
               style='max-width:80px;font-size: 12px;font-style: italic;' />
        </template>
        <template slot='fileSlot' slot-scope='text'>
          <span v-if='!text' style='font-size: 12px;font-style: italic;'>{{ $t('noFile') }}</span>
          <a-button
            v-else
            :ghost='true'
            type='primary'
            icon='download'
            size='small'
            @click='uploadFile(text)'>
            {{ $t('download') }}
          </a-button>
        </template>

        <span slot='action' slot-scope='text, record'>

          <div style='display: flex;justify-content: space-evenly'>
            <a @click='handleEdit(record)'>{{ $t('edit') }}</a>
                <a @click='handleShowVersion(record)'>设计</a>
            <a-dropdown>
               <a class="ant-dropdown-link">{{ $t('more') }} <a-icon type="down"/></a>
             <a-menu slot='overlay'>
               <a-menu-item>
                 <a @click='handlePublish(record)'>发布</a>
               </a-menu-item>
               <a-menu-item>
                    <a @click='handleShowRecord(record)'>记录</a>
               </a-menu-item>
               <a-menu-item>
                 <a-popconfirm :title="$t('confirmDeleteAlert')" @confirm='() => handleDelete(record.id)'>
                  <a>{{ $t('delete') }}</a>
                 </a-popconfirm>
               </a-menu-item>

             </a-menu>
           </a-dropdown>
            <!--                  <list-actions-->
            <!--                    :record='record'-->
            <!--                    @view='handleView'-->
            <!--                    @edit='handleEdit'-->
            <!--                    @delete='handleDelete'-->
            <!--                    @action='$refs.detailForm.handleActionClick'-->
            <!--                    @copy='handleCopy'-->
            <!--                  ></list-actions>-->



          </div>

        </span>

      </a-table>
    </div>

    <design-view ref='design'></design-view>

    <dbcpWorkflowDesign-modal ref='modalForm' @ok='modalFormOk'></dbcpWorkflowDesign-modal>
    <dbcpWorkflowDesign-detail ref='detailForm' @action='handleActionClick' @close='handleViewModalClose'
                               @loadData='loadData' @clearRouteQuery='clearRouteQuery'></dbcpWorkflowDesign-detail>
    <dbcp-workflow-design-version-list ref='versionList'></dbcp-workflow-design-version-list>
  </a-card>
</template>

<script>
import { dbcpWorkflowDesignApi } from './modules/api/DbcpWorkflowDesign'
import '@/assets/less/TableExpand.less'
import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
import { SettingColumns } from 'dbcp-frontends-common/lib/components'
import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
import DbcpWorkflowDesignModal from './modules/DbcpWorkflowDesignModal'
import DbcpWorkflowDesignDetail from './modules/DbcpWorkflowDesignDetail'
// import { ApprovalTab } from 'dbcp-frontends-common/lib/components'
import { ListActions } from 'dbcp-frontends-common/lib/components'
import { SelectorMixin } from 'dbcp-frontends-common/lib/mixins'
import { JDate } from 'dbcp-frontends-common/lib/components'
import { filterMultiDictText } from '@/components/dict/JDictSelectUtil'
import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'
import { getAction } from 'dbcp-frontends-common/lib/api'
import DesignView from './modules/DesignView'
import DbcpWorkflowDesignVersionList from './DbcpWorkflowDesignVersionList'

export default {
  name: 'DbcpWorkflowDesignList',
  mixins: [JeecgListMixin, mixinDevice, ViewMixin, ApprovalListMixin, SelectorMixin],
  components: {
    JDate,
    JSelectUserByDep,
    DbcpWorkflowDesignModal,
    DbcpWorkflowDesignDetail,
    ListActions,
    SettingColumns,
    DesignView,
    DbcpWorkflowDesignVersionList
  },
  data() {
    return {
      description: '流程设计管理管理页面',
      // 表头
      columns: [
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function(t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '流程名称',
          align: 'center',
          dataIndex: 'name'
        },
        {
          title: '流程编码',
          align: 'center',
          dataIndex: 'code'
        },
        {
          title: '创建人',
          align: 'center',
          dataIndex: 'addBy_dictText'
        },
        {
          title: '创建时间',
          align: 'center',
          dataIndex: 'addTime'
        },
        {
          title: '更新人',
          align: 'center',
          dataIndex: 'updateBy_dictText'
        },
        {
          title: '更新时间',
          align: 'center',
          dataIndex: 'updateTime'
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'status_dictText'
        },
        {
          title: this.$i18n.tc('action'),
          dataIndex: 'action',
          align: 'center',
          // fixed:"right",
          width: 200,
          scopedSlots: { customRender: 'action' }
        }
      ],
      url: dbcpWorkflowDesignApi.url,
      dictOptions: {}
    }
  },
  computed: {
    importExcelUrl: function() {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    }
  },
  methods: {
    initDictConfig() {
    },
    handleShowVersion(record) {
      getAction('/workflow/dbcpWorkflowDesign/queryVersionInfo', {
        id: record.id
      }).then(res => {
        if (res.success) {
          this.$refs.design.show(record.id, res.result)
        }

      })
    },
    /**
     * 发布
     */
    handlePublish(record) {

    },
    /**
     * 记录
     */
    handleShowRecord(record){
      this.$refs.versionList.show({ id: record.id })
    }
  }
}
</script>
<style scoped>
@import '~@assets/less/common.less';
</style>
