<template>
  <div>
    <a-modal
      :title="title"
      :width="width"
      placement="right"
      @cancel="close"
      :footer="null"
      :visible="visible">
      <a-descriptions :column="3" bordered size="small">
              <a-descriptions-item :label="$t('projectItemId')">
          {{ data.projectItemId }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('ownerId')">
          {{ data.ownerId_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('demolitionArea')">
          {{ data.demolitionArea }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('fetchableArea')">
          {{ data.fetchableArea }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('selectedRoomNo')">
          {{ data.selectedRoomNo }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('actualFetchArea')">
          {{ data.actualFetchArea }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('areaCompensationAmount')">
          {{ data.areaCompensationAmount }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('areaCompensationPaymentStatus')">
          {{ data.areaCompensationPaymentStatus }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('relocationConfirmed')">
          {{ data.relocationConfirmed }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addBy')">
          {{ data.addBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addTime')">
          {{ data.addTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateBy')">
          {{ data.updateBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateTime')" :span='3'>
          {{ data.updateTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('remark')" :span='3'>
          {{ data.remark }}
        </a-descriptions-item>
<!--        <a-descriptions-item :label="$t('deleted')">-->
<!--          {{ data.deleted }}-->
<!--        </a-descriptions-item>-->
      </a-descriptions>

        <!--   actions   -->
        <div class="detail-actions" v-if="data.action && Object.keys(data.action).length > 0">
          <a-divider></a-divider>
          <span v-for="action in Object.keys(data.action)">
            <a-button
              type="primary"
              @click="handleActionClick([data, action])"
              class="detail-actions-button"
            >
              {{ $t('actionCode["' + action + '"]') }}
            </a-button>
          </span>
        </div>

<!--      <a-divider></a-divider>-->
<!--      <detail-history :file-list="data.fileList" :history-list="histories"></detail-history>-->
    </a-modal>

    <a-modal
      :visible="actionFormVisible"
      :title="$t('actionCode[\'' + action +'\']')"
      @ok="performAction"
      @cancel="actionFormVisible = false"
    >
      <a-form>
        <a-form-item :label="$t('comment')">
          <a-textarea v-model="actionForm.comment"></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import {  cumMoveBackInfoApi } from './api/CumMoveBackInfoWorkFlow'
  import { DetailHistory } from 'dbcp-frontends-common/lib/components'
  import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ActionMixin } from 'dbcp-frontends-common/lib/mixins'

  export default {
    name: "CumMoveBackInfoDetail",
    components: { DetailHistory },
    mixins: [ DetailMixin,ActionMixin ],
    data() {
      return {
        url: cumMoveBackInfoApi.url,
      }
    },
    methods:{
      view(data){
        this.data = data ;
        this.visible = true ;
      }
    }
  }
</script>
