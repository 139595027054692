<template>
  <a-drawer
    :title="title"
    :width="width"
    placement="right"
    :closable="false"
    @close="close"
    :visible="visible">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item label="村属" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['village']" placeholder="请输入村属"></a-input>
        </a-form-item>
        <a-form-item label="房屋性质" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-dict-select-tag type="list" v-decorator="['propertyType', validatorRules.propertyType]" :trigger-change="true" dictCode="cum_property_type" placeholder="请选择房屋性质"/>
        </a-form-item>
        <a-form-item label="测绘面积" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['surveyedArea']" placeholder="请输入测绘面积" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="房屋编号" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['surveyNumber']" placeholder="请输入房屋编号"></a-input>
        </a-form-item>
        <a-form-item label="测量状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['surveyingStatus', validatorRules.surveyingStatus]" placeholder="请输入测量状态"></a-input>
        </a-form-item>
        <a-form-item label="所属地块" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['landNumber']" placeholder="请输入所属地块"></a-input>
        </a-form-item>
        <a-form-item label="权利人" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['rightHolder', validatorRules.rightHolder]" placeholder="请输入权利人"></a-input>
        </a-form-item>
        <a-form-item label="栋号" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['buildingNumber', validatorRules.buildingNumber]" placeholder="请输入栋号" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="总楼层" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['totalFloors', validatorRules.totalFloors]" placeholder="请输入总楼层" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="结构类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-dict-select-tag type="list" v-decorator="['structureType', validatorRules.structureType]" :trigger-change="true" dictCode="cum_structure_type" placeholder="请选择结构类型"/>
        </a-form-item>
        <a-form-item label="是否祖屋" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-dict-select-tag type="list" v-decorator="['ancestralHouse', validatorRules.ancestralHouse]" :trigger-change="true" dictCode="cum_ancestral_house" placeholder="请选择是否祖屋"/>
        </a-form-item>
        <a-form-item label="房产证类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-dict-select-tag type="list" v-decorator="['realEstateLicenseType', validatorRules.realEstateLicenseType]" :trigger-change="true" dictCode="cum_real_estate_license_type" placeholder="请选择房产证类型"/>
        </a-form-item>
        <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea v-decorator="['remark']" rows="4" placeholder="请输入备注"/>
        </a-form-item>

      </a-form>
    </a-spin>
    <a-button type="primary" @click="handleOk">{{ $t('confirm') }}</a-button>
    <a-button type="primary" @click="handleCancel">{{ $t('cancel') }}</a-button>
  </a-drawer>
</template>

<script>

  import {  cumBuildingApi } from './api/CumBuilding'
  import { httpAction } from 'dbcp-frontends-common/lib/api'
  import pick from 'lodash.pick'
  import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
  import { JDictSelectTag } from 'dbcp-frontends-common/lib/components'

  export default {
    name: "CumBuildingModal",
    components: {
      JDictSelectTag,
    },
    data () {
      return {
        form: this.$form.createForm(this),
        title: this.$i18n.tc('action'),
        width:800,
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules: {
          propertyType: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          surveyingStatus: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          rightHolder: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          buildingNumber: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          totalFloors: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          structureType: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          ancestralHouse: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          realEstateLicenseType: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
        },
        url: {
          add: "/cum/cumBuilding/add",
          edit: "/cum/cumBuilding/edit",
        }
      }
    },
    created () {
    },
    methods: {
      add () {
        this.edit({});
      },
      edit (record) {
        this.form.resetFields();
        this.model = Object.assign({}, record);
        this.visible = true;
        this.$nextTick(() => {
          this.form.setFieldsValue(pick(this.model,'village','propertyType','surveyedArea','surveyNumber','surveyingStatus','landNumber','rightHolder','buildingNumber','totalFloors','structureType','ancestralHouse','realEstateLicenseType','remark','addBy','addTime','updateBy','updateTime'))
        })
      },
      close () {
        this.$emit('close');
        this.visible = false;
      },
      handleOk () {
        const that = this;
        // 触发表单验证
        this.form.validateFields((err, values) => {
          if (!err) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
               method = 'put';
            }
            let formData = Object.assign(this.model, values);
            console.log("表单提交数据",formData)
            httpAction(httpurl,formData,method).then((res)=>{
              if(res.success){
                that.$message.success(res.message);
                that.$emit('ok');
              }else{
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
              that.close();
            })
          }

        })
      },
      handleCancel () {
        this.close()
      },
      popupCallback(row){
        this.form.setFieldsValue(pick(row,'village','propertyType','surveyedArea','surveyNumber','surveyingStatus','landNumber','rightHolder','buildingNumber','totalFloors','structureType','ancestralHouse','realEstateLicenseType','remark','addBy','addTime','updateBy','updateTime'))
      }

    }
  }
</script>

<style lang="less" scoped>
/** Button按钮间距 */
  .ant-btn {
    margin-left: 30px;
    margin-bottom: 30px;
    float: right;
  }
</style>
