<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- table区域-begin -->
    <div>
      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        class="j-table-force-nowrap"
        @change="handleTableChange">

        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="imgSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noPicture') }}</span>
          <img v-else :src="getImgView(text)" height="25px" alt="" style="max-width:80px;font-size: 12px;font-style: italic;"/>
        </template>
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noFile') }}</span>
          <a-button
            v-else
            :ghost="true"
            type="primary"
            icon="download"
            size="small"
            @click="uploadFile(text)">
            {{ $t('download') }}
          </a-button>
        </template>

        <span slot="action" slot-scope="text, record">
          <a @click="handleView(record)">{{ $t('view') }}</a>
        </span>
      </a-table>
      <announcement-workflow-view-detail ref="detailForm" @close="handleViewModalClose"></announcement-workflow-view-detail>
    </div>
  </a-card>
</template>

<script>

import '@assets/less/TableExpand.less'
import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
import {JeecgListMixin} from 'dbcp-frontends-common/lib/mixins'
import {ViewMixin} from 'dbcp-frontends-common/lib/mixins'
import AnnouncementWorkflowViewDetail
  from '@views/dbcp/publicPlatform/announcement/modules/AnnouncementWorkflowViewDetail'
export default {
  name: "AnnouncementList",
  mixins:[JeecgListMixin, mixinDevice, ViewMixin],
  components: {
    AnnouncementWorkflowViewDetail
  },
  data () {
    return {
      description: 'pending_tasks_workflow_view管理页面',
      // 表头
      columns: [
        {
          title: '#',
          dataIndex: '',
          key:'rowIndex',
          width:60,
          align: 'center',
          customRender:function (t,r,index) {
            return parseInt(index)+1;
          }
        },
        {
          title:'事项',
          align: 'center',
          dataIndex: 'titile'
        },
        {
          title:'发布人',
          align: 'center',
          dataIndex: 'sender_dictText'
        },
        {
          title:'发布时间',
          align: 'center',
          dataIndex: 'createTime',
          sorter: true,
          customRender:function (text) {
            return !text?"":(text.length>10?text.substr(0,10):text)
          }
        },
        {
          title: this.$i18n.tc('action'),
          dataIndex: 'action',
          align: 'center',
          // fixed:"right",
          width:147,
          scopedSlots: { customRender: 'action' }
        }
      ],
      url: {
        list: "/sys/sysAnnouncementSend/getDeptNotice",
        delete: "/oa/dbcp/pendingTasksWorkflowView/delete",
        deleteBatch: "/oa/dbcp/pendingTasksWorkflowView/deleteBatch",
        exportXlsUrl: "/oa/dbcp/pendingTasksWorkflowView/exportXls",
        importExcelUrl: "dbcp/pendingTasksWorkflowView/importExcel",
      },
      dictOptions:{},
      isorter: {
        column: 'sendTime',
        order: 'desc'
      },
    }
  },
  computed: {
    importExcelUrl: function(){
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
    },
  },
  methods: {
    initDictConfig(){
    },
    handleView(record){
      this.$refs.detailForm.detail(record)
    }
  }
}
</script>
<style scoped>
@import '~@assets/less/common.less';
</style>
