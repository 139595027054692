<template>
  <div style='position: relative;width: 100%;height: 100%'>
    <template v-if='canEdit'>
      <a-button @click='handleChange' type='primary' style='position: absolute;z-index: 99999'>编辑</a-button>
      <div id='toolControl'>
        <div v-show='actionMode === 2'>
          <div class='toolItem' id='polygon' title='多边形'></div>
          <div class='toolItem' id='circle' title='圆形'></div>
        </div>
        <div v-show='actionMode === 1'>
          <div class='toolItem' id='delete' onclick='editor.delete();' title='删除'></div>
          <!--        <div class='toolItem' id='split' onclick='editor.split();' title='拆分'></div>-->
          <!--        <div class='toolItem' id='union' onclick='editor.union();' title='合并'></div>-->
        </div>
      </div>
    </template>
    <div class='edit-position' v-if='canEdit'>
      <a class='ant-dropdown-link' @click='showEdit'>
        <a-button>手动添加/编辑
          <a-icon type='down' />
        </a-button>
      </a>
    </div>
    <div class='edit-position-table' v-show='editTableVisible'>
      <a-button @click='handleAddPosition'  type='primary'  v-if='(!currentDrawObj.buildingId && type === "building") || (!currentDrawObj.id && type === "project")'>添加</a-button>
      <a-table bordered
               rowKey='id'
               :columns='columns'
               :dataSource='realDataSource'
               :pagination='false'
               size='small'
      >
        <template
          v-for="col in ['lat', 'lng']"
          :slot='col'
          slot-scope='text, record, index'
        >
          <div :key='col'>
            <a-input
              @focus='handleInputFocus'
              @blur='handleInputBlur'
              style='margin: -5px 0'
              :value='text'
              @change='e => handleInputChange(e.target.value, record.id, col)'
              :disabled='!canEdit'
            />
            <!--              <template v-else>-->
            <!--                {{ text }}-->
            <!--              </template>-->
          </div>
        </template>
        <template slot='operation' slot-scope='text, record, index'>
          <div class='editable-row-operations'>
            <!--                  <span v-if='record.editable'>-->
            <!--          &lt;!&ndash;          <a @click="() => save(record.key)">Save</a>&ndash;&gt;-->
            <!--                    &lt;!&ndash;          <a-popconfirm title="Sure to cancel?" @confirm="() => cancel(record.key)">&ndash;&gt;-->
            <!--                    &lt;!&ndash;            <a>Cancel</a>&ndash;&gt;-->
            <!--                    &lt;!&ndash;          </a-popconfirm>&ndash;&gt;-->
            <!--                  </span>-->
            <!--              <span v-else>-->
            <!--            <a :disabled="editingKey !== ''" @click='edit(record.id)'>Edit</a>-->
            <!--        </span>-->
            <a @click='handleAdd' v-if='index === realDataSource.length - 1'>添加</a>
          </div>
        </template>
      </a-table>
    </div>
    <div class='change-base-map'>
      <a-radio-group default-value='satellite' button-style='solid' @change='handleBaseMapChange'>
        <a-radio-button value='vector'>
          默认
        </a-radio-button>
        <a-radio-button value='satellite'>
          卫星
        </a-radio-button>
      </a-radio-group>
    </div>


    <div id='container' style='width:100%;height:calc(100vh - 150px);'></div>
    <div class='detail-info' v-if='detailShow'>
      <a-tabs default-active-key='1'>
        <a-tab-pane key='1' tab='测绘信息'>
          <div class='img-box' @click='showImg'>
            <img :src='detailImg' alt='' style='width: 100%;'>
          </div>
          <a-descriptions :column='2' bordered size='small' layout='vertical'>
            <a-descriptions-item :label="$t('village')">
              {{ detailData.village }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('propertyType')">
              {{ detailData.propertyType_dictText }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('surveyedArea')">
              {{ detailData.surveyedArea }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('surveyNumber')">
              {{ detailData.surveyNumber }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('surveyingStatus')">
              {{ detailData.surveyingStatus_dictText }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('landNumber')">
              {{ detailData.landNumber }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('rightHolder')">
              {{ detailData.rightHolder_dictText }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('buildingNumber')">
              {{ detailData.buildingNumber }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('totalFloors')">
              {{ detailData.totalFloors }}
            </a-descriptions-item>
            <a-descriptions-item label="产权证建筑面积">
              {{ detailData.certificateTitleArea }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('structureType')">
              {{ detailData.structureType_dictText }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('ancestralHouse')">
              {{ detailData.ancestralHouse_dictText }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('realEstateLicenseType')">
              {{ detailData.realEstateLicenseType_dictText }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('remark')">
              {{ detailData.remark }}
            </a-descriptions-item>
          </a-descriptions>
        </a-tab-pane>
        <a-tab-pane key='2' tab='楼栋附属'>
          <cum-building-subsidiary-list :buildingId='currentSelectId'
                                        :show-building='false' size='small' :show-buttons='true'
                                        :isBranch='true'></cum-building-subsidiary-list>
        </a-tab-pane>
        <a-tab-pane key='3' tab='单元信息'>
          <div style='width: 100%'>
            <cum-unit-list :buildingId='currentSelectId' :show-building='false' size='small'
                           :show-buttons='true'></cum-unit-list>
          </div>

        </a-tab-pane>
      </a-tabs>
    </div>
    <div class='detail-info' v-if='projectDetailShow'>
      <a-descriptions :column='2' bordered size='small'>
        <a-descriptions-item :label="$t('name')">
          {{ projectData.name }}
        </a-descriptions-item>
        <!--        <a-descriptions-item :label="$t('deptId')">-->
        <!--          {{ projectData.deptId_dictText }}-->
        <!--        </a-descriptions-item>-->
        <a-descriptions-item :label="$t('owner')">
          {{ projectData.owner_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label='拆迁总监'>
          {{ projectData.demolitionDirector_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label='拆迁副总'>
          {{ projectData.demolitionDeputy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label='法务'>
          {{ projectData.legalAffairs_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label='财务'>
          {{ projectData.finance_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label='集团拆迁负责人'>
          {{ projectData.demolitionOwner_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label='规划面积(m²)'>
          {{ projectData.planArea }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('status')">
          {{ projectData.status_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addBy')">
          {{ projectData.addBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addTime')">
          {{ projectData.addTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateBy')" :span='2'>
          {{ projectData.updateBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateTime')" :span='2'>
          {{ projectData.updateTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('description')" :span='3'>
          {{ projectData.description }}
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <div class='sub-info'>
      <div class='info'>
        <div class='color' style='background-color: rgba(3,252,72,0.6)'></div>
        <div class='title'>已签约</div>
      </div>
      <div class='info'>
        <div class='color' style='background-color: rgba(215,9,9,0.6)'></div>
        <div class='title'>未签约</div>
      </div>
      <div class='info' style='flex:1'>
        <div class='color' style='background-color: rgba(101,122,245,0.3);border-color: #00FFFF'></div>
        <div class='title'>项目</div>
      </div>
      <div class='info' style='flex:2;justify-content: space-evenly'>
        <div>
          项目
          <a-switch size='small' checkedChildren='显示' unCheckedChildren='隐藏' @change='handleProjectChange'
                    default-checked />
        </div>
        <div>
          楼栋
          <a-switch size='small' checkedChildren='显示' unCheckedChildren='隐藏' @change='handleBuildingChange'
                    default-checked />
        </div>

      </div>
    </div>
    <a-modal v-model='imgShow' class='img-model' :width='1000' :footer='null' style='position: relative'>
      <a-spin style='position: absolute;left: 50%;top: 50%;z-index: 99999999999'
              v-if='modelVisibleNum !== 2 && detailModelList[0]' />
      <a-empty v-if='!detailImgList[0]' />
      <a-carousel arrows dots-class='slick-dots slick-thumb' v-if='imgShow'>
        <a slot='customPaging' slot-scope='props'>
          <img src='' v-if='props.i === 0 && detailModelList[0]' />
          <img :src='detailImgList[props.i - 1]' v-if='props.i >= 1 && detailModelList[0]' />
          <img :src='detailImgList[props.i]' v-if='!detailModelList[0]' />
        </a>
        <model-obj :src='detailModelList[0]' @on-load='handleModelLoad'></model-obj>
        <div v-for='item in detailImgList' style='cursor: pointer'>
          <viewer :images='detailImgList'>
            <img :src='item' />
          </viewer>

        </div>
      </a-carousel>

    </a-modal>

    <add-position ref='addForm' @ok='handleOk'></add-position>
  </div>
</template>

<script>
import { getAction, postAction } from 'dbcp-frontends-common/lib/api'
import CumBuildingSubsidiaryList from '../views/dbcp/city/buildingSubsidiaryManagement/CumBuildingSubsidiaryList'
import CumUnitList from '../views/dbcp/city/propertyManagement/unitList/CumUnitList'
import { Modal } from 'ant-design-vue'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
import { ModelObj } from 'vue-3d-model'
import AddPosition from './AddPosition'

const data = [
  {
    id: 0,
    lng: '',
    lat: ''
  }
]

Vue.use(VueViewer)
export default {
  name: 'CityMap',
  components: {
    CumBuildingSubsidiaryList,
    CumUnitList,
    ModelObj,
    AddPosition
  },
  props: {
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    this.cacheData = data.map(item => ({ ...item }))
    return {
      visible: false,
      map: null,
      editor: null,
      activeType: '',
      actionMode: 2, // 1为编辑 2为画画
      currentDrawObj: {},
      currentId: '',
      //详情页是否展示
      detailShow: false,
      // 项目详情页是否展示
      projectDetailShow: false,
      //  所有楼栋数据
      allBuilding: [],
      // 所有项目数据
      allProject: [],
      //  当前选中的id
      currentSelectId: '',
      //  楼栋附属
      buildingData: {},
      //  项目详情
      projectData: {},
      // 可以编辑的地图数组
      canEditArr: [],
      //  判断是项目 还是 楼栋
      type: 'building',
      detailData: {},
      imgShow: false,
      polygon: null,
      polygon1: null,
      //  地图中心点
      mapCenter: null,
      modelVisibleNum: 0,
      columns: [
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function(t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '经度',
          align: 'center',
          dataIndex: 'lng',
          scopedSlots: { customRender: 'lng' }
        },
        {
          title: '纬度',
          align: 'center',
          dataIndex: 'lat',
          scopedSlots: { customRender: 'lat' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: data,
      editingKey: '',
      editTableVisible: false,
      //  光标是否在input上
      isFocus: false
    }
  },
  computed: {
    // 判断在地图上有没有当前id数据
    currentDraw() {
      return this.allBuilding.filter(item => {
        return item.id === this.currentId
      })[0]
    },
    detailImg() {
      if (this.detailData && this.detailData.fileList) {
        let img = this.detailData.fileList.filter(item => item.classify === 'img')[0]
        if (img) {
          return img.path
        } else {
          // return require('@/assets/images/logo_leiweicity.png')
          return this.$store.state.config.loginLogo
        }
      }

    },
    detailImgList() {
      let arr = []
      if (this.detailData && this.detailData.fileList) {
        this.detailData.fileList.forEach(item => {
          if (item.classify === 'img') {
            arr.push(item.path)
          }
        })
      }
      return arr
    },
    detailModelList() {
      let arr = []
      if (this.detailData && this.detailData.fileList) {
        this.detailData.fileList.forEach(item => {
          if (item.classify === 'model') {
            arr.push(item.path)
          }
        })
      }
      return arr
    },
    realDataSource() {
      if (this.currentDrawObj.paths) {
        let arr = [...this.currentDrawObj.paths]
        arr.forEach((item, index) => {
          item.id = index
        })
        return arr
      } else {
        return []
      }
    }
  },
  methods: {
    /**
     * 初始化地图
     */
    initMap() {
      this.map = new TMap.Map(document.getElementById('container'), {
        zoom: 18,               //设置地图缩放级别
        center: this.mapCenter,      //设置中心点样式
        baseMap: {  // 设置卫星地图
          type: 'satellite'
        }
      })


//初始化polygon
      var polygon = new TMap.MultiPolygon({
        id: 'polygon-layer', //图层id
        map: this.map, //设置多边形图层显示到哪个地图实例中
        zIndex: 99999,
        //多边形样式
        styles: {
          'default': new TMap.PolygonStyle({
            'color': 'rgba(3,252,72,0.6)', //面填充色
            'showBorder': true, //是否显示拔起面的边线
            'borderColor': 'rgba(3,252,72,0.6)' //边线颜色
          }),
          'select': new TMap.PolygonStyle({
            'color': '#eeeeee', //面填充色
            'showBorder': true, //是否显示拔起面的边线
            'borderColor': '#00FFFF' //边线颜色
          }),
          'noCheck': new TMap.PolygonStyle({
            'color': 'rgba(215,9,9,0.6)', //面填充色
            'showBorder': true, //是否显示拔起面的边线
            'borderColor': 'rgba(215,9,9,0.6)' //边线颜色
          })
        },
        //多边形数据
        geometries: [
          ...this.allBuilding
        ]
      })
      // 项目图层
      var polygon1 = new TMap.MultiPolygon({
        id: 'polygon-layer-1', //图层id
        map: this.map, //设置多边形图层显示到哪个地图实例中
        zIndex: 10,
        //多边形样式
        styles: {
          'select': new TMap.PolygonStyle({
            'color': 'rgba(245,217,38,0.3)', //面填充色
            'borderColor': '#EEEEEE' //边线颜色
          }),
          'project': new TMap.PolygonStyle({
            'color': 'rgba(101,122,245,0.3)', //面填充色
            'borderColor': '#00FFFF' //边线颜色
          }),
          'current': new TMap.PolygonStyle({
            'color': 'rgba(215,9,9,0.6)', //面填充色
            'borderColor': '#00FFFF' //边线颜色
          })
        },
        //多边形数据
        geometries: [
          ...this.allProject
        ]
      })

      this.registerEditor()

      polygon.on('click', (e) => {
        console.log(e)
        // 清空编辑层所有选中
        this.editor.select([])
        this.allBuilding.forEach(item => {
          if (item.id === e.geometry.id) {
            if (item.status !== '3') {
              item.styleId = item.styleId === 'select' ? 'noCheck' : 'select'
            } else {
              item.styleId = item.styleId === 'select' ? 'default' : 'select'
            }

          } else {
            if (item.status !== '3') {
              item.styleId = 'noCheck'
            } else {
              item.styleId = 'default'
            }
          }
        })

        this.allProject.forEach(item => {
          item.styleId = 'project'
        })

        if (e.geometry.isBuilding && e.geometry.styleId === 'select') {
          this.detailShow = true
          this.getDetailById(e.geometry.id)
          this.projectDetailShow = false
        } else {
          this.detailShow = false
        }
        console.log('qi', this.allBuilding)
        // let temp = e.geometry
        // temp.styleId = temp.styleId === "select" ? "defalut" : "select"
        polygon.updateGeometries(this.allBuilding)
        polygon1.updateGeometries(this.allProject)
      })

      polygon1.on('click', (e) => {
        this.detailShow = false

        // // 清空所有选中
        this.editor.select([])
        console.log('qi', this.allProject, e)
        this.allProject.forEach(item => {
          if (item.projectId === e.geometry.projectId) {
            item.styleId = item.styleId === 'select' ? 'project' : 'select'
          } else {
            this.projectDetailShow = false
            item.styleId = 'project'
          }
        })
        this.allBuilding.forEach(item => {
          if (item.status !== '3') {
            item.styleId = 'noCheck'
          } else {
            item.styleId = 'default'
          }
        })
        if (!e.geometry.isBuilding && e.geometry.styleId === 'select') {
          this.getProjectDetailById(e.geometry.projectId)
          this.projectDetailShow = true
        } else {
          this.projectDetailShow = false
        }
        polygon1.updateGeometries(this.allProject)
        polygon.updateGeometries(this.allBuilding)
      })

      this.polygon = polygon
      this.polygon1 = polygon1
    },
    /**
     * 点击编辑按钮
     */
    handleChange() {
      this.actionMode === 1 ? this.actionMode = 2 : this.actionMode = 1
      this.editor.setActionMode(this.actionMode)
    },

    /**
     * 通过id 查询详情
     * @param id 楼栋id
     */
    getDetailById(id) {
      console.log('id', id)
      getAction('/cum/cumBuilding/list', {
        id
      }).then(res => {
        if (res.success) {
          // this.$set(this,'detailData',res.result.records[0])
          this.detailData = res.result.records[0] || {}
          // this.detailData = JSON.parse(JSON.stringify(res.result.records[0]))

        }
      })
    },
    getProjectDetailById(id) {
      getAction('/cum/cumProject/list', {
        id
      }).then(res => {
        if (res.success) {
          this.projectData = res.result.records[0] || {}
        }
      })
    },
    // 查询
    getBuildingSurveyed() {
      getAction('/cum/cumProjectSurveyed/queryList').then(res => {
        if (res.success) {
          let arr = []
          let canEditArr = []
          // 楼栋数据处理
          res.result.buildingSurveyedList.forEach((item, index) => {
            let obj = {}
            obj.id = item.buildingId
            // obj.rank = 100
            obj.isBuilding = true
            obj.status = item.status
            let temp = []
            item.paths.forEach(pathItem => {
              temp.push(new TMap.LatLng(pathItem.lat, pathItem.lng))
            })
            obj.paths = temp
            if (this.canEdit) {
              /**
               * 表示已经测绘过 并且 该类型是楼栋
               */
              if (item.buildingId === this.currentId && this.type === 'building') {
                this.currentDrawObj = {
                  buildingId: item.buildingId,
                  paths: item.paths
                }
                obj.styleId = 'current'
                canEditArr = [obj]
                console.log(' this.currentDrawObj', this.currentDrawObj)
              } else {

                if (item.status !== '3') {
                  obj.styleId = 'noCheck'
                } else {
                  obj.styleId = 'default'
                }
                console.log(obj)
                arr.push(obj)
              }
            } else {
              if (item.status !== '3') {
                obj.styleId = 'noCheck'
              } else {
                obj.styleId = 'default'
              }
              arr.push(obj)
            }
          })

          // 项目数据处理
          let projectArr = []
          res.result.projectSurveyedList.forEach(item => {
            let obj = {}
            obj.isBuilding = false
            obj.projectId = item.projectId
            obj.id = item.polygonId
            let temp = []
            item.paths.forEach(pathItem => {
              temp.push(new TMap.LatLng(pathItem.lat, pathItem.lng))
            })
            obj.paths = temp
            console.log(this.canEdit, this.type, item.projectId, this.currentId)
            if (this.canEdit) {
              /**
               * 表示已经测绘过 并且 该类型是项目
               */
              if (this.type === 'project' && item.projectId === this.currentId) {
                this.currentDrawObj = {
                  id: item.polygonId,
                  paths: item.paths
                }
                obj.styleId = 'current'
                canEditArr.push(obj)
              } else {
                obj.styleId = 'project'
                projectArr.push(obj)
              }
            } else {
              projectArr.push(obj)
            }
          })

          // 分为3个图层 可编辑 楼栋  项目
          this.canEditArr = canEditArr
          console.log('this.canEditArr',canEditArr)
          this.allProject = projectArr
          this.allBuilding = arr
          console.log('this.allBuilding', this.allBuilding)
          this.setMapCenter()
          this.initMap()
          this.$nextTick(() => {
            console.log('this.editor', this.editor)
            //  设置激活状态
            if (this.currentId && this.currentDrawObj) {
              this.actionMode = 1
              this.editor.setActionMode(this.actionMode)
              if (this.type === 'building') {
                this.editor.select([this.currentDrawObj.buildingId])
              } else {
                this.editor.select([this.currentDrawObj.id])
              }
            }
          })
        }

      }).catch(err => {
        console.error(err)
      })
    },

    handleCancel() {
      this.activeType = ''
      this.detailShow = false
      this.currentDrawObj = {}
      this.editor.destroy()
      this.map.destroy()
      Modal.destroyAll()
    },
    show(record, type = 'building') {
      this.type = type

      this.getBuildingSurveyed()
      // 如果不能编辑
      if (!this.canEdit) {
        return
      }

      this.currentId = record.id
      if (this.type === 'building') {
        this.getDetailById(record.id)
      }

      this.$nextTick(() => {
        document.getElementById('toolControl').addEventListener('click', (e) => {
          let id = e.target.id
          if (id !== 'toolControl') {
            if (this.activeType !== '') {
              document.getElementById(this.activeType).className = 'toolItem'
            }
            document.getElementById(id).className = 'toolItem active'
            this.activeType = id
            this.editor.setActiveOverlay(id)
          }
        })
      })
    },
    showImg() {
      this.modelVisibleNum = 0
      this.imgShow = true
    },
    handleProjectChange(check) {
      this.polygon1.setVisible(check)
    },
    handleBuildingChange(check) {
      this.polygon.setVisible(check)
    },
    /**
     * 设置地图中心点
     */
    setMapCenter() {
      // new TMap.LatLng(22.537222709099595, 114.02624022396088)
      if (this.canEdit) {
        if (this.canEditArr[0]) {
          this.mapCenter = new TMap.LatLng(this.canEditArr[0].paths[0].lat, this.canEditArr[0].paths[0].lng)
        } else {
          this.mapCenter = new TMap.LatLng(22.537222709099595, 114.02624022396088)
        }
      } else {
        if (this.type === 'building') {
          if (this.allBuilding[0]) {
            this.mapCenter = new TMap.LatLng(this.allBuilding[0].paths[0].lat, this.allBuilding[0].paths[0].lng)
          } else {
            this.mapCenter = new TMap.LatLng(22.537222709099595, 114.02624022396088)
          }

        } else {
          if (this.allBuilding[0]) {
            this.mapCenter = new TMap.LatLng(this.allProject[0].paths[0].lat, this.allProject[0].paths[0].lng)
          } else {
            this.mapCenter = new TMap.LatLng(22.537222709099595, 114.02624022396088)
          }

        }

      }
    },
    handleBaseMapChange(e) {
      this.map.setBaseMap({
        type: e.target.value
      })
    },
    handleModelLoad(e) {
      this.modelVisibleNum++
    },
    /**
     * 表格输入事件
     */
    handleInputChange(value, key, column) {
      const newData = [...this.realDataSource]
      const target = newData.filter(item => key === item.id)[0]
      if (target) {
        target[column] = value
      }
      if (this.type === 'building') {

        this.canEditArr[0].paths = []
        // console.log('getActiveOverlay',this.editor.getActiveOverlay())
        newData.forEach(item => {
          this.canEditArr[0].paths.push(new TMap.LatLng(item.lat, item.lng))
        })

        this.editor.select([this.currentDrawObj.buildingId])
        this.editor.delete()
        this.editor.removeOverlay('polygon')

        console.log('this.canEditArr',this.canEditArr)
        this.editor.addOverlay({
          overlay: new TMap.MultiPolygon({
            map: this.map,
            zIndex: 100,
            styles: {
              highlight: new TMap.PolygonStyle({
                color: 'rgba(255, 255, 0, 0.6)'
              }),
              current: new TMap.PolygonStyle({
                color: 'rgba(255, 255, 0, 0.6)',
                borderColor: 'rgba(255, 255, 0, 0.8)'
              })
            },
            geometries: [
              ...this.canEditArr
            ]
          }),
          id: 'polygon',
          selectedStyleId: 'highlight'
        })
        this.editor.setActiveOverlay('polygon')
        this.actionMode = 1
        this.editor.setActionMode(this.actionMode)
        console.log('this.editor.select([this.currentDrawObj.id])',this.currentDrawObj.buildingId)
        this.editor.select([this.currentDrawObj.buildingId])
      } else {
        let cacheCurrentId = this.currentDrawObj.id
        let arr = []
        let idArr = []
        newData.forEach(item => {
          arr.push(new TMap.LatLng(item.lat, item.lng))
        })
        this.canEditArr.forEach(item => {
          if (item.id === this.currentDrawObj.id) {
            console.log('this.currentDrawObj.id', this.currentDrawObj.id)
            item.paths = arr
          }
          idArr.push(item.id)
        })
        console.log('idArr', idArr)
        this.editor.select(idArr)
        this.editor.delete()
        this.editor.removeOverlay('polygon')
        console.log('this.canEditArr', this.canEditArr)
        this.editor.addOverlay({
          overlay: new TMap.MultiPolygon({
            map: this.map,
            zIndex: 100,
            styles: {
              highlight: new TMap.PolygonStyle({
                color: 'rgba(255, 255, 0, 0.6)'
              }),
              current: new TMap.PolygonStyle({
                color: 'rgba(255, 255, 0, 0.6)',
                borderColor: 'rgba(255, 255, 0, 0.8)'
              })
            },
            geometries: [
              ...this.canEditArr
            ]
          }),
          id: 'polygon',
          selectedStyleId: 'highlight'
        })
        this.editor.setActiveOverlay('polygon')
        this.actionMode = 1
        this.editor.setActionMode(this.actionMode)
        this.editor.select([cacheCurrentId])


      }

    },
    // save(key) {
    //   const newData = [...this.dataSource]
    //   const newCacheData = [...this.cacheData]
    //   const target = newData.filter(item => key === item.id)[0]
    //   const targetCache = newCacheData.filter(item => key === item.id)[0]
    //   if (target && targetCache) {
    //     delete target.editable
    //     this.dataSource = newData
    //     Object.assign(targetCache, target)
    //     this.cacheData = newCacheData
    //   }
    //   this.editingKey = ''
    // },
    // edit(key) {
    //   const newData = [...this.dataSource]
    //   const target = newData.filter(item => key === item.id)[0]
    //   this.editingKey = key
    //   if (target) {
    //     target.editable = true
    //     this.dataSource = newData
    //   }
    // },
    showEdit() {
      this.editTableVisible = !this.editTableVisible
    },
    handleAdd() {
      this.currentDrawObj.paths.push({
        id: this.realDataSource.length + 1,
        lat: '',
        lng: ''
      })
    },
    /**
     * 因为地图监听了backspace 和 delete键 所以需要判断一下光标是否在输入框上
     */
    handleInputFocus() {
      this.isFocus = true
    },
    handleInputBlur() {
      this.isFocus = false
    },
    handleAddPosition(){
      this.$refs.addForm.show()
    },
    handleOk(data){
      let obj = {}
      obj.isBuilding = this.type === 'building'
      let str = this.type === 'building' ? 'buildingId' : 'projectId'
      obj[str] = this.currentId
      obj.id = this.currentId
      let temp = []
      data.forEach(pathItem => {
        temp.push(new TMap.LatLng(pathItem.lat, pathItem.lng))
      })
      obj.paths = temp

      this.canEditArr = [obj]
      console.log('mythis.canEditArr',this.canEditArr)
      this.editor.removeOverlay('polygon')
      this.registerEditor()
      this.currentDrawObj = {
        id:this.currentId,
        paths:temp
      }
      this.setMapCenter()
      // console.log('getActiveOverlay',this.editor.getActiveOverlay())
    },
    registerEditor(){
      this.editor = new TMap.tools.GeometryEditor({
        map: this.map, // 编辑器绑定的地图对象
        overlayList: [ // 可编辑图层
          {
            overlay: new TMap.MultiPolyline({
              map: this.map
            }),
            id: 'polyline'
          },
          {
            overlay: new TMap.MultiPolygon({
              map: this.map,
              zIndex: 100,
              styles: {
                highlight: new TMap.PolygonStyle({
                  color: 'rgba(255, 255, 0, 0.6)'
                }),
                current: new TMap.PolygonStyle({
                  color: 'rgba(255, 255, 0, 0.6)',
                  borderColor: 'rgba(255, 255, 0, 0.8)'
                })
              },
              geometries: [
                ...this.canEditArr
              ]
            }),
            id: 'polygon',
            selectedStyleId: 'highlight'
          },
          {
            overlay: new TMap.MultiCircle({
              map: this.map
            }),
            id: 'circle'
          }
        ],
        actionMode: this.canEdit ? this.actionMode : 1, // 编辑器的工作模式
        activeOverlayId: 'polygon', // 激活图层
        snappable: true, // 开启吸附
        selectable: true
      })
      // 没有这句 点击删除图标时会报找不到window.editor
      window.editor = this.editor
      // 监听绘制结束事件，获取绘制几何图形
      this.editor.on('draw_complete', (geometry) => {
        console.log('绘制完了', geometry)
        let obj = {}
        if (this.type === 'building') {
          obj = {
            buildingId: this.currentId,
            paths: geometry.paths
          }
        } else {
          this.canEditArr.push(geometry)
        }
        console.log(obj)
        this.currentDrawObj = obj
      })

      this.editor.on('adjusting', (e) => {
        // if (e.geometry.id !== this.currentId) {
        //   this.$message.error('不可编辑其它楼栋')
        //   this.editor.stop()
        // }
      })

      this.editor.on('adjust_complete', (geometry) => {
        console.log(geometry, this.canEditArr)
        if (geometry.id == this.currentId && this.type === 'building') {
          let obj = {}
          obj = {
            buildingId: this.currentId,
            paths: geometry.paths
          }
          console.log(obj)
          this.currentDrawObj = obj
        } else {
          this.canEditArr.forEach(item => {
            if (item.id === geometry.id) {
              item.paths = geometry.paths
            }
          })
        }

      })

      this.editor.on('drawing', (e) => {
        if (Object.keys(this.currentDrawObj).length > 0 && this.type === 'building') {
          this.editor.stop()
          this.$message.error('每个楼栋只能绘画一个')
        }
      })

      // 编辑时选中事件
      this.editor.on('select', () => {
        // 清空所有选中
        this.allBuilding.forEach(item => {
          if (item.status !== '3') {
            item.styleId = 'noCheck'
          } else {
            item.styleId = 'default'
          }

        })
        this.allProject.forEach(item => {
          item.styleId = 'project'
        })
        this.polygon.updateGeometries(this.allBuilding)
        this.polygon1.updateGeometries(this.allProject)

        console.log('当前选中对象', this.editor.getSelectedList())
        if (this.editor.getSelectedList()[0].isBuilding) {
          this.detailShow = true
          this.getDetailById(this.editor.getSelectedList()[0].id)
        } else {
          this.detailShow = false
        }
        this.currentDrawObj = this.editor.getSelectedList()[0]
        this.currentSelectId = this.editor.getSelectedList()[0].id

      })

      // 删除事件
      this.editor.on('delete_complete', (e) => {
        console.log(e)
        if (this.isFocus) {
          return
        }

        if (this.currentSelectId === this.currentId && this.type === 'building') {
          getAction('/cum/cumBuildingSurveyed/delete', {
            buildingId: e[0].id
          }).then(res => {
            if (res.success) {
              this.$message.success('删除成功')
            }
          })
          this.currentDrawObj = {}
        }
        if (this.type === 'project') {

          getAction('/cum/cumProjectSurveyed/delete', {
            projectId: e[0].projectId,
            polygonId: e[0].polygonId
          }).then(res => {
            if (res.success) {
              this.$message.success('删除成功')
              let index = this.canEditArr.findIndex(item => item.id === this.currentSelectId)
              this.canEditArr.splice(index, 1)
            }
          })

        }
      })

      // 监听拆分失败事件，获取拆分失败原因
      this.editor.on('split_fail', (res) => {
        alert(res.message)
      })
      // 监听合并失败事件，获取合并失败原因
      this.editor.on('union_fail', (res) => {
        alert(res.message)
      })
    }
  }
}
</script>

<style lang='less' scoped>
@import '~@assets/less/common.less';

/deep/ .edit-position-table {
  table {
    width: 100% !important;
  }
}

#toolControl {
  position: absolute;
  top: 10px;
  left: 0px;
  right: 0px;
  margin: auto;
  width: 168px;
  z-index: 1001;
}

.edit-position {
  position: absolute;
  top: 0;
  left: 100px;
  right: 0px;
  width: 168px;
  z-index: 1001;
}

.edit-position-table {
  position: absolute;
  top: 32px;
  left: 100px;
  right: 0px;
  width: 600px;
  z-index: 1001;
  background-color: white;
}

.toolItem {
  width: 30px;
  height: 30px;
  float: left;
  margin: 1px;
  padding: 4px;
  border-radius: 3px;
  background-size: 30px 30px;
  background-position: 4px 4px;
  background-repeat: no-repeat;
  box-shadow: 0 1px 2px 0 #E4E7EF;
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.toolItem:hover {
  border-color: #789CFF;
}

.active {
  border-color: #D5DFF2;
  background-color: #D5DFF2;
}

#marker {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/marker_editor.png');
}

#polyline {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/polyline.png');
}

#polygon {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/polygon.png');
}

#circle {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/circle.png');
}

#delete {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/delete.png');
}

#split {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/split.png');
}

#union {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/union.png');
}

/deep/ .ant-modal {
  top: 0 !important;
}

.change-base-map {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999999;
  opacity:.3;
  &:hover{
    opacity: 1;
  }
}

.detail-info {
  width: 20vw;
  height: 70vh;
  position: fixed;
  right: 40px;
  top: 150px;
  background-color: white;
  z-index: 999999999;
  padding: 20px;

  .img-box {
    width: 100px;
    height: 100px;
    border: 1px solid black;
    cursor: pointer;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
}

.img-model {
  /deep/ .ant-modal {
    top: 10% !important;
  }
}

.ant-tabs {
  height: 100% !important;
  overflow: scroll !important;
}

/* For demo */
/deep/ .ant-carousel .slick-dots {
  height: auto;
}

/deep/ .ant-carousel .slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 80%;
}

/deep/ .ant-carousel .slick-thumb {
  position: relative;
  margin-top: 15px;
}

/deep/ .ant-carousel .slick-thumb li {
  width: 60px;
  height: 45px;
}

/deep/ .ant-carousel .slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}

/deep/ .ant-carousel .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}

.sub-info {
  position: absolute;
  bottom: 0;
  left: 10px;
  display: flex;
  padding: 10px;
  background-color: white;
  z-index: 999999999;
  width: 300px;
  align-items: center;
  //justify-content: space-evenly;
  flex-wrap: wrap;

  .info {
    width: 45%;
    display: flex;
    margin-top: 10px;

    .color {
      width: 40px;
      height: 20px;
      background-color: pink;
      margin-right: 10px;
      border: 1px solid transparent;
    }
  }
}

/deep/ .ant-descriptions-item-label {
  padding: 2px 2px !important;
  background-color: #cb9f1312;
}

/deep/ .ant-descriptions-item-content {
  padding: 2px 2px !important;
}
</style>