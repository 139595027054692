<template>
  <div>
    <a-modal
      :title="title"
      :width="width"
      placement="right"
      @cancel="close"
      :footer="null"
      :visible="visible">
      <a-descriptions :column="3" bordered size="small">
              <a-descriptions-item :label="$t('projectId')">
          {{ data.projectId_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('buildingId')">
          <span v-if='data.buildingId'>
              <span v-for='(item,index) in data.buildingId.split(",")' :key='index'>
                <a @click='handleBuildingDetail(item)' style='display: block'>#{{item}}</a>
              </span>
          </span>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('unitId')">
          <span v-if='data.unitId'>
              <span v-for='(item,index) in data.unitId.split(",")' :key='index'>
                <a @click='handleUnitDetail(item)' style='display: block'>#{{item}}</a>
              </span>
          </span>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('follower')">
          {{ data.follower_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('rightHolder')">
          {{ data.rightHolder_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label="权利人核实">
          {{ data.rightHolderChecked_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('negotiationDifficultyLevel')">
          {{ data.negotiationDifficultyLevel }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('status')">
          {{ data.status_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label="合同评审状态">
          {{ data.contractStatus_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label="合同评审完成时间">
          {{ data.contractDoneTime }}
        </a-descriptions-item>
        <a-descriptions-item label="签约时间">
          {{ data.contractedTime }}
        </a-descriptions-item>
        <a-descriptions-item label="谈判条件状态">
          {{ data.bargainingPowerStatus_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label="谈判条件完成时间">
          {{ data.bargainingPowerTime }}
        </a-descriptions-item>
        <a-descriptions-item label="付款状态">
          {{ data.firstPayStatus_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label="最近支付时间">
          {{ data.firstPayTime }}
        </a-descriptions-item>
        <a-descriptions-item label="收楼审批状态">
          {{ data.collectionPaymentTargetStatus_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label="收楼审批时间">
          {{ data.collectionPaymentTargetTime }}
        </a-descriptions-item>
        <a-descriptions-item label="签约状态">
          {{ data.contractedStatus_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('isEntrustedNotarization')">
          {{ data.isEntrustedNotarization_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('isCancellationRealEstateLicense')">
          {{ data.isCancellationRealEstateLicense_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addBy')">
          {{ data.addBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addTime')" >
          {{ data.addTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateBy')">
          {{ data.updateBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateTime')" :span='2'>
          {{ data.updateTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('remark')" :span='3'>
          {{ data.remark }}
        </a-descriptions-item>
      </a-descriptions>
      <a-divider></a-divider>

      <multi-attachment :files='data.fileList' :tab-option="[{
        key: 'ownership',
        label: '权属资料'
      },{
        key: 'other',
        label: '其他'
      }]" :view='true'></multi-attachment>
    </a-modal>

    <cumBuilding-detail ref='buildingDetailForm'></cumBuilding-detail>

    <cumUnit-detail ref='unitDetailForm' ></cumUnit-detail>

  </div>
</template>

<script>
  import {  cumProjectItemApi } from './api/CumProjectItemWorkFlow'
  import { DetailHistory } from 'dbcp-frontends-common/lib/components'
  import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ActionMixin } from 'dbcp-frontends-common/lib/mixins'
  import { getAction } from 'dbcp-frontends-common/lib/api'
  import CumBuildingDetail from '../../propertyManagement/buildingList/modules/CumBuildingDetail'
  import CumUnitDetail from '../../propertyManagement/unitList/modules/CumUnitDetail'
  import MultiAttachment from '../../../../../components/dbcp/city/MultiAttachment'

  export default {
    name: "CumProjectItemDetail",
    components: { DetailHistory,CumBuildingDetail,CumUnitDetail,MultiAttachment },
    mixins: [ DetailMixin,ActionMixin ],
    data() {
      return {
        url: cumProjectItemApi.url,
      }
    },
    methods:{
      view(data){
        this.data = data
        this.visible = true
      },
      handleBuildingDetail(id){
        getAction("/cum/cumBuilding/list",{id:id}).then(res => {
          console.log("获取楼栋信息",res.result.records);
          this.$refs.buildingDetailForm.view(res.result.records[0])
        })
      },
      handleUnitDetail(id){
        getAction("/cum/cumUnit/list",{id:id}).then(res=>{
          console.log("获取单位信息",res.result.records);
          this.$refs.unitDetailForm.view(res.result.records[0])
        })
      },
    }

  }
</script>
