<template>
  <a-card :bordered='false'>
    <div class="clearfix">
      <a-upload
        list-type="picture-card"
        :file-list="fileListx"
        @preview="handlePreview"
        @change="handleChange"
        :beforeUpload='beforeUpload'
        :remove="handleRemove"
      >
        <div >
          <a-icon type="plus" />
          <div class="ant-upload-text">
            Upload
          </div>
        </div>
      </a-upload>
      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import { dbcpHomeCarouselApi } from './modules/api/DbcpHomeCarousel'
import '@/assets/less/TableExpand.less'
import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
import { PrinterMixin } from 'dbcp-frontends-common/lib/mixins'
import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
import DbcpHomeCarouselModal from './modules/DbcpHomeCarouselModal'
import DbcpHomeCarouselDetail from './modules/DbcpHomeCarouselDetail'
// import DbcpHomeCarouselPrint from './modules/DbcpHomeCarouselPrint'
import ApprovalTab from '@comp/dbcp/approval/ApprovalTab'
import { ListActions } from 'dbcp-frontends-common/lib/components'
import draggable from 'vuedraggable'
import { deleteAction, getAction,postAction } from 'dbcp-frontends-common/lib/api'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  name: 'DbcpHomeCarouselList',
  mixins: [JeecgListMixin, mixinDevice, ViewMixin, PrinterMixin, ApprovalListMixin],
  components: {
    DbcpHomeCarouselModal,
    // DbcpHomeCarouselPrint,
    DbcpHomeCarouselDetail,
    ListActions,
    ApprovalTab
  },
  data() {
    return {
      description: 'dbcp_home_carousel管理页面',

      url: dbcpHomeCarouselApi.url,
      dictOptions: {},

      loading: false,
      imageUrl: '',

      previewVisible: false,
      previewImage: '',
      // fileList: [
      //   {
      //     uid: '',
      //     name: '',
      //     status: '',
      //     url: '',
      //   }
      // ],
      fileListx:[]

    }
  },
  computed: {
    importExcelUrl: function() {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    }
  },
  created() {
    this.getFileList();
  },
  methods: {
    initDictConfig() {
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileListx = fileList;
    },
    //文件上传前的钩子
    beforeUpload(file){
      var formData = new FormData();
      formData.append("files", file);
      postAction(dbcpHomeCarouselApi.url.add,formData
      ).then(res=>{
        this.getFileList();
        return true;
      })
      return false;
    },
    //查询轮播图列表
    getFileList(){
      getAction(dbcpHomeCarouselApi.url.list,{
      }).then(res=>{
        this.fileListx=[]
        let arr = []
        console.log("res",res,res.result.records.length)
        for (let i=0;i<res.result.records.length;i++){
          var element=res.result.records[i];

        this.fileListx.push({
              uid: element.id,
              name: element.id + "",
              status: 'done',
              url: element.fileList[0]!=null?element.fileList[0].path:null,
            })
        }

      })
    },
    handleRemove(file){
      console.log("file:::",file)
      const index = this.fileListx.indexOf(file);
      const newFileList = this.fileListx.slice();
      newFileList.splice(index, 1);
      this.fileListx = newFileList;
      deleteAction(dbcpHomeCarouselApi.url.delete,{
        id:file.uid
      }).then(res=>{
        console.log("res::",res)
        if(res && res.result){
          this.$message.success(res.message)
        }else{
          this.$message.warning(res.message)
        }
      })
    }

  }
}
</script>
<style scoped>
@import '~@assets/less/common.less';

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
