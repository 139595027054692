<template>
  <j-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('cancel')">
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item label="dwneId" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['dwneId', validatorRules.dwneId]" placeholder="请输入dwneId" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="approver" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-select-user-by-dep :hideDept="true" v-decorator="['approver']"/>
        </a-form-item>
        <a-form-item label="status" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['status']" placeholder="请输入status" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="username" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-select-user-by-dep :hideDept="true" v-decorator="['username', validatorRules.username]"/>
        </a-form-item>
        <a-form-item label="createTime" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-date placeholder="请选择createTime" v-decorator="['createTime', validatorRules.createTime]" :trigger-change="true" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="serialNumber" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['serialNumber']" placeholder="请输入serialNumber"></a-input>
        </a-form-item>
        <a-form-item label="moduleName" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['moduleName', validatorRules.moduleName]" placeholder="请输入moduleName"></a-input>
        </a-form-item>

      </a-form>
    </a-spin>
  </j-modal>
</template>

<script>

  import { httpAction } from 'dbcp-frontends-common/lib/api'
  import pick from 'lodash.pick'
  import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
  import { JDate } from 'dbcp-frontends-common/lib/components'
  import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'



  export default {
    name: "PendingTasksWorkflowViewModal",
    components: {
      JDate,
      JSelectUserByDep,
    },
    data () {
      return {
        form: this.$form.createForm(this),
        title: this.$i18n.tc('action'),
        width: 800,
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules: {
          dwneId: {
            rules: [
              { required: true, message: '请输入dwneId!'},
            ]
          },
          username: {
            rules: [
              { required: true, message: '请输入username!'},
            ]
          },
          createTime: {
            rules: [
              { required: true, message: '请输入createTime!'},
            ]
          },
          moduleName: {
            rules: [
              { required: true, message: '请输入moduleName!'},
            ]
          },
        },
        url: {
          add: "/oa/dbcp/pendingTasksWorkflowView/add",
          edit: "/oa/dbcp/pendingTasksWorkflowView/edit",
        }
      }
    },
    created () {
    },
    methods: {
      add () {
        this.edit({});
      },
      edit (record) {
        this.form.resetFields();
        this.model = Object.assign({}, record);
        this.visible = true;
        this.$nextTick(() => {
          this.form.setFieldsValue(pick(this.model,'dwneId','approver','status','username','createTime','serialNumber','moduleName'))
        })
      },
      close () {
        this.$emit('close');
        this.visible = false;
      },
      handleOk () {
        const that = this;
        // 触发表单验证
        this.form.validateFields((err, values) => {
          if (!err) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
               method = 'put';
            }
            let formData = Object.assign(this.model, values);
            console.log("表单提交数据",formData)
            httpAction(httpurl,formData,method).then((res)=>{
              if(res.success){

                that.$emit('ok');
              }else{

              }
            }).finally(() => {
              that.confirmLoading = false;
              that.close();
            })
          }

        })
      },
      handleCancel () {
        this.close()
      },
      popupCallback(row){
        this.form.setFieldsValue(pick(row,'dwneId','approver','status','username','createTime','serialNumber','moduleName'))
      },


    }
  }
</script>
