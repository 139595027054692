<template>
  <div>
    <a-modal
      :title="title"
      :width="width"
      placement="right"
      @cancel="close"
      :footer="null"
      :visible="visible">
      <a-descriptions :column="3" bordered size="small">
        <a-descriptions-item :label="$t('projectId')">
          <a @click='turnProjectDetail(data.projectId)'>{{ data.projectId_dictText }}</a>
        </a-descriptions-item>
              <a-descriptions-item :label="$t('village')">
          {{ data.village }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('propertyType')">
          {{ data.propertyType_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label="测绘编号">
          {{ data.surveyedNo }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('surveyedArea')">
          {{ data.surveyedArea }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('surveyNumber')">
          {{ data.surveyNumber }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('surveyingStatus')">
          {{ data.surveyingStatus_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('landNumber')">
          {{ data.landNumber }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('rightHolder')">
          {{ data.rightHolder_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('buildingNumber')">
          {{ data.buildingNumber }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('totalFloors')">
          {{ data.totalFloors }}
        </a-descriptions-item>
        <a-descriptions-item label="产权证建筑面积">
          {{ data.certificateTitleArea }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('structureType')">
          {{ data.structureType_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('ancestralHouse')">
          {{ data.ancestralHouse_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('realEstateLicenseType')">
          {{ data.realEstateLicenseType_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label="地址">
          {{ data.surveyAddress }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addBy')" >
          {{ data.addBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addTime')" >
          {{ data.addTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateBy')">
          {{ data.updateBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateTime')" :span='3'>
          {{ data.updateTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('remark')" :span='3'>
          {{ data.remark }}
        </a-descriptions-item>
      </a-descriptions>

      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="附属">
          <cum-building-subsidiary-list :show-building='false' :buildingId='data.id'></cum-building-subsidiary-list>
        </a-tab-pane>
        <a-tab-pane key="2" tab="附件">
          <div style='margin-left:30px'>
            <multi-attachment
              :files='data.fileList'
              :tab-option="[{
                               key: 'file',
                               label: '文件'
                            },{
                             key:'other',
                             label:'其它'
                            },{
                  key: 'img',
                  label: '图片'
                },{
                  key: 'model',
                  label: '模型'
                }]"
              :view='true'
            ></multi-attachment>
          </div>
        </a-tab-pane>
      </a-tabs>



    </a-modal>
    <cum-project-detail ref='projectDetailForm'></cum-project-detail>
  </div>
</template>

<script>
  import {  cumBuildingApi } from './api/CumBuildingWorkFlow'
  import { DetailHistory } from 'dbcp-frontends-common/lib/components'
  import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ActionMixin } from 'dbcp-frontends-common/lib/mixins'
  import CumBuildingSubsidiaryList from '../../../buildingSubsidiaryManagement/CumBuildingSubsidiaryList'
  import MultiAttachment from '../../../../../../components/dbcp/city/MultiAttachment'
  import Attachment from 'dbcp-frontends-common/lib/components'
  import CumProjectDetail from '../../../projectManagement/modules/CumProjectDetail'
  import { getAction } from 'dbcp-frontends-common/lib/api'

  export default {
    name: "CumBuildingDetail",
    components: { DetailHistory,CumBuildingSubsidiaryList,MultiAttachment,Attachment,CumProjectDetail },
    mixins: [ DetailMixin,ActionMixin ],
    data() {
      return {
        url: cumBuildingApi.url,
      }
    },
    methods:{
      view(data){
        console.log("详情",data)
        this.data = data
        this.visible = true
      },
      turnProjectDetail(id){
        getAction("/cum/cumProject/list",{id}).then(res=>{
          let data = res.result.records[0];
          this.$nextTick(()=>{
            this.$refs.projectDetailForm.view(data);
          })

        })
      }
    }
  }
</script>
