<template>
  <a-card :bordered="false">

    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">

          <a-col :md="6" :sm="8">
            <a-form-item label="模板CODE">
              <a-input placeholder="请输入模板CODE" v-model="queryParam.templateCode"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item label="模板内容">
              <a-input placeholder="请输入模板内容" v-model="queryParam.templateContent"></a-input>
            </a-form-item>
          </a-col>
          <template v-if="toggleSearchStatus">
            <a-col :md="6" :sm="8">
              <a-form-item :label="$t('title')">
                <a-input :placeholder="$t('title')" v-model="queryParam.templateName"></a-input>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="8">
              <a-form-item label="模板类型">
                <a-input placeholder="请输入模板类型" v-model="queryParam.templateType"></a-input>
              </a-form-item>
            </a-col>
          </template>
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a @click="handleToggleSearch" style="margin-left: 8px">
                {{ toggleSearchStatus ? $t('collapse') : $t('expand') }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
              </a>
            </span>
          </a-col>

        </a-row>
      </a-form>
    </div>

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus">{{ $t('create') }}</a-button>
      <a-dropdown v-if="selectedRowKeys.length > 0">
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="batchDel">
            <a-icon type="delete"/>
            {{ $t('delete') }}
          </a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> {{ $t('batchOperation') }}
          <a-icon type="down"/>
        </a-button>
      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
        <i class="anticon anticon-info-circle ant-alert-icon"></i> {{ $t('selected') }}  <a style="font-weight: 600">{{
        selectedRowKeys.length }}</a> {{ $t('item') }}
        <a style="margin-left: 24px" @click="onClearSelected">{{$t('clean')}}</a>
      </div>

      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        @change="handleTableChange">

        <!-- 字符串超长截取省略号显示-->
        <span slot="templateContent" slot-scope="text">
          <j-ellipsis :value="text" :length="25" />
        </span>


        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">{{ $t('edit') }}</a>

          <a-divider type="vertical"/>
          <a-dropdown>
            <a class="ant-dropdown-link">{{ $t('more') }} <a-icon type="down"/></a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a-popconfirm :title="$t('confirmDeleteAlert')" @confirm="() => handleDelete(record.id)">
                  <a>{{ $t('delete') }}</a>
                </a-popconfirm>
              </a-menu-item>
              <a-menu-item>
                  <a @click="handleTest(record)">发送测试</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>

      </a-table>
    </div>
    <!-- table区域-end -->

    <!-- 表单区域 -->
    <sysMessageTemplate-modal ref="modalForm" @ok="modalFormOk"></sysMessageTemplate-modal>

    <sysMessageTest-modal ref="testModal"></sysMessageTest-modal>
  </a-card>
</template>

<script>
  import SysMessageTemplateModal from './modules/SysMessageTemplateModal'
  import SysMessageTestModal from './modules/SysMessageTestModal'
  import {JeecgListMixin} from 'dbcp-frontends-common/lib/mixins'
  import { JEllipsis } from 'dbcp-frontends-common/lib/components'

  export default {
    name: "SysMessageTemplateList",
    mixins: [JeecgListMixin],
    components: {
      JEllipsis,
      SysMessageTemplateModal,
      SysMessageTestModal
    },
    data() {
      return {
        description: '消息模板管理页面',
        // 表头
        columns: [
          {
            title: '#',
            dataIndex: '',
            key: 'rowIndex',
            width: 60,
            align: 'center',
            customRender: function (t, r, index) {
              return parseInt(index) + 1;
            }
          },
          {
            title: '模板CODE',
            align: 'center',
            dataIndex: 'templateCode'
          },
          {
            title: this.$i18n.tc('title'),
            align: 'center',
            dataIndex: 'templateName'
          },
          {
            title: '模板内容',
            align: 'center',
            dataIndex: 'templateContent',
            scopedSlots: {customRender: 'templateContent'},
          },
          {
            title: '模板类型',
            align: 'center',
            dataIndex: 'templateType',
            customRender: function (text) {
              if(text=='1') {
                return "短信";
              }
              if(text=='2') {
                return "邮件";
              }
              if(text=='3') {
                return "微信";
              }
              if(text=='4') {
                return "系统";
              }
            }
          },
          {
            title: this.$i18n.tc('action'),
            dataIndex: 'action',
            align: 'center',
            scopedSlots: {customRender: 'action'},
          }
        ],
        url: {
          list: "/message/sysMessageTemplate/list",
          delete: "/message/sysMessageTemplate/delete",
          deleteBatch: "/message/sysMessageTemplate/deleteBatch",
          exportXlsUrl: "message/sysMessageTemplate/exportXls",
          importExcelUrl: "message/sysMessageTemplate/importExcel",
        },
      }
    },
    computed: {
      importExcelUrl: function () {
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      }
    },
    methods: {
      handleTest(record){
        this.$refs.testModal.open(record);
        this.$refs.testModal.title = "发送测试";
      }

    }
  }
</script>
<style lang="less" scoped>
  /** Button按钮间距 */
  .ant-btn {
    margin-left: 3px
  }

  .ant-card-body .table-operator {
    margin-bottom: 18px;
  }

  .ant-table-tbody .ant-table-row td {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .anty-row-operator button {
    margin: 0 5px
  }

  .ant-btn-danger {
    background-color: #ffffff
  }

  .ant-modal-cust-warp {
    height: 100%
  }

  .ant-modal-cust-warp .ant-modal-body {
    height: calc(100% - 110px) !important;
    overflow-y: auto
  }

  .ant-modal-cust-warp .ant-modal-content {
    height: 90% !important;
    overflow-y: hidden
  }
</style>
