import { deleteAction, getAction, uploadAction } from 'dbcp-frontends-common/lib/api'
export const cumFirstPayApi = {
url: {
  action: "/cum/cumFirstPay/action",
  list: "/cum/cumFirstPayWorkflow/list"
},
  execute: {
    list: function(params) {
      return getAction(cumFirstPayApi.url.list, params)
    },
    create: function(object) {
      return getAction(cumFirstPayApi.url.add, object)
    },
    retrieve: function(id, params = {}) {
      return getAction(cumFirstPayApi.queryById, { id, ...params })
    },
    update: function(object) {
      return uploadAction(cumFirstPayApi.url.edit, object)
    },
    destroy: function(id) {
      return deleteAction(cumFirstPayApi.url.delete, id)
    }
  }
}
