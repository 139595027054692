<template>
  <a-card :bordered='false'>
    <!-- 查询区域 -->
    <div class='table-page-search-wrapper'>
      <a-form layout='inline' @keyup.enter.native='searchQuery'>
        <a-row :gutter='24'>
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <a-form-item label='序列号'>
              <a-input placeholder='请输入查询序列号' v-model='queryParam.serialNumber_like'></a-input>
            </a-form-item>
          </a-col>
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>查询</a-button>
              <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset')
                }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class='table-operator'>
      <a-button @click='handleAdd(isSpecial ? $attrs.modalRecord:currentRecord)' type='primary' icon='plus'
                v-has="'cumFirstPay:create'">{{ $t('create') }}
      </a-button>
      <a-dropdown v-if='selectedRowKeys.length > 0'>
        <a-menu slot='overlay'>
          <a-menu-item key='1' @click='batchDel'>
            <a-icon type='delete' />
            {{ $t('delete') }}
          </a-menu-item>
        </a-menu>
        <a-button style='margin-left: 8px'> {{ $t('batchOperation') }}
          <a-icon type='down' />
        </a-button>
      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>
      <approval-tab @change='handleGroupChange' permission='cumFirstPay:view:all'></approval-tab>
      <setting-columns :columns='columns' @changeRealColumns='(data) => columns = data'></setting-columns>
      <a-table
        ref='table'
        size='middle'
        bordered
        rowKey='id'
        :columns='columns'
        :dataSource='dataSource'
        :pagination='ipagination'
        :loading='loading'
        :rowSelection='type?{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type,onSelect:handleSelection,onSelectAll:handleSelectAll}:null'
        class='j-table-force-nowrap'
        @change='handleTableChange'>

        <template slot='projectItemId' slot-scope='text,record'>
          <a @click='turnProjectItemDetail(record)'>{{ record.rightHolder_dictText }}</a>
        </template>
        <template slot='projectId' slot-scope='text,record'>
          <a @click='turnProjectDetail(record)'>{{ text }}</a>
        </template>
        <span slot='action' slot-scope='text, record'>
          <list-actions
            :record='record'
            @view='handleView'
            @edit='handleEdit'
            @delete='handleDelete'
            @action='$refs.detailForm.handleActionClick'
            @copy='handleCopy'
          ></list-actions>
        </span>

      </a-table>
    </div>


    <cumFirstPay-modal ref='modalForm' @ok='modalFormOk' v-if='!isSpecial'></cumFirstPay-modal>
    <cum-pay-modal ref='modalForm' @ok='modalFormOk' v-else></cum-pay-modal>
    <cumFirstPay-detail ref='detailForm' @action='handleActionClick' @close='handleViewModalClose'
                        @loadData='loadData' @clearRouteQuery='clearRouteQuery'></cumFirstPay-detail>
    <cumProjectItem-detail ref='projectItemDetailForm'></cumProjectItem-detail>
    <cum-project-detail ref='projectDetailForm'></cum-project-detail>
  </a-card>
</template>

<script>
import { cumFirstPayApi } from './modules/api/CumFirstPay'
import '@/assets/less/TableExpand.less'
import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
import { SettingColumns } from 'dbcp-frontends-common/lib/components'
import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
import CumFirstPayModal from './modules/CumFirstPayModal'
import CumFirstPayDetail from './modules/CumFirstPayDetail'
import { ApprovalTab } from 'dbcp-frontends-common/lib/components'
import { ListActions } from 'dbcp-frontends-common/lib/components'
import { SelectorMixin } from 'dbcp-frontends-common/lib/mixins'
import CumProjectItemDetail from '../projectManagement/modules/CumProjectItemDetail'
import { getAction } from 'dbcp-frontends-common/lib/api'
import CumProjectDetail from '../projectManagement/modules/CumProjectDetail'
import CumPayModal from './modules/CumPayModal'

export default {
  name: 'CumFirstPayList',
  mixins: [JeecgListMixin, mixinDevice, ViewMixin, ApprovalListMixin, SelectorMixin],
  components: {
    CumFirstPayModal,
    CumFirstPayDetail,
    ListActions,
    SettingColumns,
    CumProjectItemDetail,
    ApprovalTab,
    CumProjectDetail,
    CumPayModal
  },
  data() {
    return {
      description: 'cum_first_pay管理页面',
      // 表头
      columns: [
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function(t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '序列号',
          align: 'center',
          dataIndex: 'serialNumber'
        },
        {
          title: '此次付款',
          align: 'center',
          dataIndex: 'thisPayment'
        },
        {
          title: '项目',
          align: 'center',
          dataIndex: 'projectId_dictText',
          scopedSlots: { customRender: 'projectId' }
        },
        {
          title: '项目明细',
          align: 'center',
          dataIndex: 'projectItemId',
          scopedSlots: { customRender: 'projectItemId' }
        },
        {
          title: '创建人',
          align: 'center',
          dataIndex: 'addBy_dictText'
        },
        {
          title: '创建时间',
          align: 'center',
          dataIndex: 'addTime'
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'status_dictText'
        },
        {
          title: '当前处理人',
          align: 'center',
          dataIndex: 'approver_dictText'
        },
        {
          title: this.$i18n.tc('action'),
          dataIndex: 'action',
          align: 'center',
          // fixed:"right",
          width: 147,
          scopedSlots: { customRender: 'action' }
        }
      ],
      url: cumFirstPayApi.url,
      dictOptions: {},
      /* 禁用created列表数据加载 */
      disableMixinCreated: true,
      //  是否特殊 从项目管理的项目补偿过来
      isSpecial: false
    }
  },
  computed: {
    importExcelUrl: function() {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    }
  },
  props: {
    currentRecord: {
      type: Object,
      default: () => {
      }
    }
  },
  watch: {
    currentRecord: {
      deep: true,
      handler: function(newV) {
        console.log('newV23132', newV)
        for (let key in newV) {
          this.queryParam[key] = newV[key]
        }
        console.log('this.queryParam', this.queryParam)
        this.dataSource = []
        this.loadData()
      }
    }
  },
  created() {
    console.log('currentRecord', this.currentRecord)
    for (let key in this.currentRecord) {
      console.log('key', key)
      this.queryParam[key] = this.currentRecord[key]
    }
    this.dataSource = []
    this.loadData()
    this.isSpecial = this.$attrs.special
  },
  methods: {
    initDictConfig() {
    },
    turnProjectItemDetail(record) {
      getAction('/cum/cumProjectItem/list', { id: record.projectItemId }).then(res => {
        let data = res.result.records[0]
        this.$nextTick(() => {
          this.$refs.projectItemDetailForm.view(data)
        })

      })
    },
    turnProjectDetail(record) {
      getAction('/cum/cumProject/list', { id: record.projectId }).then(res => {
        let data = res.result.records[0]
        this.$nextTick(() => {
          this.$refs.projectDetailForm.view(data)
        })

      })
    },
    searchReset() {
      this.queryParam = {}
      for (let key in this.currentRecord) {
        console.log('key', key)
        this.queryParam[key] = this.currentRecord[key]
      }
      this.loadData(1)
    }
  }
}
</script>
<style scoped>
@import '~@assets/less/common.less';
</style>
