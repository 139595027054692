<template>
  <j-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('cancel')">
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item label="项目" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <InputSelect v-decorator="['projectId', validatorRules.projectId]" show-name='name' send-params='id'
                       url='dbcp/city/projectManagement/CumProjectList.vue'
                       type='radio' :isEdit='model.projectId?true:false'></InputSelect>
        </a-form-item>

        <a-form-item label="项目明细" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if='form.getFieldValue("projectId") || model.projectItemId'>
          <InputSelect v-decorator="['projectItemId', validatorRules.projectItemId]" show-name='rightHolder_dictText' send-params='id'
                       url='dbcp/city/projectManagement/CumProjectItemTable.vue' :projectId='form.getFieldValue("projectId")'
                       type='radio' :isEdit='model.projectItemId?true:false' @ok='(data) => handleInputSelectOk(data)'></InputSelect>
        </a-form-item>
        <a-form-item label="金额" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-table :columns='firstPayMoneyColumns' :dataSource='firstPayMoneyDataSource' :pagination="false" bordered>
            <template slot='detail' slot-scope='text, record,index'>
              <p v-for='item in record.cumPaymentAmountList'>
                {{ item.compensateTypeId_dictText }} : {{item.amount}}
              </p>
            </template>
          </a-table>
        </a-form-item>
        <a-form-item label='此次付款金额' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input-number v-decorator="['thisPayment']" style='width: 100%'></a-input-number>
        </a-form-item>
        <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea v-decorator="['remark']" rows="4" placeholder="请输入备注"/>
        </a-form-item>
        <a-form-item :label="$t('attachments')" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <attachment v-decorator="['upload']" @handleFileRemove="handleFileRemove" @beforeFileUpload="beforeFileUpload" :file-list="uploadFiles" :default-file-list="defaultFileList"></attachment>
        </a-form-item>

      </a-form>
    </a-spin>

    <template slot="footer">
      <a-button type="primary" @click="handleOk(false)">{{ $t('saveDraft') }}</a-button>
      <a-button type="primary" @click="handleOk(true)">{{ $t('submit') }}</a-button>
      <a-button @click="handleCancel">{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
  import { cumFirstPayApi } from './api/CumFirstPay'
  import { httpAction } from 'dbcp-frontends-common/lib/api'
  import pick from 'lodash.pick'
  import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
  import { Attachment } from 'dbcp-frontends-common/lib/components'
  import { JDate } from 'dbcp-frontends-common/lib/components'
  import { JSelectDepart } from 'dbcp-frontends-common/lib/components'
  import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'
  import { UploadFormMixin } from 'dbcp-frontends-common/lib/mixins'
  import InputSelect from '../../../../../components/dbcp/InputSelect'
  import { getAction } from 'dbcp-frontends-common/lib/api'

  export default {
    name: "CumFirstPayModal",
    components: {
      JDate,
      JSelectDepart,
      JSelectUserByDep,
        Attachment,
      InputSelect
    },
    mixins: [ UploadFormMixin ],
    data () {
      return {
        form: this.$form.createForm(this),
        title: this.$i18n.tc('action'),
        width: '80%',
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules: {
          money: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          projectId: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          },
          projectItemId: {
            rules: [
              { required: true, message: this.$i18n.tc('fieldEmptyHint')},
            ]
          }
        },
        url: cumFirstPayApi.url,
        moneyDisabled:false ,
        firstPayMoneyColumns: [
          {
            title: '项目细项',
            align: 'center',
            dataIndex: 'rightHolder_dictText'
          },
          {
            title: '应付款',
            align: 'center',
            dataIndex: 'rightHolder_dictText',
            children: [
              {
                title: '详情',
                align: 'center',
                dataIndex: 'detail',
                scopedSlots: { customRender: 'detail' }
              },
              {
                title: '合计',
                align: 'center',
                dataIndex: 'payableMoney'
              }
            ]
          },
          {
            title: '已付款',
            align: 'center',
            dataIndex: 'rightHolder_dictText',
            children: [
              {
                title: '合计',
                align: 'center',
                dataIndex: 'paidMoney'
              }
            ]
          },
          {
            title: '未付款',
            align: 'center',
            dataIndex: 'rightHolder_dictText',
            children: [
              {
                title: '合计',
                align: 'center',
                dataIndex: 'pendingMoney'
              }
            ]
          }
        ],
        firstPayMoneyDataSource:[]
      }
    },
    created () {
    },
    watch:{
      "model.projectItemId":{
        immediate:true,
        handler(newV){
          console.log("model.projectItemIdmodel.projectItemId",newV);
          if(newV){
           this.getFirstPayMoney(newV);
          }
        }
      }
    },
    methods: {
      afterAdd (record) {
        this.edit({
            ...record
        })
      },
      afterEdit (record) {
        console.log("record",record)
        this.$nextTick(() => {
          this.form.setFieldsValue(pick(this.model,'money','thisPayment','projectId','projectItemId','addTime','status','deleted','projectItemId','remark'))
        })
      },
      afterClose () {
        this.moneyDisabled = false ;
        this.payableMoneyDataSource = []
        this.firstPayMoneyDataSource = []
      },
      popupCallback(row){
        this.form.setFieldsValue(pick(row,'money','thisPayment','projectId','projectItemId','addTime','status','deleted','projectItemId'))
      },
      handleInputSelectOk(data){
        console.log("handleInputSelectOk",data);
        this.getPayList(data.id)
      },
      getPayList(projectItemId){
        getAction('/cum/cumProjectCompensation/list',{
          projectItemId
        }).then(res => {
          if(res.success){
            this.firstPayMoneyDataSource = res.result.records
          }
        })
      },
      processFormData(formData){
        if(this.firstPayMoneyDataSource.length > 0){
          formData.set('cumPaymentAmountList', JSON.stringify(this.firstPayMoneyDataSource[0].cumPaymentAmountList))
          formData.set('paidMoney',this.firstPayMoneyDataSource[0].paidMoney)
          formData.set('payableMoney',this.firstPayMoneyDataSource[0].payableMoney)
          formData.set('pendingMoney',this.firstPayMoneyDataSource[0].pendingMoney)
        }
        return formData
      }

    }
  }
</script>
