<template>
  <a-modal v-model='visible' width='800px'   @ok="handleOk">
    <a-table bordered
             rowKey='id'
             :columns='columns'
             :dataSource='dataSource'
             :pagination='false'
             size='small'
    >
      <template
        v-for="col in ['lat', 'lng']"
        :slot='col'
        slot-scope='text, record, index'
      >
        <div :key='col'>
          <a-input
            style='margin: -5px 0'
            :value='text'
            @change='e => handleInputChange(e.target.value, record.id, col)'
          />
          <!--              <template v-else>-->
          <!--                {{ text }}-->
          <!--              </template>-->
        </div>
      </template>
      <template slot='operation' slot-scope='text, record, index'>
        <div class='editable-row-operations'>
          <!--                  <span v-if='record.editable'>-->
          <!--          &lt;!&ndash;          <a @click="() => save(record.key)">Save</a>&ndash;&gt;-->
          <!--                    &lt;!&ndash;          <a-popconfirm title="Sure to cancel?" @confirm="() => cancel(record.key)">&ndash;&gt;-->
          <!--                    &lt;!&ndash;            <a>Cancel</a>&ndash;&gt;-->
          <!--                    &lt;!&ndash;          </a-popconfirm>&ndash;&gt;-->
          <!--                  </span>-->
          <!--              <span v-else>-->
          <!--            <a :disabled="editingKey !== ''" @click='edit(record.id)'>Edit</a>-->
          <!--        </span>-->
          <a @click='handleAdd' v-if='index === dataSource.length - 1'>添加</a>
        </div>
      </template>
    </a-table>
  </a-modal>

</template>

<script>
export default {
  name: 'AddPosition',
  data(){
    return {
      columns: [
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function(t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '经度',
          align: 'center',
          dataIndex: 'lng',
          scopedSlots: { customRender: 'lng' }
        },
        {
          title: '纬度',
          align: 'center',
          dataIndex: 'lat',
          scopedSlots: { customRender: 'lat' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: [],
      visible:false
    }
  },
  methods:{
    show(){
      this.visible = true
      this.dataSource = [
        {
          id: 0,
          lng: '',
          lat: ''
        }
      ]
    },
    handleInputChange(value, key, column) {
      const newData = [...this.dataSource]

      const target = newData.filter(item => key === item.id)[0]
      if (target) {
        target[column] = value
        this.dataSource = newData;
      }
    },
    handleAdd() {
      const { dataSource } = this
      const newData = {
        id: dataSource.length + 1,
        lat: '',
        lng: ''
      }
      this.dataSource = [...dataSource, newData]
    },
    handleOk(){
      this.$emit('ok',this.dataSource)
      this.visible = false
    }
  }
}
</script>

<style scoped>

</style>