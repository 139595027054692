<template>
  <a-calendar @panelChange="onPanelChange" @select="handleSelect">
    <template slot="dateCellRender" slot-scope="value">
      <div class="holidayText" v-if="getHolidayData(value)">休</div>
    </template>
  </a-calendar>
</template>

<script>

  //存储当前页面日期
  let dateList = []

  import {getAction} from 'dbcp-frontends-common/lib/api'
  import moment from 'moment'

  export default {
    name: 'calendar',
    data() {
      return {
        url_holiday: "/oa/dbcp/dbcpAttendanceHoliday/list",
        holidayList:[],
        defaultValue: moment()
      }
    },
    mounted:function() {
      //页面加载
      this.onLoad();
    },
    methods: {
      onLoad(){
        let param = {
          pageSize:-1,
          date_begin:dateList[0],
          date_end:dateList[41],
        };
        //请求后端接口
        getAction(this.url_holiday,param).then(res=> {
          if (res.success) {
            if (res.result.length>0){
              for (var i = 0;i<res.result.length;i++){
                this.holidayList.push(res.result[i].date);
              }
            }
          }
        });
      },
      //日期面板变化事件
      onPanelChange(value, mode) {
        dateList = []
        this.holidayList = []
        this.onLoad()
      },
      //自定义日期标签
      getHolidayData(value){
        var _that = this;

        //日期格式化
        var time = value.format("YYYY-MM-DD");
        dateList.push(time)

        return _that.holidayList.filter(date => date === time).length > 0;

      },
      handleSelect(value) {
        console.log('call handleSelect', value)
        this.defaultValue = value
        this.$store.dispatch('SetAccountingDate', value.format('YYYY-MM-DD'))
      }
    },
  }
</script>

<style scoped>
  .ant-fullcalendar-fullscreen {
    background: white;
  }
  .holidayText{
    color: #1890FF;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    height: 100%;
    line-height: 100%;
  }
</style>
