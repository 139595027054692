<template>
  <a-modal
    v-model='itemListVisible'
    @ok='itemListVisible = false'
    width='90%'
    @cancel='handleCancel'
    :footer='null'
  >
    <component ref='itemTable' :is='currentComponents' :currentRecord='currentRecord' @closeModal='closeModal' v-bind='$attrs'></component>
  </a-modal>
</template>

<script>
export default {
  name: 'TurnListModal',
  data(){
    return {
      itemListVisible: false,
      currentComponents: null,
      currentRecord: {},
      queryParam: {},
    }
  },
  props:{
    url:{
      type:String,
      default:"",
    },
  },
  methods: {
    show(record) {
      console.log("url",this.url)
      import(`@views/${this.url}`).then(res => {
        this.currentComponents = res.default
        console.log("TurnUnitList record",record)
        this.currentRecord = record ;
        this.itemListVisible = true ;
      })

    },
    handleCancel() {
      this.itemListVisible = false ;
    },
    closeModal(){
      this.itemListVisible = false ;
    }
  }
}
</script>

<style scoped>

</style>