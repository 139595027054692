<template>
  <a-drawer
    :title="title"
    :width="width"
    placement="right"
    :closable="false"
    @close="close"
    :visible="visible">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item label="文件路径" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea v-decorator="['path']" rows="4"/>
        </a-form-item>
        <a-form-item label="文件名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['title']"></a-input>
        </a-form-item>
        <a-form-item label="文件扩展名" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['extension']"></a-input>
        </a-form-item>
        <a-form-item label="文件大小" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['size']" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="文件所属模块" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['objectType']"></a-input>
        </a-form-item>
        <a-form-item label="文件所属模块id(关联id)" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['objectId']" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="上传时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-date v-decorator="['addedTime']" :trigger-change="true" style="width: 100%"/>
        </a-form-item>
        <a-form-item label="上传用户" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['addedUsername']"></a-input>
        </a-form-item>
        <a-form-item label="下载次数" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['download']" style="width: 100%"/>
        </a-form-item>

      </a-form>
    </a-spin>
    <a-button type="primary" @click="handleOk">{{ $t('confirm') }}</a-button>
    <a-button type="primary" @click="handleCancel">{{ $t('cancel') }}</a-button>
  </a-drawer>
</template>

<script>

  import { httpAction } from 'dbcp-frontends-common/lib/api'
  import pick from 'lodash.pick'
  import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
  import { JDate } from 'dbcp-frontends-common/lib/components'

  export default {
    name: "DbcpFileModal",
    components: {
      JDate,
    },
    data () {
      return {
        form: this.$form.createForm(this),
        title: this.$i18n.tc('action'),
        width: 800,
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules: {
        },
        url: {
          add: "/oa/dbcp/dbcpFile/add",
          edit: "/oa/dbcp/dbcpFile/edit",
        }
      }
    },
    created () {
    },
    methods: {
      add () {
        this.edit({});
      },
      edit (record) {
        this.form.resetFields();
        this.model = Object.assign({}, record);
        this.visible = true;
        this.$nextTick(() => {
          this.form.setFieldsValue(pick(this.model,'path','title','extension','size','objectType','objectId','addedTime','addedUsername','download'))
        })
      },
      close () {
        this.$emit('close');
        this.visible = false;
      },
      handleOk () {
        const that = this;
        // 触发表单验证
        this.form.validateFields((err, values) => {
          if (!err) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
               method = 'put';
            }
            let formData = Object.assign(this.model, values);
            console.log("表单提交数据",formData)
            httpAction(httpurl,formData,method).then((res)=>{
              if(res.success){

                that.$emit('ok');
              }else{

              }
            }).finally(() => {
              that.confirmLoading = false;
              that.close();
            })
          }

        })
      },
      handleCancel () {
        this.close()
      },
      popupCallback(row){
        this.form.setFieldsValue(pick(row,'path','title','extension','size','objectType','objectId','addedTime','addedUsername','download'))
      }

    }
  }
</script>

<style lang="less" scoped>
/** Button按钮间距 */
  .ant-btn {
    margin-left: 30px;
    margin-bottom: 30px;
    float: right;
  }
</style>
