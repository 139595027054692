<template>
  <j-modal
    :title='title'
    :width='width'
    :visible='visible'
    :confirmLoading='confirmLoading'
    switchFullscreen
    @ok='handleOk'
    @cancel='handleCancel'
    :cancelText="$t('cancel')">
    <a-spin :spinning='confirmLoading'>
      <a-form :form='form'>


        <a-form-item label='房屋性质' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <j-dict-select-tag type='list' v-decorator="['propertyType']" :trigger-change='true'
                             dictCode='cum_property_type' :openSearch='true'/>
        </a-form-item>
        <a-form-item label='结构类型' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <j-dict-select-tag type='list' v-decorator="['structureType']" :trigger-change='true'
                             dictCode='cum_structure_type' />
        </a-form-item>
        <a-form-item label='补偿类型' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-select  style="width: 100%" v-decorator="['compensateType', validatorRules.compensateType]">
            <template v-for='item in typeList' >
              <a-select-option :value="item.id" :key='item.id'>
                {{ item.name  }}
              </a-select-option>
            </template>
          </a-select>
        </a-form-item>
        <a-form-item label='补偿标准' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input v-decorator="['compensation', validatorRules.compensation]"
                   style='width: 100%' />
        </a-form-item>
        <a-form-item label='备注' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-textarea v-decorator="['remark']" rows='4' placeholder='请输入备注' />
        </a-form-item>

      </a-form>
    </a-spin>

    <template slot='footer'>
      <!--      <a-button type="primary" @click="handleOk(false)">{{ $t('saveDraft') }}</a-button>-->
      <a-button type='primary' @click='handleOk(true)'>{{ $t('submit') }}</a-button>
      <a-button @click='handleCancel'>{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
import { cumCompensationStandardApi } from './api/CumCompensationStandard'
import { httpAction, getAction } from 'dbcp-frontends-common/lib/api'
import pick from 'lodash.pick'
import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
import { Attachment } from 'dbcp-frontends-common/lib/components'
import { JDate } from 'dbcp-frontends-common/lib/components'
import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'
import { UploadFormMixin } from 'dbcp-frontends-common/lib/mixins'
import { JDictSelectTag } from 'dbcp-frontends-common/lib/components'

export default {
  name: 'CumCompensationStandardModal',
  components: {
    JDate,
    JSelectUserByDep,
    Attachment,
    JDictSelectTag
  },
  mixins: [UploadFormMixin],
  data() {
    return {
      form: this.$form.createForm(this),
      title: this.$i18n.tc('action'),
      width: 800,
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      validatorRules: {
        projectId: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        propertyType: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        structureType: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        compensateMethod: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        compensateType: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        compensation: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        compensationRate: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        addBy: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        addTime: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        updateBy: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        updateTime: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        }
      },
      url: cumCompensationStandardApi.url,
      typeList: []
    }
  },
  created() {
  },
  methods: {
    afterAdd(record) {
      this.getCumCompensationType(record)
      this.edit({
        ...record
      })
    },
    afterEdit(record) {
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'remark', 'projectId', 'propertyType', 'structureType', 'compensateMethod', 'compensateType', 'compensation', 'compensationRate', 'addBy', 'addTime', 'updateBy', 'updateTime'))
      })
    },
    afterClose() {
    },
    popupCallback(row) {
      this.form.setFieldsValue(pick(row, 'projectId', 'propertyType', 'structureType', 'compensateMethod', 'compensateType', 'compensation', 'compensationRate', 'addBy', 'addTime', 'updateBy', 'updateTime'))
    },
    /**
     * 查询补偿类型
     * @param projectId
     * @param compensateMethod
     */
    async getCumCompensationType({ projectId, compensateMethod }) {
      let result = await getAction('/cum/cumCompensationType/list', {
        projectId,
        compensateMethod,
        pageSize: -1
      })
      if (result.success) {
        this.typeList = result.result
      }
    }

  }
}
</script>
