<template>
  <div>
    <a-modal
      :title="title"
      :width="width"
      placement="right"
      @cancel="close"
      :footer="null"
      :visible="visible">
      <a-descriptions :column="3" bordered size="small">
        <a-descriptions-item :label="$t('serialNumber')" :span="3">
          {{ data.serialNumber }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('createBy')">
          {{ data.addBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('createTime')">
          {{ data.addTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('applicant')">
          {{ data.applicant_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('dept')">
          {{ data.dept_dictText }} ({{ data.companyName }})
        </a-descriptions-item>
        <a-descriptions-item :label="$t('personnel')">
          {{ data.personnel_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('location')">
          {{ data.location_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('address')">
          <span v-html="data.address ? cutout(data.address): '' "/>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('schedule')">
          <span v-html="data.schedule ? cutout(data.schedule): '' "/>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('cause')">
          <span v-html="data.cause ? cutout(data.cause): '' "/>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('trafficType')">
          {{ data.trafficType_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('trafficRemark')">
          <span v-html="data.trafficRemark ? cutout(data.trafficRemark): '' "/>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('accommodationType')">
          {{ data.accommodationType_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('accommodationRemark')">
          <span v-html="data.accommodationRemark ? cutout(data.accommodationRemark): '' "/>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('loanDemand')">
          {{ data.loanDemand ? $t('yes') : $t('no') }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('useing')" v-if="data.loanDemand !== false">
          <span v-html="data.useing ? cutout(data.useing): '' "/>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('beginTime')">
          {{ data.beginTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('endTime')">
          {{ data.endTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('status')">
          {{ $t(`workflowStatus.${data.status}`) }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('currentProcessor')">
          {{ data.approver_dictText }}
        </a-descriptions-item>
      </a-descriptions>

      <a-divider></a-divider>

      <detail-step :url="`/oa/dbcp/dbcpBusinessTrip/workflowVisual?id=${data.id}`" :visible="visible" ref="detailStep"></detail-step>

      <!--   actions   -->
      <div class="detail-actions" v-if="data.action && Object.keys(data.action).length > 0">
        <a-divider></a-divider>
        <span v-for="action in Object.keys(data.action)">
         <a-button
            type="primary"
            @click="handleActionClick([data, action.replace('_dictText','')])"
            class="detail-actions-button"
            v-if="action.includes('_dictText')"
          >
            {{ data.action[action]}}
          </a-button>
        </span>
      </div>

      <a-divider></a-divider>

      <detail-history :file-list="data.fileList" :history-list="histories"></detail-history>
    </a-modal>

    <a-modal
      :visible="actionFormVisible"
      :title="$t(`actionCode[\'${action}\']`)"
      @ok="performAction"
      @cancel="actionFormVisible = false"
      :confirmLoading="actionLoading"
    >
      <a-form>
        <a-form-item :label="$t('comment')">
          <a-textarea v-model="actionForm.comment"></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { businessTripApi } from './api/DbcpBusinessTripWorkFlow'
import { ActionMixin } from 'dbcp-frontends-common/lib/mixins'
import { DetailHistory } from 'dbcp-frontends-common/lib/components'
import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'
import DetailStep from '@comp/dbcp/detail/DetailStep'

export default {
  name: 'DbcpBusinessTripDetail',
  components: { DetailHistory, DetailStep },
  mixins: [DetailMixin, ActionMixin],
  data() {
    return {
      url: businessTripApi.url,
      actionData: null,
      currentData: null
    }
  },
  methods: {
    actionClick(data, action) {
      console.log(data)
      this.currentData = data
    }
  }
}
</script>

<style scoped lang="less">
</style>