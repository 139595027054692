<template>
  <a-card :bordered='false'>

    <div >
      <!--   展示图片   -->
      <a-row >
        <a-col :span='6' style='margin-bottom: 25px;'  v-for='(item,index) in fileList'>
          <div >

            <div style='padding-left:43px;display: inline-block'>
              <!--              <a-upload-->
              <!--                name='avatar'-->
              <!--                list-type='picture-card'-->
              <!--                class='avatar-uploader'-->
              <!--                :show-upload-list='false'-->
              <!--                :before-upload='beforeUpload'-->
              <!--                @change='handleChange'-->
              <!--              >-->
              <!--                <img v-if='fileList' :src='imageUrl' alt='avatar' width='100' height='100'/>-->
              <!--                <div v-else>-->
              <!--                  <a-icon :type="loading ? 'loading' : 'plus'" />-->
              <!--                  <div class='ant-upload-text'>-->
              <!--                    Upload-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </a-upload>-->
              <img :src='item.url' :key='index' alt='avatar' width='100' height='100'/>
            </div>

            <div>
              <p>
                <span>排序：</span><a-input style='width:120px;margin:6px 0 6px 0;' placeholder="Basic usage" />
              </p>
              <p>
                <span>操作：<a-button type="primary">更新</a-button>
                   <a-button type="danger" style='background:red'>删除</a-button>
                </span>
              </p>
            </div>
          </div>
        </a-col>

      </a-row>
      <!--上传图片区域-->
      <div>
        <div style='padding-left:43px;display: inline-block'>

          <a-upload
            name='avatar'
            list-type='picture-card'
            class='avatar-uploader'
            :show-upload-list='false'
            action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
            :before-upload='beforeUpload'
            @change='handleChange'
          >
            <img v-if='imageUrl' :src='imageUrl' alt='avatar' width='100' height='100'/>
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class='ant-upload-text'>
                Upload
              </div>
            </div>
          </a-upload>

        </div>

        <div>
          <p>
            <span>排序：</span><a-input style='width:120px;margin:6px 0 6px 0;' placeholder="Basic usage" />
          </p>
          <p>
                <span>操作：<a-button type="primary">更新</a-button>
                   <a-button type="danger" style='background:red'>删除</a-button>
                </span>
          </p>
        </div>

      </div>

    </div>


  </a-card>
</template>

<script>
import { dbcpHomeCarouselApi } from './modules/api/DbcpHomeCarousel'
import '@/assets/less/TableExpand.less'
import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
import { PrinterMixin } from 'dbcp-frontends-common/lib/mixins'
import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
import DbcpHomeCarouselModal from './modules/DbcpHomeCarouselModal'
import DbcpHomeCarouselDetail from './modules/DbcpHomeCarouselDetail'
// import DbcpHomeCarouselPrint from './modules/DbcpHomeCarouselPrint'
import ApprovalTab from '@comp/dbcp/approval/ApprovalTab'
import { ListActions } from 'dbcp-frontends-common/lib/components'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  name: 'DbcpHomeCarouselList',
  mixins: [JeecgListMixin, mixinDevice, ViewMixin, PrinterMixin, ApprovalListMixin],
  components: {
    DbcpHomeCarouselModal,
    // DbcpHomeCarouselPrint,
    DbcpHomeCarouselDetail,
    ListActions,
    ApprovalTab
  },
  data() {
    return {
      description: 'dbcp_home_carousel管理页面',

      url: dbcpHomeCarouselApi.url,
      dictOptions: {},

      loading: false,
      imageUrl: '',
      fileList:[
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
        {
          uid: '-2',
          name: 'image.png',
          status: 'done',
          url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
      ]

      // {//文件列表
      //   id:null,
      //     title:null,//标题
      //   sort:null,//排序
      //   url:null,//路径
      // }

    }
  },
  computed: {
    importExcelUrl: function() {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    }
  },
  methods: {
    initDictConfig() {
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, imageUrl => {
          this.imageUrl = imageUrl
          this.loading = false
        })
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!')
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!')
      }
      return isJpgOrPng && isLt2M
    }
  }
}
</script>
<style scoped>
@import '~@assets/less/common.less';

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
