import { render, staticRenderFns } from "./CumBuildingModal.vue?vue&type=template&id=a5de32f6&scoped=true&"
import script from "./CumBuildingModal.vue?vue&type=script&lang=js&"
export * from "./CumBuildingModal.vue?vue&type=script&lang=js&"
import style0 from "./CumBuildingModal.vue?vue&type=style&index=0&id=a5de32f6&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5de32f6",
  null
  
)

export default component.exports