<template>
  <a-modal
    v-model='itemListVisible'
    @ok='itemListVisible = false'
    width='90%'
    @cancel='handleCancel'
    :footer='null'
  >
    <cum-collection-payment-list ref='itemTable' :projectId='currentRecord.projectId' :rightHolder='currentRecord.rightHolder' @closeModal='closeModal'></cum-collection-payment-list>
  </a-modal>
</template>

<script>


import CumProjectItemTable from './CumProjectItemTable'
import CumCollectionPaymentList from '../collectionPayment/CumCollectionPaymentList'
export default {
  name: 'TurnPaymentModal',
  components: { CumCollectionPaymentList, CumProjectItemTable },
  data() {
    return {
      itemListVisible: false,
      description: '跳转查看收楼付款模态框',
      currentRecord:{},
      queryParam:{},
    }
  },
  computed: {
    importExcelUrl: function() {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    }
  },
  methods: {
    initDictConfig() {
    },
    show(record) {
      console.log("TurnUnitList record",record)
      this.currentRecord = record ;
      this.itemListVisible = true ;

      // this.$nextTick(()=>{
      //   this.$refs.itemTable.initListData(record);
      // })
    },
    handleCancel() {
      // this.$emit('close');
      this.itemListVisible = false ;
    },
    closeModal(){
      this.itemListVisible = false ;
    }


  }
}
</script>
<style scoped>
@import '~@assets/less/common.less';
</style>
