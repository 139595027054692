<template>
  <j-modal
    :title='title'
    :width='width'
    :visible='visible'
    :confirmLoading='confirmLoading'
    switchFullscreen
    @ok='handleOk'
    @cancel='handleCancel'
    v-if='visible'
    :cancelText="$t('cancel')">
    <a-spin :spinning='confirmLoading'>
      <a-form :form='form'>
        <a-form-item label='项目' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <!--          <a-input-number v-decorator="['projectItemId', validatorRules.projectItemId]" placeholder="请输入项目明细" style="width: 100%"/>-->
          <InputSelect v-decorator="['projectId', validatorRules.projectId]" show-name='name' send-params='id'
                       url='dbcp/city/projectManagement/CumProjectList.vue'
                       type='radio' :isEdit='model.projectId?true:false' @ok='handleSelectProject'></InputSelect>
        </a-form-item>
        <a-form-item label='项目明细' :labelCol='labelCol' :wrapperCol='wrapperCol'
                     v-if='form.getFieldValue("projectId") || model.projectItemId'>
          <InputSelect v-decorator="['projectItemId', validatorRules.projectItemId]" show-name='rightHolder_dictText'
                       send-params='id'
                       url='dbcp/city/projectManagement/CumProjectItemTable.vue'
                       :projectId='form.getFieldValue("projectId")'
                       type='radio' :isEdit='model.projectItemId?true:false' @ok='handleSelectItemOk'></InputSelect>
        </a-form-item>
        <!--        <a-form-item label="部门" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
        <!--          <j-select-depart v-decorator="['dept', validatorRules.dept]" multi/>-->
        <!--        </a-form-item>-->

        <div style='display: flex;margin: 20px 0' v-if='buildingsId.length > 0'>
          楼栋：
          <div v-for='item in buildingsId'>
            <a-tag color='#2db7f5' @click='handleToBuilding(item)' style='cursor: pointer'>
              #{{ item }}
            </a-tag>
          </div>

        </div>
        <a-table
          bordered
          :data-source='realDataSource'
          :columns='columns'
          :pagination='false'
          :customRow='exitCustomRow'
          :style='{ margin:"20px"}'
        >
          <template slot='money'
                    slot-scope='text,record,index'>
            <div style='display:flex;width: 100%' v-if='record.editable'>
              <a-input-number style='margin:-5px 0 ;' :value='text'
                       @change='e => handleChange(e,record.key,"money")'
              ></a-input-number>
            </div>
            <div v-else>{{ record.money || record.area }}</div>
          </template>

          <template
            v-for="col in ['remark']"
            :slot='col'
            slot-scope='text,record,index'
          >
            <div :key='col'>
              <div style='display:flex;' v-if='record.editable'>
                <a-input style='margin:-5px 0 ;' :value='text'
                         @change='e => handleChange(e.target.value,record.key,col)'
                ></a-input>
              </div>
              <div v-else>{{ text }}</div>
            </div>
          </template>

          <template
            slot='compensateMethod'
            slot-scope='text,record,index'
          >
            <div style='display:flex;width: 100%' v-if='record.editable'>
              <j-dict-select-tag type='list' v-model='record.compensateMethod' :open-search='true'
                                 dictCode='cum_compensation_standard_compensation_method' :showManage='false'
                                 style='width: 100%' />
            </div>
            <div v-else>{{ compensateMethod[text] }}</div>
          </template>

          <template
            slot='compensateType'
            slot-scope='text,record,index'
          >
            <div style='display:flex;width: 100%' v-if='record.editable'>

              <a-select style='width: 100%' :value='record.compensateType' show-search
                        @change='(a,b) => handleCompensateTypeChange(a,b,record)' :filter-option='filterOption'>
                <a-select-option :value='item.value' v-for='item in findTypeByMethod(record.compensateMethod)'>
                  {{ item.text }}
                </a-select-option>
              </a-select>
            </div>
            <div v-else>{{ findNameByType(record.compensateType) }}</div>
          </template>

          <template
            slot='compensation'
            slot-scope='text,record,index'
          >
            <template>
              <a-tooltip>
                <template slot='title'>
                  {{ findItemValue(record.compensateType, 'remark', record, false) }}
                </template>
                {{ findItemValue(record.compensateType, 'compensation', record) }}
              </a-tooltip>

            </template>
          </template>
          <!--       最左边的操作  -->
          <template slot='operation' slot-scope='text, record,index'>
            <div class='editable-row-operations' v-if='index + 1 <= count'>
                <span class='editable-btn' v-if='record.optionShow'>
                    <div class='editable-add-btn' @click.stop='handleAdd(index)'>+</div>
                   <a-popconfirm
                     v-if='count > 1'
                     title='Sure to delete?'
                     @confirm='() => onDelete(record.key)'
                   >
                     <div class='editable-delete-btn'>-</div>
                  </a-popconfirm>
                </span>
            </div>
            <template v-else>
              {{ record.operation }}
            </template>
          </template>
        </a-table>

        <a-form-item :label="$t('attachments')" :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <attachment v-decorator="['upload']" @handleFileRemove='handleFileRemove' @beforeFileUpload='beforeFileUpload'
                      :file-list='uploadFiles' :default-file-list='defaultFileList'></attachment>
        </a-form-item>
      </a-form>
    </a-spin>
    <cumBuilding-detail ref='buildingDetailForm'></cumBuilding-detail>
    <template slot='footer'>
      <a-button type='primary' @click='handleOk(false)'>{{ $t('saveDraft') }}</a-button>
      <a-button type='primary' @click='handleOk(true)'>{{ $t('submit') }}</a-button>
      <a-button @click='handleCancel'>{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
import { cumBargainingPowerApi } from './api/CumBargainingPower'
import { ajaxGetDictItems, getAction, httpAction, postAction } from 'dbcp-frontends-common/lib/api'
import pick from 'lodash.pick'
import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
import { Attachment } from 'dbcp-frontends-common/lib/components'
import { JDate } from 'dbcp-frontends-common/lib/components'
import { JSelectDepart } from 'dbcp-frontends-common/lib/components'
import { UploadFormMixin } from 'dbcp-frontends-common/lib/mixins'
import InputSelect from '@/components/dbcp/InputSelect'
import { JDictSelectTag } from 'dbcp-frontends-common/lib/components'
import CumBuildingDetail from '../../propertyManagement/buildingList/modules/CumBuildingDetail'

export default {
  name: 'CumBargainingPowerModal',
  components: {
    JDate,
    JSelectDepart,
    Attachment,
    InputSelect,
    JDictSelectTag,
    CumBuildingDetail
  },
  mixins: [UploadFormMixin],
  data() {
    return {
      form: this.$form.createForm(this),
      title: this.$i18n.tc('action'),
      width: '80%',
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      validatorRules: {
        projectId: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        projectItemId: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        }
      },
      url: cumBargainingPowerApi.url,
      columns: [
        {
          title: '#',
          dataIndex: '',
          width: 60,
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        },
        // {
        //   title: '谈判条件ID',
        //   align: 'center',
        //   dataIndex: 'bargainingPowerId',
        //   scopedSlots: { customRender: 'bargainingPowerId'}
        // },
        {
          title: '补偿方式',
          align: 'center',
          dataIndex: 'compensateMethod',
          scopedSlots: { customRender: 'compensateMethod' },
          width: '200px'
        },
        {
          title: '补偿类型',
          align: 'center',
          dataIndex: 'compensateType',
          scopedSlots: { customRender: 'compensateType' }
        },
        {
          title: '补偿标准',
          align: 'center',
          dataIndex: 'compensation',
          scopedSlots: { customRender: 'compensation' }
        },
        {
          title: '总额',
          align: 'center',
          dataIndex: 'money',
          scopedSlots: { customRender: 'money' },
          width: '200px'
        },
        {
          title: '备注',
          align: 'center',
          dataIndex: 'remark',
          scopedSlots: { customRender: 'remark' }
        }
      ],
      dataSource: [
        {
          key: 0,
          bargainingPowerId: '',
          compensateMethod: '',
          compensateType: '',
          compensation: '',
          money: '',
          remark: '',
          editable: true
        }
      ],
      canIEdit: true,
      filterSelectArr: [],
      currentMethod: '-1',
      currentType: '-1',
      cumCompensationStandardList: [],
      compensateMethod: {},
      compensateType: {},
      buildingsId: [],
      // 当前编辑的record
      currentEditRecord: {},
      compensationSelectList: [],
      cumCompensationTypeList: []
    }
  },
  computed: {
    count() {
      return this.dataSource.length
    },
    realDataSource() {
      let arr = [...this.dataSource]
      arr.forEach(item => {
        item.key = item.id || item.key
      })

      return arr
    }
  },
  created() {
  },
  methods: {
    afterAdd(record) {
      this.edit({
        ...record
      })
    },
    async afterEdit(record) {
      console.log('record', record)
      let { result: method } = await ajaxGetDictItems('cum_compensation_standard_compensation_method')

      method.forEach(item => {
        this.compensateMethod[item.value] = item.title
      })
      // this.dataSource = record

      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'serialNumber', 'dept', 'addBy', 'addTime', 'status', 'deleted', 'projectItemId', 'projectId'))
        if (record.projectId) {
          this.handleSelectProject()
        }
        if (record.projectItemId && record.projectId) {
          this.handleSelectItemOk()
        }
        if (record.targetList) {
          record.targetList.forEach(item => {
            item.compensateType = item.compensationStandardId
          })
          this.dataSource = record.targetList
        }
      })

    },
    afterClose() {
      this.dataSource = [
        {
          key: 0,
          bargainingPowerId: '',
          compensateMethod: '',
          compensateType: '',
          compensation: '',
          money: '',
          remark: '',
          editable: true
        }
      ]
      this.buildingsId = []
    },
    popupCallback(row) {
      this.form.setFieldsValue(pick(row, 'serialNumber', 'dept', 'addBy', 'addTime', 'status', 'deleted', 'projectItemId', 'projectId'))
    },
    handleChange(value, key, column) {
      const newData = [...this.dataSource]
      const target = newData.filter(item => key === item.key)[0]
      if (target) {
        target[column] = value
        this.dataSource = newData
      }
    },
    // 选择类型
    handleSelectType(e, key) {
      const newData = [...this.dataSource]
      const record = newData.filter(item => key === item.key)[0]
      if (record) {
        this.dataSource = newData
      }
    },
    exitCustomRow(record, index) {
      return {
        on: {
          click: event => {
            if (this.canIEdit) {
              //当前点击行
              const newData = [...this.dataSource]
              for (let i = 0; i < newData.length; i++) {
                if (index === i) {
                  newData[i].editable = true
                  newData[i].optionShow = true
                  this.currentEditRecord = newData[i]
                  this.dataSource = newData
                } else {
                  newData[i].editable = false
                  newData[i].optionShow = false
                }
              }
            }

          }
        }

      }
    },
    handleAdd(index) {
      const { dataSource } = this
      const newData = {
        key: this.count,
        bargainingPowerId: '',
        compensateMethod: '',
        compensateType: '',
        compensation: '',
        money: '',
        remark: '',
        editable: true
      }
      this.dataSource = [...dataSource, newData]
      this.dataSource[index].editable = false
    },
    onDelete(key) {
      const dataSource = [...this.dataSource]
      this.dataSource = dataSource.filter(item => item.key !== key)
    },
    //  提交表单
    processFormData(formData) {
      console.log(this.dataSource)
      let arr = JSON.parse(JSON.stringify(this.dataSource))
      arr.forEach(item => {
        let t = item.compensateType
        item.compensateType = item.realCompensateType
        item.compensationStandardId = t
        if (item.compensateMethod == '1') {
          item.area = item.money
          delete item.money
        }
        delete item.realCompensateType
      })
      formData.set('targetList', JSON.stringify(arr))
      return formData
    },
    handleSelectProject() {
      getAction('/cum/cumCompensationType/list', {
        projectId: this.form.getFieldValue('projectId'),
        pageSize: -1
      }).then(res => {
        if (res.success) {
          //  补偿类型
          this.cumCompensationTypeList = res.result
        }
      })

      getAction('/cum/cumCompensationStandard/list', {
        projectId: this.form.getFieldValue('projectId'),
        pageSize: -1
      }).then(res => {
        if (res.success) {
          this.cumCompensationStandardList = res.result
        }
      })
    },
    handleSelectItemOk() {
      getAction('/cum/cumProjectItem/getBuildingInformation', {
        projectItemId: this.form.getFieldValue('projectItemId')
      }).then(res => {
        if (res.success) {
          this.buildingsId = res.result.buildingIds
        }
      })
    },
    /**
     * 根据字典从标准找到对应项 是否可以修改
     */
    findItemIsDisable(method, type, key) {
      let arr = this.cumCompensationStandardList.filter(item => {
        return item.compensateMethod === method && item.compensateType === type
      })
      if (!arr[0]) {
        return false
      }
      return arr[0][key]
    },
    /**
     * 根据补偿方式找到补偿类型
     */
    findTypeByMethod(method) {
      if (!method) return []
      let map = {}
      if ((this.cumCompensationStandardList && this.cumCompensationStandardList.length === 0) || (this.cumCompensationTypeList && this.cumCompensationTypeList.length === 0)) {
        return []
      }
      let arr = this.cumCompensationTypeList.filter(item => {
        if (item.compensateMethod === method) {
          map[item.id] = true
          return true
        }
      })

      let standardArr = []
      standardArr = this.cumCompensationStandardList.filter(item => {
        return map[Number(item.compensateType)]
      })
      console.log(standardArr, map)

      let selectArr = []
      standardArr.forEach(item => {
        let str = ''
        if (item.structureType_dictText) {
          str += item.structureType_dictText + '-'
        }
        if (item.propertyType_dictText) {
          str += item.propertyType_dictText + '-'
        }
        str += item.compensateType_dictText
        let obj = {
          text: str,
          value: item.id
        }
        selectArr.push(obj)
      })


      if (!arr[0]) {
        return []
      }
      return selectArr
    },
    /**
     * 找到标准对应的值
     * @param method
     * @param type
     * @param key
     * @param isUpdate 是否赋值
     */
    findItemValue(type, key, record, isUpdate = true) {
      console.log(type, key)
      let arr = this.cumCompensationStandardList.filter(item => {
        return item.id == type
      })

      if (!arr[0]) {
        return ''
      }
      if (isUpdate) {
        record[key] = arr[0][key]
      }

      return arr[0][key]
    },

    handleToBuilding(id) {
      getAction('/cum/cumBuilding/list', { id: id }).then(res => {
        console.log('获取楼栋信息', res.result.records)
        this.$refs.buildingDetailForm.view(res.result.records[0])
      })
    },
    /**
     * 根据id 去找补偿类型
     * @param id
     */
    findNameByType(id) {
      let arr = this.cumCompensationStandardList.filter(item => {
        console.log(item.id, id)
        return item.id == id
      })
      if (!arr[0]) {
        return ''
      }
      let str = ''
      arr.forEach(item => {

        if (item.structureType_dictText) {
          str += item.structureType_dictText + '-'
        }
        if (item.propertyType_dictText) {
          str += item.propertyType_dictText + '-'
        }
        str += item.compensateType_dictText
      })
      return str
    },
    handleCompensateTypeChange(value, option, record) {
      record.realCompensateType = this.cumCompensationStandardList.filter(item => item.id == value)[0].compensateType || ''
      record.compensateType = value
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
</script>

<style scoped lang='less'>
.ant-table-tbody .ant-table-row td {
  padding: 20px 8px;
}

.editable-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  line-height: 15px;
  font-weight: 500;
}

.editable-add-btn {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #4ea9fe;
  text-align: center;
  margin-right: 4px;
  color: #2596ff;

}

.editable-delete-btn {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #f8b4be;
  text-align: center;
  color: #ff2741;
}

.editable-row-operations a {
  margin-right: 8px;
}
</style>
