<template>
  <j-modal
    :title='title'
    :width='width'
    :visible='visible'
    :confirmLoading='confirmLoading'
    switchFullscreen
    @ok='handleOk'
    @cancel='handleCancel'
    :cancelText="$t('cancel')">
    <a-table ref='table'
             size='middle'
             bordered
             rowKey='id'
             :columns='columns'
             :dataSource='realDataSource'
             :pagination='false'
    >
      <template slot='detail' slot-scope='text, record,index'>
        <p v-for='item in record.cumPaymentAmountList'>
          {{ item.compensateTypeId_dictText }} : {{item.amount}}
        </p>
      </template>
    </a-table>
    <br>
    <a-spin :spinning='confirmLoading'>
      <a-form :form='form'>

        <a-form-item label='此次付款金额' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input-number v-decorator="['thisPayment']" style='width: 100%'></a-input-number>
        </a-form-item>
        <a-form-item label='备注' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-textarea v-decorator="['remark']" rows='4' placeholder='请输入备注' />
        </a-form-item>
        <a-form-item :label="$t('attachments')" :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <attachment v-decorator="['upload']" @handleFileRemove='handleFileRemove' @beforeFileUpload='beforeFileUpload'
                      :file-list='uploadFiles' :default-file-list='defaultFileList'></attachment>
        </a-form-item>

      </a-form>
    </a-spin>

    <template slot='footer'>
      <a-button type='primary' @click='handleOk(false)'>{{ $t('saveDraft') }}</a-button>
      <a-button type='primary' @click='handleOk(true)'>{{ $t('submit') }}</a-button>
      <a-button @click='handleCancel'>{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
import { cumFirstPayApi } from './api/CumFirstPay'
import { httpAction } from 'dbcp-frontends-common/lib/api'
import pick from 'lodash.pick'
import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
import { Attachment } from 'dbcp-frontends-common/lib/components'
import { JDate } from 'dbcp-frontends-common/lib/components'
import { JSelectDepart } from 'dbcp-frontends-common/lib/components'
import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'
import { UploadFormMixin } from 'dbcp-frontends-common/lib/mixins'
import { getAction } from 'dbcp-frontends-common/lib/api'

export default {
  name: 'CumFirstPayModal',
  components: {
    JDate,
    JSelectDepart,
    JSelectUserByDep,
    Attachment
  },
  mixins: [UploadFormMixin],
  data() {
    return {
      form: this.$form.createForm(this),
      title: this.$i18n.tc('action'),
      width: '80%',
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      validatorRules: {
        money: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        projectId: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        projectItemId: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        }
      },
      url: cumFirstPayApi.url,
      moneyDisabled: false,
      columns: [
        {
          title: '项目细项',
          align: 'center',
          dataIndex: 'rightHolder_dictText'
        },
        {
          title: '应付款',
          align: 'center',
          dataIndex: 'rightHolder_dictText',
          children: [
            {
              title: '详情',
              align: 'center',
              dataIndex: 'detail',
              scopedSlots: { customRender: 'detail' }
            },
            {
              title: '合计',
              align: 'center',
              dataIndex: 'payableMoney'
            }
          ]
        },
        {
          title: '已付款',
          align: 'center',
          dataIndex: 'rightHolder_dictText',
          children: [
            {
              title: '合计',
              align: 'center',
              dataIndex: 'paidMoney'
            }
          ]
        },
        {
          title: '未付款',
          align: 'center',
          dataIndex: 'rightHolder_dictText',
          children: [
            {
              title: '合计',
              align: 'center',
              dataIndex: 'pendingMoney'
            }
          ]
        }
      ],
      dataSource:[]
    }
  },
  created() {
  },
  computed: {
    realDataSource() {
      let arr = [...this.dataSource]
      let allPayableMoney = arr.reduce((prev,next) => {
        return prev + next.payableMoney
      },0)

      let allTotalPaidMoney = arr.reduce((prev,next) => {
        return prev + next.paidMoney
      },0)

      let allTotalPendingMoney = arr.reduce((prev,next) => {
        return prev + next.pendingMoney
      },0)
      arr.push({
        rightHolder_dictText:'合计',
        payableMoney:allPayableMoney,
        paidMoney:allTotalPaidMoney,
        pendingMoney:allTotalPendingMoney
      })
      return arr
    }
  },
  methods: {
    afterAdd(record) {
      if(record.id){
        delete record.id
      }
      this.edit({
        ...record
      })
    },
    afterEdit(record) {
      console.log('record', record)
      this.dataSource = [record]
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'thisPayment','money', 'projectId', 'projectItemId', 'addTime', 'status', 'deleted', 'projectItemId', 'remark'))
      })
    },
    afterClose() {
      this.moneyDisabled = false
      this.payableMoneyDataSource = []
    },
    popupCallback(row) {
      this.form.setFieldsValue(pick(row, 'money', 'projectId', 'projectItemId', 'addTime', 'status', 'deleted', 'projectItemId'))
    },
    processFormData(formData) {
      formData.delete('id')
      return formData
    }

  }
}
</script>
