<template>
  <div class='index' ref='index'>
    <!--  <div class="bg"></div>-->
    <fullscreen :fullscreen.sync='fullscreen'>
      <div class='container' :style='{height:fullscreen?"100%":"10.5rem"}' ref='container'>
        <a-button type='primary' style='position: absolute;z-index: 9999999999' @click='quanping'>全屏</a-button>
        <!--        <a-button type='primary' style='position: absolute;right: 0;z-index: 9999' @click='filter'>筛选</a-button>-->
        <InputSelect show-name='name' send-params='id'
                     url='dbcp/city/projectManagement/CumProjectList.vue'
                     @ok='(data) => handleInputSelectOk(data)' type='radio'
                     style='position: absolute;right: 0;z-index: 9999999999' :show-button='true'
                     :get-container='$refs.container'>
          <div class='currentProjectItem'>
            {{ currentProjectItemId ?  currentProjectItemName : '全部' }}
            <span v-if='currentProjectItemId' @click.stop='changeAll'><a-icon type="undo" /></span>
          </div>
          <!--          <a-button>筛选</a-button>-->
        </InputSelect>
        <div class='head'>
          <dv-decoration-10 style='width:33.3%;height:.0625rem;' />
          <dv-decoration-8 :color="['#568aea', '#000000']" style='width:2.5rem;height:.625rem;' />
          <div class='title'>
            <div class='main'>
<!--              城更管控宝数据大屏-->
              {{ $store.state.config.appName }}
            </div>
            <dv-decoration-5 style='width:3.125rem;height:.2rem;' class='title-bottom' />
          </div>
          <dv-decoration-8 :reverse='true' :color="['#568aea', '#000000']" style='width:2.5rem;height:.625rem;' />
          <dv-decoration-10 style='width:33.3%;height:.0625rem;transform: rotateY(180deg);' />
        </div>

        <div class='content'>
          <dv-border-box-13 style='padding: 0.2rem;height: auto'>
            <div class='top'>
              <!--            总户数-->
              <div class='allHousehold'>
                <div class='info-title'>总户数</div>
                <div class='num'>
                  <div class='item' v-for='(item,index) in totalHouseholds.toString()' :key='index'>
                    {{ item }}
                  </div>
                </div>
              </div>
              <!--            已签约户数-->
              <div class='signedHousehold'>
                <div class='info-title'>已签约户数</div>
                <div class='num'>
                  <img src='./assets/fire.e1e2a310.png' alt='' style='width: 0.3rem;height: 0.5rem;'>
                  <div class='item' v-for='(item,index) in signedHouseholds.toString()' :key='index'>
                    {{ item }}
                  </div>
                </div>
              </div>
              <!--            未签约户数-->
              <div class='allHousehold'>
                <div class='info-title'>未签约户数</div>
                <div class='num'>
                  <div class='item' v-for='(item,index) in notSignedHouseholds.toString()' :key='index'>
                    {{ item }}
                  </div>
                </div>
              </div>
              <!--            总拆迁面积-->
              <div class='totalDemolitionArea'>
                <div class='info-title'>总拆迁面积(m²)</div>
                <div class='num'>
                  <div class='item' v-for='(item,index) in totalDemolitionArea.toString()' :key='index'>
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
          </dv-border-box-13>

          <div style='width: 100%;height: 75%;display: flex'>
            <div class='left'>
              <div style='height: 40%'>
                <dv-border-box-12>
                  <div style='height: 100%;display: flex'>
                    <div class='left-content-top'>
                      <div>
                        <div>
                          <dv-decoration-7 style='width:3rem;height:0.4rem;font-size: 0.22rem;color: white'>应付款金额
                          </dv-decoration-7>
                        </div>
                        <div class='number'>
                          {{ amountPayable }} <span class='unit'>元</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <dv-decoration-7 style='width:3rem;height:0.4rem;font-size: 0.22rem;color: white;'>已付款金额
                          </dv-decoration-7>
                        </div>
                        <div class='number'>
                          {{ amountPaid }} <span class='unit'>元</span>
                        </div>
                      </div>

                    </div>
                    <!--                    <div class='right-content-top'>-->
                    <!--                     -->
                    <!--                    </div>-->
                  </div>


                </dv-border-box-12>
              </div>
              <div style='height: 60%'>
                <dv-border-box-12>
                  <div class='left-content'>
                    <div style='padding: 0.2rem;height: 100%;position: relative'>
                      <div style='width: 100%;height: 100%;position: absolute;top: -0.8rem;transform: translateX(-50%);left: 50%'
                                 ref='chart' />

                      <div class='bottom-info'>
                        <div style='color: rgba(3,252,72,0.6)'>未付款：{{unpaidAmount}}</div>
                        <div style='color: rgba(215,9,9,0.8)'>已付款：{{ downPaymentMade }}</div>
                      </div>

                    </div>


                    <!--                  <dv-decoration-6 style='width:5rem;height:2rem;' />-->
                  </div>

                </dv-border-box-12>
              </div>

            </div>

            <div class='right'>
              <div style='display: flex;height: 60%'>
                <dv-border-box-12 style='height: 100%;width: 70%'>
                  <div style='padding: 0.2rem;height: 100%;'>
                    <city-map :can-edit='false' ref='CityMap' style='height: 100%'></city-map>
                  </div>
                </dv-border-box-12>

                <div style='height: 100%;width: 30%;position: relative'>

                  <dv-border-box-12 style='height: 50%;width: 100%'>
                      <dv-charts :option='signAreaOption' style='width: 100%;height: 100%' ref='area' />
                  </dv-border-box-12>
                  <div class='right-middle-area'>规划面积(m²)：{{planArea}}</div>
                  <dv-border-box-12 style='height: 50%;width: 100%'>
                      <dv-charts :option='signOption' style='width: 100%;height: 100%' ref='sign' />
                  </dv-border-box-12>
                </div>

              </div>
              <dv-border-box-12 style='height: 40%;position: relative'>
                <dv-charts :option='lineOptions' style='width: 115%;height: 115%;position: absolute;top: -5%;left: -7.5%'
                           ref='pay' />
              </dv-border-box-12>


            </div>
          </div>
          <dv-decoration-9
            style='width:1rem;height:1rem;position: relative;left: 50%;transform: translateX(-25%);margin-top: 0.1rem'
            v-show='fullscreen'></dv-decoration-9>
        </div>

        <!--        <fire-work style='position: absolute;top: 0;z-index: 999' ref='fireWork' @isStop='handleStop'></fire-work>-->
        <firework-two style='position: absolute;top: 0;z-index: 999999999;left: 0;opacity: 0.8;' ref='fireWork'
                      @isStop='handleStop' v-show='!isNoPlaying'></firework-two>
      </div>
    </fullscreen>

  </div>
</template>

<script>
import CityMap from '../../../../components/CityMap'
import VueFullscreen from 'vue-fullscreen'
import Vue from 'vue'
import { getAction } from 'dbcp-frontends-common/lib/api'
import CumProjectItemList from '../projectManagement/CumProjectItemList'
// import FireWork from '../../../../components/dbcp/city/FireWork'
import FireworkTwo from '../../../../components/dbcp/city/FireworkTwo'
import InputSelect from '../../../../components/dbcp/InputSelect'
import * as echarts from 'echarts';
Vue.use(VueFullscreen)
export default {
  name: 'DataOverview',
  components: {
    CityMap,
    CumProjectItemList,
    // FireWork,
    InputSelect,
    FireworkTwo
  },
  props: {
    realIndex: {
      type: Number
    }
  },
  data() {
    return {
      fullscreen: false,
      signedHouseholds: 1,
      notSignedHouseholds: 1,
      signedDemolitionArea: 1,
      notSignedDemolitionArea: 1,
      totalHouseholds: 0,
      totalDemolitionArea: 0,
      currentProjectItemId: '',
      timer: null,
      cacheProjectItemId: '',
      cacheSignedHouseholds: -1,
      isNoPlaying: true,
      currentProjectItemName: '',
      // 已首付款
      downPaymentMade: 0,
      // 已收楼付款
      paymentReceived: 0,
      // 应付款金额
      amountPayable: 0,
      //已付款金额
      amountPaid: 0,
      // 未付款
      unpaidAmount: 0,
    //  折线
      lineArr:[],
      legendArr:[],
      xName:[],
    //   规划面积
      planArea:'',
      myChart:null

    }
  },
  computed: {
    signOption() {
      let chu
      let beichu
      if (this.signedHouseholds + this.notSignedHouseholds === 0) {
        chu = 1
        beichu = 1
      } else {
        chu = this.signedHouseholds + this.notSignedHouseholds
        beichu = this.signedHouseholds
      }
      console.log(chu,beichu)
      let top = (beichu / chu).toFixed(2) * 100
      console.log('top', top)


      return {
        title: {
          show: false
        },
        color: ['rgba(3,252,72,0.6)', 'rgba(215,9,9,0.8)'],
        series: [
          {
            type: 'pie',
            data: [
              { name: '已签约', value:  top },
              { name: '未签约', value: 100 - top  }
            ],
            insideLabel: {
              show: true,
              style: {
                fontSize: this.nowSize(12)
              }
            },
            radius: ['40%', '50%'],
            outsideLabel: {
              style: {
                fontSize: this.nowSize(14),
                fontWeight: 'bold'
              },
              labelLineBendGap: '10%',
              labelLineEndLength: 30
            }
          }
        ]
      }
    },
    signAreaOption() {
      let chu
      let beichu
      if (this.signedDemolitionArea + this.notSignedDemolitionArea === 0) {
        chu = 1
        beichu = 1
      } else {
        chu = this.signedDemolitionArea + this.notSignedDemolitionArea
        beichu = this.signedDemolitionArea
      }
      console.log(chu,beichu)
      let top = (beichu / chu).toFixed(2) * 100
      console.log('top2', top)
      return {
        title: {
          show: false
        },
        color: ['rgba(3,252,72,0.6)', 'rgba(215,9,9,0.8)'],
        series: [
          {
            type: 'pie',
            data: [
              { name: '已签约面积', value:  top },
              { name: '未签约面积', value:  100 - top }
            ],
            insideLabel: {
              show: true,
              style: {
                fontSize: this.nowSize(12)
              }
            },
            radius: ['40%', '50%'],
            outsideLabel: {
              style: {
                fontSize: this.nowSize(14),
                fontWeight: 'bold'
              },
              labelLineBendGap: '10%',
              labelLineEndLength: 30
            }
          }
        ]
      }
    },
    payOption() {
      return {
        title: {
          show: false
        },
        color: ['rgba(3,252,72,0.6)', 'rgba(215,9,9,0.8)'],
        series: [
          {
            type: 'pie',
            data: [
              { name: '未付款', value:  this.unpaidAmount < 0 ? 0:this.unpaidAmount},
              { name: '已付款', value:  this.downPaymentMade < 0 ? 0:this.downPaymentMade }
            ],
            insideLabel: {
              show: true,
              style: {
                fontSize: this.nowSize(12)
              }
            },
            outsideLabel: {
              style: {
                fontSize: this.nowSize(14),
                fontWeight: 'bold'
              },
              labelLineBendGap: '5%',
              labelLineEndLength: 20
            }
          }
        ]
      }
    },
    lineOptions(){
      console.log('this.lineArr',this.lineArr)
      return  {
        title: {
          text: '月份数据统计',
          style: {
            fill: '#ffffff',
          }
        },
        color:['red','green','yellow','blue','orange'],
        legend: {
          data: this.legendArr,
          textStyle: {
            fill: '#ffffff'
          }
        },
        xAxis: {
          name: '',
          data: this.xName,
          axisLine:{
            style:{
              stroke:'rgba(255,255,255,0.3)',
              lineWidth: 2,
            }
          },
          nameTextStyle: {
            fill: '#ffffff',
          },
          axisLabel:{
            style:{
              fill: '#ffffff',
            }
          }
        },
        yAxis: {
          name: '',
          data: 'value',
          nameTextStyle: {
            fill: '#ffffff',
          },
          axisLine:{
            style:{
              stroke:'rgba(255,255,255,0.3)',
              lineWidth: 2,
            }
          },
          axisLabel:{
            style:{
              fill: '#ffffff',
            }
          }
        },
        series: this.lineArr
      }
    }
  },
  created() {
    this.getProjectItem()
  },
  mounted() {
    this.$refs.CityMap.show()
    this.timer = setInterval(() => {
      if ( this.isNoPlaying) {

        this.getProjectItemDetail(this.currentProjectItemId)
        // this.getPolylineData(this.currentProjectItemId)
      }
    }, 5000)
    //监听页面的变化
    window.addEventListener('resize',() => {
      this.myChart.resize();
    },false);
    this.keyDown()
  },
  destroyed() {
    clearInterval(this.timer)
  },
  deactivated() {
    clearInterval(this.timer)
  },
  methods: {
    quanping() {
      let ToggleMultipage = 'ToggleMultipage'
      this.fullscreen = !this.fullscreen
      // if (!this.fullscreen) {
      //     console.log('sssss')
      //     this.$store.dispatch(ToggleMultipage, false)
      //     this.$nextTick(() => {
      //       this.$store.dispatch(ToggleMultipage, true)
      //     })
      // }
    },
    filter() {
      this.$refs.CumProjectItemList.show({ projectId: undefined })
    },
    nowSize(val, initWidth = 1920) {
      let nowClientWidth = document.documentElement.clientWidth
      return val * (nowClientWidth / initWidth)
    },
    async getProjectItem() {
      let { result } = await getAction('/cum/cumProject/list')
      if (result.records.length > 0) {

        this.getProjectItemDetail()
        this.getPolylineData()
        this.currentProjectItemId = ''
        this.currentProjectItemName = '全部'
      }
    },
    getProjectItemDetail(id) {
      getAction('/cum/dataScreen/index', {
        projectId: id
      }).then(res => {
        console.log('res', res)
        if (res.result) {
          this.signedHouseholds = res.result.signedHouseholds
          this.notSignedHouseholds = res.result.notSignedHouseholds
          this.signedDemolitionArea = res.result.signedDemolitionArea
          this.notSignedDemolitionArea = res.result.notSignedDemolitionArea
          this.totalDemolitionArea = res.result.totalDemolitionArea
          this.totalHouseholds = res.result.totalHouseholds
          this.amountPayable = res.result.amountPayable
          this.downPaymentMade = res.result.downPaymentMade
          this.amountPaid = res.result.amountPaid
          this.unpaidAmount = res.result.unpaidAmount
          this.planArea = res.result.planArea
          if (this.cacheProjectItemId === id) {
            if (this.signedHouseholds > this.cacheSignedHouseholds) {
              console.log(this.signedHouseholds, this.cacheSignedHouseholds)
              this.$refs.fireWork.play()
              this.isNoPlaying = false
            }
          }
          this.cacheProjectItemId = id
          this.cacheSignedHouseholds = this.signedHouseholds
          this.showChart()
        }
      })
    },
    getPolylineData(id){
      getAction('/cum/dataScreen/polylineData',{
        projectId:id
      }).then(res => {
        if(res.success){
          let arr = []
          let legendArr = []
          res.result.dataInfos.forEach((item,index) => {
            let obj = {
              name: item.name,
              data: item.data,

              type: 'line',
              linePoint:{
                show:true
              },
              fill: {
                show: true
              },
              label:{
                style:{
                  color:'#ffffff'
                },
                show:true
              },
              lineStyle: {
                lineWidth: 2
              }
            }
            arr.push(obj)
            legendArr.push(item.name)
          })
          this.legendArr = legendArr
          this.lineArr = arr
          this.xName = res.result.xaxis

        }
      })
    },
    handleStop(value) {
      this.isNoPlaying = value
    },
    handleInputSelectOk(data) {
      this.getProjectItemDetail(data.id)
      this.getPolylineData(data.id)
      this.currentProjectItemId = data.id
      this.currentProjectItemName = data.name
    },
    keyDown() {
      document.onkeydown = (e) => {
        //事件对象兼容
        let e1 = e || event || window.event || arguments.callee.caller.arguments[0]

        if (e1 && e1.keyCode == 65) {
          this.$refs.fireWork.play()
          this.isNoPlaying = false
        }
      }
    },
    changeAll(){
      this.currentProjectItemId = ''
      this.currentProjectItemName = ''
      this.getProjectItemDetail()
      this.getPolylineData()
    },
    showChart(){
      this.myChart = echarts.init(this.$refs.chart);
      this.myChart.setOption({
        tooltip: {
          trigger: 'item'
        },
        color: ['rgba(3,252,72,0.6)', 'rgba(215,9,9,0.8)','#67E0E3'],
        series: [
          {
            name: '',
            type: 'pie',
            radius: '50%',
            data: [
              { name: '未付款', value:  this.unpaidAmount < 0 ? 0:this.unpaidAmount},
              { name: '已付款', value:  this.downPaymentMade < 0 ? 0:this.downPaymentMade },
              { name: '已收楼付款', value:  this.paymentReceived < 0 ? 0:this.paymentReceived }
            ],
            label:{            //饼图图形上的文本标签
              normal:{
                show:true,
                position:'inner', //标签的位置
                textStyle : {
                  fontSize : this.nowSize(12)    //文字的字体大小
                },
                formatter:'{d}%'
              }
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      })
      this.myChart.resize();
    }
  }
}
</script>

<style scoped lang='less'>
.index {
  width: 100%;
  position: relative;
  display: inline-block;
  height: calc(100vh - 120px);
  //.height{
  //  margin-top: 30%;
  //}
  .container {
    position: absolute;
    left: 0;
    right: 0;

    background: url("../../../../assets/background.png") center center;
    background-size: cover;
    padding: 0.2rem 0.7rem 0 0.7rem;

    .head {
      display: flex;

      .title {
        position: relative;
        width: 7rem;
        text-align: center;
        background-size: cover;
        background-repeat: no-repeat;

        .main {
          width: 100%;
          font-size: 0.3rem;
          color: white;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
        }

        .title-bottom {
          position: absolute;
          bottom: -0.2rem;
          left: 50%;
          transform: translate(-50%);
        }
      }
    }

    .content {
      margin-top: 0.3rem;
      height: 95%;

      .top {
        display: flex;
        justify-content: space-around;
        width: 100%;

        .allHousehold {
          position: relative;
        }

        .signedHousehold {
          position: relative;
        }

        .totalDemolitionArea {
          position: relative;
        }

        .info-title {
          color: white;
          font-size: 0.25rem;
          margin-bottom: 0.1rem;
          position: relative;
          left: -0.2rem;
        }

        .num {
          display: flex;
          align-items: center;
        }

        .item {
          background: url("./assets/card.c80bcc10.png");
          background-size: cover;
          //margin: 0 ;
          margin-left: 0.05rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 0.5rem;
          height: 0.7rem;
          color: #fdbe01;
          font-size: 0.4rem;
        }

      }

      .left {
        margin-top: 0.2rem;
        width: 20%;
        height: 100%;


        .left-content-top {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;

          .number {
            color: #fdbe01;
            font-size: 0.3rem;
            text-align: center;
          }

          .unit {
            font-size: 0.1rem;
            color: white;
          }
        }

        .right-content-top {
          width: 50%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .left-content {
          width: 100%;
          height: 100%;
          position: relative;
          //display: flex;
          //flex-direction: column;
          //justify-content: space-evenly;
          //align-items: center;

          .bottom-info{
            position: absolute;
            text-align: center;
            bottom: 0.2rem;
            left: 50%;
            width: 100%;
            transform: translateX(-50%);

            div{
              margin: 0.15rem 0;
              font-size: 0.2rem;
              font-weight: bold;
            }
          }
        }
      }


      .right {
        margin-top: 0.2rem;
        margin-left: 0.2rem;
        width: 80%;
        height: 100%;

        .right-middle-area{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          font-size: 0.2rem;
          color: #fdbe01;
        }
      }
    }
  }
}

/deep/ #container {
  height: 100% !important;
}

/deep/ .detail-info {
  width: 250px !important;
  height: 30vh !important;
  position: absolute !important;
  right: 20px !important;
  top: 20px !important;
  background-color: white;
  z-index: 999999999;
  padding: 20px;
  overflow: scroll;
}

/deep/ .sub-info {
  left: 20px !important;
  bottom: 20px !important;
}

.currentProjectItem {
  cursor: pointer;
  //width: 2rem;
  display: block;
  height: 0.7rem;
  color: #fdbe01;
  font-size: 0.25rem;
  margin-right: 10px
}
</style>