<template>
  <j-modal
    :title='title'
    :width='width'
    height='100vh'
    :visible='visible'
    switchFullscreen
    @cancel='handleCancel'
    :cancelText="$t('cancel')">
      <process ref='process'></process>
    <template slot='footer'>
      <!--      <a-button type="primary" @click="handleOk(false)">{{ $t('saveDraft') }}</a-button>-->
      <a-button type='primary' @click='handleSubmit'>{{ $t('save') }}</a-button>
      <a-button @click='handleCancel'>{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
import process from '../../../process/process'
import { postAction } from 'dbcp-frontends-common/lib/api'
export default {
  name: 'DesignView',
  components:{
    process
  },
  data(){
    return {
      width: '100%',
      visible: false,
      title: '设计',
      currentDesignId:''
    }
  },
  methods:{
    handleCancel() {
      this.visible = false
    },
    show(id,record) {

      this.visible = true
        this.$nextTick(() => {
          if(record){
            this.$refs.process.open(record.diagramData)
          }else{
            this.$refs.process.open('{}')
          }

        })
      this.currentDesignId = id
    },
    handleSubmit() {
      let formData = new FormData()
      formData.set('designId',this.currentDesignId)
      formData.set('diagramData',JSON.stringify(this.$refs.process.save()))
      postAction('/workflow/dbcpWorkflowDesign/design',formData)
      // this.$refs.process.save()
    },
  }
}
</script>

<style scoped lang='less'>
/deep/ .ant-modal {
  top: 0 !important;
}
</style>