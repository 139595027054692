import { deleteAction, getAction, uploadAction } from 'dbcp-frontends-common/lib/api'
export const cumIncomeValueContrastApi = {
url: {
  action: "/cum/cumIncomeValueContrast/action",
  list: "/cum/cumIncomeValueContrastWorkflow/list"
},
  execute: {
    list: function(params) {
      return getAction(cumIncomeValueContrastApi.url.list, params)
    },
    create: function(object) {
      return getAction(cumIncomeValueContrastApi.url.add, object)
    },
    retrieve: function(id, params = {}) {
      return getAction(cumIncomeValueContrastApi.queryById, { id, ...params })
    },
    update: function(object) {
      return uploadAction(cumIncomeValueContrastApi.url.edit, object)
    },
    destroy: function(id) {
      return deleteAction(cumIncomeValueContrastApi.url.delete, id)
    }
  }
}
