<template>
  <j-modal
    :title='title'
    :width='width'
    :visible='true'
    v-if='visible'
    switchFullscreen
    @cancel='handleCancel'
    :cancelText="$t('cancel')">
      <city-map ref='cityMap'></city-map>
    <template slot='footer'>
      <!--      <a-button type="primary" @click="handleOk(false)">{{ $t('saveDraft') }}</a-button>-->
      <a-button type='primary' @click='handleSubmit'>{{ $t('save') }}</a-button>
      <a-button @click='handleCancel'>{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
import { getAction, postAction } from 'dbcp-frontends-common/lib/api'
import CityMap from '../../../../../../components/CityMap'
export default {
  name: 'BuildingMap',
  components:{
    CityMap
  },
  data() {
    return {
      title: this.$i18n.tc('action'),
      width: '100%',
      visible: false,
    }
  },
  mounted() {


  },
  methods: {
    handleCancel() {
      this.visible = false
      this.$refs.cityMap.handleCancel()
    },
    show(record) {
      this.visible = true
      this.$nextTick(() => {
        this.$refs.cityMap.show(record)
      })

    },
    /**
     * 提交表单
     */
    handleSubmit() {
      if(Object.keys(this.$refs.cityMap.currentDrawObj).length > 0){
        postAction('/cum/cumBuildingSurveyed/handleSurveyed', this.$refs.cityMap.currentDrawObj)
      }
    },
  }
}
</script>

<style lang='less' scoped>
#toolControl {
  position: absolute;
  top: 10px;
  left: 0px;
  right: 0px;
  margin: auto;
  width: 168px;
  z-index: 1001;
}

.toolItem {
  width: 30px;
  height: 30px;
  float: left;
  margin: 1px;
  padding: 4px;
  border-radius: 3px;
  background-size: 30px 30px;
  background-position: 4px 4px;
  background-repeat: no-repeat;
  box-shadow: 0 1px 2px 0 #E4E7EF;
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.toolItem:hover {
  border-color: #789CFF;
}

.active {
  border-color: #D5DFF2;
  background-color: #D5DFF2;
}

#marker {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/marker_editor.png');
}

#polyline {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/polyline.png');
}

#polygon {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/polygon.png');
}

#circle {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/circle.png');
}

#delete {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/delete.png');
}

#split {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/split.png');
}

#union {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/union.png');
}

/deep/ .ant-modal {
  top: 0 !important;
}

.detail-info {
  width: 400px;
  height: 70vh;
  position: fixed;
  right: 40px;
  top: 100px;
  background-color: white;
  z-index: 999999999;
  padding: 20px;
}
</style>