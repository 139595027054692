<template>
  <j-modal
    :title='title'
    :width='width'
    :visible='visible'
    :confirmLoading='confirmLoading'
    switchFullscreen
    @ok='handleOk'
    @cancel='handleCancel'
    :cancelText="$t('cancel')">
    <a-spin :spinning='confirmLoading'>
      <a-form :form='form'>


        <!--        <a-form-item label="序列号" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
        <!--          <a-input v-decorator="['serialNumber', validatorRules.serialNumber]" placeholder="请输入序列号"></a-input>-->
        <!--        </a-form-item>-->
        <a-form-item label='项目' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <!--                    <a-input-number v-decorator="['projectItemId', validatorRules.projectItemId]" placeholder="请输入项目明细" style="width: 100%"/>-->
          <InputSelect v-decorator="['projectId', validatorRules.projectId]" show-name='name' send-params='id'
                       url='dbcp/city/projectManagement/CumProjectList.vue'
                       type='radio' :isEdit='model.projectId?true:false'></InputSelect>
        </a-form-item>
        <a-form-item label='项目明细' :labelCol='labelCol' :wrapperCol='wrapperCol'
                     v-if='form.getFieldValue("projectId") || model.projectItemId'>
          <!--            <a-input-number v-decorator="['projectItemId', validatorRules.projectItemId]" placeholder="请输入项目明细" style="width: 100%"/>-->
          <InputSelect v-decorator="['projectItemId', validatorRules.projectItemId]" show-name='rightHolder_dictText'
                       send-params='id'
                       url='dbcp/city/projectManagement/CumProjectItemTable.vue'
                       :projectId='form.getFieldValue("projectId")'
                       type='radio' :isEdit='model.projectItemId?true:false'
                       @ok='handleSelectProjectItem'></InputSelect>
        </a-form-item>
        <!--        <a-form-item label="部门" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
        <!--          <j-select-depart v-decorator="['dept', validatorRules.dept]" multi/>-->
        <!--        </a-form-item>-->
        <a-form-item label='房屋移交时间' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <j-date placeholder='请选择房屋移交时间' v-decorator="['handoverTime', validatorRules.handoverTime]"
                  :trigger-change='true' style='width: 100%' />
        </a-form-item>
        <a-form-item label='签约面积(m²)' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input-number v-decorator="['contractedArea', validatorRules.contractedArea]" placeholder='请输入签约面积'
                          style='width: 100%' :step='0.01' :max='99999999.99' />
        </a-form-item>
        <a-form-item label='签约时间' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <j-date placeholder='请选择房屋移交时间' v-decorator="['contractedTime', validatorRules.contractedTime]"
                  :trigger-change='true' style='width: 100%' />
        </a-form-item>
        <a-form-item label='款项汇入人' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input v-decorator="['payee', validatorRules.payee]" placeholder='请输入款项汇入人'></a-input>
        </a-form-item>
        <a-form-item label='开户行' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input v-decorator="['openingBank', validatorRules.openingBank]" placeholder='请输入开户行'></a-input>
        </a-form-item>
        <a-form-item label='银行账号' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-input v-decorator="['bankAccount', validatorRules.bankAccount]" placeholder='请输入银行账号'></a-input>
        </a-form-item>
        <a-form-item label='应付款金额' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-table :columns='realColumns' :dataSource='[{}]' :pagination='false' v-if='realColumns.length > 0'>
            <template
              v-for='(col,index) in realColumns'
              :slot="'amount'+index"
              slot-scope='text, record'
            >
              <div :key='col'>
                <a-input-number
                  style='margin: -5px 0'
                  v-model='payableMoneyDataSource[index].amount'
                />
              </div>
            </template>
          </a-table>
        </a-form-item>
        <!--        <a-form-item label="首付款金额" :labelCol="labelCol" :wrapperCol="wrapperCol" >-->
        <!--          <a-table :columns='firstPayMoneyColumns' :dataSource='firstPayMoneyDataSource' :pagination="false">-->
        <!--            <template-->
        <!--              v-for="col in ['firstPayMoney0', 'firstPayMoney1', 'firstPayMoney2','firstPayMoney3','firstPayMoney4']"-->
        <!--              :slot="col"-->
        <!--              slot-scope="text, record, index"-->
        <!--            >-->
        <!--              <div :key="col">-->
        <!--                <a-input-number-->
        <!--                  style="margin: -5px 0"-->
        <!--                  v-model='record[col]'-->
        <!--                />-->
        <!--              </div>-->
        <!--            </template>-->
        <!--          </a-table>-->
        <!--        </a-form-item>-->
        <a-form-item label='备注' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-textarea v-decorator="['remark']" rows='4' placeholder='请输入备注' />
        </a-form-item>
        <!--        <a-form-item label="创建人" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
        <!--          <j-select-user-by-dep :hideDept="true" v-decorator="['addBy', validatorRules.addBy]"/>-->
        <!--        </a-form-item>-->
        <!--        <a-form-item label="创建时间" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
        <!--          <j-date placeholder="请选择创建时间" v-decorator="['addTime', validatorRules.addTime]" :trigger-change="true" :show-time="true" date-format="YYYY-MM-DD HH:mm:ss" style="width: 100%"/>-->
        <!--        </a-form-item>-->
        <!--        <a-form-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
        <!--          <a-input-number v-decorator="['status', validatorRules.status]" placeholder="请输入状态" style="width: 100%"/>-->
        <!--        </a-form-item>-->
        <a-form-item :label="$t('attachments')" :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <attachment v-decorator="['upload',validatorRules.upload]" @handleFileRemove='handleFileRemove'
                      @beforeFileUpload='beforeFileUpload' :file-list='uploadFiles'
                      :default-file-list='defaultFileList'></attachment>
        </a-form-item>

      </a-form>
    </a-spin>

    <template slot='footer'>
      <a-button type='primary' @click='handleOk(false)'>{{ $t('saveDraft') }}</a-button>
      <a-button type='primary' @click='handleOk(true)'>{{ $t('submit') }}</a-button>
      <a-button @click='handleCancel'>{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
import { cumContractApi } from './api/CumContract'
import { getAction, httpAction } from 'dbcp-frontends-common/lib/api'
import pick from 'lodash.pick'
import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
import { Attachment } from 'dbcp-frontends-common/lib/components'
import { JDate } from 'dbcp-frontends-common/lib/components'
import { JSelectDepart } from 'dbcp-frontends-common/lib/components'
import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'
import { UploadFormMixin } from 'dbcp-frontends-common/lib/mixins'
import InputSelect from '@/components/dbcp/InputSelect'

export default {
  name: 'CumContractModal',
  components: {
    JDate,
    JSelectDepart,
    JSelectUserByDep,
    Attachment,
    InputSelect
  },
  mixins: [UploadFormMixin],
  data() {
    return {
      form: this.$form.createForm(this),
      title: this.$i18n.tc('action'),
      width: 800,
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      validatorRules: {
        serialNumber: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        projectId: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        projectItemId: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        dept: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        handoverTime: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        contractedArea: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        contractedTime: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        payee: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        openingBank: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        bankAccount: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        payableMoney: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        firstPayMoney: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        status: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        },
        upload: {
          rules: [
            { required: true, message: this.$i18n.tc('fieldEmptyHint') }
          ]
        }
      },
      url: cumContractApi.url,
      projectItemIdFlag: false,
      payableMoneyColumns: [
        {
          title: '货币补偿价格',
          align: 'center',
          dataIndex: 'payableMoney0',
          scopedSlots: { customRender: 'payableMoney0' }
        },
        {
          title: '装修补偿',
          align: 'center',
          dataIndex: 'payableMoney1',
          scopedSlots: { customRender: 'payableMoney1' }
        },
        {
          title: '附属物补偿',
          align: 'center',
          dataIndex: 'payableMoney2',
          scopedSlots: { customRender: 'payableMoney2' }
        },
        {
          title: '临时安置补助费',
          align: 'center',
          dataIndex: 'payableMoney3',
          scopedSlots: { customRender: 'payableMoney3' }
        },
        {
          title: '其它',
          align: 'center',
          dataIndex: 'payableMoney4',
          scopedSlots: { customRender: 'payableMoney4' }
        }
      ],
      payableMoneyDataSource: [{}]
    }
  },
  created() {
  },
  computed: {
    realColumns() {
      if(this.payableMoneyDataSource.length === 0)return []
      let arr = this.payableMoneyDataSource || []
      let columns = []
      arr.forEach((item, index) => {
        let obj = {
          title: item.compensateTypeId_dictText,
          align: 'center',
          scopedSlots: { customRender: 'amount' + index }
        }
        columns.push(obj)
      })

      return columns
    }

  },
  methods: {
    afterAdd(record) {
      this.edit({
        ...record
      })
    },
    afterEdit(record) {
      console.log('record', record)
      if (record.projectItemId) {
        this.handleSelectProjectItem({ id: record.projectItemId })
      }
      if(record.cumPaymentAmountList){
        this.payableMoneyDataSource = record.cumPaymentAmountList
      }
      this.$nextTick(() => {
        if (record.fileList && record.fileList.length > 0) {
          this.form.setFieldsValue({
            'upload': record.fileList
          })
        }
        this.form.setFieldsValue(pick(this.model, 'contractedTime', 'projectId', 'payableMoney', 'firstPayMoney', 'serialNumber', 'projectItemId', 'dept', 'handoverTime', 'contractedArea', 'payee', 'openingBank', 'bankAccount', 'remark', 'addBy', 'addTime', 'status'))
      })
    },
    afterClose() {
      this.projectItemIdFlag = false
      this.payableMoneyDataSource = []
    },
    popupCallback(row) {
      this.form.setFieldsValue(pick(row, 'contractedTime', 'projectId', 'payableMoney', 'firstPayMoney', 'serialNumber', 'projectItemId', 'dept', 'handoverTime', 'contractedArea', 'payee', 'openingBank', 'bankAccount', 'remark', 'addBy', 'addTime', 'status'))
    },
    handleSelectProjectItem(data) {
      if(!this.model.id){
        getAction('/cum/cumContract/getAmountPayable', {
          projectItemId: data.id
        }).then(res => {
          if (res.success) {
            this.payableMoneyDataSource = res.result
          }
        })
      }


      // getAction('/cum/cumFirstPay/getFirstPayMoney',{
      //   projectItemId:data.id
      // }).then(res => {
      //   if(res.success){
      //     this.firstPayMoneyDataSource = [res.result]
      //   }
      // })
    },
    processFormData(formData) {
      formData.set(`cumPaymentAmountList`, JSON.stringify(this.payableMoneyDataSource))


      // Object.keys(this.firstPayMoneyDataSource[0]).forEach(item => {
      //   formData.set(item,this.firstPayMoneyDataSource[0][item])
      // })

      return formData
    }


  }
}
</script>
