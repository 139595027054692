import { render, staticRenderFns } from "./CumIncomeValueContrastList.vue?vue&type=template&id=706a90e0&scoped=true&"
import script from "./CumIncomeValueContrastList.vue?vue&type=script&lang=js&"
export * from "./CumIncomeValueContrastList.vue?vue&type=script&lang=js&"
import style0 from "./CumIncomeValueContrastList.vue?vue&type=style&index=0&id=706a90e0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "706a90e0",
  null
  
)

export default component.exports