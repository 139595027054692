<template>
  <!-- 查询区域 -->

  <a-form layout='inline'>
    <a-row :gutter='24'>
      <a-col :span='4'>
        <a-form-item :label="$t('serialNumber')">
          <a-input v-model='groupQueryParams.serialNumber' :trigger-change='true' customReturnField='id' />
        </a-form-item>
      </a-col>
      <a-col :span='4' v-if='deptShow'>
        <a-form-item :label="$t('department')">
          <j-select-depart v-model='groupQueryParams.dept' :multi='false' />
        </a-form-item>
      </a-col>
      <a-col :span='4'>
        <a-form-item :label="$t('applicant')">
          <j-select-user-by-dep :hideDept="true" v-model='groupQueryParams.applicant' :multi='false' />
        </a-form-item>
      </a-col>
      <a-col :span='6' v-if='amountShow'>
        <a-form-item :label="$t('amount')">
          <a-input-group compact>
            <a-input style=' width: 100px; text-align: center' placeholder='Minimum'
                     v-model='groupQueryParams.amount_gt_eq' />
            <a-input
              style=' width: 30px; border-left: 0; pointer-events: none; backgroundColor: #fff'
              placeholder='~'
              disabled
            />
            <a-input style='width: 100px; text-align: center; border-left: 0' placeholder='Maximum'
                     v-model='groupQueryParams.amount_lt_eq' />
          </a-input-group>
        </a-form-item>
      </a-col>
      <a-col>
            <span style='float: left;overflow: hidden;margin-left: 15px' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchGroupQuery' icon='search'>{{ $t('search') }}</a-button>
              <a-button type='primary' @click='searchGroupReset' icon='reload' style='margin-left: 8px'>{{
                  $t('reset')
                }}</a-button>
              <a @click='handleToggleSearch' style='margin-left: 8px'>
                {{ toggleSearchStatus ? $t('collapse') : $t('expand') }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'" />
              </a>
            </span>
      </a-col>

    </a-row>
    <a-row :guttar='24' v-show='toggleSearchStatus'>
      <a-col :span='4'>
        <a-form-item :label="$t('status')">
          <a-select v-model='groupQueryParams.status' allowClear :placeholder="$t('queryByStatus')">
            <a-select-option v-for='(item,index) in statusList' :key='item'>
              {{ $tc(`workflowStatus.${item}`) }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span='6' style='margin-left: 15px'>
        <a-form-item :label="$t('applicationDate')">
          <a-range-picker @change='dateChange' :value='defaultDate' />
        </a-form-item>
      </a-col>
      <a-col :span='5' style='margin-left: 15px' v-if='unitCollectShow'>
        <a-form-item :label="$t('receivingUnit')">
          <a-input v-model='groupQueryParams.unitCollect' :trigger-change='true' customReturnField='id' />
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import { JSelectDepart } from 'dbcp-frontends-common/lib/components'
import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'

export default {
  name: 'FinanceSearch',
  components: {
    JSelectDepart, JSelectUserByDep
  },
  props: {
    amountShow: {
      type: Boolean,
      default: true
    },
    deptShow: {
      type: Boolean,
      default: true
    },
    unitCollectShow: {
      type: Boolean,
      default: false
    },
    statusList: {
      type: Array,
      default: () => [0, 2, 4, 5, 6, 7]
    }
  },
  data() {
    return {
      groupQueryParams: {
        serialNumber: '',
        dept: '',
        amount_gt_eq: '',
        amount_lt_eq: '',
        applicant: '',
        status: null,
        addBy_begin: '',
        addBy_end: '',
        unitCollect: ''
      },
      /* 查询折叠 */
      toggleSearchStatus: false,
      defaultDate: null
    }
  },
  methods: {
    searchGroupQuery() {
      this.$emit('searchChange', this.groupQueryParams)
    },
    searchGroupReset() {
      this.groupQueryParams = {
        serialNumber: '',
        dept: '',
        amount_gt_eq: '',
        amount_lt_eq: '',
        applicant: '',
        status: null,
        addTime_begin: '',
        addTime_end: '',
        unitCollect: ''
      }
      this.defaultDate = null
      console.log('this.groupQueryParams', this.groupQueryParams)
      this.$emit('searchChange', this.groupQueryParams)
    },
    dateChange(date, dateString) {
      this.defaultDate = date
      this.groupQueryParams.addTime_begin = dateString[0]
      this.groupQueryParams.addTime_end = dateString[1]
    },
    handleToggleSearch() {
      this.toggleSearchStatus = !this.toggleSearchStatus
    }
  }
}
</script>

<style scoped>

</style>
