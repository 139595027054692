<template>
  <div>
    <a-card :title="$t('announcement')">
      <a slot="extra" href="#" @click="moreAnnouncement">{{ $t('more') }}</a>
    </a-card>
    <div class="announcement-container" :style="{height: annHeight+'px'}">
      <div v-for="(item,index) in annoList" :key="index" class="announcement-content">
        <a  @click="navAnnouncement(item)" class="announcement-list" :title="item.titile">{{item.titile}}</a>
        <p>{{item.createTime}}</p>
      </div>
    </div>
    <announcement-workflow-view-detail ref="detailForm"  @close="handleViewModalClose"></announcement-workflow-view-detail>
  </div>
</template>

<script>
  import eventCommunicate from '@/assets/eventCommunicate'
  import {getAnnouncement} from 'dbcp-frontends-common/lib/api'
  import AnnouncementWorkflowViewDetail
    from '@views/dbcp/publicPlatform/announcement/modules/AnnouncementWorkflowViewDetail'
  import { getAction } from 'dbcp-frontends-common/lib/api'

  export default {
    name: 'Announcement',
    data(){
      return{
        annoList: [
        ],
        annHeight: '',
        url: {
          list: "/sys/sysAnnouncementSend/getDeptNotice"
        }
      }
    },
    components:{
      AnnouncementWorkflowViewDetail
    },
    mixins:[],
    created() {
      this.getAnnouncement()
    },
    mounted() {
      let self = this;
      eventCommunicate.$on('annHeight', function(data) {
        self.annHeight = Number(data) - 58;
      })
      console.log("公告高度 self.annHeight", self.annHeight)
    },
    methods: {
      getObject(id) {
        getAction(this.url.list, { id }).then((res) => {
          if (res.result.records && res.result.records[0]) {
            let record = res.result.records && res.result.records[0]
            this.handleView(record)
          }
        })
      },
      handleViewModalClose() {
        // 关闭modal时，清除所带的query id
        this.clearRouteQuery()
      },
      clearRouteQuery() {
        if (this.$route.query.id) {
          delete this.$route.query.id
          // 参数变化才会触发更新，增加 { _: +new Date() }
          this.$router.push({
            query: Object.assign({}, this.$route.query, { _: +new Date() })
          })
        }
      },
      //跳转到对应的通知
      navAnnouncement(item) {
        this.getObject(item.id)
      },
      //获取通知
      async getAnnouncement(){
        let data = await getAnnouncement({
          column: 'sendTime',
          order: 'desc'
        })
        this.annoList = data.result.records
      },
      //更多
      moreAnnouncement(){
        this.$router.push({
          path:'/public-platform/announcement'
        })
      },
      handleView(record){
        console.log('i am announcement' )
        this.$refs.detailForm.detail(record)
      }
    }
  }
</script>

<style scoped>

  .announcement-container{
    width: 100%;
    background: #ffffff;
    overflow: auto;
    padding: 20px;
    border: 1px solid #e8e8e8;
    border-top: 0px;
  }

  .announcement-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .announcement-list{
    display: block;
    font-size: 15px;
    margin-bottom: 18px;
    color: #898989;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }

  .announcement-list:hover{
    color: #40a9ff;
  }
</style>
