<template>
  <div>
    <a-tabs @change="tabChange">
      <a-tab-pane key="1" tab="富文本">
        <j-editor v-model="richValue"></j-editor>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Markdown">
        <mavon-editor v-model="mdValue" ref="md" @imgAdd="imgAdd" @imgDel="imgDel" @change="changData"/>
      </a-tab-pane>
    </a-tabs>
    <div style="display: flex;justify-content: space-between;bottom: 0;margin-top: 10px;align-items: center"
         v-if="operateButton">
      <a-checkbox @change="onChange" :checked="anonymous">
        匿名
      </a-checkbox>
      <a-button type="primary" size="small" @click="submit">
        评论
      </a-button>
    </div>

  </div>
</template>

<script>
import JEditor from '@comp/jeecg/JEditor'
import axios from 'axios'
import marked from 'marked'

export default {
  name: 'RichAndMdEditor',
  components: {
    JEditor
  },
  props: {
    operateButton: {
      type: Boolean,
      default: true
    },
    value:{
      type:String
    }
  },
  model:{
    model: {
      prop: 'value',
      event: 'change'
    }
  },
  data() {
    return {
      richValue: '',
      mdValue: '',
      anonymous: false,
      activeKey:'1'
    }
  },
  watch: {
    value(newV){

    },
    richValue(newV) {
      this.$emit('change',newV)
    },
    mdValue(newV) {
      this.$emit('change',newV)
    },
  //  监控是否选择为markdown
    activeKey(newV){
      newV === '2' ? this.$emit('isMarkDown',true) : this.$emit('isMarkDown',false)
    }
  },
  mounted() {
    // 如果原始md字符串中存在曾上传的图片， 则需要将对应<img>中的src替换为base64
    // this.$nextTick(() => {
    //   // $vm.$imgUpdateByUrl 详情见本页末尾
    //   this.$refs.md.$imgUpdateByUrl(0, );
    // })
  },
  methods: {
    imgAdd(pos, $file) {
      let formData = new FormData()
      formData.append('image', $file)
      axios({
        url: '',
        method: 'post',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      }).then((flag) => {
        this.$refs.md.$img2Url(pos, url)
      })
    },
    imgDel() {

    },
    changData(value, render) {

    },
    tabChange(activeKey) {
      this.activeKey = activeKey
    },
    onChange(e) {
      console.log(`checked = ${e.target.checked}`);
      this.anonymous = e.target.checked
    },
  //  发表
    submit(){
      //true代表为是否为markdown
      if(this.activeKey === '2'){
        this.$emit('submit',this.mdValue,true,this.anonymous)
      }else{
        this.$emit('submit',this.richValue,false,this.anonymous)
      }
    }
  }
}
</script>

<style scoped>

</style>
