<template>
  <a-select v-model="data" :value="value" @change="handleInput" :mode="multiple">
    <a-select-option v-for="item in companyList" :value="item.id" :key="item.id">{{ item.companyName }}</a-select-option>
  </a-select>
</template>

<script>
  import { companies } from 'dbcp-frontends-common/lib/api'
  export default {
    name: 'CompanySelector',
    props: {
      value: {
        type: String | Array
      },
      multiple:{
        type:String,
        default:''
      }
    },
    data() {
      return {
        data: '',
        companyList: []
      }
    },
    watch: {
      value() {
        this.data = this.value
      }
    },
    created() {
      this.data = this.value
      companies().then(res => {
        this.companyList = res.result
      })
    },
    methods: {
      handleInput(value) {
        this.$emit('change', value)
        this.$emit('input', value)
      }
    }
  }
</script>

<style scoped>

</style>
