<template>
  <a-card :bordered='false'>

    <template v-if='!isSimple'>
      <!-- 查询区域 -->
      <div class='table-page-search-wrapper'>
        <a-form layout='inline' @keyup.enter.native='searchQuery'>
          <a-row :gutter='24'>
            <a-col :xl='6' :lg='7' :md='8' :sm='24'>
              <a-form-item label='项目名称'>
                <a-input placeholder='请输入项目名称' v-model='queryParam.name_like'></a-input>
              </a-form-item>
            </a-col>
            <!--          <a-col :xl='6' :lg='7' :md='8' :sm='24'>-->
            <!--            <a-form-item label='所属片区'>-->
            <!--              <j-select-depart placeholder='请选择所属片区' v-model='queryParam.deptId' />-->
            <!--            </a-form-item>-->
            <!--          </a-col>-->
            <template v-if='toggleSearchStatus'>
              <a-col :xl='6' :lg='7' :md='8' :sm='24'>
                <a-form-item label='项目负责人'>
                  <j-select-user-by-dep :hideDept='true' placeholder='请选择项目负责人' v-model='queryParam.owner'
                                        :multi='false' />
                </a-form-item>
              </a-col>
              <a-col :xl='6' :lg='7' :md='8' :sm='24'>
                <a-form-item label='项目状态'>
                  <j-dict-select-tag placeholder='请选择项目状态' v-model='queryParam.status' dictCode='cum_project_status'
                                     :showManage='false' />
                </a-form-item>
              </a-col>
            </template>
            <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>查询</a-button>
              <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset')
                }}</a-button>
              <a @click='handleToggleSearch' style='margin-left: 8px'>
                {{ toggleSearchStatus ? $t('collapse') : $t('expend') }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'" />
              </a>
            </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 查询区域-END -->

      <!-- 操作按钮区域 -->
      <div class='table-operator'>
        <a-button @click='handleAdd' type='primary' icon='plus' v-has="'cumProject:create'">{{ $t('create') }}</a-button>

        <!--      <a-button type="primary" icon="download" @click="handleExportXls('项目管理')">{{ $t('export') }}</a-button>-->
        <!--      <a-upload name="file" :showUploadList="false" :multiple="false" :headers="tokenHeader" :action="importExcelUrl" @change="handleImportExcel">-->
        <!--        <a-button type="primary" icon="import">{{ $t('import') }}</a-button>-->
        <!--      </a-upload>-->
        <a-dropdown v-if='selectedRowKeys.length > 0'>
          <a-menu slot='overlay'>
            <a-menu-item key='1' @click='batchDel'>
              <a-icon type='delete' />
              {{ $t('delete') }}
            </a-menu-item>
          </a-menu>
          <a-button style='margin-left: 8px'> {{ $t('batchOperation') }}
            <a-icon type='down' />
          </a-button>
        </a-dropdown>
      </div>

      <!-- table区域-begin -->
      <div>
        <!--      <approval-tab @change="handleGroupChange" permission="cumProject:view:all"></approval-tab>-->

        <a-table
          ref='table'
          size='middle'
          bordered
          rowKey='id'
          :columns='columns'
          :dataSource='dataSource'
          :pagination='ipagination'
          :loading='loading'
          :rowSelection='type?{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type,onSelect:handleSelection,onSelectAll:handleSelectAll}:null'
          class='j-table-force-nowrap'
          @change='handleTableChange'>


          <template slot='htmlSlot' slot-scope='text'>
            <div v-html='text'></div>
          </template>
          <template slot='imgSlot' slot-scope='text'>
            <span v-if='!text' style='font-size: 12px;font-style: italic;'>{{ $t('noPicture') }}</span>
            <img v-else :src='getImgView(text)' height='25px' alt=''
                 style='max-width:80px;font-size: 12px;font-style: italic;' />
          </template>
          <template slot='fileSlot' slot-scope='text'>
            <span v-if='!text' style='font-size: 12px;font-style: italic;'>{{ $t('noFile') }}</span>
            <a-button
              v-else
              :ghost='true'
              type='primary'
              icon='download'
              size='small'
              @click='uploadFile(text)'>
              {{ $t('download') }}
            </a-button>
          </template>

          <span slot='status' slot-scope='text,record' v-if='text'>
<!--          <a-tag :color='getProjectStatusColor(record.status)'>{{ text }}</a-tag>-->
          <a-tag :color='getProjectStatusColor(record.status)'>{{ text }}</a-tag>
        </span>

          <span slot='action' slot-scope='text, record'>
          <div style='display: flex;justify-content: space-evenly;align-items: center'>
            <a @click='handlePrint(record)'>测绘</a>
              <a-divider type='vertical' />
            <a @click='handleShowItem(record)'>明细</a>
             <a-divider type='vertical' />
            <a @click='turnTemplate(record)'>核实表模板</a>
              <a-divider type='vertical' />
            <a @click='turnPayment(record)'>收楼付款</a>
            <a-divider type='vertical' />
            <a @click='handleEdit(record)'>{{ $t('edit') }}</a>
             <a-divider type='vertical' />
            <a @click='handleStandard(record)'>补偿标准</a>
             <a-divider type='vertical' />
<!--            <a @click='handleExport(record.id)'>导出项目补偿表</a>-->
            <a @click='handleProjectStandard(record)'>项目补偿</a>
             <a-divider type='vertical' />
               <list-actions
                 :record='record'
                 @view='handleView'
                 @edit='handleEdit'
                 @delete='handleDelete'
                 @action='$refs.detailForm.handleActionClick'
                 @copy='handleCopy'
               ></list-actions>
             <a-divider type='vertical' />
            <a-popconfirm :title="$t('confirmDeleteAlert')" @confirm='() => handleDelete(record.id)'>
              <a>{{ $t('delete') }}</a>
            </a-popconfirm>
          </div>

        </span>

        </a-table>
      </div>
    </template>

    <simple-project-template @handlePrint='handlePrint' @handleShowItem='handleShowItem'
                             @turnTemplate='turnTemplate'
                             @turnPayment='turnPayment' @handleExport='handleExport'
                             @handleStandard='handleStandard' @childData='handleChildData'
                             @handleView='handleView' @handleProjectStandard='handleProjectStandard' v-else></simple-project-template>

    <cumProject-modal ref='modalForm' @ok='modalFormOk'></cumProject-modal>
    <cumProject-detail ref='detailForm' @action='handleActionClick' @close='handleViewModalClose'
                       @loadData='loadData' @clearRouteQuery='clearRouteQuery'></cumProject-detail>
    <cum-project-item-list ref='CumProjectItemList'></cum-project-item-list>
    <project-map ref='modalMap'></project-map>

    <cum-collection-payment-detail ref='paymentDetail'></cum-collection-payment-detail>

    <cum-collection-payment-modal ref='paymentModal' @ok='modalPaymentOK'></cum-collection-payment-modal>
    <cum-compensation-standard-list ref='standardModal'></cum-compensation-standard-list>
<!--    <cum-standard-modal ref='standardModal' ></cum-standard-modal>-->

    <turn-payment-modal ref='turnPaymentModal'></turn-payment-modal>


    <cum-project-compensation ref='projectCompensation'></cum-project-compensation>

    <turn-list-modal ref='turnListModal' :url='turnUrl'></turn-list-modal>
  </a-card>
</template>

<script>
import { cumProjectApi } from './modules/api/CumProject'
import '@/assets/less/TableExpand.less'
import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
import CumProjectModal from './modules/CumProjectModal'
import CumProjectDetail from './modules/CumProjectDetail'
import { ListActions } from 'dbcp-frontends-common/lib/components'
import { JDictSelectTag } from 'dbcp-frontends-common/lib/components'
import { filterMultiDictText } from '@/components/dict/JDictSelectUtil'
import { JSelectUserByDep } from 'dbcp-frontends-common/lib/components'
import { JSelectDepart } from 'dbcp-frontends-common/lib/components'
import CumProjectItemList from './CumProjectItemList'
import ProjectMap from './modules/ProjectMap'
import { SelectorMixin } from 'dbcp-frontends-common/lib/mixins'
import { downFile, getAction } from 'dbcp-frontends-common/lib/api'
import CumCollectionPaymentDetail from '../collectionPayment/modules/CumCollectionPaymentDetail'
import CumCollectionPaymentModal from '../collectionPayment/modules/CumCollectionPaymentModal'
import { getProjectStatusColor } from '@/utils/util'
import CumStandardModal from './modules/CumStandardModal'
import SimpleProjectTemplate from './simpleProjectTemplate'
import TurnPaymentModal from './TurnPaymentModal'
import CumProjectCompensation from './modules/CumProjectCompensation'
import CumCompensationStandardList from '../projectStandard/CumCompensationStandardList'
import TurnListModal from '@/components/TurnListModal'
export default {
  name: 'CumProjectList',
  mixins: [JeecgListMixin, mixinDevice, ViewMixin, ApprovalListMixin, SelectorMixin],
  components: {
    CumCollectionPaymentModal,
    CumCollectionPaymentDetail,
    JDictSelectTag,
    JSelectUserByDep,
    JSelectDepart,
    CumProjectModal,
    CumProjectDetail,
    ListActions,
    CumProjectItemList,
    ProjectMap,
    CumStandardModal,
    SimpleProjectTemplate,
    TurnPaymentModal,
    CumProjectCompensation,
    CumCompensationStandardList,
    TurnListModal
  },
  props: {
    flag: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'checkbox'
    }
  },
  data() {
    return {
      description: '项目管理管理页面',
      // 表头
      columns: [
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function(t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '项目名称',
          align: 'center',
          dataIndex: 'name'
        },
        // {
        //   title: '所属片区',
        //   align: 'center',
        //   dataIndex: 'deptId_dictText'
        // },
        {
          title: '项目负责人',
          align: 'center',
          dataIndex: 'owner_dictText'
        },
        {
          title: '项目状态',
          align: 'center',
          dataIndex: 'status_dictText',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '更新人',
          align: 'center',
          dataIndex: 'updateBy_dictText'
        },
        {
          title: '更新时间',
          align: 'center',
          dataIndex: 'updateTime'
        },
        {
          title: this.$i18n.tc('action'),
          dataIndex: 'action',
          align: 'center',
          // fixed:"right",
          width: 147,
          scopedSlots: { customRender: 'action' }
        }
      ],
      url: cumProjectApi.url,
      turnUrl:"",
      dictOptions: {},
      getProjectStatusColor,
      //  是否单表
      isSimple:false,
      /* 禁用created列表数据加载 */
      disableMixinCreated: true
    }
  },
  computed: {
    importExcelUrl: function() {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    }
  },
  created() {
    getAction('/cum/cumProject/canCreateProjectNumber').then(res => {
      if(res.result === 1){
        this.isSimple = true
      }else{
        this.loadData()
        this.isSimple = false
      }
    })
  },
  methods: {
    initDictConfig() {
    },
    handleShowItem(record) {
      console.log('record.id', record.id)
      this.$refs.CumProjectItemList.show({ projectId: record.id })
    },
    handlePrint(record) {
      this.$refs.modalMap.show(record, 'project')
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectionRows = selectionRows
      this.$emit('childData', selectionRows)
      this.$emit('childFn', selectionRows)
    },
    turnPayment(record) {
      // if (record.collectionPaymentId) {
      //   getAction('/cum/cumCollectionPayment/list', { id: record.collectionPaymentId }).then(res => {
      //     this.$refs.paymentDetail.view(res.result.records[0])
      //   })
      // } else {
      //   let data = {}
      //   data.projectId = record.id
      //   this.$refs.paymentModal.add(data)
      // }
      this.$nextTick(()=>{
        this.$refs.turnPaymentModal.show({ projectId: record.id });
      })
    },
    turnTemplate(record){
      this.turnUrl = "dbcp/city/verificationTemplate/CumVerificationTemplateList.vue";
      this.$nextTick(()=>{
        this.$refs.turnListModal.show({ projectId:record.id });
      })
    },
    modalPaymentOK() {
      this.loadData()
      this.$router.push('/cum/collection-payment')
    },
    handleExport(id) {
      let fileName = '项目补偿表'
      downFile('/cum/cumProject/getProjectCompensation', {
        projectId: id
      }).then((data) => {
        if (!data) {
          return
        }
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(new Blob([data], { type: 'application/vnd.ms-excel' }), fileName + '.xlsx')
        } else {
          let url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', fileName + '.xlsx')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link) //下载完成移除元素
          window.URL.revokeObjectURL(url) //释放掉blob对象
        }
      })
    },
    handleStandard(record){
      this.$refs.standardModal.show({projectId:record.id})
    },
    handleChildData(childData){
      this.$emit('childData', childData.id)
      this.$emit('childFn', childData)
    },
    handleView(record){
      this.$refs.detailForm.view(record)
    },
    /**
     * 项目补偿
     */
    handleProjectStandard(record){
      this.$refs.projectCompensation.show(record)
    }
  }
}
</script>
<style scoped>
@import '~@assets/less/common.less';
</style>
