<template>
  <a-modal
    v-model='visible'
    @ok='visible = false'
    width='1400px'
    @cancel='handleCancel'
  >
    <a-card :bordered='false'>
      <!-- 查询区域 -->
      <div class='table-page-search-wrapper'>
        <a-form layout='inline' @keyup.enter.native='searchQuery'>
          <a-row :gutter='24'>
          </a-row>
        </a-form>
      </div>
      <!-- 查询区域-END -->

      <!-- 操作按钮区域 -->
      <div class='table-operator'>
        <a-button @click='handleAdd' type='primary' icon='plus' v-has="'dbcpWorkflowDesignVersion:create'">
          {{ $t('create') }}
        </a-button>
<!--        <a-button type='primary' icon='download' @click="handleExportXls('流程设计版本管理')">{{ $t('export') }}</a-button>-->
<!--        <a-upload name='file' :showUploadList='false' :multiple='false' :headers='tokenHeader' :action='importExcelUrl'-->
<!--                  @change='handleImportExcel'>-->
<!--          <a-button type='primary' icon='import'>{{ $t('import') }}</a-button>-->
<!--        </a-upload>-->
        <a-dropdown v-if='selectedRowKeys.length > 0'>
          <a-menu slot='overlay'>
            <a-menu-item key='1' @click='batchDel'>
              <a-icon type='delete' />
              {{ $t('delete') }}
            </a-menu-item>
          </a-menu>
          <a-button style='margin-left: 8px'> {{ $t('batchOperation') }}
            <a-icon type='down' />
          </a-button>
        </a-dropdown>
      </div>

      <!-- table区域-begin -->
      <div>
        <!-- <approval-tab @change="handleGroupChange" permission="dbcpWorkflowDesignVersion:view:all"></approval-tab> -->
        <setting-columns :columns='columns' @changeRealColumns='(data) => columns = data'></setting-columns>
        <a-table
          ref='table'
          size='middle'
          bordered
          rowKey='id'
          :columns='columns'
          :dataSource='dataSource'
          :pagination='ipagination'
          :loading='loading'
          :rowSelection='{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type}'
          class='j-table-force-nowrap'
          @change='handleTableChange'>

          <template slot='htmlSlot' slot-scope='text'>
            <div v-html='text'></div>
          </template>
          <template slot='imgSlot' slot-scope='text'>
            <span v-if='!text' style='font-size: 12px;font-style: italic;'>{{ $t('noPicture') }}</span>
            <img v-else :src='getImgView(text)' height='25px' alt=''
                 style='max-width:80px;font-size: 12px;font-style: italic;' />
          </template>
          <template slot='fileSlot' slot-scope='text'>
            <span v-if='!text' style='font-size: 12px;font-style: italic;'>{{ $t('noFile') }}</span>
            <a-button
              v-else
              :ghost='true'
              type='primary'
              icon='download'
              size='small'
              @click='uploadFile(text)'>
              {{ $t('download') }}
            </a-button>
          </template>

          <span slot='action' slot-scope='text, record'>
          <list-actions
            :record='record'
            @view='handleView'
            @edit='handleEdit'
            @delete='handleDelete'
            @action='$refs.detailForm.handleActionClick'
            @copy='handleCopy'
          ></list-actions>
        </span>

        </a-table>
      </div>


      <dbcpWorkflowDesignVersion-modal ref='modalForm' @ok='modalFormOk'></dbcpWorkflowDesignVersion-modal>
      <dbcpWorkflowDesignVersion-detail ref='detailForm' @action='handleActionClick' @close='handleViewModalClose'
                                        @loadData='loadData'
                                        @clearRouteQuery='clearRouteQuery'></dbcpWorkflowDesignVersion-detail>
    </a-card>
  </a-modal>
</template>

<script>
import { dbcpWorkflowDesignVersionApi } from './modules/api/DbcpWorkflowDesignVersion'
import '@/assets/less/TableExpand.less'
import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
import { SettingColumns } from 'dbcp-frontends-common/lib/components'
import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
import DbcpWorkflowDesignVersionModal from './modules/DbcpWorkflowDesignVersionModal'
import DbcpWorkflowDesignVersionDetail from './modules/DbcpWorkflowDesignVersionDetail'
// import { ApprovalTab } from 'dbcp-frontends-common/lib/components'
import { ListActions } from 'dbcp-frontends-common/lib/components'
import { SelectorMixin } from 'dbcp-frontends-common/lib/mixins'
import { filterMultiDictText } from '@/components/dict/JDictSelectUtil'

export default {
  name: 'DbcpWorkflowDesignVersionList',
  mixins: [JeecgListMixin, mixinDevice, ViewMixin, ApprovalListMixin, SelectorMixin],
  components: {
    DbcpWorkflowDesignVersionModal,
    DbcpWorkflowDesignVersionDetail,
    ListActions,
    SettingColumns

  },
  data() {
    return {
      visible: false,
      description: '流程设计版本管理管理页面',
      // 表头
      columns: [
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function(t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '流程设计id',
          align: 'center',
          dataIndex: 'designId'
        },
        {
          title: '版本号',
          align: 'center',
          dataIndex: 'versionNo'
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'status_dictText'
        },
        {
          title: '创建人',
          align: 'center',
          dataIndex: 'addBy_dictText'
        },
        {
          title: '创建时间',
          align: 'center',
          dataIndex: 'addTime'
        },
        {
          title: '更新人',
          align: 'center',
          dataIndex: 'updateBy_dictText'
        },
        {
          title: '更新时间',
          align: 'center',
          dataIndex: 'updateTime'
        },
        {
          title: this.$i18n.tc('action'),
          dataIndex: 'action',
          align: 'center',
          // fixed:"right",
          width: 147,
          scopedSlots: { customRender: 'action' }
        }
      ],
      url: dbcpWorkflowDesignVersionApi.url,
      dictOptions: {},
      /* 禁用created列表数据加载 */
      disableMixinCreated: true,
    }
  },
  computed: {
    importExcelUrl: function() {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    }
  },
  methods: {
    initDictConfig() {
    },
    show(record) {
      this.visible = true
      this.queryParam = {};
      this.queryParam = Object.assign({},record,this.queryParam)
      this.loadData()
    },
    handleCancel() {
      this.$emit('close')
    },
  }
}
</script>
<style scoped>
@import '~@assets/less/common.less';
</style>
