<template>
  <div>
    <div @click='showModal()' style='width:100%'>
      <a-select
        style="width: 100%"
        :dropdownMatchSelectWidth='false'
        :dropdownStyle='{width:`${width}px`,zIndex:"1001"}'
        :value='goodsName'
        ref='dropdown'
        :disabled='isEdit'
        v-if='!showButton'
        :allowClear='true'
        @mouseenter='clearValue'
      >
        <div slot="dropdownRender" slot-scope="menu">
        </div>

      </a-select>
      <slot v-else></slot>

    </div>

    <a-modal
      :visible='modalVisible'
      :width='modalWidth'
      @ok='handleOk'
      @cancel='onModalCancel'
      :mask='modalMask'
    >
      <component v-if='currentComponents != null' :is='currentComponents' @childFn="parentFn" v-bind='$attrs'></component>
    </a-modal>
  </div>
</template>

<script>
import {getAction} from 'dbcp-frontends-common/lib/api'
import { Modal } from 'ant-design-vue'


export default {
  name: "InputSelect",
  components: {
  },
  props:{
    goods:{
      type:String
    },
    value:{
      type:String | Number
    },
    sendParams:{
      type:String,
      default:'id'
    },
    showName:{
      type:String
    },
    url:{
      type:String
    },
  //  传进来的方法
    handleFun:{
      type:Function
    },
    isEdit:{
      type:Boolean
    },
    cancelRequest:{
      type:Boolean
    },
    width:{
      type:Number,
      default:1200
    },
    showButton:{
      type:Boolean,
      default:false
    },
  //  备选showName
    otherShowName:{
      type:String
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data() {
    return {
      currentComponents:null,
      goodsName:'',
      list:"",
      modalVisible: false,
      modalWidth: "80%",
      modalMask: false,
      currentData: {},
      attrName:"",
      isHasRegx:false,
    }
  },
  watch:{
    // url:{
    //   immediate:true,
    //   handler:function(newV) {
    //     // this.currentComponents = require("@views/dbcp/city/propertyManagement/buildingList/CumBuildingList.vue").
    //     console.log("this.currentComponents",this.currentComponents,this.url)
    //     if(!this.currentComponents){
    //       console.log(9999,newV);
    //
    //         import(`@views/${this.url}`).then(res => {
    //           this.currentComponents = res.default
    //           this.list = res.default.data.apply(this).url.list;
    //         })
    //     }
    //   },
    // },
    // currentComponents:function(newV,oldV){
    //   console.log("currentComponents newV",newV,oldV)
    // },
    value:{
      immediate:true,
      handler:function(newV){
        this.goodsName = '';
        // if(!!newV) {
          // if (!this.currentComponents) {
            console.log(8888, newV)
            import(`@views/${this.url}`).then(res => {
              this.currentComponents = res.default
              // console.log("11111",res.default.data.apply(this).url.list,newV)
              this.list = res.default.data.apply(this).url.list;

              if (newV) {
                console.log("my newV", newV);
                this.$nextTick(() => {
                  this.initSearch(newV)
                  console.log("---", this.list)
                })
              }
            })
          // }else{
          //   if (newV) {
          //     console.log("my newV", newV);
          //     this.$nextTick(() => {
          //       this.initSearch(newV)
          //       console.log("---", this.list)
          //     })
          //   }
          // }
        // }
      },
    },
    isEdit:{
      immediate:true,
      handler:function(newV){
        console.log("变化啦");
        this.isEdit = newV ;
      }
    }
  },
  methods: {
    onModalCancel() {
      this.currentComponents = null
      this.modalVisible = false
    },
    showModal() {
      console.log("showModal",this.url,this.currentComponents)

      // if (!this.currentComponents) {
        import(`@views/${this.url}`).then(res => {
          this.currentComponents = res.default
          console.log("11111", res.default.data.apply(this).url.list)
          this.list = res.default.data.apply(this).url.list;
        })
      // }
      if (this.isEdit) {

      } else {
        this.modalVisible = true
      }
    },
    parentFn(payload) {
      console.log("payload",payload,this.$attrs.type)
      if(this.$attrs.type==="radio"){
        if(Object.prototype.toString.call(payload)==='[object Array]'){
          this.currentData = payload[0]
        }else{
          this.currentData = payload ;
        }
      }else{
        this.currentData = payload ;
      }


    },
    initSearch(value){

      this.$nextTick(() => {
        let regx = /\$\{.+\}/;
        this.isHasRegx = regx.test(this.showName);
        if(this.isHasRegx){
          this.attrName = regx.exec(this.showName)[0].slice(2, -1);
        }
        console.log("this.attrName",this.attrName)
        if(!this.cancelRequest){
          getAction(this.list, { [this.sendParams+"_IN"]: value, pageSize: -1 }).then(res => {
            if (res.success) {
              if (this.$attrs.type==="radio" && this.handleFun ) {
                this.handleFun(res.result[0]).then(res => {
                  this.goodsName = res
                })
              } else {
                // this.goodsName = res.result[0][this.showName]
                if(res.result.length>1){
                  let value = "";
                  res.result.forEach((item,index)=>{
                    if(index===0){
                      if(item[this.attrName] || item[this.showName]){
                        if(this.isHasRegx){
                          value =  this.showName.replace(regx, item[this.attrName])
                        }else{
                          value = item[this.showName]
                        }
                      }else{
                        if(regx.test(this.otherShowName)){
                          value =  this.otherShowName.replace(regx, item[regx.exec(this.otherShowName)[0].slice(2, -1)])
                        }else{
                          value = item[this.otherShowName]
                        }
                      }

                    }else{
                      if(item[this.attrName] || item[this.showName]){
                        if(this.isHasRegx){
                          value = value + "," +this.showName.replace(regx, item[this.attrName])
                        }else{
                          value = value +"," + item[this.showName]
                        }
                      }else{
                        if(regx.test(this.otherShowName)){
                          value = value + "," +this.otherShowName.replace(regx, item[regx.exec(this.otherShowName)[0].slice(2, -1)])
                        }else{
                          value = value +"," + item[this.otherShowName]
                        }
                      }

                    }
                  })
                  this.goodsName = value ;
                }else {
                  console.log("showName", this.showName)
                  if(res.result[0][this.attrName] || res.result[0][this.showName]){
                    if(this.isHasRegx){
                      this.goodsName = this.showName.replace(regx, res.result[0][this.attrName])
                    }else{
                      this.goodsName = res.result[0][this.showName]
                    }
                  }else{
                    if(regx.test(this.otherShowName)){
                      this.goodsName =  this.otherShowName.replace(regx, res.result[0][regx.exec(this.otherShowName)[0].slice(2, -1)])
                    }else{
                      this.goodsName = res.result[0][this.otherShowName]
                    }
                  }


                }
              }
            } else {
              this.goodsName = ''
            }
            console.log("当前选中值显示",this.goodsName,this.showName)
          })
        }else{
          this.goodsName = ''
        }

      })
    },
    handleOk(){

      // this.$refs.dropdown.blur()
      // //失去焦点
      console.log("currentData",this.currentData)
      if(Object.prototype.toString.call(this.currentData)==='[object Array]'){
        let value = "";
        this.currentData.forEach((item,index)=>{
          if(index===0){
            value = item[this.sendParams];
          }else{
            value = value + "," + item[this.sendParams];
          }
        })
        this.$emit('change',value);
      }else {

        this.$emit('change', this.currentData[this.sendParams]);
        console.log(222,this.currentData[this.sendParams])
        // this.goodsName = this.currentData[this.showName];
      }
      this.$emit('ok', this.currentData)
      this.modalVisible = false
      this.currentComponents = null ;
    },
    clearValue(e){
      console.log(e)
      let clearDom = e.path[0].children[0].children[1]
      clearDom.addEventListener("click", () => {
        this.$emit('change',undefined)
        this.goodsName = ''
      })
    }
  }
}
</script>
<style lang='less' scoped>
.add{
  width: 100%;
  cursor: pointer;
  text-align: center;
  padding: 10px 0;
}

</style>
