import { deleteAction, getAction, uploadAction } from 'dbcp-frontends-common/lib/api'
export const dbcpWorkflowDesignApi = {
url: {
  action: "/workflow/dbcpWorkflowDesign/action",
  list: "/workflow/dbcpWorkflowDesignWorkflow/list"
},
  execute: {
    list: function(params) {
      return getAction(dbcpWorkflowDesignApi.url.list, params)
    },
    create: function(object) {
      return getAction(dbcpWorkflowDesignApi.url.add, object)
    },
    retrieve: function(id, params = {}) {
      return getAction(dbcpWorkflowDesignApi.queryById, { id, ...params })
    },
    update: function(object) {
      return uploadAction(dbcpWorkflowDesignApi.url.edit, object)
    },
    destroy: function(id) {
      return deleteAction(dbcpWorkflowDesignApi.url.delete, id)
    }
  }
}
