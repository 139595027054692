<template>
  <div>
    <a-upload v-model="uploadFiles" :remove="handleFileRemove" :before-upload="beforeFileUpload"
              :file-list="uploadFiles" @preview="handlePreview" :show-upload-list=" false" :multiple="multi">
      <a-button>
        <a-icon type="upload"/>
        {{ $t('selectFileHint') }}
      </a-button>
    </a-upload>

    <div v-for="(item,index) in uploadFiles" class="ant-upload-list-item" :key="index">
      <div class="ant-upload-list-item-info">
        <span style="line-height: normal">
          <a-icon type="paper-clip" />
          <span class="ant-upload-list-item-name">{{ item.name }}</span>
          <span class="ant-upload-list-item-card-actions" @click="handlePreview(item)" style="right:30px"><a
            title="删除文件"><a-icon type="eye" /></a></span>
          <!--          删除按钮-->
          <span class="ant-upload-list-item-card-actions" @click="handleFileRemove(item)"><a title="删除文件"><a-icon type="delete" /></a></span>
        </span>
      </div>
    </div>

    <div class="upload-file">
      <files v-if="defaultFileList.length" :files="defaultFileList" size="small"></files>
    </div>

    <a-modal v-model="previewVisible" :footer="null" width="70%">
      <div v-if="previewType === 'image'" >
        <img :src="previewUrl" alt=""  style="width: 100%">
      </div>
<!--      <div v-if="previewType === 'pdf'">-->
<!--        <pdf :src="previewUrl"></pdf>-->
<!--      </div>-->
    </a-modal>
  </div>
</template>

<script>
import { Files } from 'dbcp-frontends-common/lib/components'
// import pdf from 'vue-pdf'

export default {
  name: 'Attachment',
  components: {
    Files,
  },
  props: {
    defaultFileList: {
      type: Array
    },
    fileList: {
      type: Array,
      default: () => []
    },
    multi:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      uploadFiles: [],
      previewVisible: false,
      previewType: 'image',
      previewUrl: ''
    }
  },
  watch: {
    fileList(val) {
      console.log('watch fileList', val)
      this.uploadFiles = val
      this.$emit('change', val)
    }
  },
  methods: {
    handleFileRemove(files) {
      this.$emit('handleFileRemove', files)
    },
    beforeFileUpload(files) {
      this.$emit('beforeFileUpload', files)
      return false
    },
    //  预览
    handlePreview(file) {
      // 获取后缀名
      let extName = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length)
      console.log(extName)
      let acceptImgExtArr = ['jpg', 'jpeg', 'png', 'gif']
      if (acceptImgExtArr.includes(extName.toLowerCase())) {
        this.previewType = 'image'
        let url = URL.createObjectURL(file)
        this.previewUrl = url
        this.previewVisible = true
      } else if (extName.toLowerCase() === 'pdf') {
        this.previewType = 'pdf'
        let url = URL.createObjectURL(file)
        this.previewUrl = url
        this.previewVisible = true
      }
      else {
        this.$message.error('不支持预览此类型')
      }
    }
  }
}
</script>

<style scoped>
.upload-file {
  padding-top: 10px;
}
</style>
