<template>
  <div>
    <a-modal
      :title="title"
      :width="width"
      placement="right"
      @cancel="close"
      :footer="null"
      :visible="visible">
      <a-descriptions :column="3" bordered size="small">
        <a-descriptions-item :label="$t('projectId')">
          <a @click='turnProjectDetail(data.projectId)'>{{ data.projectId_dictText }}</a>
        </a-descriptions-item>
              <a-descriptions-item :label="$t('buildingId')">
                <a @click='turnBuildingDetail(data.buildingId)'> {{ data.buildingNumber }}</a>

        </a-descriptions-item>
        <a-descriptions-item :label="$t('floor')">
          {{ data.floor }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('roomNo')">
          {{ data.roomNo }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('certificateConstructionArea')">
          {{ data.certificateConstructionArea }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('increasedConstructionArea')">
          {{ data.increasedConstructionArea }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('rightHolder')">
          {{ data.rightHolder_dictText }}
        </a-descriptions-item>
        <a-descriptions-item label="测绘编号">
          {{ data.surveyedNo }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('surveyedArea')">
          {{ data.surveyedArea }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('surveyingStatus')">
          {{ data.surveyingStatus_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addBy')">
          {{ data.addBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('addTime')">
          {{ data.addTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateBy')">
          {{ data.updateBy_dictText }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('updateTime')" :span='3'>
          {{ data.updateTime }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('remark')" :span='3'>
          {{ data.remark }}
        </a-descriptions-item>
      </a-descriptions>

      <a-tabs default-active-key="1" >
        <a-tab-pane key="1" tab="附属">
          <cum-unit-subsidiary-list :show-unit='false' :unitId='data.id'></cum-unit-subsidiary-list>
        </a-tab-pane>
      </a-tabs>


    </a-modal>
    <cumBuilding-detail ref='buildingDetailForm'></cumBuilding-detail>
    <cum-project-detail ref='projectDetailForm'></cum-project-detail>
  </div>
</template>

<script>
  import {  cumUnitApi } from './api/CumUnitWorkFlow'
  import { DetailHistory } from 'dbcp-frontends-common/lib/components'
  import { DetailMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ActionMixin } from 'dbcp-frontends-common/lib/mixins'
  import CumUnitSubsidiaryList from '../../../unitAffiliateManagement/CumUnitSubsidiaryList'
  import CumProjectDetail from '../../../projectManagement/modules/CumProjectDetail'
  import { getAction } from 'dbcp-frontends-common/lib/api'
  import CumBuildingDetail from '../../buildingList/modules/CumBuildingDetail'

  export default {
    name: "CumUnitDetail",
    components: { DetailHistory,CumUnitSubsidiaryList,CumProjectDetail,CumBuildingDetail },
    mixins: [ DetailMixin,ActionMixin ],
    data() {
      return {
        url: cumUnitApi.url,
      }
    },
    methods:{
      view(data){
        this.data = data
        this.visible = true
      },
      turnProjectDetail(id){
        getAction("/cum/cumProject/list",{id}).then(res=>{
          let data = res.result.records[0];
          this.$nextTick(()=>{
            this.$refs.projectDetailForm.view(data);
          })

        })
      },
      turnBuildingDetail(id){
        getAction("/cum/cumBuilding/list",{id}).then(res=>{
          let data = res.result.records[0];
          this.$nextTick(()=>{
            this.$refs.buildingDetailForm.view(data);
          })

        })
      }
    }
  }
</script>
