<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :xl='4' :lg='7' :md='8' :sm='24'>
            <a-form-item label='序列号'>
              <a-input placeholder='请输入查询序列号' v-model='queryParam.serialNumber_like'></a-input>
            </a-form-item>
          </a-col>
          <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
          <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{
              $t('reset')
            }}</a-button>
        </a-row>


      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd({'projectId':projectId})" type="primary" icon="plus" v-has="'cumCollectionPayment:create'">{{ $t('create') }}</a-button>
<!--      <a-dropdown v-if="selectedRowKeys.length > 0">-->
<!--        <a-menu slot="overlay">-->
<!--          <a-menu-item key="1" @click="batchDel"><a-icon type="delete"/>{{ $t('delete') }}</a-menu-item>-->
<!--        </a-menu>-->
<!--        <a-button style="margin-left: 8px"> {{ $t('batchOperation') }} <a-icon type="down" /></a-button>-->
<!--      </a-dropdown>-->
    </div>

    <!-- table区域-begin -->
    <div>
      <approval-tab @change="handleGroupChange" permission="cumCollectionPayment:view:all"></approval-tab>
      <setting-columns :columns='columns' @changeRealColumns='(data) => columns = data'></setting-columns>
      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">

        <template slot='projectId' slot-scope='text,record'>
          <a @click='turnProjectDetail(record)' >{{text}}</a>
        </template>

        <span slot="action" slot-scope="text, record">
          <list-actions
            :record="record"
            @view="handleView"
            @edit="handleEdit"
            @delete="handleDelete"
            @action="$refs.detailForm.handleActionClick"
            @copy="handleCopy"
          ></list-actions>
        </span>

      </a-table>
    </div>


    <cumCollectionPayment-modal ref="modalForm" @ok="modalFormOk"></cumCollectionPayment-modal>
    <cumCollectionPayment-detail ref="detailForm" @action="handleActionClick" @close="handleViewModalClose"
                                      @loadData="loadData" @clearRouteQuery="clearRouteQuery"></cumCollectionPayment-detail>
    <cum-project-detail ref='projectDetailForm'></cum-project-detail>
  </a-card>
</template>

<script>
  import { cumCollectionPaymentApi } from './modules/api/CumCollectionPayment'
  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
  import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
  import { SettingColumns } from 'dbcp-frontends-common/lib/components'
  import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
  import CumCollectionPaymentModal from './modules/CumCollectionPaymentModal'
  import CumCollectionPaymentDetail from './modules/CumCollectionPaymentDetail'
  import { ApprovalTab } from 'dbcp-frontends-common/lib/components'
  import { ListActions } from 'dbcp-frontends-common/lib/components'
  import { SelectorMixin } from 'dbcp-frontends-common/lib/mixins'
  import {filterMultiDictText} from '@/components/dict/JDictSelectUtil'
  import CumProjectDetail from '../projectManagement/modules/CumProjectDetail'
  import { getAction } from 'dbcp-frontends-common/lib/api'
  import { JDictSelectTag } from 'dbcp-frontends-common/lib/components'
  export default {
    name: "CumCollectionPaymentList",
    mixins:[JeecgListMixin, mixinDevice, ViewMixin, ApprovalListMixin,SelectorMixin],
    components: {
      CumCollectionPaymentModal,
      CumCollectionPaymentDetail,
      ListActions,
      SettingColumns,
      CumProjectDetail,
      ApprovalTab,
      JDictSelectTag
    },
    data () {
      return {
        description: '收楼付款管理页面',
        // 表头
        columns: [
          {
            title: '#',
            dataIndex: '',
            key: 'rowIndex',
            width: 60,
            align: 'center',
            customRender: function (t,r,index) {
              return parseInt(index)+1;
            }
          },
          {
            title: '序列号',
            align: 'center',
            dataIndex: 'serialNumber'
          },
          // {
          //   title: '合同',
          //   align: 'center',
          //   dataIndex: 'contractId'
          // },
          {
            title: '项目',
            align: 'center',
            dataIndex: 'projectId_dictText',
            scopedSlots: { customRender: 'projectId'}
          },
          {
            title: '创建人',
            align: 'center',
            dataIndex: 'addBy_dictText'
          },
          {
            title: '创建时间',
            align: 'center',
            dataIndex: 'addTime'
          },
          {
            title: '状态',
            align: 'center',
            dataIndex: 'status_dictText'
          },
          {
            title: '当前处理人',
            align: 'center',
            dataIndex: 'approver_dictText',
          },
          {
            title: this.$i18n.tc('action'),
            dataIndex: 'action',
            align: 'center',
            // fixed:"right",
            width: 147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: cumCollectionPaymentApi.url,
        dictOptions:{},
        /* 禁用created列表数据加载 */
        disableMixinCreated: true,

      }
    },
    props: {
      projectId: {
        type:String,
        default:""
      }
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
    },
    watch: {
      'projectId': {
        handler:function(newV){
          console.log('newV23132', newV)
          this.queryParam.projectId = newV
          this.dataSource = []
          this.loadData();
          }
        },
    },
    created() {
      this.queryParam.projectId = this.projectId
      this.dataSource = []
      this.loadData();
    },
    methods: {
      initDictConfig(){
      },
      turnProjectDetail(record){
        getAction("/cum/cumProject/list",{id:record.projectId}).then(res=>{
          let data = res.result.records[0];
          this.$nextTick(()=>{
            this.$refs.projectDetailForm.view(data);
          })

        })
      },
      searchReset(){
        if(this.projectId){
          this.queryParam = {};
          this.queryParam.projectId = this.projectId;
          this.loadData(1);
        }
      }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>
