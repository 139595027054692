<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <a-form-item label='序列号'>
              <a-input placeholder='请输入查询序列号' v-model='queryParam.serialNumber_like'></a-input>
            </a-form-item>
          </a-col>
          <a-col :xl='6' :lg='7' :md='8' :sm='24'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>查询</a-button>
              <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset')
                }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd(currentRecord)" type="primary" icon="plus" v-has="'cumContract:create'">{{ $t('create') }}</a-button>
      <a-button @click="showTemplate" >合同模板</a-button>
<!--      <a-button type="primary" icon="download" @click="handleExportXls('合同审批')">{{ $t('export') }}</a-button>-->
<!--      <a-upload name="file" :showUploadList="false" :multiple="false" :headers="tokenHeader" :action="importExcelUrl" @change="handleImportExcel">-->
<!--        <a-button type="primary" icon="import">{{ $t('import') }}</a-button>-->
<!--      </a-upload>-->
<!--      <a-dropdown v-if="selectedRowKeys.length > 0">-->
<!--        <a-menu slot="overlay">-->
<!--          <a-menu-item key="1" @click="batchDel"><a-icon type="delete"/>{{ $t('delete') }}</a-menu-item>-->
<!--        </a-menu>-->
<!--        <a-button style="margin-left: 8px"> {{ $t('batchOperation') }} <a-icon type="down" /></a-button>-->
<!--      </a-dropdown>-->
    </div>

    <!-- table区域-begin -->
    <div>
     <!-- <approval-tab @change="handleGroupChange" permission="cumContract:view:all"></approval-tab> -->
      <div style='display: flex;justify-content: space-between'>
        <approval-tab @change="handleGroupChange" permission="cumContract:view:all"></approval-tab>
        <setting-columns :columns='columns' @changeRealColumns='(data) => columns = data'></setting-columns>

      </div>

      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">

        <template slot='projectId' slot-scope='text,record'>
          <a @click='turnProjectDetail(record.projectId)' >{{text}}</a>
        </template>

        <template slot='projectItemId' slot-scope='text,record'>
          <a @click='turnProjectItemDetail(record)' >{{record.rightHolder_dictText}}</a>
        </template>

        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="imgSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noPicture') }}</span>
          <img v-else :src="getImgView(text)" height="25px" alt="" style="max-width:80px;font-size: 12px;font-style: italic;"/>
        </template>
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">{{ $t('noFile') }}</span>
          <a-button
            v-else
            :ghost="true"
            type="primary"
            icon="download"
            size="small"
            @click="uploadFile(text)">
            {{ $t('download') }}
          </a-button>
        </template>

        <span slot="action" slot-scope="text, record">
          <list-actions
            :record="record"
            @view="handleView"
            @edit="handleEdit"
            @delete="handleDelete"
            @action="$refs.detailForm.handleActionClick"
            @copy="handleCopy"
          ></list-actions>
        </span>

      </a-table>
    </div>


    <cumContract-modal ref="modalForm" @ok="modalFormOk"></cumContract-modal>
    <cumContract-detail ref="detailForm" @action="handleActionClick" @close="handleViewModalClose"
                                      @loadData="loadData" @clearRouteQuery="clearRouteQuery"></cumContract-detail>


    <cumProjectItem-detail ref='projectItemDetailForm' ></cumProjectItem-detail>
    <cum-contract-template-list ref='templateList'></cum-contract-template-list>

    <cum-project-detail ref='projectDetailForm'></cum-project-detail>
  </a-card>
</template>

<script>
  import {  cumContractApi } from './modules/api/CumContract'
  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from 'dbcp-frontends-common/lib/utils'
  import { JeecgListMixin } from 'dbcp-frontends-common/lib/mixins'
  import { ViewMixin } from 'dbcp-frontends-common/lib/mixins'
  import { SettingColumns } from 'dbcp-frontends-common/lib/components'
  import { ApprovalListMixin } from 'dbcp-frontends-common/lib/mixins'
  import CumContractModal from './modules/CumContractModal'
  import CumContractDetail from './modules/CumContractDetail'
  import { ApprovalTab } from 'dbcp-frontends-common/lib/components'
  import { ListActions } from 'dbcp-frontends-common/lib/components'
  import { SelectorMixin } from 'dbcp-frontends-common/lib/mixins'
  import {filterMultiDictText} from '@/components/dict/JDictSelectUtil'
  import { getAction } from 'dbcp-frontends-common/lib/api'
  import CumProjectItemDetail from '../projectManagement/modules/CumProjectItemDetail'
  import CumContractTemplateList from '../contractTemplate/CumContractTemplateList'
  import CumProjectDetail from '../projectManagement/modules/CumProjectDetail'

  export default {
    name: "CumContractList",
    mixins:[JeecgListMixin, mixinDevice, ViewMixin, ApprovalListMixin,SelectorMixin],
    components: {
      CumContractModal,
      CumContractDetail,
      ListActions,
      SettingColumns,
      CumProjectItemDetail,
      ApprovalTab,
      CumContractTemplateList,
      CumProjectDetail
    },
    props:{
      flag:{
        type:Boolean,
        default:false
      },
      type:{
        type:String,
        default:'checkbox'
      },
      currentRecord: {
        type:Object,
        default:()=>{}
      }
    },
    data () {
      return {
        aaa:false,
        description: '合同审批管理页面',
        // 表头
        columns: [
          {
            title: '#',
            dataIndex: '',
            key: 'rowIndex',
            width: 60,
            align: 'center',
            customRender: function (t,r,index) {
              return parseInt(index)+1;
            }
          },
          {
            title: '合同编号',
            align: 'center',
            dataIndex: 'serialNumber'
          },
          {
            title: '项目',
            align: 'center',
            dataIndex: 'projectId_dictText',
            scopedSlots: { customRender: 'projectId' }
          },
          {
            title: '项目明细',
            align: 'center',
            dataIndex: 'projectItemId',
            scopedSlots: { customRender: 'projectItemId'}
          },
          // {
          //   title: '部门',
          //   align: 'center',
          //   dataIndex: 'dept_dictText'
          // },
          {
            title: '房屋移交时间',
            align: 'center',
            dataIndex: 'handoverTime',
            customRender: function (text) {
              return !text?"":(text.length>10?text.substr(0,10):text)
            }
          },
          {
            title: '签约面积(m²)',
            align: 'center',
            dataIndex: 'contractedArea'
          },
          // {
          //   title: '款项汇入人',
          //   align: 'center',
          //   dataIndex: 'payee'
          // },
          // {
          //   title: '开户行',
          //   align: 'center',
          //   dataIndex: 'openingBank'
          // },
          // {
          //   title: '银行账号',
          //   align: 'center',
          //   dataIndex: 'bankAccount'
          // },
          // {
          //   title: '备注',
          //   align: 'center',
          //   dataIndex: 'remark'
          // },
          {
            title: '创建人',
            align: 'center',
            dataIndex: 'addBy_dictText'
          },
          {
            title: '创建时间',
            align: 'center',
            dataIndex: 'addTime'
          },
          {
            title: '状态',
            align: 'center',
            dataIndex: 'status_dictText'
          },
          {
            title: '当前处理人',
            align: 'center',
            dataIndex: 'approver_dictText',
          },
          {
            title: this.$i18n.tc('action'),
            dataIndex: 'action',
            align: 'center',
            // fixed:"right",
            width: 147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: cumContractApi.url,
        dictOptions:{},
        /* 禁用created列表数据加载 */
        disableMixinCreated: true,
      }
    },
    watch:{
      currentRecord: {
        deep:true,
        handler:function(newV){
          console.log('newV23132', newV)
          for(let key in newV){
            this.queryParam[key] = newV[key]
          }
          console.log("this.queryParam",this.queryParam);
          this.dataSource = []
          this.loadData();
        }
      }
    },
    created() {
      console.log('currentRecord', this.currentRecord)
      for(let key in this.currentRecord){
        console.log("key",key)
        this.queryParam[key] = this.currentRecord[key]
      }
      this.dataSource = [] ;
      this.loadData();
      console.log(1212)
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
    },
    methods: {
      initDictConfig(){
      },
      turnProjectItemDetail(record){
        getAction("/cum/cumProjectItem/list",{id:record.projectItemId}).then(res=>{
          let data = res.result.records[0];
          this.$nextTick(()=>{
            this.$refs.projectItemDetailForm.view(data);
          })

        })
      },
      showTemplate(){
        this.$refs.templateList.show()
      },
      onSelectChange(selectedRowKeys, selectionRows) {
        this.selectedRowKeys = selectedRowKeys;
        this.selectionRows = selectionRows;
        this.$emit('childData',selectionRows)
        this.$emit('childFn',selectionRows)
      },
      turnProjectDetail(id){
        getAction("/cum/cumProject/list",{id}).then(res=>{
          let data = res.result.records[0];
          this.$nextTick(()=>{
            this.$refs.projectDetailForm.view(data);
          })

        })
      },
      searchReset(){
        this.queryParam = {};
        for(let key in this.currentRecord){
          console.log("key",key)
          this.queryParam[key] = this.currentRecord[key]
        }
        this.loadData(1);
      },
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>
