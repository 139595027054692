<template>
  <a-card :title="title" style="width: 100%" :size="size">
    <a-spin :spinning="spinning" size="small" :indicator="null">
      <a-button type="primary" @click="selectAll" v-if="files.length > 0" style="margin-right: 15px">
        全选
      </a-button>
      <a-button v-if="checkedArray && checkedArray.length > 0 && files.length > 0 " type="primary"
                @click="multiDownload">
        批量下载
      </a-button>
      <a-checkbox-group style="width: 100%" @change="handleChange" :value="checkedArray">
        <a-list item-layout="horizontal" :data-source="files">
          <a-list-item slot="renderItem" slot-scope="item, index">
            <a-checkbox :value="item.path" class="checkbox">
              <div style="width:100%;display: flex;align-items: center;margin-left: 10px;">
                <a-list-item-meta>
                  <a slot="title" :href="item.path" target="_blank" :download="item.title">{{ `${item.title}` }}
                    ({{ item.size | prettyBytes }})</a>

                  <!--                  <a-avatar-->
                  <!--                    slot="avatar"-->
                  <!--                    src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"-->
                  <!--                  />-->

                </a-list-item-meta>
                <div class="file-content" style="margin-right: 40px">{{ item.addedTime }}</div>
                <div slot="actions">
                  <a-button style="margin-right: 15px" @click="handlePreview(item)">预览</a-button>

                  <a-popconfirm
                    placement="left"
                    :title="$t('confirmDeleteAlert')"
                    :ok-text="$t('confirm')"
                    :cancel-text="$t('cancel')"
                    @confirm="handleFileDelete(item.id, index)"
                    v-show="isDelete"
                  >
                    <a-button>{{ $t('delete') }}</a-button>
                  </a-popconfirm>
                </div>
              </div>
            </a-checkbox>
          </a-list-item>

        </a-list>
      </a-checkbox-group>

      <a-modal v-model="previewVisible" :footer="null" width="70%">
        <div>
          <iframe :src="`${previewBaseUrl}?url=${previewUrl}`" width="100%" frameborder="0"
                  style="height:80vh"></iframe>
        </div>
      </a-modal>
      <!--    <a-modal v-model="multiVisible" :footer="null" width="70%">-->
      <!--      <a-progress type="circle" :percent="downloadPercent"/>-->
      <!--    </a-modal>-->
    </a-spin>
    <div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);">
      <a-progress type="circle" :percent="downloadPercent" v-show="multiVisible"/>
    </div>
  </a-card>
</template>

<script>
import * as fileApi from 'dbcp-frontends-common/lib/api'
import pdf from 'vue-pdf'
import JSzip from 'jszip'
import FileSaver from 'file-saver'
import axios from 'axios'

export default {
  name: 'Files',
  components: {
    pdf
  },
  props: {
    size: {
      type: String,
      default: 'default' // default, small
    },
    files: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    isDelete: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      previewVisible: false,
      previewUrl: '',
      checkedArray: [],
      excelData: null,
      multiVisible: false,
      downloadPercent: 0,
      spinning: false
    }
  },
  computed: {
    previewBaseUrl() {
      return window._CONFIG['onlinePreviewDomainURL']
    }
  },
  created() {
  },
  methods: {
    handleFileDelete(id, index) {
      fileApi.destroy(id).then((res) => {
        // this.data = this.data.filter(item => item.id !== id)
        this.files.splice(index, 1)
        this.$emit('change')
      })
    },
    handlePreview(file) {
      console.log(file)
      // // 获取后缀名
      let extName = file.title.substring(file.title.lastIndexOf('.') + 1, file.title.length)
      if (extName === 'pdf') {
        this.previewUrl = file.path + '&officePreviewType=pdf'
      } else {
        this.previewUrl = file.path
      }
      this.previewVisible = true
    },
    handleChange(checkValue) {
      this.checkedArray = checkValue
      console.log('checkValue',checkValue)
      // this.checkedArray.forEach((item, index) => {
      //   window.open(item)
      // })
    },
    multiDownload() {
      let successNumber = 0
      const fileList = [...this.checkedArray]
      const zip = new JSzip()
      const cache = {}
      const promises = []
      this.multiVisible = true
      this.spinning = true
      this.downloadPercent = 0
      const getFile = url => {
        return new Promise((resolve, reject) => {
          axios({
            method: 'get',
            url,
            responseType: 'blob'
          }).then(data => {
              successNumber++
              //解决进度条OK的时候 还没下载完成
              if (successNumber < fileList.length) {
                this.downloadPercent = 100 * (successNumber / fileList.length)
              }
              resolve(data.data)
            }).catch(error => {
              reject(error.toString())
            })
        })
      }


      fileList.forEach(item => {
        const promise = getFile(item).then(data => {
          // 下载文件, 并存成ArrayBuffer对象
          const arr_name = item.split('/')
          const file_name = arr_name[arr_name.length - 1] // 获取文件名
          zip.file(file_name, data, { binary: true }) // 逐个添加文件
          cache[file_name] = data
        })
        promises.push(promise)
      })
      Promise.all(promises).then(() => {
        zip.generateAsync({ type: 'blob' }).then(content => {
          // 生成二进制流
          FileSaver.saveAs(content, `压缩文件_${new Date().toLocaleDateString()}.zip`) // 利用file-saver保存文件  自定义文件名
          //解决进度条OK的时候 还没下载完成
          if (successNumber = fileList.length) {
            this.downloadPercent = 100
          }
          setTimeout(() => {
            this.multiVisible = false
            this.spinning = false
          }, 500)
        })
      })
    },
    selectAll(){
    //  如果全部选中再点击全选时  要全部清除掉
      if(this.files.length === this.checkedArray.length){
        this.checkedArray = []
      }else{
        let arr = []
        //第一次点击全选时
        this.files.forEach(item => {
          arr.push(item.path)
        })
        this.checkedArray = arr
      }
    }
  }
}
</script>

<style scoped lang="less">
.ant-btn-danger {
  background-color: #f5222d !important;
}

.checkbox {
  width: 100%;
  display: flex;
  align-items: center;
}

/deep/ .ant-checkbox + span {
  width: 100%;
}

</style>
