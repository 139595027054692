<template>
  <div class='page'>
    <div class='tool'>
      <div v-for='(item, index) in tools' :key='index'>
        <div class='title'>{{ item.group }}</div>
        <div class='buttons'>
          <a
            v-for='(btn, i) in item.children'
            :key='i'
            :title='btn.name'
            :draggable='btn.data'
            @dragstart='onDrag($event, btn)'
          >
            <i :class='`iconfont ${btn.icon}`'></i>
          </a>
        </div>
      </div>
    </div>
    <a-popconfirm placement="bottomRight" ok-text="Yes" cancel-text="No" @confirm="confirm">
      <template slot="title">
        <a-input type='number' v-model='quickCreate.column' style='width: 50px'></a-input>
        X
        <a-input type='number' v-model='quickCreate.row' style='width: 50px'></a-input>
      </template>
      <a-button>快速生成</a-button>
    </a-popconfirm>

    <!--    画布-->
    <div class='full' id='topology-canvas' @contextmenu='onContextMenu($event)'></div>

    <!--    配置-->
    <div class='props' :style="props.expand ? 'overflow: visible' : ''">
      <CanvasProps :props.sync='props' @change='onUpdateProps'></CanvasProps>
    </div>

    <!--   右键 -->
    <div class='context-menu' v-if='contextmenu.left' :style='this.contextmenu'>
      <CanvasContextMenu :canvas='canvas' :props.sync='props'></CanvasContextMenu>
    </div>
  </div>
</template>

<script>
import { Topology } from '@topology/core'
// 图形库
import { canvasRegister } from '@/services/canvas'
import { Tools } from '@/services/canvas'
import * as FileSaver from 'file-saver'
import CanvasProps from '@/components/CanvasProps'
import CanvasContextMenu from '@/components/CanvasContextMenu'

export default {
  name: 'Home',
  components: {
    CanvasProps, CanvasContextMenu
  },
  created() {
    canvasRegister()
    document.onclick = event => {
      this.contextmenu = {
        left: null,
        top: null,
        bottom: null
      }
    }
  },
  computed: {
    event() {
      return this.$store.state.event.event
    }
  },
  watch: {
    event(curVal) {
      console.log(curVal)
      if (this['handle_' + curVal.name]) {
        this['handle_' + curVal.name](curVal.data)
      }
    }
  },
  data() {
    return {
      tools: Tools,
      // 图形库
      canvas: {},
      // 图形库选项：https://www.yuque.com/alsmile/topology/canvas#hOupV
      canvasOptions: {
        rotateCursor: ''
      },
      // 右侧属性栏数据
      props: {
        node: null,
        line: null,
        multi: false
      },
      contextmenu: {
        left: null,
        top: null,
        bottom: null
      },
      quickCreate:{
        column:0,
        row:0
      }
    }
  },
  sockets: {
    connect: () => {
      console.log('socket已连接')
    },
    newData(data) {
      console.log(data)
      this.canvas.open(data.data)
      // this.canvas.render()
    },
    newData2(data) {
      console.log('newData2')
      this.canvas.open(data.data)
      // this.canvas.render()
    }
  },
  mounted() {
    this.canvasOptions.on = this.onMessage
    this.canvas = new Topology('topology-canvas', this.canvasOptions)
    console.log(this.canvas)
    canvasRegister()
  },
  methods: {
    onDrag(event, node) {
      event.dataTransfer.setData('Text', JSON.stringify(node.data))
    },
    onMessage(event, data) {
      console.log('onMessage', event, data)
      // 初始key
      if(typeof data === 'object' && !data.nodeData){
        data.nodeData = {}
      }
      // 右侧输入框编辑状态时点击编辑区域其他元素，onMessage执行后才执行onUpdateProps方法，通过setTimeout让onUpdateProps先执行
      setTimeout(() => {
        switch (event) {
          case 'node':
            this.props = {
              node: data,
              line: null,
              multi: false,
              expand: this.props.expand,
              nodes: null,
              locked: data.locked
            }
            break
          case 'addNode':
            this.props = {
              node: data,
              line: null,
              multi: false,
              expand: this.props.expand,
              nodes: null,
              locked: data.locked
            }
            //发送当前数据给服务器
            break
          case 'line':
            this.props = {
              node: null,
              line: data,
              multi: false,
              nodes: null,
              locked: data.locked
            }
            break
          case 'addLine':
            this.props = {
              node: null,
              line: data,
              multi: false,
              nodes: null,
              locked: data.locked
            }
            //发送当前数据给服务器

            break
          case 'multi':
            this.props = {
              node: null,
              line: null,
              multi: true,
              nodes: data.length > 1 ? data : null,
              locked: this.getLocked({ nodes: data })
            }
            break
          case 'space':
            this.props = {
              node: null,
              line: null,
              multi: false,
              nodes: null,
              locked: false
            }
            // //发送当前数据给服务器

            break
          case 'moveOut':
            break
          case 'moveNodes':
            break
          case 'resizeNodes':
            if (data.length > 1) {
              this.props = {
                node: null,
                line: null,
                multi: true,
                nodes: data,
                locked: this.getLocked({ nodes: data })
              }
            } else {
              this.props = {
                node: data[0],
                line: null,
                multi: false,
                nodes: null,
                locked: false
              }
            }
            //发送当前数据给服务器

            break
          case 'resizePens':
            if (data.length > 1) {
              this.props = {
                node: null,
                line: null,
                multi: true,
                nodes: data,
                locked: this.getLocked({ nodes: data })
              }
            } else {
              this.props = {
                node: data[0],
                line: null,
                multi: false,
                nodes: null,
                locked: false
              }
            }

            break
          case 'move':

            break
          case 'resize':
            break
          case 'scale':
            break
          case 'locked':
            if (this.canvas && this.canvas.data) {
              console.log(this.canvas)
              this.$store.commit('data', {
                scale: this.canvas.data.scale || 1,
                lineName: this.canvas.data.lineName,
                fromArrowType: this.canvas.data.fromArrow,
                toArrowType: this.canvas.data.toArrow,
                fromArrowlockedType: this.canvas.data.locked
              })
            }
            break
        }

        console.log(this.props)
      }, 0)
    },
    getLocked(data) {
      let locked = true
      if (data.nodes && data.nodes.length) {
        for (const item of data.nodes) {
          if (!item.locked) {
            locked = false
            break
          }
        }
      }
      if (locked && data.lines) {
        for (const item of data.lines) {
          if (!item.locked) {
            locked = false
            break
          }
        }
      }

      return locked
    },

    //  变更上面菜单栏的时候
    handle_state(data) {
      this.canvas.data[data.key] = data.value
      this.$store.commit('data', {
        scale: this.canvas.data.scale || 1,
        lineName: this.canvas.data.lineName,
        fromArrowType: this.canvas.data.fromArrow,
        toArrowType: this.canvas.data.toArrow,
        fromArrowlockedType: this.canvas.data.locked
      })
    },
    onUpdateProps(node) {
      // 如果是node属性改变，需要传入node，重新计算node相关属性值
      // 如果是line属性改变，无需传参
      this.canvas.updateProps(node)
    },

    handle_new(data) {
      this.canvas.open()
    },

    handle_open(data) {
      this.handle_replace(data)
    },

    handle_replace(data) {
      const input = document.createElement('input')
      input.type = 'file'
      input.onchange = event => {
        const elem = event.srcElement || event.target
        if (elem.files && elem.files[0]) {
          const name = elem.files[0].name.replace('.json', '')
          const reader = new FileReader()
          reader.onload = e => {
            const text = e.target.result + ''
            try {
              const data = JSON.parse(text)
              this.canvas.open(data)
            } catch (e) {
              return false
            }
          }
          reader.readAsText(elem.files[0])
        }
      }
      input.click()
    },

    handle_save(data) {
      FileSaver.saveAs(
          new Blob([JSON.stringify(this.canvas.data)], {
            type: 'text/plain;charset=utf-8'
          }),
          `FlowChart.json`
      );
      // console.log(JSON.stringify(this.canvas.data))
    },

    handle_savePng(data) {
      this.canvas.saveAsImage('FlowChart.png')
    },


    handle_undo(data) {
      this.canvas.undo()
    },

    handle_redo(data) {
      this.canvas.redo()
    },

    handle_copy(data) {
      this.canvas.copy()
    },

    handle_cut(data) {
      this.canvas.cut()
    },

    handle_parse(data) {
      this.canvas.parse()
    },

    onContextMenu(event) {
      event.preventDefault()
      event.stopPropagation()

      if (event.clientY + 360 < document.body.clientHeight) {
        this.contextmenu = {
          left: event.clientX + 'px',
          top: event.clientY + 'px'
        }
      } else {
        this.contextmenu = {
          left: event.clientX + 'px',
          bottom: document.body.clientHeight - event.clientY + 'px'
        }
      }
    },
    /**
     * 快速生成泳道图
     */
    confirm(){
      for (let i = 0; i < this.quickCreate.row; i++) {
        this.canvas.addNode({
          name: 'swimlaneH', // registerNode的第一个参数。
          text: '水平泳道',
          rect: {
            width: 200 * this.quickCreate.column + 0.08625 * 200 * this.quickCreate.column,
            height: 200,
            x: 100 ,
            y: 100 + (i * 200)
          },
          disableSizeY: true
        })
      }
      for (let i = 0; i < this.quickCreate.column; i++) {
        this.canvas.addNode({
          name: 'swimlaneV', // registerNode的第一个参数。
          text: '垂直泳道',
          rect: {
            width: 200,
            height: 200 * this.quickCreate.row + 0.08625 * 200 * this.quickCreate.row,
            x: 100 + 0.08625 * 200 * this.quickCreate.column + (i * 200),
            y:100 - 0.08625 * 200 * this.quickCreate.row
          },
          disableSizeX: true
        })
      }
    }
  }
}
</script>
<style lang='less' scoped>
.page {
  display: flex;
  width: 100%;
  height: 100%;

  .tool {
    flex-shrink: 0;
    width: 175px;
    background-color: #f8f8f8;
    border-right: 1px solid #d9d9d9;
    overflow-y: auto;

    .title {
      color: #0d1a26;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      padding: 5px 10px;
      margin-top: 8px;
      border-bottom: 1px solid #ddd;

      &:first-child {
        border-top: none;
      }
    }

    .buttons {
      padding: 10px 0;

      a {
        display: inline-block;
        color: #314659;
        line-height: 1;
        width: 40px;
        height: 40px;
        text-align: center;
        text-decoration: none !important;
        cursor: pointer;

        .iconfont {
          font-size: 24px;
        }

        &:hover {
          color: #1890ff;
        }
      }
    }
  }

  .full {
    flex: 1;
    width: initial;
    position: relative;
    overflow: auto;
    background: #fff;
  }

  .props {
    flex-shrink: 0;
    width: 240px;
    padding: 10px 0;
    background-color: #f8f8f8;
    border-left: 1px solid #d9d9d9;
    overflow-y: auto;
    position: relative;
  }

  .context-menu {
    position: fixed;
    z-index: 10;
  }
}
</style>
