<template>
  <a-radio-group v-if="tagType=='radio'" @change="handleInput" :value="getValueSting" :allowClear="allowClear"
                 :disabled="disabled">
    <a-radio v-for="(item, key) in dictOptions" :key="key" :value="item.value">{{ item.text }}</a-radio>
  </a-radio-group>

  <a-radio-group v-else-if="tagType=='radioButton'" buttonStyle="solid" @change="handleInput" :allowClear="allowClear"
                 :value="getValueSting" :disabled="disabled">
    <a-radio-button v-for="(item, key) in dictOptions" :key="key" :value="item.value">{{ item.text }}</a-radio-button>
  </a-radio-group>

  <div style="display: flex" v-else-if="tagType=='select'">
    <a-select :mode="selectMode"
              :show-search="openSearch" :option-filter-prop="openSearch?'children':undefined"
              :filter-option="openSearch?filterOption:undefined" :getPopupContainer="(target) => target.parentNode"
              :allowClear="allowClear" :placeholder="placeholder"
              :disabled="disabled" :value="getValueSting" @change="handleInput">
      <!--    如果不是搜索模式则保留原有多一个“请选择”选项-->
      <!--    <a-select-option v-if="!openSearch" :value="undefined">{{ $t('pleaseSelectHint') }}</a-select-option>-->
      <a-select-option v-for="(item, index) in realDictOptions" :key="item.title + '' + key" :value="item.value"
                       :labelT="item.text || item.label">
      <span style="display: inline-block;width: 100%" :title=" item.text || item.label ">
        {{ item.text || item.label }}
      </span>
      </a-select-option>
    </a-select>

    <a-button type="primary" style="margin-left: 5px" @click="editDict"
              v-if=" hashAuth(['dataDict:management:all',`dataDict:management:${this.dictCode}`]) && showManage">管理
    </a-button>
    <dict-item-list ref="dictItemList" @ok="handleOk" :filterItem="filterItem" :is-use-filter-item="isUseFilterItem" v-if="showManage"></dict-item-list>
  </div>

</template>

<script>
import {ajaxGetDictItems, getDictItemsFromCache} from 'dbcp-frontends-common/lib/api'
import { DictItemList } from "dbcp-frontends-common/lib/views";
import {hashAuth} from "dbcp-frontends-common/lib/utils";
import {getAction} from "dbcp-frontends-common/lib/api";

export default {
  name: "JDictSelectTag",
  components: {
    DictItemList
  },
  props: {
    dictCode: String,
    placeholder: String,
    triggerChange: Boolean,
    disabled: Boolean,
    value: {
      type: Array | String | Number
    },
    type: String,
    selectMode: {
      type: String,
      default: 'default'
    },
    allowClear: Boolean,
    //打开搜索功能(Jackie  2020-5-7 16:21:22   待全面测试)
    openSearch: Boolean,
    showManage: {
      type: Boolean,
      default: true
    },
    //  筛选某些字段
    filterItem: {
      type: Array,
      default: () => []
    },
    //   要这些筛选字段 还是不要
    isUseFilterItem:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dictOptions: [],
      tagType: "",
      hashAuth: hashAuth
    }
  },
  watch: {
    dictCode: {
      immediate: true,
      handler() {
        this.initDictData()
      },
    }
  },
  created() {
    console.log()
    // console.log(this.dictCode);
    if (!this.type || this.type === "list") {
      this.tagType = "select"
    } else {
      this.tagType = this.type
    }
    //获取字典数据
    // this.initDictData();
  },
  computed: {
    getValueSting() {
      console.log('this.value',this.value)
      if (this.selectMode === 'multiple') {
        if (this.value == '') {
          return undefined
        } else {
          console.log(this.value)
          return this.value ? this.value.split(',') : this.value
        }

      }
      return this.value != null ? this.value.toString() : null;
    },
    realDictOptions() {
      if (this.filterItem.length === 0) {
        return this.dictOptions
      } else {
        let arr = []
        this.dictOptions.forEach(filterItem => {
          if(this.isUseFilterItem){
            if (this.filterItem.includes(filterItem.value)) {
              arr.push(filterItem)
            }
          }else{
            if (!this.filterItem.includes(filterItem.value)) {
              arr.push(filterItem)
            }
          }

        })
        return arr
      }
    }
  },
  methods: {
    //是否需要请求
    initDictData(request = false) {

      //优先从缓存中读取字典配置
      if (getDictItemsFromCache(this.dictCode) && !request) {
        this.dictOptions = getDictItemsFromCache(this.dictCode);
        return
      }

      //根据字典Code, 初始化字典数组
      ajaxGetDictItems(this.dictCode, null).then((res) => {
        if (res.success) {
//                console.log(res.result);
          this.dictOptions = res.result;
        }
      })
    },
    handleInput(e,data) {
      let val;
      if (this.tagType == "radio") {
        val = e.target.value
      } else {
        val = e
      }
      if (this.triggerChange) {
        if (val) {
          this.$emit('change', typeof val == 'string' ? val : val.join(','), data.data.attrs.labelT);
        } else {
          this.$emit('change', '',data.data.attrs.labelT);
        }

      } else {
        this.$emit('input', typeof val == 'string' ? val : val.join(','));
      }
    },
    setCurrentDictOptions(dictOptions) {
      this.dictOptions = dictOptions
    },
    getCurrentDictOptions() {
      return this.dictOptions
    },
    filterOption(input, option) {
      // console.log("======111",option,option.data,option.data.attrs,option.data.attrs.labelT)
      return (
        (option.componentOptions.children[0].text || option.data.attrs.labelT).toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    //  管理字典
    async editDict() {

      this.$refs.dictItemList.edit({dictCode: this.dictCode});
    },
    //  操作成功
    handleOk() {
      this.initDictData(true)
    }
  }
}
</script>

<style scoped>
</style>