<template>
  <j-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('cancel')">
    <a-spin :spinning="confirmLoading">
      <a-table :columns="columns" :data-source="dataSource" bordered :customRow='exitCustomRow'>
        <template
          v-for="col in ['entry', 'beforeSigning', 'afterSigning','afterFetching']"
          :slot="col"
          slot-scope="text, record, index"
        >
          <div :key="col">
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="e => handleChange(e.target.value, record.key, col)"
            />
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>
        <span slot="operation" slot-scope="text, record, index" style='display: flex;justify-content: space-around'>
           <span v-if="record.editable">
             <a @click.stop="() => save(record.key)">保存</a>
           </span>
        <span v-if='index === dataSource.length - 1'>
          <a @click.stop='handleAdd(record.key)'>添加</a>
        </span>

                <a-popconfirm
                  title='确定删除?'
                  @confirm='() => onDelete(record.key)'
                  v-if='dataSource.length !== 1'
                >
                  <a>删除</a>
                </a-popconfirm>
        </span>
      </a-table>
    </a-spin>

    <template slot="footer">
    <!--      <a-button type="primary" @click="handleOk(false)">{{ $t('saveDraft') }}</a-button>-->
<!--      <a-button type="primary" @click="handleOk(true)">{{ $t('submit') }}</a-button>-->
      <a-button @click="handleCancel">{{ $t('cancel') }}</a-button>
    </template>
  </j-modal>
</template>

<script>
  import {  cumIncomeValueContrastApi } from './api/CumIncomeValueContrast'
  import { getAction, httpAction } from 'dbcp-frontends-common/lib/api'
  import pick from 'lodash.pick'
  import { validateDuplicateValue } from 'dbcp-frontends-common/lib/utils'
  import { Attachment } from 'dbcp-frontends-common/lib/components'
  import { UploadFormMixin } from 'dbcp-frontends-common/lib/mixins'
  import { postAction,deleteAction } from 'dbcp-frontends-common/lib/api'

  const columns = [
    {
      title: '条目',
      dataIndex: 'entry',
      width: '25%',
      scopedSlots: { customRender: 'entry' },
    },
    {
      title: '签约前',
      dataIndex: 'beforeSigning',
      width: '15%',
      scopedSlots: { customRender: 'beforeSigning' },
    },
    {
      title: '签约后',
      dataIndex: 'afterSigning',
      width: '40%',
      scopedSlots: { customRender: 'afterSigning' },
    },
    {
      title: '回迁后',
      dataIndex: 'afterFetching',
      width: '40%',
      scopedSlots: { customRender: 'afterFetching' },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width:'150px',
      align: 'center',
      scopedSlots: { customRender: 'operation' },
    },
  ];
  export default {
    name: "CumIncomeValueContrastModal",
    components: {
        Attachment,
    },
    mixins: [ UploadFormMixin ],
    data () {
      return {
        form: this.$form.createForm(this),
        title: "价值表",
        width: 1000,
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        url: cumIncomeValueContrastApi.url,
        dataSource:[{
          key: '0',
          entry: ``,
          beforeSigning: '',
          afterSigning:'',
          afterFetching:'' ,
          editable:true
        }],
        cacheData:[],
        columns,
        editingKey: '',
        currentProjectItemId:'',
        count:0
      }
    },
    watch:{
      dataSource:{
        deep:true,
        handler:function(newV){
          console.log('mewBV',newV)
        }
      }
    },
    created () {
     this.cacheData = this.dataSource.map(item => ({ ...item }))
    },
    methods: {
      show(id){
        this.visible = true
        this.currentProjectItemId = id
        getAction('/cum/cumIncomeValueContrast/list',{
          projectItemId:id
        }).then(res => {
          if(res.success){
            if(res.result.records.length > 0){
              this.dataSource = res.result.records
              this.dataSource.forEach((item,index) => {
                item.key = index
              })
            }else {

            }

            this.count = this.dataSource.length
          }
        })
      },
      exitCustomRow(record, index) {
        return {
          on: {
            click: event => {
                //当前点击行
                const newData = [...this.dataSource]
                for (let i = 0; i < newData.length; i++) {
                  if (index === i) {
                    newData[i].editable = true

                    this.dataSource = newData
                  } else {
                    newData[i].editable = false
                  }
                }
                console.log("dataSource click ",this.dataSource);
            }
          }

        }
      },
      afterClose () {
        this.dataSource = [{
          key: '0',
          entry: ``,
          beforeSigning: '',
          afterSigning:'',
          afterFetching:'' ,
          editable:true
        }];
      },
      popupCallback(row){
        this.form.setFieldsValue(pick(row,'projectItemId','entry','beforeSigning','afterSigning','afterFetching'))
      },
      handleChange(value, key, column) {
        const newData = [...this.dataSource];
        const target = newData.filter(item => key === item.key)[0];
        if (target) {
          target[column] = value;
          this.dataSource = newData;
        }
      },
      edit(key) {
        const newData = [...this.dataSource];
        const target = newData.filter(item => key === item.key)[0];
        this.editingKey = key;
        if (target) {
          target.editable = true;
          this.dataSource = newData;
        }
      },
      onDelete(key){
        const dataSource = [...this.dataSource]
        const target = dataSource.filter(item => key === item.key)[0];
        if(target.id){
          deleteAction('/cum/cumIncomeValueContrast/delete',{
            id:target.id
          }).then(res => {

          })
        }

        this.dataSource = dataSource.filter(item => item.key !== key)

      },
      handleAdd(key){
        const newData = [...this.dataSource];
        const target = newData.filter(item => key === item.key)[0];
        this.editingKey = key;
        if (target) {
          target.editable = false;
          this.dataSource = newData;
        }
        this.count++
        newData.push({
          key: this.count,
          projectItemId: this.currentProjectItemId,
          entry: '',
          afterSigning: '',
          afterFetching:'',
          editable:true
        })
        this.dataSource = newData
      },
      save(key){
        const newData = [...this.dataSource];
        const target = newData.filter(item => key === item.key)[0];
        console.log('target',target)
        let flag = true
        Object.keys(target).forEach(item => {
          if((typeof target[item] === 'string' && target[item].trim() === '')){
            flag = false
          }
        })
        if(!flag){
          this.$message.error('请填写完整信息')
          return
        }
        let formData = new FormData()
        Object.keys(target).forEach(item => {
          formData.append(item,target[item])
        })
        formData.append('projectItemId',this.currentProjectItemId)
        let url = ''
        if(target.id){
          url += this.url.edit
        }else{
          url += this.url.add
        }
        postAction(url,formData).then(res => {
          if(res.success){
            newData.forEach((item,index) => {
              if(item.key === key){
                item.editable = false
                this.dataSource = newData
              }
            })
          }
        })

        this.editingKey = '';
      },
      processFormData(formData,data){
        this.dataSource.forEach((item,index) => {
          Object.keys(item).forEach(obj => {
            formData.append(`test[${index}].${obj}`,item[obj])
          })
        })
        formData.set('projectItemId',this.currentProjectItemId)
        return formData
      }
    }
  }
</script>
